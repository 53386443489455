import React, { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faEuroSign,
  faInfoCircle,
  faShippingFast,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { RootState } from "../../store";
import {
  POINT_CLOUD_BILLED,
  POINT_CLOUD_CHECKED,
  POINT_CLOUD_DELIVERED,
  POINT_CLOUD_RECEIVE,
  TPointCloudStates,
  WORKFLOW_INFO,
} from "../../config/workflow";

import { WorkflowComment } from "../workflow/WorkflowComment";
import { useSavePointCloudState } from "../../hooks/pointcloud/useSavePointCloudState";
import { Button } from "../Button";

type TPointCloudWorkflowButtons = {
  orderId: number;
  setForce: () => void;
  currentState: TPointCloudStates | null;
} & ReturnType<typeof mapStateToProps>;

const Component: React.FC<TPointCloudWorkflowButtons> = ({
  orderId,
  currentState,
  companyType,
  setForce,
}) => {
  const [deliveredDisabled, setDeliveredDisabled] = useState(true);
  const [receiveDisabled, setReceiveDisabled] = useState(false);
  const [checkedDisabled, setCheckDisabled] = useState(false);
  const [billedDisabled, setBilledDisabled] = useState(false);
  const [commitState, setCommitState] = useState<TPointCloudStates | null>(
    null
  );
  const [requiredState, setRequiredState] = useState<TPointCloudStates | null>(
    null
  );
  const [comment, setComment] = useState<null | string>(null);
  const [commentOpen, setCommentOpen] = useState(false);
  const saving = useSavePointCloudState(orderId, commitState, comment);

  useEffect(() => {
    setDeliveredDisabled(true);
    setReceiveDisabled(true);
    setCheckDisabled(true);
    setBilledDisabled(true);

    switch (true) {
      case companyType === "smb" &&
        currentState === POINT_CLOUD_DELIVERED &&
        !saving:
      case companyType === "leitung" &&
        currentState === POINT_CLOUD_DELIVERED &&
        !saving:
        setReceiveDisabled(false);
        break;
      case companyType === "smb" &&
        currentState === POINT_CLOUD_RECEIVE &&
        !saving:
      case companyType === "leitung" &&
        currentState === POINT_CLOUD_RECEIVE &&
        !saving:
        setCheckDisabled(false);
        break;
      case companyType === "amt" &&
        currentState === POINT_CLOUD_CHECKED &&
        !saving:
        setBilledDisabled(false);
        break;
      case companyType === "firma" && currentState === null && !saving:
        setDeliveredDisabled(false);
        break;
    }
  }, [
    currentState,
    companyType,
    saving,
    setDeliveredDisabled,
    setReceiveDisabled,
    setCheckDisabled,
    setBilledDisabled,
  ]);

  useEffect(() => {
    if (saving === false) {
      setCommitState(null);
      setComment(null);
      setForce();
    }
  }, [saving, setCommitState, setComment, setForce]);

  const buttonClick = useCallback(
    (state: TPointCloudStates) => {
      setCommentOpen(true);
      setRequiredState(state);
    },
    [setCommentOpen, setRequiredState]
  );

  return (
    <>
      <WorkflowComment
        isOpen={commentOpen}
        setComment={setComment}
        onClose={() => {
          setCommentOpen(false);
          setCommitState(requiredState);
        }}
        onCancel={() => setCommentOpen(false)}
      />
      <div className="text-right mt-2">
        <Button
          buttonStyle="outline"
          className="mr-2"
          onClick={() => buttonClick(WORKFLOW_INFO)}
          disabled={!!saving}
        >
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          Bemerkung
        </Button>
        <Button
          buttonStyle="outline"
          className="mr-2"
          disabled={billedDisabled}
          onClick={() => buttonClick(POINT_CLOUD_BILLED)}
        >
          <FontAwesomeIcon icon={faEuroSign} className="mr-2" />
          Abgerechnet
        </Button>
        <Button
          intent="success"
          buttonStyle="outline"
          className="mr-2"
          disabled={checkedDisabled}
          onClick={() => buttonClick(POINT_CLOUD_CHECKED)}
        >
          <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
          Geprüft
        </Button>
        <Button
          buttonStyle="outline"
          className="mr-2"
          disabled={receiveDisabled}
          onClick={() => buttonClick(POINT_CLOUD_RECEIVE)}
        >
          <FontAwesomeIcon icon={faWarehouse} className="mr-2" />
          Empfangen
        </Button>
        <Button
          intent="success"
          buttonStyle="outline"
          disabled={deliveredDisabled}
          onClick={() => buttonClick(POINT_CLOUD_DELIVERED)}
        >
          <FontAwesomeIcon icon={faShippingFast} className="mr-2" />
          Geliefert
        </Button>
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyType: state.user.companyType,
});

export const PointCloudWorkflowButtons = connect(mapStateToProps)(Component);
