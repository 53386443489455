import * as React from "react";
import { Tooltip } from "../../Tooltip";

type TSidebarTooltipProps = {
  isOpen: boolean;
  tip: React.ReactNode;
};

export const SidebarTooltip: React.FunctionComponent<TSidebarTooltipProps> = ({
  isOpen,
  tip,
  children,
}) => {
  if (isOpen) {
    return <>{children}</>;
  }

  return (
    <Tooltip content={tip} position="right">
      {children}
    </Tooltip>
  );
};
