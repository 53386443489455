import React from "react";

export type TSmbToastContext = {
  addToast: (toast: TSmbToast) => void;
};

export type TSmbToast = {
  message: string | JSX.Element;
  intent: "success" | "warning" | "error";
  autoClose: number;
  id?: number;
};
export const SmbToastContext = React.createContext<Partial<TSmbToastContext>>(
  {}
);
