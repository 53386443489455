import moment from "moment";
import { bytesToSize } from "../../../helper/bytesTosize";
import { TFileResult } from "../../../types/files";
import { FileActions } from "./FileActions";
import { FileDescription } from "./FileDescription";
import { Tooltip } from "../../../Tooltip";
import { Icon } from "../../../components/Icon";

type TFileDetailProps = {
  force: () => void;
  setEditData: (id: number) => void;
} & TFileResult;

const dateFormat = "DD.MM.YYYY HH:mm";

export const FileDetail: React.FC<TFileDetailProps> = ({
  id,
  displayUser,
  fileName,
  size,
  updatedAt,
  createdAt,
  systemFile,
  info,
  forRole,
  force,
  setEditData,
}) => {
  return (
    <div className="border-b border-gray-800 py-1">
      <div className={`flex`}>
        <div className="w-8">{id}</div>
        <div className="w-8">
          <Tooltip
            className="cursor-help"
            content={
              displayUser === "YES"
                ? "Wird dem User in Downloads angezeigt"
                : "Ist nur für das System"
            }
          >
            <Icon
              icon={displayUser === "YES" ? "EYE_REGULAR" : "EYE_SLASH_REGULAR"}
            />
          </Tooltip>
        </div>
        <div className="flex-1">{fileName}</div>
        <div className="w-10">{forRole ? forRole.toUpperCase() : "ALLE"}</div>
        <div className="w-24">
          <Icon
            className=""
            icon={systemFile === "YES" ? "CHECK_SOLID" : "TIMES_SOLID"}
          />
        </div>
        <div className="w-24 text-right">{bytesToSize(size)}</div>
        <div className="w-32 text-right">
          {moment(createdAt).format(dateFormat)}
        </div>
        <div className="w-32 text-right">
          {moment(updatedAt).format(dateFormat)}
        </div>
        <div style={{ width: "180px" }} className="pl-2 flex">
          <FileActions
            force={force}
            id={id}
            systemFile={systemFile}
            setEditData={setEditData}
          />
        </div>
      </div>
      <FileDescription info={info} />
    </div>
  );
};
