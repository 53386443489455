import React, { useCallback, useEffect, useState } from "react";
import { ToolTip } from "../../../components/ToolTip";
import { useDeleteFile } from "../../../hooks/admin/useDeleteFile";
import { useDownload } from "../../../hooks/useDownload";
import { Button } from "../../../components/Button";

type TFileActionsProps = {
  id: number;
  systemFile: null | "YES" | "NO";
  setEditData: (id: number) => void;
  force: () => void;
};

export const FileActions: React.FC<TFileActionsProps> = ({
  systemFile,
  id,
  setEditData,
  force,
}) => {
  const [downloadId, setDownloadId] = useState<null | number>(null);
  const [deleteId, setDeleteId] = useState<null | number>(null);
  const success = useDeleteFile(deleteId);
  const progress = useDownload(downloadId);

  useEffect(() => {
    if (success) {
      force();
    }
  }, [success, force]);

  const onDelete = useCallback(
    (did: number) => {
      if (window.confirm("Datei wirklich löschen") && systemFile !== "YES") {
        setDeleteId(did);
      }
    },
    [setDeleteId, systemFile]
  );

  useEffect(() => {
    if (progress && downloadId) {
      setDownloadId(null);
    }
  }, [downloadId, setDownloadId, progress]);

  return (
    <>
      {systemFile === "YES" ? (
        ""
      ) : (
        <div className="pl-2">
          <Button
            intent="danger"
            icon="TRASH_ALT_REGULAR"
            onClick={() => onDelete(id)}
          />
        </div>
      )}
      <div className="pl-2">
        <ToolTip content="Datei Info bearbeiten">
          <Button
            icon="PENCIL_ALT_SOLID"
            intent="success"
            onClick={() => setEditData(id)}
          />
        </ToolTip>
      </div>
      <div className="pl-2">
        <ToolTip content="Datei herunterladen">
          <Button
            onClick={() => setDownloadId(id)}
            icon="CLOUD_DOWNLOAD_ALT_SOLID"
          />
        </ToolTip>
      </div>
    </>
  );
};
