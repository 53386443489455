import React, { useEffect, useState } from "react";
import { TMoSyncImportData } from "./hooks/useMoSyncImporter";
import {
  leadingZeroFormatter,
  numberFormatter,
} from "../../../helper/formatter";
import moment from "moment-business-days";
import { useVerifyBuilding } from "../../../hooks/verify/useVerifyBuilding";
import { useVerifyMeasureOrder } from "../../../hooks/verify/useVerifyMeasureOrder";
import { useVerifyCompany } from "../../../hooks/verify/useVerifyCompany";
import { useVerifyMoSyncImport } from "../../../hooks/verify/useVerifyMoSyncImport";

type TDataRowProps = {
  isImportable: boolean;
  isChecked: boolean;
  setImportable: (path: string, remove: boolean) => void;
  setChecked: (checked: boolean, path: string) => void;
  force?: number;
} & TMoSyncImportData;

export const DataRow: React.FC<TDataRowProps> = ({
  isChecked,
  isImportable,
  path,
  economicUnit,
  buildingNumber,
  description,
  orderNumber,
  company,
  los,
  nrfChecked,
  dateMoSync,
  neZuweisung,
  dwgArchiv,
  dwgArchivUpload,
  setImportable,
  setChecked,
  force,
}) => {
  const { buildingId, buildingExists } = useVerifyBuilding(path);
  const [comments, setComments] = useState<
    Record<string, string | JSX.Element>
  >({});

  const [classNames, setClassNames] = useState<string>("bg-green-200");
  const serverData = useVerifyMoSyncImport(path, force);
  const isOrderValid = useVerifyMeasureOrder(path);
  const isCompanyValid = useVerifyCompany(company);
  // Statusmeldung bzw link für das Gebäude
  useEffect(() => {
    if (buildingExists === null || Object.keys(comments).includes("building")) {
      return;
    }

    let tmp: string | JSX.Element = "Kein Gebäude gefunden";
    if (buildingExists) {
      tmp = (
        <a
          href={`/building/${buildingId}/measure/`}
          className="smb-link"
          target={"_blank"}
          rel={"noreferrer"}
        >
          zum Gebäude
        </a>
      );
    }

    setComments({ ...comments, building: tmp });
  }, [buildingId, buildingExists, comments, setComments]);

  useEffect(() => {
    if (isOrderValid === null || isOrderValid) {
      return;
    }

    setClassNames("bg-red-500");
  }, [isOrderValid, setClassNames]);

  useEffect(() => {
    if (isCompanyValid === null || isCompanyValid) {
      return;
    }

    setClassNames("bg-red-500");
  }, [isCompanyValid, setClassNames]);

  // Verifizieren ob, der Datensatz zum import geeignet ist
  useEffect(() => {
    if (!path || !serverData) {
      return;
    }

    if (
      (dateMoSync ?? "") !== (serverData.moSync ?? "") ||
      neZuweisung !== serverData.neAllocation ||
      dwgArchiv !== serverData.dwgArchive ||
      (dwgArchivUpload ?? "") !== (serverData.dwgSyncDate ?? "")
    ) {
      setImportable(path, false);
    }
  }, [
    isImportable,
    path,
    dateMoSync,
    neZuweisung,
    dwgArchiv,
    dwgArchivUpload,
    serverData,
    setImportable,
  ]);

  if (buildingExists === null || serverData === null) {
    return null;
  }

  return (
    <tr className={classNames}>
      <td className="border border-border p-1">
        <input
          checked={isChecked}
          type={"checkbox"}
          disabled={!isImportable}
          className={isImportable ? "" : "opacity-20"}
          onChange={(e) => setChecked(e.target.checked, path ?? "")}
        />
      </td>
      <td className="border border-border p-1">{path}</td>
      <td className="border border-border p-1">
        {leadingZeroFormatter(economicUnit)}
      </td>
      <td className="border border-border p-1">
        {leadingZeroFormatter(buildingNumber)}
      </td>
      <td className="border border-border p-1">{description}</td>
      <td className="border border-border p-1">{orderNumber}</td>
      <td
        className={`border border-border p-1${
          !isCompanyValid ? " bg-red-800" : ""
        }`}
      >
        {company}
      </td>
      <td className="border border-border p-1">{los}</td>
      <td
        className={`border border-border p-1 text-right${
          nrfChecked !== serverData.nrf ? " bg-warning" : ""
        }`}
      >
        <div>{numberFormatter(nrfChecked)}</div>
        <div>{numberFormatter(serverData.nrf)}</div>
      </td>
      <td
        className={`border border-border p-1${
          (dateMoSync ?? "") === (serverData.moSync ?? "")
            ? " bg-green-800"
            : ""
        }`}
      >
        <div>{dateMoSync ? moment(dateMoSync).format("DD.MM.YYYY") : ""}</div>
        <div>
          {serverData.moSync
            ? moment(serverData.moSync).format("DD.MM.YYYY")
            : ""}
        </div>
      </td>
      <td
        className={`border border-border p-1 ${
          neZuweisung === serverData.neAllocation ? " bg-green-800" : ""
        }`}
      >
        <div>{neZuweisung}</div>
        <div>{serverData.neAllocation}</div>
      </td>
      <td
        className={`border border-border p-1${
          dwgArchiv === serverData.dwgArchive ? " bg-green-800" : ""
        }`}
      >
        <div>{dwgArchiv}</div>
        <div>{serverData.dwgArchive}</div>
      </td>
      <td
        className={`border border-border p-1${
          (dwgArchivUpload ?? "") === (serverData.dwgSyncDate ?? "")
            ? " bg-green-800"
            : ""
        }`}
      >
        <div>
          {dwgArchivUpload ? moment(dwgArchivUpload).format("DD.MM.YYYY") : ""}
        </div>
        <div>
          {serverData.dwgSyncDate
            ? moment(serverData.dwgSyncDate).format("DD.MM.YYYY")
            : ""}
        </div>
      </td>
      <td className="border border-border p-1">
        {Object.values(comments).map((v, i) => (
          <div key={`comment-${i}`}>{v}</div>
        ))}
      </td>
    </tr>
  );
};
