import React from "react";

type TNoResultProps = {
  count: number | null;
};

export const NoResult: React.FunctionComponent<TNoResultProps> = ({
  count,
}) => {
  if (count !== null) {
    return null;
  }

  return (
    <div
      className="absolute border border-border p-8 z-10 bg-warning rounded text-xl font-extrabold"
      style={{ top: "200px", left: "50%" }}
    >
      Ihre Anfrage führte zu keinem Ergebnis
    </div>
  );
};
