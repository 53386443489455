import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store";
import { Popover } from "../../Popover";
import { Spinner } from "../../Spinner";

type TAjaxProgressProps = ReturnType<typeof mapStateToProps>;

const AjaxProgress: React.FunctionComponent<TAjaxProgressProps> = ({
  isOpen,
  loadingText,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Popover>
      <div className="loading-spinner-wrapper">
        <Spinner size="xlarge" />
        <div className="spinner-message">{loadingText}</div>
      </div>
    </Popover>
  );
};

const mapStateToProps = (state: RootState) => ({
  isOpen: state.app.ajaxProgress,
  loadingText: state.app.loadingText,
});

export default connect(mapStateToProps)(AjaxProgress);
