import { useContext, useEffect, useState } from "react";
import { TMeasureDetailResponse } from "../../store/editOrder/types";
import { STATUS_CODES } from "../../config/app";
import axios from "axios";
import { SmbToastContext } from "../../context/SmbTabsContext";

export const useGetEditData = (id?: number, force?: number) => {
  const [data, setData] = useState<TMeasureDetailResponse | null>(null);

  const { addToast } = useContext(SmbToastContext);

  useEffect(() => {
    if (!id) {
      return;
    }

    const process = async () => {
      try {
        const response = await axios.get<TMeasureDetailResponse>(
          `/measure/detail/${id}/`
        );

        if (response.status === STATUS_CODES.No_Content) {
          return;
        }
        setData(response.data);
      } catch (err) {
        if (
          axios.isAxiosError(err) &&
          err.response?.status === STATUS_CODES.Server_Error &&
          addToast
        ) {
          // addToast({
          //   message: "Fehler beim laden der Daten für den Auftrag",
          //   intent: "error",
          //   autoClose: 0,
          // });
        }
      }
    };

    process();
  }, [id, force, setData, addToast]);

  return data;
};
