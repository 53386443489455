import { Action } from "redux";
import { ITableHeader } from "../../config/dataTable";
import { TFilterActions } from "../../config/quickFilter";
import { TMeasureDetailResponse } from "../editOrder/types";
import {
  TDetailClosed,
  TFilterOperator,
  TFilterSettings,
  TSettingsState,
} from "./settingsReducer";

export const SETTINGS_HOME_TIP = "settings/HOME_TIP";
export const SETTINGS_HOME_DATA = "settings/HOME_DATA";
export const SETTINGS_LIMIT_DATA_TABLE = "settings/LIMIT_DATA_TABLE";
export const SETTINGS_RESTORE = "settings/RESTORE";
export const SETTINGS_FILTER = "settings/FILTER";
export const SETTINGS_FILTER_ORDER = "settings/Filter/order";
export const SETTINGS_DETAIL_OPEN = "settings/OPEN_DETAIL";
export const SETTINGS_DETAIL_CLOSE = "settings/CLOSE_DETAIL";
export const SETTINGS_DETAIL_TABLE_PAGE = "settings/DETAIL_TABLE_PAGE";
export const SETTINGS_DETAIL_TABLE_CELL_VISIBILITY =
  "settings/DETAIL_TABLE_CELL_VISIBILITY";

interface IRestoreSettings extends Action<typeof SETTINGS_RESTORE> {
  payload: TSettingsState;
}

export type TSetFilterOrder = {
  order: TFilterSettings["order"];
  orderField: TFilterSettings["orderField"];
};

export type IDataTableSetFilter = {
  filterField: keyof TMeasureDetailResponse;
  filterTerm: string | number;
  operator: TFilterOperator;
};

interface ISetFilter extends Action<typeof SETTINGS_FILTER> {
  payload: TFilterSettings;
}

interface ISetFilterOrder extends Action<typeof SETTINGS_FILTER_ORDER> {
  payload: TSetFilterOrder;
}

interface ISettingsHomeTip extends Action<typeof SETTINGS_HOME_TIP> {
  payload: boolean;
}

interface ISettingsHomeData extends Action<typeof SETTINGS_HOME_DATA> {
  payload: TFilterActions;
}

interface ISetDetailClose extends Action<typeof SETTINGS_DETAIL_CLOSE> {
  payload: TDetailClosed;
}

interface ISetDetailOpen extends Action<typeof SETTINGS_DETAIL_OPEN> {
  payload: TDetailClosed;
}

interface ISettingsDataTableLimit
  extends Action<typeof SETTINGS_LIMIT_DATA_TABLE> {
  payload: number;
}

interface ISettingsDetailTablePage
  extends Action<typeof SETTINGS_DETAIL_TABLE_PAGE> {
  payload: number;
}

interface ISettingsDetailTableCellVisibility
  extends Action<typeof SETTINGS_DETAIL_TABLE_CELL_VISIBILITY> {
  payload: ITableHeader["dataIndex"];
}

export type TSettingActions =
  | ISetDetailOpen
  | ISetDetailClose
  | ISettingsHomeTip
  | ISettingsHomeData
  | IRestoreSettings
  | ISetFilter
  | ISetFilterOrder
  | ISettingsDetailTablePage
  | ISettingsDetailTableCellVisibility
  | ISettingsDataTableLimit;
