import React, { useEffect, useState } from "react";
import zxcvbn from "zxcvbn";

const defaultColors: string[][] = [
  ["white", "white"],
  ["rgba(253,29,29,1)", "rgba(253,29,29,1)"],
  ["red 60%", "orange 90%"],
  [
    "rgba(253,29,29,1) 43%",
    "rgba(252,176,69,1) 77%",
    "rgba(122,252,69,1) 100%",
  ],
  ["rgba(252,176,69,1) 29%", "rgba(4,187,13,1) 88%"],
  ["rgba(8,244,19,1) 39%", "rgba(15,142,21,1) 66%"],
];

const defaultValidScore = 2;
type IPasswordStrength = {
  value: string;
  colors?: [string[]];
  score?: number;
  setExists?: (isValid: boolean) => void;
};
const PasswordStrength: React.FunctionComponent<IPasswordStrength> = ({
  value,
  colors = defaultColors,
  score = defaultValidScore,
  setExists,
}) => {
  const [strength, setStrength] = useState<zxcvbn.ZXCVBNResult>();
  if (value !== "") {
    const secure = zxcvbn(value);
    if (secure.score !== strength?.score) {
      setStrength(secure);
    }
  }

  useEffect(() => {
    if (strength && setExists) {
      setExists(strength?.score ?? 0 >= score ? true : false);
    }
  }, [strength, setExists, value, score]);

  const styleColor = () => {
    if (!strength) {
      return colors[0].join(",");
    }
    return colors[strength.score + 1].join(",");
  };

  return (
    <>
      <div
        style={{
          height: "5px",
          backgroundImage: `linear-gradient(90deg, ${styleColor()})`,
        }}
      ></div>
    </>
  );
};

export default PasswordStrength;
