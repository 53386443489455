import { RouteComponentProps } from "@reach/router";
import moment, { Moment } from "moment";
import React from "react";
import { connect } from "react-redux";
import { DidYouKnow } from "../../components/DidYouKnow";
import { Help } from "../../components/Help";
import ProgressChart from "../../components/ProgressChart";
import Tile from "../../components/Tile";
import { LastEdit } from "../../components/widgets/LastEdit/LastEdit";
import { MeasureOrder } from "../../components/widgets/MeasureOrder/MeasureOrder";
import { RootState } from "../../store";

export type THomeProps = ReturnType<typeof mapStateToProps> &
  RouteComponentProps;

const Home: React.FunctionComponent<THomeProps> = (props): JSX.Element => {
  if (props.email === null) {
    return <></>;
  }

  let lastLogin!: Moment;
  if (props.lastLogin) {
    lastLogin = moment.unix(props.lastLogin);
  } else {
    lastLogin = moment();
  }

  const gridSize = window.innerWidth < 1280 ? 2 : 3;

  return (
    <div>
      <h1>
        Willkommen {props.firstname} {props.lastname}
      </h1>

      <h2>Ihr letzter login war am: {lastLogin.format("LLL")}</h2>

      <div className={`grid grid-cols-${gridSize} gap-2`}>
        <LastEdit companyType={props.companyType} />
        <div>
          <Tile
            title={
              <>
                Status Nach/Neuaufmaß
                <Help text="Überblick zu dem Stand des Auftrages Neuaufmass/Nachaufmass" />
              </>
            }
          >
            <MeasureOrder companyType={props.companyType} />
          </Tile>
        </div>
        <DidYouKnow />
        <div>
          <Tile title="Nach/Neuaufmaß Plausibilitätsprüfung Fortschritt">
            <ProgressChart type="pt" />
          </Tile>
        </div>
        <div>
          <Tile title="Nach/Neuaufmaß Qualitätsprüfung Fortschritt">
            <ProgressChart type="qs" />
          </Tile>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  ...state.user,
});

export const HomePage = connect(mapStateToProps)(Home);
