import React from "react";

type TImporterTableHeadCell = {
  headline: string;
};

export const ImporterTableHeadCell: React.FC<TImporterTableHeadCell> = ({
  headline,
}) => {
  return <th className="border border-border p-1">{headline}</th>;
};
