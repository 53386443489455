import { useEffect, useState } from "react";
import { bytesToSize } from "../../helper/bytesTosize";
import { dateFormatter } from "../../helper/formatter";
import { useDownload } from "../../hooks/useDownload";
import { TFileResult } from "../../types/files";
import { Button } from "../Button";

export const DownloadRow: React.FC<TFileResult> = ({
  fileName,
  size,
  updatedAt,
  info,
  id,
}) => {
  const [download, setDownload] = useState<null | number>(null);
  const progress = useDownload(download);

  useEffect(() => {
    if (download && progress) {
      setDownload(null);
    }
  }, [progress, download, setDownload]);

  return (
    <div className="py-2">
      <div className="flex">
        <div className="px-2 flex-1">{fileName}</div>
        <div className="px-2 w-28 text-right">{bytesToSize(size)}</div>
        <div className="px-2 w-28 text-right">{dateFormatter(updatedAt)}</div>
        <div style={{ width: "160px" }} className="text-right">
          <Button
            icon="CLOUD_DOWNLOAD_ALT_SOLID"
            buttonStyle="elevated"
            onClick={() => setDownload(id)}
            text="Herunterladen"
            disabled={progress}
          />
        </div>
      </div>
      <div className="px-2 text-xs">{info}</div>
    </div>
  );
};
