import {
  IDataTableConfig,
  TDataTableFormatterTypes,
} from "../components/DataTable/DataTable";
import { TBuildingListResponse } from "../hooks/useBuildingsList";

export interface ITableHeader extends IDataTableConfig {
  dataIndex: keyof TBuildingListResponse;
}

export type IFormatterTypes = TDataTableFormatterTypes;

export type IFilterInputTypes = "number" | "fromToDate" | "text";

export const TABLE_LIMIT_CONF: number[] = [10, 20, 30, 40, 50, 75, 100];

export const LOCAL_STORAGE_LIMIT = "dora_building_table";
export const LOCAL_STORAGE_ORDER_FIELD = "dora_building_order";
export const LOCAL_STORAGE_ORDER_DIRECTION = "dora-building_order";
export const LOCAL_STORAGE_PAGE = "dora_building_page";
export const LOCAL_STORAGE_HIDDEN_COLUMNS =
  "dora_building_tabler_hidden_columns";

export const LOCAL_STORAGE_SEARCH_TERM = "dora_building_search_term";

export const TABLE_CONFIG: ITableHeader[] = [
  {
    name: "Pfad",
    dataIndex: "path",
    sortAble: true,
  },

  {
    name: "WE-Nr",
    formatter: "LeadingZero",
    dataIndex: "economicUnit",
    sortAble: true,
    size: 90,
  },
  {
    name: "Geb-Nr",
    formatter: "LeadingZero",
    dataIndex: "buildingNumber",
    sortAble: true,
    size: 90,
  },
  {
    name: "Bezeichnung",
    dataIndex: "designation",
    sortAble: true,
  },
  {
    name: "Amt",
    dataIndex: "office",
    sortAble: true,
    size: 60,
  },
  {
    name: "Adresse",
    dataIndex: "address",
    sortAble: true,
  },
  {
    name: "zuständige Abteilung",
    dataIndex: "responsibleDepartment",
    sortAble: true,
  },
  {
    name: "Aufträge",
    dataIndex: "orders",
    sortAble: false,
    formatter: "orderTypes",
    size: 90,
  },
  {
    name: "Letzte Bearbeitung",
    formatter: "Datetime",
    dataIndex: "updated",
    sortAble: true,
    size: 150,
  },
];
