import * as React from "react";

type TBackdropProps = {
  closeOnClick: boolean;
  backdrop: boolean;
  onClick?: () => void;
};

export const Backdrop: React.FunctionComponent<TBackdropProps> = ({
  children,
  closeOnClick,
  backdrop,
  onClick,
}) => {
  if (!backdrop) {
    return <>{children}</>;
  }

  return (
    <div
      className="backdrop-filter backdrop-blur-sm inset-0 absolute z-10"
      onClick={() => {
        if (onClick && closeOnClick) {
          onClick();
        }
      }}
    >
      {children}
    </div>
  );
};
