import * as React from "react";

export const useAnimatedWidth = (
  ref: React.RefObject<HTMLElement>,
  open: boolean | null,
  openWidth: number | string,
  closeWidth: number | string
) => {
  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    let w = openWidth;
    if (!open) {
      w = closeWidth;
    }
    if (typeof w === "number") {
      w = `${w}px`;
    }

    ref.current.style.width = w;
  }, [open, ref, closeWidth, openWidth]);
};
