import { TFileResult } from "../../../types/files";
import { FileDetail } from "./FileDetail";

type TFileTableProps = {
  files: TFileResult[];
  force: () => void;
  setEditData: (id: number) => void;
};

export const FileTable: React.FC<TFileTableProps> = ({
  files,
  setEditData,
  force,
}) => {
  if (!files.length) {
    return <h1 className="text-center">Keine Files im System</h1>;
  }

  return (
    <>
      <h1>FileAdmin</h1>
      <div className="flex font-bold text-center">
        <div className="w-8">ID</div>
        <div className="w-8">&nbsp;</div>
        <div className="flex-1">Dateiname</div>
        <div className="w-10">Für</div>
        <div className="w-24">Systemfile</div>
        <div className="w-24">Größe</div>
        <div className="w-32">Erstellt</div>
        <div className="w-32">Geändert</div>
        <div style={{ width: "180px" }}>&nbsp;</div>
      </div>
      {files.map((file, i) => (
        <FileDetail
          {...file}
          key={`admin-file-detail-${i}`}
          force={force}
          setEditData={setEditData}
        />
      ))}
    </>
  );
};
