import Axios from "axios";
import { useEffect, useState } from "react";
import { TCompany } from "../types/types";
import { LOCAL_STORAGE_SEARCH_TERM } from "../config/dataTable";

export type TBuildingListResponse = {
  id: number;
  path: string;
  economicUnit: number;
  buildingNumber: number;
  office: string;
  designation: string;
  responsibleDepartment: null | string;
  address: null | string;
  updated: string;
  orders: Record<string, boolean>;
};

export const useBuildingsList = (
  companyType: TCompany | null,
  limit: number,
  currentPage: number,
  orderField: string,
  direction: string,
  searchTerm = "",
  force?: number
) => {
  const [response, setResponse] = useState<TBuildingListResponse[]>([]);
  const [count, setCount] = useState<number | null>(0);

  useEffect(() => {
    let term = searchTerm;
    if (localStorage.getItem(LOCAL_STORAGE_SEARCH_TERM) !== searchTerm) {
      term = localStorage.getItem(LOCAL_STORAGE_SEARCH_TERM) ?? "";
    }

    const process = async () => {
      const res = await Axios.get<{
        result: TBuildingListResponse[];
        count: number;
      }>(
        `/building/list/${companyType}/${limit}/${currentPage}/${orderField}/${direction}/`,
        {
          params: { term: term ? term : undefined },
        }
      );

      let rCount = null;
      if (res.data.count) {
        rCount = res.data.count;
      }
      setResponse(res.data.result);
      setCount(rCount);
    };

    if (
      limit &&
      orderField !== "" &&
      direction !== "" &&
      companyType !== null
    ) {
      setResponse([]);
      process();
    }
  }, [
    companyType,
    limit,
    currentPage,
    orderField,
    direction,
    force,
    searchTerm,
  ]);

  return { data: response, count };
};
