import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { TSystemUser } from "../../hooks/useGetUsers";
import { InputCheckbox } from "../InputCheckbox/InputCheckbox";
import { Modal } from "../Modal";
import { Button } from "../Button";
import { Spinner } from "../Spinner";

type TUserDetailModalProps = {
  userDetail: TSystemUser | null;
  onClose: () => void;
  setForce: () => void;
};

type TRowe = {
  label: string;
  value: string;
  isVisible?: boolean;
};
const Row: React.FC<TRowe> = ({ label, value, isVisible = true }) => {
  if (!isVisible) {
    return null;
  }
  return (
    <div className="flex">
      <div style={{ width: "120px" }}>{label}:</div>
      <div className="flex-1">{value}</div>
    </div>
  );
};

export const UserDetailModal: React.FC<TUserDetailModalProps> = ({
  userDetail,
  onClose,
  setForce,
}) => {
  const [progress, setProgress] = useState(false);
  const [progressMessage, setProgressMessage] = useState("");

  const [hasReminder, setReminder] = useState(false);

  useEffect(() => {
    if (!userDetail || userDetail.companyType !== "amt") {
      return;
    }

    setReminder(userDetail.workflowReminder === "YES");
  }, [userDetail, setReminder]);

  const onReset = useCallback(async () => {
    if (!userDetail) {
      return;
    }
    setProgress(true);
    setProgressMessage("Setze neues Passwort für Benutzer");

    await Axios.patch(`/admin/user/`, { id: userDetail.id });
    setProgressMessage("");
    setProgress(false);
    onClose();
  }, [userDetail, setProgress, setProgressMessage, onClose]);

  const onSave = useCallback(async () => {
    if (!userDetail) {
      return;
    }
    setProgress(true);
    setProgressMessage("Speichere Email Einstellungen");

    await Axios.post(`/admin/user/reminders/${userDetail.id}/`, {
      officeReminder: hasReminder,
    });
    setProgressMessage("");
    setProgress(false);
    onClose();
    setForce();
  }, [
    userDetail,
    hasReminder,
    onClose,
    setForce,
    setProgressMessage,
    setProgress,
  ]);

  const onDelete = useCallback(async () => {
    if (!userDetail) {
      return;
    }

    setProgress(true);
    setProgressMessage("Lösche Benutzer");

    await Axios.delete(`/admin/user/${userDetail.id}`);
    setProgressMessage("");
    setProgress(false);
    onClose();
    setForce();
  }, [userDetail, setProgress, setProgressMessage, onClose, setForce]);

  if (userDetail === null) {
    return null;
  }

  const { email, firstname, lastname, companyName, companyType } = userDetail;

  return (
    <Modal
      closeOnEscape={true}
      width="600px"
      top="200px"
      isOpen={true}
      onClose={onClose}
    >
      <div className={`${progress ? "hidden" : ""}`}>
        Benutzer Details:
        <Row label="Vorname" value={firstname} />
        <Row label="Nachname" value={lastname} />
        <Row label="Email" value={email} />
        <Row label="Firma" value={companyName} />
        <div className={`flex${companyType === "amt" ? "" : " hidden"}`}>
          <div style={{ width: "120px" }}>Reminder Email</div>
          <InputCheckbox
            value={hasReminder}
            canEdit={true}
            onChange={setReminder}
          />
        </div>
        <div className="flex justify-end mt-3">
          <Button intent="danger" text="Benutzer löschen" onClick={onDelete} />
          <Button
            className={"ml-2"}
            intent="warning"
            text="Passwort zurücksetzen"
            onClick={onReset}
          />
          <Button
            intent="primary"
            text="Abbrechen"
            onClick={onClose}
            className={"ml-2"}
          />
          <Button
            className={companyType === "amt" ? "ml-2" : " hidden"}
            intent="success"
            text="Speichern"
            onClick={onSave}
          />
        </div>
      </div>
      <div className={`${progress ? "" : "hidden"}`}>
        <div className="relative mb-1" style={{ height: "100px" }}>
          <Spinner center={true} />
        </div>
        <div className="text-center">{progressMessage}</div>
      </div>
    </Modal>
  );
};
