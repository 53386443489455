import React, { useState } from "react";
import CreateToken from "./CreateToken";
import DisplayToken from "./DisplayToken";
import { Modal } from "../../../components/Modal";

type MyProps = {
  isOpen: boolean;
  onClose: () => void;
};

const TokenModal: React.FunctionComponent<MyProps> = ({ isOpen, onClose }) => {
  const [token, setToken] = useState<null | string>(null);

  if (isOpen === false) return <></>;

  return (
    <Modal
      width="700px"
      isOpen={isOpen}
      onClose={onClose}
      title="Neuen Token erzeigen"
    >
      <div className="">
        <CreateToken
          onClose={onClose}
          token={token}
          setResponseToken={(t: string) => setToken(t)}
        />
        <DisplayToken
          token={token}
          onClose={() => {
            setToken(null);
            onClose();
          }}
        />
      </div>
    </Modal>
  );
};

export default TokenModal;
