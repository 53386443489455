import React, { useCallback, useState } from "react";

type TNumberInput = {
  value: number | string;
  onChange: (v: number) => void;
  readonlyValue: string;
  digits?: number;
  canEdit?: boolean;
};

export const InputNumber: React.FunctionComponent<TNumberInput> = ({
  value,
  digits = 2,
  readonlyValue,
  canEdit = true,
  onChange,
}) => {
  const [isNumber, setIsNumber] = useState(true);
  const [val, setVal] = useState(
    Number(value).toLocaleString("de-De", {
      minimumFractionDigits: digits,
      maximumFractionDigits: 20,
    })
  );

  const onValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const currentVal = e.target.value;
      if (isNaN(Number(currentVal.replace(".", "").replace(",", ".")))) {
        setIsNumber(false);
      } else {
        setIsNumber(true);
      }

      setVal(currentVal);
    },
    [setVal, setIsNumber]
  );

  const onBlur = useCallback(() => {
    if (!isNumber) {
      return;
    }
    onChange(Number(val.replace(".", "").replace(",", ".")));
  }, [val, isNumber, onChange]);

  if (!canEdit) {
    return <>{readonlyValue}</>;
  }

  return (
    <>
      <input type="text" value={val} onChange={onValueChange} onBlur={onBlur} />
      <div className={`text-danger text-xs${isNumber ? " hidden" : ""}`}>
        Der eingegebene Wert ist keine Zahl
      </div>
    </>
  );
};
