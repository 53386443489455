export const ROUTE_INDEX = "/home";
export const ROUTE_BUILDING_LIST = "/buildings/";
export const ROUTE_NRF_EDIT_DETAILS = "/nrf-edit-details/";
export const ROUTE_LOGOUT = "/logout/";
export const ROUTE_LOGIN = "/login/";
export const ROUTE_DOWNLOADS = "/downloads/";
export const ROUTE_BOARDS = "/board";
export const ROUTE_MEASURE_BOARD = `/measure/`;
export const ROUTE_POINT_CLOUD_BOARD = `/point-cloud/`;
// Account Routes
export const ROUTE_ACCOUNT = "/my-account";
export const ROUTE_ACCOUNT_SETTINGS = "/my-settings/";
export const ROUTE_UI_SETTINGS = "/ui-settings/";
export const ROUTE_CHANGELOG = "/changelog/";
export const ROUTE_ABOUT = "/about/";

// Admin Routes
export const ROUTE_ADMIN = "/admin";
export const ROUTE_CSV_IMPORT = "/csv-import/";
export const ROUTE_USER_MANAGEMENT = "/benutzer/";
export const ROUTE_REPORTS = "/reports/";
export const ROUTE_TOKEN_MANAGEMENT = "/token/";
export const ROUTE_FILE_ADMIN = "/files/";
export const ROUTE_MIGRATE_ADMIN = "/migrate/";
export const ROUTE_VACATION_ADMIN = "/vacation/";

// SMB only Routes
export const ROUTE_SMB = "/smb";
export const ROUTE_SMB_BOARD = "/board/editor/";

export const ROUTE_BUILDING = "/building/";
