import * as React from "react";
import { TNavigationMobileWrappper } from "./types";

export const NavigationMobileWrapper: React.FunctionComponent<
  TNavigationMobileWrappper
> = ({ children, isOpen }) => {
  return (
    <div
      className={`z-50 absolute top-0 bottom-0 left-0 backdrop-filter backdrop-grayscale backdrop-blur-sm${
        isOpen ? " w-full" : ""
      }`}
    >
      {children}
    </div>
  );
};
