import {
  faExclamationTriangle,
  faInfoCircle,
  faLock,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import React, { useCallback, useContext, useState } from "react";
import {
  MEASURE_STATE_DECLINED,
  WORKFLOW_INFO,
  MEASURE_STATE_PROCESS,
  MEASURE_STATE_PTS_UPLOAD,
  MEASURE_STATE_SUCCESS,
  TMeasureQualityStates,
} from "../../config/workflow";
import { TWorkflowType } from "../../hooks/useLastHistoryStatus";
import { TCompanyType } from "../../store/user/types";
import { CommentModal } from "./CommentModal/CommentModal";
import { WorkflowButton } from "./WorkflowButton/WorkflowButton";
import { SmbToastContext, TSmbToast } from "../../context/SmbTabsContext";

type TPlausibilityButtonsProps = {
  workflowType: TWorkflowType;
  lastState: TMeasureQualityStates;
  companyType?: TCompanyType;
  buildingId: number;
  forceFetch?: () => void;
};

export const PlausibilityButtons: React.FC<TPlausibilityButtonsProps> = ({
  workflowType,
  lastState,
  companyType,
  buildingId,
  forceFetch,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [workflowState, setWorkflowState] = useState<TMeasureQualityStates>();
  const { addToast } = useContext(SmbToastContext);

  const onSubmit = useCallback(
    async (state: TMeasureQualityStates, comment = "") => {
      if (companyType !== "amt") {
        return;
      }
      let message: TSmbToast = {
        message: "Status erfolgreich geändert",
        intent: "success",
        autoClose: 3000,
      };

      try {
        await Axios.post(`/workflow/measure/plausiCheck/${buildingId}/`, {
          workflowState: state,
          comment,
        });
        if (forceFetch) {
          forceFetch();
        }
      } catch (e) {
        message = {
          message: "Fehler beim setzen des Status",
          intent: "error",
          autoClose: 0,
        };
      }
      if (addToast) {
        addToast(message);
      }
      setWorkflowState(undefined);
    },
    [companyType, buildingId, forceFetch, addToast]
  );

  const addComment = useCallback(
    (comment: string) => {
      if (!workflowState) {
        setOpen(false);
        return;
      }
      setOpen(false);
      onSubmit(workflowState, comment);
    },
    [workflowState, onSubmit, setOpen]
  );

  if (
    !lastState ||
    lastState === MEASURE_STATE_DECLINED ||
    workflowType !== "plausibilityCheck" ||
    lastState === MEASURE_STATE_SUCCESS ||
    companyType !== "amt"
  ) {
    return null;
  }

  return (
    <div>
      <CommentModal
        isOpen={isOpen}
        onClose={() => {
          setOpen(false);
          setWorkflowState(undefined);
        }}
        onSubmit={addComment}
      />
      <WorkflowButton
        text="Information"
        icon={faInfoCircle}
        disabled={lastState === MEASURE_STATE_PTS_UPLOAD}
        onClick={() => {
          setOpen(true);
          setWorkflowState(WORKFLOW_INFO);
        }}
      />
      <WorkflowButton
        intent="danger"
        text="Fehlerhaft"
        icon={faExclamationTriangle}
        disabled={lastState === MEASURE_STATE_PTS_UPLOAD}
        onClick={() => {
          setOpen(true);
          setWorkflowState(MEASURE_STATE_DECLINED);
        }}
      />
      <WorkflowButton
        text="Erfolgreich"
        intent="success"
        icon={faLock}
        disabled={lastState === MEASURE_STATE_PTS_UPLOAD}
        onClick={() => onSubmit(MEASURE_STATE_SUCCESS)}
      />
      <WorkflowButton
        text="Starten"
        icon={faUnlock}
        disabled={lastState !== MEASURE_STATE_PTS_UPLOAD}
        onClick={() => onSubmit(MEASURE_STATE_PROCESS)}
      />
    </div>
  );
};
