import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import moment from "moment-business-days";
import { getJsDateFromExcel } from "excel-date-to-js";

const DATA_START = 7;
const SHEET_NAME = "Lieferung";

const DATA_MAPPING: Record<keyof TMoSyncImportData, string> = {
  path: "A",
  economicUnit: "B",
  buildingNumber: "C",
  description: "D",
  orderNumber: "E",
  company: "F",
  los: "G",
  nrfChecked: "H",
  dateMoSync: "I",
  neZuweisung: "J",
  dwgArchiv: "K",
  dwgArchivUpload: "L",
};

export type TMoSyncImportData = {
  path?: string;
  economicUnit?: number;
  buildingNumber?: number;
  description?: number;
  orderNumber?: string;
  company?: string;
  los?: string;
  nrfChecked?: number;
  dateMoSync?: string;
  neZuweisung?: string;
  dwgArchiv?: string;
  dwgArchivUpload?: string;
};

export const useMoSyncImporter = (workBook: XLSX.WorkBook | null) => {
  const [isSheet, setSheet] = useState(false);
  const [importData, setImportData] = useState<TMoSyncImportData[]>([]);

  // Überprüfung ob des Sheet im excelFile ist
  useEffect(() => {
    if (!workBook) {
      return;
    }

    setSheet(workBook?.SheetNames.includes(SHEET_NAME) ?? false);
  }, [workBook, setSheet]);

  // Konvertierung der Daten aus dem Excel
  useEffect(() => {
    if (!isSheet || !workBook) {
      return;
    }

    let index = DATA_START;
    const sheetData = workBook.Sheets[SHEET_NAME];
    const impData: TMoSyncImportData[] = [];
    const keys = Object.keys(DATA_MAPPING);
    while (sheetData[`${DATA_MAPPING.path}${index}`]) {
      const tmp: TMoSyncImportData = {};
      if (
        sheetData[`${DATA_MAPPING.dateMoSync}${index}`]?.v ||
        sheetData[`${DATA_MAPPING.neZuweisung}${index}`]?.v ||
        sheetData[`${DATA_MAPPING.dwgArchiv}${index}`]?.v ||
        sheetData[`${DATA_MAPPING.dwgArchivUpload}${index}`]?.v
      ) {
        for (const key of keys) {
          switch (key) {
            case "dateMoSync":
              try {
                tmp.dateMoSync = moment(
                  getJsDateFromExcel(
                    sheetData[`${DATA_MAPPING.dateMoSync}${index}`].v
                  )
                ).format("YYYY-MM-DD");
              } catch (e) {
                tmp.dateMoSync = "";
              }
              break;
            case "dwgArchivUpload":
              try {
                tmp.dwgArchivUpload = moment(
                  getJsDateFromExcel(
                    sheetData[`${DATA_MAPPING.dwgArchivUpload}${index}`].v
                  )
                ).format("YYYY-MM-DD");
              } catch (e) {
                tmp.dwgArchivUpload = "";
              }

              break;
            default:
              tmp[key as keyof TMoSyncImportData] =
                sheetData[
                  `${DATA_MAPPING[key as keyof TMoSyncImportData]}${index}`
                ].v;
          }
        }

        impData.push(tmp);
      }

      ++index;
    }

    setImportData(impData);
  }, [isSheet, workBook, setImportData]);

  return { isSheet, importData };
};
