import React from "react";
import { TTabs } from "../../config/tabs";
import { ROUTE_BUILDING } from "../../config/routes";

type TBuildingLinkProps = {
  buildingId?: number;
  tab?: TTabs;
  blank?: boolean;
};
export const BuildingLink: React.FC<TBuildingLinkProps> = ({
  buildingId,
  tab = "measure",
  blank = false,
  children,
}) => {
  if (!buildingId) {
    return <>{children}</>;
  }

  return (
    <a
      target={blank ? "_blank" : ""}
      className="smb-link"
      href={`${ROUTE_BUILDING}${buildingId}/${tab}`}
      rel="noreferrer"
    >
      {children}
    </a>
  );
};
