import * as React from "react";
// import "../css/tooltip.css";
import { TTips } from "./Tip";
import { TipWrapper } from "./TipWrapper";
import { getTestId } from "../components/utils/getTestId";

export type TToolTipProps = {
  content?: React.ReactNode;
  delay?: TTips["delay"];
  position?: TTips["position"];
  disabled?: boolean;
  className?: string;
};

type TState = {
  isOpen: boolean;
  rect: DOMRect | null;
};

export const Tooltip: React.FunctionComponent<TToolTipProps> = ({
  children,
  content,
  className,
  position = "auto",
  delay = 1000,
  disabled = false,
}) => {
  const [state, setState] = React.useState<TState>({
    isOpen: false,
    rect: null,
  });

  return (
    <div
      className={className}
      onMouseLeave={() => {
        setState({ isOpen: false, rect: null });
      }}
      onMouseEnter={(e) => {
        if (content && disabled === false) {
          setState({
            isOpen: true,
            rect: e.currentTarget.getBoundingClientRect(),
          });
        }
      }}
      data-testid={getTestId("tooltip")}
    >
      {children}
      <TipWrapper {...state} delay={delay} position={position}>
        {content}
      </TipWrapper>
    </div>
  );
};
