import { useCallback, useState } from "react";
import {
  TBoardResponse,
  useGetMeasureBoardData,
} from "../../../hooks/measure/useGetMeasureBoardData";
import { TCompanyType } from "../../../store/user/types";
import { StateColumn } from "./StateColumn";
import { WidgetHeadline } from "./WidgetHeadline";

type TMeasureOrderProps = {
  companyType: TCompanyType;
};

export type TMeasureWidgetStates = {
  label: string;
  key: keyof TBoardResponse;
};

const WidgetStatesPTS: TMeasureWidgetStates[] = [
  {
    label: "PTS-Upload nicht erfolgt",
    key: "notStarted",
  },
  {
    label: "PTS-Upload erfolgt",
    key: "psPtsUploaded",
  },
];

const WidgetStatesPlausibility: TMeasureWidgetStates[] = [
  {
    label: "Plausibilitätsprüfung nicht bestanden",
    key: "psRejected",
  },
  {
    label: "Plausibilitätsprüfung gestartet",
    key: "psStarted",
  },
];

const WidgetStatesQs: TMeasureWidgetStates[] = [
  {
    label: "Warten auf Qualitätsprüfung",
    key: "qsWaiting",
  },
  {
    label: "In Qualitätsprüfung",
    key: "qsStarted",
  },
  {
    label: "Qualitätsprüfung nicht bestanden",
    key: "qsRejected",
  },
  {
    label: "Prüfungen bestanden",
    key: "success",
  },
];

export const MeasureOrder: React.FC<TMeasureOrderProps> = ({ companyType }) => {
  const [force] = useState(Date.now());

  const buildings = useGetMeasureBoardData(force);

  const statusRenderer = useCallback(
    (val: TMeasureWidgetStates) => {
      return (
        <StateColumn
          {...val}
          count={buildings === null ? null : buildings[val.key].length}
        />
      );
    },
    [buildings]
  );

  if (buildings === null) {
    return <>Loading</>;
  }

  return (
    <div>
      <WidgetHeadline headline="PTS-Upload" />
      {WidgetStatesPTS.map(statusRenderer)}
      <WidgetHeadline headline="Plausibilitätsprüfung" />
      {WidgetStatesPlausibility.map(statusRenderer)}
      <WidgetHeadline headline="Qualitätsprüfung" />
      {WidgetStatesQs.map(statusRenderer)}
    </div>
  );
};
