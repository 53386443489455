import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RootState } from "../../store";
import { setHomeTip } from "../../store/settings/action";
import { TSettingsState } from "../../store/settings/settingsReducer";
import { Button } from "../Button";

type TBoolSettingsProps = {
  value: any;
  valueKey: keyof TSettingsState;
} & ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

function isKeyOfUserSettings(
  list: TSettingsState,
  key: string
): key is keyof TSettingsState {
  return Object.keys(list).includes(key);
}

const BoolSettingsComponent: React.FunctionComponent<TBoolSettingsProps> = ({
  value,
  valueKey,
  settings,
  updateHomeTip,
}) => {
  if (isKeyOfUserSettings(settings, valueKey) === false) {
    console.error(`Got no key ${valueKey} in users settings`, settings);
    return null;
  }
  const onChange = (val: boolean) => {
    if (valueKey === "homeTip") {
      updateHomeTip(val);
    }
  };

  return (
    <>
      <Button
        text="Aus"
        intent={!!value === false ? "danger" : undefined}
        onClick={() => onChange(false)}
      />
      <Button
        text="An"
        onClick={() => onChange(true)}
        intent={!!value === true ? "success" : undefined}
      />
    </>
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateHomeTip: (v: boolean) => dispatch(setHomeTip(v)),
});

const mapStateToProps = (state: RootState) => ({
  settings: state.settings,
});

export const BoolSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(BoolSettingsComponent);
