import { USER_SET, USER_UPDATE } from "./action";
import { IUserState, UserActionTypes } from "./types";

const initialState: IUserState = {
  email: null,
  firstname: null,
  lastname: null,
  lastIp: null,
  lastLogin: null,
  type: "",
  company: null,
  companyType: null,
  companyName: null,
  companyId: null,
};

export function userStateReducer(
  state = initialState,
  action: UserActionTypes
): IUserState {
  switch (action.type) {
    case USER_SET:
    case USER_UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}
