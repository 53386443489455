import * as React from "react";
import { LoginForm } from "./LoginForm";
import { LoginLoading } from "./LoginLoading";
import { PasswordRequestForm } from "./PasswordRequestForm";

export type TLoginError = {
  userError: string;
  passwordError: string;
};

export type TContentType = "login" | "loading" | "request";

export type TLoginProps = {
  username: string;
  password: string;
  isLoading?: boolean;
  error?: null | TLoginError;
  passwordRequest?: boolean;
  showRemember?: boolean;
  isRemember?: boolean;
  userHelp?: string;
  text?: string;
  onPasswordChange: (val: string) => void;
  onLogin: () => void;
  onUserChange: (val: string) => void;
  onPasswordRequest?: () => void;
  onRememberChange?: () => void;
};

export const Login: React.FunctionComponent<TLoginProps> = (props) => {
  const [content, setContent] = React.useState<TContentType>(
    props.isLoading === true ? "loading" : "login"
  );

  React.useEffect(() => {
    if (content !== "loading" && props.isLoading === true) {
      setContent("loading");
    } else if (content === "loading" && props.isLoading === false) {
      setContent("login");
    }
  }, [content, setContent, props.isLoading]);

  return (
    <div className="relative" style={{ minHeight: "210px" }}>
      <LoginForm
        {...props}
        content={content}
        setContent={(val: TContentType) => setContent(val)}
      />
      <LoginLoading content={content} />
      <PasswordRequestForm
        help={props.userHelp}
        content={content}
        {...props}
        setContent={(val: TContentType) => setContent(val)}
      />
    </div>
  );
};
