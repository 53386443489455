import Axios from "axios";
import { useEffect, useState } from "react";
export type TCompanyResponse = {
  createdAt: string;
  id: number;
  name: string;
  type: "smb" | "firma" | "amt";
  updatedAt: string;
  amt: string;
};

export const useGetCompanies = () => {
  const [companies, setCompanies] = useState<TCompanyResponse[]>([]);

  useEffect(() => {
    const fetchCompanies = async () => {
      const response = await Axios.get("/company");
      setCompanies(response.data);
    };
    fetchCompanies();
  }, [setCompanies]);

  return companies;
};
