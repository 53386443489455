import React, { createRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
export type TTips = {
  isOpen: boolean;
  position: "auto" | "bottom" | "left" | "right" | "top";
  delay: 75 | 100 | 150 | 200 | 300 | 500 | 700 | 1000;
  usePortal: boolean;
  rect: DOMRect | null;
};

export const Tip: React.FunctionComponent<TTips> = ({
  children,
  position,
  delay,
  rect,
}) => {
  const adjust = 15;
  const tipRef = createRef<HTMLDivElement>();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [pos, setPos] = useState(position);
  const [arrow, setArrow] = useState("bottom");
  const [style, setStyle] = useState<React.CSSProperties>();

  useEffect(() => {
    if (tipRef && tipRef.current && width === 0) {
      const w = tipRef.current.offsetWidth + 1;
      const h = tipRef.current.offsetHeight + 1;
      setWidth(w);
      setHeight(h);
    }
  }, [tipRef, width, setWidth]);

  useEffect(() => {
    if (tipRef && tipRef.current && rect && position === "auto") {
      const windowWidth = window.innerWidth - adjust;
      const windowHeight = window.innerHeight - adjust;

      switch (true) {
        case rect.x > width / 2 &&
          windowWidth - rect.x > width / 2 &&
          rect.y > height:
          setPos("top");
          setArrow("bottom");
          break;

        case rect.x > width && rect.y > height / 2:
          setPos("left");
          setArrow("right");
          break;

        case windowWidth - rect.x > width && rect.y > height / 2:
          setPos("right");
          setArrow("left");
          break;

        case windowHeight - rect.y > height &&
          windowWidth - rect.x > width / 2 &&
          rect.x > width / 2:
          setPos("bottom");
          setArrow("top");
          break;
      }
    }
  }, [tipRef, rect, position, setPos, width, height]);

  useEffect(() => {
    if (pos !== "auto" && rect) {
      let s: React.CSSProperties = { opacity: 1 };
      switch (pos) {
        case "top":
          s = {
            ...s,
            top: `${rect.y - height - adjust}px`,
            left: `${rect.x + rect.width / 2 - width / 2}px`,
          };
          break;
        case "right":
          s = {
            ...s,
            top: `${rect.y + rect.height / 2 - height / 2}px`,
            left: `${rect.x + rect.width}px`,
          };
          break;
        case "left":
          s = {
            ...s,
            left: `${rect.x - width - adjust}px`,
            top: `${rect.y + rect.height / 2 - height / 2}px`,
          };
          break;
        case "bottom":
          s = {
            ...s,
            left: `${rect.x + rect.width / 2 - width / 2}px`,
            top: `${rect.y + rect.height + adjust}px`,
          };
      }

      setStyle(s);
    }
  }, [pos, setStyle, rect, position, height, width, adjust]);

  const content = (
    <div
      style={style}
      className={`tooltip absolute arrow-${arrow} p-2 bg-white border border-border rounded transition-opacity delay-${delay} text-sm`}
      ref={tipRef}
    >
      {children}
    </div>
  );

  return createPortal(content, document.body);
};
