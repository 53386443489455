import React from "react";
import { ModalContent } from "./ModalContent";
import { Modal } from "../../Modal";

type TCommentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (c: string) => void;
};

export const CommentModal: React.FunctionComponent<TCommentModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      title="Information zum Status"
      isOpen={isOpen}
      onClose={onClose}
      closeOnEscape={true}
      width="50%"
    >
      <ModalContent onClose={onClose} isOpen={isOpen} onSubmit={onSubmit} />
    </Modal>
  );
};
