import * as XLSX from "xlsx";
import { useEffect, useState } from "react";
import { TMoSyncImportData } from "../../MoSync/hooks/useMoSyncImporter";

const SHEET_NAME = "Aufmaßlöschen";

export type TAufmassLoeschenData = {
  path?: string;
};

const DATA_MAPPING: Record<keyof TAufmassLoeschenData, string> = {
  path: "A",
};

const DATA_START = 2;

export const useAufmassLoeschen = (workBook: XLSX.WorkBook | null) => {
  const [isSheet, setSheet] = useState(false);
  const [importData, setImportData] = useState<TAufmassLoeschenData[]>([]);
  useEffect(() => {
    if (!workBook) {
      return;
    }

    setSheet(workBook?.SheetNames.includes(SHEET_NAME) ?? false);
  }, [workBook, setSheet]);

  // Konvertierung der Daten aus dem Excel
  useEffect(() => {
    if (!workBook || !isSheet) {
      return;
    }

    let index = DATA_START;
    const sheetData = workBook.Sheets[SHEET_NAME];
    const impData: TMoSyncImportData[] = [];
    const keys = Object.keys(DATA_MAPPING);
    while (sheetData[`${DATA_MAPPING.path}${index}`]) {
      const tmp: TAufmassLoeschenData = {};

      for (const key of keys) {
        tmp[key as keyof TAufmassLoeschenData] =
          sheetData[
            `${DATA_MAPPING[key as keyof TAufmassLoeschenData]}${index}`
          ].v;
      }
      impData.push(tmp);
      ++index;
    }
    setImportData(impData);
  }, [isSheet, workBook, setImportData]);

  return { isSheet, importData };
};
