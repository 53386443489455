import { useEffect, useState } from "react";
import { TMoSyncImportData } from "../../MoSync/hooks/useMoSyncImporter";
import * as XLSX from "xlsx";
import moment from "moment-business-days";
import { getJsDateFromExcel } from "excel-date-to-js";

export type TBillImportData = {
  path?: string;
  economicUnit?: number;
  buildingNumber?: number;
  description?: number;
  billDate?: string;
  billNumber?: string;
};

const DATA_MAPPING: Record<keyof TBillImportData, string> = {
  path: "A",
  economicUnit: "B",
  buildingNumber: "C",
  description: "D",
  billDate: "G",
  billNumber: "H",
};

const DATA_START = 2;
const SHEET_NAME = "Rechnung";

export const useBillImporter = (workBook: XLSX.WorkBook | null) => {
  const [isSheet, setSheet] = useState(false);
  const [importData, setImportData] = useState<TBillImportData[]>([]);
  // Überprüfung ob des Sheet im excelFile ist
  useEffect(() => {
    if (!workBook) {
      return;
    }

    setSheet(workBook?.SheetNames.includes(SHEET_NAME) ?? false);
  }, [workBook, setSheet]);

  // Konvertierung der Daten aus dem Excel
  useEffect(() => {
    if (!workBook || !isSheet) {
      return;
    }

    let index = DATA_START;
    const sheetData = workBook.Sheets[SHEET_NAME];
    const impData: TMoSyncImportData[] = [];
    const keys = Object.keys(DATA_MAPPING);
    while (sheetData[`${DATA_MAPPING.path}${index}`]) {
      const tmp: TBillImportData = {};

      for (const key of keys) {
        if (key === "billDate") {
          try {
            let billDate = moment(
              sheetData[`${DATA_MAPPING.billDate}${index}`].v,
              "DD.MM.YYYY"
            );

            if (!billDate.isValid()) {
              billDate = moment(
                getJsDateFromExcel(
                  sheetData[`${DATA_MAPPING.billDate}${index}`].v
                )
              );
            }

            if (billDate.isValid()) {
              tmp.billDate = billDate.format("YYYY-MM-DD");
            } else {
              tmp.billDate = "";
            }
          } catch (e) {
            tmp.billDate = "";
          }
        } else {
          tmp[key as keyof TBillImportData] =
            sheetData[
              `${DATA_MAPPING[key as keyof TBillImportData]}${index}`
            ].v;
        }
      }
      impData.push(tmp);
      ++index;
    }
    setImportData(impData);
  }, [isSheet, workBook, setImportData]);

  return { isSheet, importData };
};
