import React from "react";
import { Username } from "./Username";

const getHeaderColorByHost = () => {
  const host = window.location.host;

  if (host.includes("-dora.office")) {
    return "bg-red-800";
  } else if (host.startsWith("dora.testing")) {
    return "bg-warning";
  }
  return "bg-head";
};

export const Header: React.FunctionComponent = () => {
  return (
    <div className={`${getHeaderColorByHost()} h-10 overflow-hidden w-full`}>
      <div className="text-lg text-blue-100 font-extrabold flex-1 text-center pt-1">
        Dora - Dokumentation Rahmenvertragsaufträge
      </div>
      <div className="p-3 text-white absolute top-0 right-0">
        <Username />
      </div>
    </div>
  );
};
