import React from "react";
import { MigrationPointCloudResult } from "./MigrationPointCloudResult";
import { TMigrationResultTemplate } from "../../config/migration";

export type TMigrationSuccessProps = {
  progress: boolean;
  data: any[];
  errors: any[];
  template: TMigrationResultTemplate;
};

export const MigrationPointCloudSuccess: React.FC<TMigrationSuccessProps> = ({
  progress,
  data,
  template,
  errors,
}) => {
  if (progress || template !== "pointCloud") {
    return null;
  }

  if (data.length === 0) {
    return <h1>Es wurden keine Datensätze migriert</h1>;
  }

  return (
    <>
      Es wurden {data.length} Datensätze migriert. Es sind {errors.length}{" "}
      Fehler aufgetreten
      <a
        href="#errors"
        className={`smb-link}`}
        style={{
          display: !errors.length ? "none" : "",
        }}
      >
        Fehler
      </a>
      <div className="grid grid-cols-12 bg-head text-white p-1">
        <div className="col-span-4">Pfad</div>
        <div className="text-center">Detaillevel </div>
        <div className="text-center">Wirtschaftseinheit</div>
        <div className="text-center">Gebäudenummer</div>
        <div className="text-center">Los</div>
        <div className="text-center">Amt</div>
        <div className="col-span-3">Bezeichnung</div>
      </div>
      <div className="divide-y divide-gray-500">
        {data.map((item, index) => (
          <MigrationPointCloudResult {...item} key={`migration-row-${index}`} />
        ))}
      </div>
    </>
  );
};
