import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { usePriorityImporter } from "./hooks/usePriorityImporter";
import { DataRow } from "./DataRow";
import { Button } from "../../Button";
import { usePriorityImporterProcess } from "../../../hooks/importer/usePriorityImporterProcess";

export type TImport = {
  path: string;
  priority: number;
};

export const Priority: React.FC<{
  workBook: XLSX.WorkBook | null;
  isWorkBook: (state: boolean) => void;
}> = ({ workBook, isWorkBook }) => {
  const { isPrioritySheet, buildingData } = usePriorityImporter(workBook);
  const [selected, setSelected] = useState<string[]>([]);
  const [toImport, setImport] = useState<TImport[]>([]);
  const { inProgress } = usePriorityImporterProcess(toImport);
  const [force, setForce] = useState(Date.now());

  useEffect(() => {
    if (isPrioritySheet) {
      isWorkBook(true);
    }
  }, [isPrioritySheet, isWorkBook]);
  useEffect(() => {
    if (toImport.length && inProgress === false) {
      setForce(Date.now());
      setImport([]);
      setSelected([]);
    }
  }, [inProgress, toImport, setSelected]);

  const selectAll = () => {
    if (selected.length === buildingData?.length) {
      setSelected([]);
    } else if (buildingData?.length) {
      setSelected(buildingData?.map((data) => data.path) as string[]);
    }
  };

  const setBuildingSelected = (path: string) => {
    if (selected.includes(path)) {
      setSelected(selected.filter((p) => p !== path));
    } else {
      setSelected([...selected, path]);
    }
  };

  const processImportData = () => {
    const imp =
      buildingData?.filter((data) => selected.includes(data.path ?? "")) ?? [];
    setImport(
      imp.map((val) => ({ path: val.path ?? "", priority: val.priority ?? 2 }))
    );
  };

  if (!isPrioritySheet) {
    return null;
  }

  return (
    <div>
      <div className="flex">
        <h1 className="mr-4">Punktwolken Import</h1>
        <Button
          buttonStyle="outline"
          intent="success"
          disabled={inProgress || !selected.length}
          onClick={processImportData}
        >
          Importieren
        </Button>
      </div>

      <table>
        <thead>
          <tr>
            <th className="border border-border p-1">
              <input
                type="checkbox"
                checked={selected.length === buildingData?.length}
                onChange={selectAll}
              />
            </th>
            <th className="border border-border p-1">Pfad</th>
            <th className="border border-border p-1">WE</th>
            <th className="border border-border p-1">GE</th>
            <th className="border border-border p-1">Bezeichnung</th>
            <th className="border border-border p-1">Prio</th>
            <th className="border border-border p-1">Bemerkung</th>
          </tr>
        </thead>
        <tbody>
          {buildingData?.map((data, index) => (
            <DataRow
              force={force}
              {...data}
              key={`import-${index}`}
              selected={selected}
              setSelected={setBuildingSelected}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
