import { TCols } from "../../config/measureBoard";
import { BoardCol } from "./BoardCol";
import { BoardHelp } from "./BoardHelp";
import { TCalculateResult, useBoardFilter } from "./useBoardFilter";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../Button";
import { Tooltip } from "../../Tooltip";
import { Icon } from "../Icon";

type TBoardProps = {
  data: any | null;
  config: TCols[] | null;
  displayHelp?: boolean;
};

export const Board: React.FC<TBoardProps> = ({
  config,
  data,
  displayHelp,
  children,
}) => {
  const {
    transferData,
    target,
    searchTerm,
    boardData,
    onTermChange,
    setTarget,
    dragSetTransferData,
  } = useBoardFilter(config, data);

  if (config === null) {
    return null;
  }

  return (
    <>
      <div className="mb-2 flex">
        <div className={"relative"}>
          <FontAwesomeIcon
            icon={faSearch}
            className={"absolute"}
            size={"lg"}
            style={{ top: "7px", left: "5px" }}
          />
          <input
            type="text"
            placeholder="Suche..."
            className="py-1 w-96 pl-8"
            value={searchTerm}
            onChange={(e) => onTermChange(e.target.value)}
          />
          <Button
            buttonStyle="elevated"
            disabled={!searchTerm.length}
            onClick={() => {
              onTermChange("");
            }}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </Button>
          <div className={"text-xs"}>
            Volltextsuche über WE-Nr, Geb-Nr und Bezeichnung
          </div>
        </div>

        {children}
        <div className="flex ml-2 pt-2 text-primary ">
          <Tooltip
            content={<BoardHelp />}
            className={`cursor-help${displayHelp ? "" : " hidden"}`}
          >
            <Icon icon="INFO_CIRCLE_SOLID" size={5} />
          </Tooltip>
        </div>
      </div>
      <div
        className={"w-full flex-1"}
        style={{
          overflow: "scroll",
          width: "100%",
          height: "calc(100% - 70px)",
        }}
      >
        <div
          className={`flex`}
          style={{
            minWidth: `${290 * config.length}px`,
          }}
        >
          {config.map((item, i) => (
            <BoardCol
              key={`board-col-${i}`}
              {...item}
              config={item}
              data={boardData.find((v) => v.accessKey === item.dataKey)}
              transferData={transferData}
              currentTargets={target}
              setTarget={(value: React.SetStateAction<any[]>) =>
                item.canSetTarget ? setTarget(value) : null
              }
              setTransferData={(value: TCalculateResult | null) =>
                item.canSetTransferId ? dragSetTransferData(value) : null
              }
            />
          ))}
        </div>
      </div>
    </>
  );
};
