import dragIndicator from "../../assets/drag_indicator.png";

type TDragIndicatorProps = {
  isDraggable: boolean;
  isHover: boolean;
};

export const DragIndicator: React.FC<TDragIndicatorProps> = ({
  isDraggable,
  isHover,
}) => {
  if (!isDraggable) {
    return null;
  }

  return (
    <div
      className={`bg-transparent w-3 h-full bg-repeat-y opacity-${
        isHover ? "100" : "40"
      }`}
      style={{
        backgroundImage: `url(${dragIndicator})`,
      }}
    ></div>
  );
};
