import axios, { AxiosPromise, AxiosRequestConfig, AxiosResponse } from "axios";
import { STATUS_CODES } from "./app";
import { disableAjaxProgress, setShowLogin } from "../store/appState/action";
import Store from "../store/Store";
import { resetUser } from "../store/user/action";
import { JWT_SESSION_KEY } from "./app";

let token: string | null = localStorage.getItem(JWT_SESSION_KEY);

axios.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    config.baseURL = "/api/";
    config.headers = {
      "x-jwt-token": token,
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };
    return config;
  }
);

axios.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    token = response.headers["x-renewed-jwt-token"];

    localStorage.setItem(
      JWT_SESSION_KEY,
      response.headers["x-renewed-jwt-token"]
    );

    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error): AxiosPromise => {
    if (!error.response) {
      return Promise.reject(error);
    }
    const errCode: number = error.response.status;
    if (STATUS_CODES.Unauthorized === errCode) {
      Store.dispatch(setShowLogin(true));
      Store.dispatch(resetUser());

      localStorage.removeItem(JWT_SESSION_KEY);
    } else if (
      STATUS_CODES.Not_Found === errCode ||
      STATUS_CODES.Server_Error === errCode
    ) {
      // toast(
      //   "Ups da ging was daneben, versuchen Sie es erneut oder melden Sie ein Fehler",
      //   {
      //     intent: "warning",
      //     autoClose: 2000,
      //   }
      // );
    } else if (STATUS_CODES.Forbidden === errCode) {
      // toast("Zugriff nicht erlaubt", {
      //   intent: "danger",
      //   autoClose: 3000,
      // });
      window.history.back();
    }
    Store.dispatch(disableAjaxProgress());
    // console.log("Request error ===> ", error.response.status);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);
