import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import moment from "moment";
import { getJsDateFromExcel } from "excel-date-to-js";

const SHEET_NAME = "AufmassAuftrag";
const DATA_START = 2;
const DATA_MAPPING: Record<keyof TAufmassImportData, string> = {
  path: "A",
  economicUnit: "B",
  buildingNumber: "C",
  description: "D",
  address: "E",
  nrfEstimated: "P",
  category: "G",
  areaCluster: "H",
  detailLevel: "I",
  los: "J",
  orderType: "K",
  dateRetrieval: "L",
  orderNumber: "M",
  company: "N",
  nrfMeasurement: "Q",
  deadline: "R",
  priceUnit: "S",
  office: "Y",
  cutsViews: "AD",
  ptsUpload: "AC",
};

export type TAufmassImportData = {
  path?: string;
  economicUnit?: number;
  buildingNumber?: number;
  description?: string;
  address?: string;
  nrfEstimated?: number;
  category?: number;
  areaCluster?: string;
  detailLevel?: string;
  los?: number;
  dateRetrieval?: string;
  orderNumber?: string;
  company?: string;
  nrfMeasurement?: number;
  deadline?: string;
  priceUnit?: number;
  office?: string;
  cutsViews?: "Yes" | "N0";
  ptsUpload?: string;
  orderType?: string;
};

const DATE_VALUES = ["dateRetrieval", "deadline"];
const NUMBER_VALUES = [
  "economicUnit",
  "buildingNr",
  "category",
  "los",
  "nrfMeasurement",
  "priceUnit",
];

export const useNeuaufmassImporter = (workBook: XLSX.WorkBook | null) => {
  const [isAufmassSheet, setIsAufmassSheet] = useState(false);

  const [defaultCheckable, setDefaultCheckable] = useState<string[]>([]);
  const [importData, setImportData] = useState<TAufmassImportData[]>([]);

  useEffect(() => {
    setIsAufmassSheet(workBook?.SheetNames.includes(SHEET_NAME) ?? false);
  }, [workBook]);

  useEffect(() => {
    if (!workBook || !isAufmassSheet) {
      return;
    }

    let index = DATA_START;
    const sheetData = workBook.Sheets[SHEET_NAME];
    const checkable: string[] = [];

    const sheetImport: TAufmassImportData[] = [];
    const keys = Object.keys(DATA_MAPPING);
    while (sheetData[`${DATA_MAPPING.path}${index}`]) {
      const rowData: TAufmassImportData = {};
      checkable.push(sheetData[`${DATA_MAPPING.path}${index}`].v);

      for (const key of keys) {
        const cellCoords = `${
          DATA_MAPPING[key as keyof TAufmassImportData]
        }${index}`;
        let cellValue = sheetData[cellCoords]?.v ?? "";

        if (DATE_VALUES.includes(key)) {
          if (typeof cellValue === "number") {
            cellValue = moment(getJsDateFromExcel(cellValue));
          } else {
            cellValue = moment(cellValue, "DD.MM.YYYY");
          }
          cellValue = cellValue.format("YYYY-MM-DD");
        }

        if (NUMBER_VALUES.includes(key)) {
          const numberValue = Number(cellValue);
          if (isNaN(numberValue)) {
            cellValue = 0;
          } else {
            cellValue = numberValue;
          }
        }

        if (key === "cutsViews") {
          if (cellValue.toLowerCase() === "ja") {
            cellValue = "Yes";
          } else {
            cellValue = "No";
          }
        }

        rowData[key as keyof TAufmassImportData] = cellValue;
      }

      sheetImport.push(rowData);
      ++index;
    }

    setDefaultCheckable(checkable);
    setImportData(sheetImport);
  }, [isAufmassSheet, workBook, setImportData, setDefaultCheckable]);

  return { isAufmassSheet, importData, defaultCheckable };
};
