import { connect } from "react-redux";
import { TReports } from "../../config/reports";
import { useDownloadReport } from "../../hooks/navigation/useDownloadReport";
import { RootState } from "../../store";
import { ReportRow } from "./ReportRow";

type TReportDownloadProps = {
  reports: TReports[];
} & ReturnType<typeof mapStateToProps>;

const ReportDownloadComponent: React.FunctionComponent<
  TReportDownloadProps
> = ({ reports, companyType }) => {
  const { onDownload, inProgress } = useDownloadReport(companyType);

  return (
    <div className="w-96 divide-y divide-gray-500">
      <div className="flex p-1 text-lg font-bold">Report</div>
      {reports.map((report, i) => (
        <ReportRow
          report={report}
          inProgress={inProgress.includes(report.report)}
          onClick={onDownload}
          key={`report-dowbload-${i}`}
        />
      ))}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyType: state.user.companyType,
});

export const ReportDownload = connect(mapStateToProps)(ReportDownloadComponent);
