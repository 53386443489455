type TError = {
  code: string;
  errno: number;
  fatal: boolean;
  sql: string;
  sqlState: string;
};

export type TSequelizeError = {
  name: string;
  original: TError;
  parent: TError;
  sql: string;
};

export const isSequelizeError = (error: any): error is TSequelizeError => {
  return (
    error.name &&
    error.name.toLowerCase().includes("sequelize") &&
    error.sql &&
    typeof error.sql === "string"
  );
};
