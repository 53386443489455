import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

type TProcessIndicator = {
  process: boolean;
};

export const ProcessIndicator: React.FC<TProcessIndicator> = ({
  process,
  children,
}) => {
  if (!process) {
    return null;
  }

  return (
    <div className="text-center mb-2 mt-10">
      <FontAwesomeIcon icon={faSpinner} size="5x" spin />
      {children}
    </div>
  );
};
