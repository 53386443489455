import React from "react";
import { DisplayError } from "./DisplayError";

type TBackendSqlError = {
  errors: string[];
};

export const BackendError: React.FC<TBackendSqlError> = ({ errors }) => {
  return (
    <div id="errors">
      {errors.map((err, index) => (
        <DisplayError error={err} key={`error-${index}`} />
      ))}
    </div>
  );
};
