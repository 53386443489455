import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "../Modal";
import { Button } from "../Button";

type TPointCloudCommentProps = {
  isOpen: boolean;
  setComment: (comment: string) => void;
  onClose: () => void;
  onCancel: () => void;
};

export const WorkflowComment: React.FC<TPointCloudCommentProps> = ({
  isOpen,
  setComment,
  onClose,
  onCancel,
}) => {
  const areaRef = React.createRef<HTMLTextAreaElement>();
  const [comment, updateComment] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (!areaRef || !areaRef.current) {
        return;
      }
      areaRef.current.focus();
    }, 0);
  }, [areaRef, updateComment]);

  if (!isOpen) {
    if (comment !== "") {
      updateComment("");
    }

    return null;
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEscape={true}
      title="Kommentar"
      width={"600px"}
    >
      <div>
        <textarea
          ref={areaRef}
          placeholder="Optionaler Kommentar zum Auftrag"
          className="resize-none w-full h-40"
          value={comment}
          onChange={(e) => updateComment(e.target.value)}
        />
      </div>
      <div className={"text-right"}>
        <Button
          buttonStyle={"outline"}
          intent={"danger"}
          className={"mr-2"}
          onClick={onCancel}
        >
          <FontAwesomeIcon icon={faTimes} /> Abbrechen
        </Button>
        <Button
          buttonStyle={"outline"}
          intent={"success"}
          onClick={() => {
            setComment(comment);
            onClose();
          }}
        >
          <FontAwesomeIcon icon={faSave} /> Status Speichern
        </Button>
      </div>
    </Modal>
  );
};
