import React from "react";
import { TUiSettings } from "../../config/uiSettings";
import { SettingElement } from "./SettingsElement";

export const Settings: React.FunctionComponent<TUiSettings> = (props) => {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex-1">{props.help}</div>
      <div className="text-right" style={{ width: "33%" }}>
        {<SettingElement {...props} />}
      </div>
    </div>
  );
};
