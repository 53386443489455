import * as React from "react";

export type TLabelProps = {
  htmlFor: string;
  testId: string;
  displayOnValue?: boolean;
  show?: boolean;
};

export const Label: React.FunctionComponent<TLabelProps> = ({
  htmlFor,
  testId,
  displayOnValue = false,
  show = false,
  children,
}) => {
  const [opacity, setOpacity] = React.useState("0");
  React.useEffect(() => {
    if (!displayOnValue || (displayOnValue && show)) {
      setOpacity("1");
    } else {
      setOpacity("0");
    }
  }, [show, displayOnValue, setOpacity]);
  if (!children) {
    return null;
  }
  return (
    <div data-testid={`label-${testId}`} className="h-6 flex-1">
      <label
        htmlFor={htmlFor}
        className={`transition-opacity duration-300 ease-out pr-2 truncate overflow-hidden whitespace-nowrap opacity-${opacity}`}
      >
        {children}
      </label>
    </div>
  );
};
