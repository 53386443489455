import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useDeadlineImporter } from "./hooks/useDeadlineImporter";
import { DataRow } from "./DataRow";
import {
  TImport,
  useDeadlineImporterProcess,
} from "../../../hooks/importer/useDeadlineImporterProcess";
import { ImporterTableHeadCell } from "../ImporterTableHeadCell";
import { Button } from "../../Button";

export const Deadline: React.FC<{
  workBook: XLSX.WorkBook | null;
  isWorkBook: (state: boolean) => void;
}> = ({ workBook, isWorkBook }) => {
  const [checkable, setCheckable] = useState<string[]>([]);
  const { isDeadlineSheet, importData } = useDeadlineImporter(workBook);
  const [toImport, setImport] = useState<TImport[]>([]);
  const [processImport, setProcessImport] = useState(false);
  const progress = useDeadlineImporterProcess(toImport, processImport);
  const [force, setForce] = useState(Date.now);

  useEffect(() => {
    if (isDeadlineSheet) {
      isWorkBook(true);
    }
  }, [isDeadlineSheet, isWorkBook]);

  const checkableCallback = useCallback(
    (path: string) => {
      if (checkable.includes(path) || !path) {
        return;
      }

      setCheckable([...checkable, path]);
    },
    [checkable, setCheckable]
  );

  const toggleCheck = useCallback(
    (togglePath: string, state: boolean) => {
      if (!togglePath || !importData) {
        return;
      }
      if (state && !toImport.find((item) => item.path === togglePath)) {
        const data = importData.find((item) => item.path === togglePath);
        if (!data) {
          return;
        }
        setImport([
          ...toImport,
          {
            path: data.path ?? "",
            deadline: data.deadline ?? "",
          },
        ]);
      } else if (
        !state &&
        !!toImport.find((item) => item.path === togglePath)
      ) {
        setImport(toImport.filter((item) => item.path !== togglePath));
      }
    },
    [importData, toImport, setImport]
  );

  const checkAll = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!event.currentTarget.checked || !importData) {
        setImport([]);
        return;
      }

      const checked: TImport[] = checkable.map((path) => {
        const data = importData.find((item) => item.path === path);
        if (!data) {
          throw new Error("Something went wrong");
        }
        return {
          path: data.path ?? "",
          deadline: data.deadline ?? "",
        };
      });
      setImport(checked);
    },
    [checkable, importData, setImport]
  );

  useEffect(() => {
    if (processImport && !progress) {
      setProcessImport(false);
      setForce(Date.now);
    }
  }, [progress, processImport, setProcessImport]);
  const headlines = [
    "Pfad",
    "Wirtschaftseinheit (WE)",
    "Gebäude Nummer (GE)",
    "Bezeichnung",
    "Liefertermin",
    "Datenbank",
    "Meldung",
  ];

  if (!isDeadlineSheet || !importData) {
    return null;
  }

  return (
    <div>
      <div className="flex mb-4">
        <h1>Liefertermin Import</h1>
        <Button
          intent={"success"}
          buttonStyle="outline"
          className="ml-4"
          disabled={!toImport.length || progress}
          onClick={() => setProcessImport(true)}
        >
          Importieren
        </Button>
      </div>
      <table className="table-auto border-collapse border border-border">
        <thead>
          <tr>
            <th className="border border-border p-1">
              <input type="checkbox" onChange={checkAll} />
            </th>
            {headlines.map((val, index) => (
              <ImporterTableHeadCell
                headline={val}
                key={`deadline-headline-${index}`}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {importData.map((val, index) => (
            <DataRow
              {...val}
              force={force}
              key={`deadline-row-${index}`}
              setCheckable={checkableCallback}
              checkable={checkable.includes(val.path ?? "")}
              checked={!!toImport.find((v) => v.path === val.path)}
              toggleChecked={toggleCheck}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
