import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { TCols } from "../../../config/measureBoard";
import { leadingZeroFormatter } from "../../../helper/formatter";
import { RootState } from "../../../store";
import { DisplayNrf } from "../DisplayNrf";
import { DragIndicator } from "../DragIndicator";
import { KanbanBoardContext } from "../KanbanBoard";
import { TCalculateResult } from "../useBoardFilter";
import { QsState } from "../carItems/QsState";
import { TIntent } from "../../../config/colors";
import { TIconNames } from "../../Icon";

type TBoardCardProps = {
  intent?: TIntent;
  isDraggable?: boolean;
  bgColor?: string;
  remaningTime?: number;
  nrfMeasurement?: number;
  displayNrf?: boolean;
  isOpen: boolean;
  onDragStart?: () => void;
  onDragEnd?: () => void;
} & TCalculateResult &
  TCols &
  ReturnType<typeof mapStateToProps>;

const BoardCardComponent: React.FC<TBoardCardProps> = ({
  isOpen,
  buildingId,
  buildingNumber,
  companyName,
  designation,
  economicUnit,
  losNumber,
  office,
  psFails,
  qsFails,
  isDraggable = false,
  qsOffice,
  useCondition,
  companyId,
  companyType,
  qsEditor,
  displayNoEditor,
  nrfMeasurement,
  displayNrf,
  onDragStart,
  onDragEnd,
  workdays,
  cardColor,
  finishDate,
}) => {
  const [bgCard, setBgCard] = useState<string>();
  const [draggable, setDraggable] = useState(isDraggable);
  const { onClick } = useContext(KanbanBoardContext);
  const [isHover, setHover] = useState(false);
  const [iconSize, setIconSize] = useState(4);
  const [icon, setIcon] = useState<TIconNames>("CHECK_CIRCLE_REGULAR");
  const [tooltip, setTooltip] = useState("");

  useEffect(() => {
    const fails = qsFails + psFails;

    if (fails === 3) {
      setTooltip(
        `Limit der erlaubten Zurückweisungen ist erreicht, ${fails} von 3`
      );
      setIcon("CHECK_CIRCLE_SOLID");
      setIconSize(5);
    } else if (fails > 3) {
      setTooltip(
        `Limit der erlaubten Qualitätsprüfung Zurückweisungen ist überschritten, ${fails} von 3`
      );
      setIcon("EXCLAMATION_TRIANGLE_SOLID");
      setIconSize(5);
    } else {
      setTooltip(
        `Anzahl Qualitätsprüfung Zurückweisungen im Limit: ${fails} von 3`
      );
    }
  }, [psFails, qsFails, setIcon, setIconSize]);

  useEffect(() => {
    if (useCondition !== "companyId") {
      return;
    }

    if ((qsOffice === null && companyId === 1) || qsOffice === companyId) {
      setDraggable(true);
    }
  }, [useCondition, qsOffice, companyId, setDraggable]);

  useEffect(() => {
    if (companyType === "smb" && displayNoEditor && qsEditor === null) {
      setDraggable(false);
      setBgCard("bg-blue-800 bg-opacity-80 text-white");
      return;
    }

    setBgCard(cardColor);
  }, [
    cardColor,
    companyType,
    qsEditor,
    displayNoEditor,
    setDraggable,
    setBgCard,
  ]);
  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={{
        width: "278px",
        height: "125px",
      }}
      onClick={() => {
        if (typeof onClick === "function") {
          onClick(buildingId);
        }
      }}
      onDragStart={(e) => {
        if (typeof onDragStart === "function") {
          onDragStart();
        }
      }}
      onDragEnd={(e) => {
        if (typeof onDragEnd === "function") {
          onDragEnd();
        }
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      draggable={draggable}
      className={`text-xs flex flex-row rounded m-1 hover:border hover:bg-gray-400 py-1 pl-1 ${
        bgCard ?? "bg-gray-300"
      } border-4 bg-center bg-no-repeat bg-contain`}
    >
      <div className="w-full overflow-hidden">
        <div className="h-3.5 flex justify-between pr-2">
          <div>
            <strong>
              {workdays !== undefined ? (
                <>Verbleibende Werktage: ({workdays ?? ""})</>
              ) : null}
            </strong>
          </div>
          <div>{finishDate ?? ""}</div>
        </div>

        <div className="h-4 mt-1  overflow-ellipsis truncate">
          {designation}
        </div>
        <div className="flex flex-row mt-0.5">
          <div className="w-1/2">WE: {leadingZeroFormatter(economicUnit)}</div>
          <div className="w-1/2">
            GE: {leadingZeroFormatter(buildingNumber)}
          </div>
        </div>
        <div className="flex flex-row mt-0.5">
          <div className="w-1/2">Amt: {office}</div>
          <div className="w-1/2">Los: {losNumber}</div>
        </div>
        <div className="overflow-hidden h-4 overflow-ellipsis truncate mt-0.5 flex justify-between">
          <div>Firma: {companyName}</div>
          <DisplayNrf displayNrf={displayNrf} nrf={nrfMeasurement} />
        </div>
        <QsState
          tooltip={tooltip}
          icon={icon}
          iconSize={iconSize}
          psFails={psFails}
          qsFails={qsFails}
        />
      </div>
      <DragIndicator isDraggable={draggable} isHover={isHover} />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyId: state.user.companyId,
  companyType: state.user.companyType,
});

export const MeasureProgressBoardCard =
  connect(mapStateToProps)(BoardCardComponent);
