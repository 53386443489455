import { useContext, useEffect, useState } from "react";
import {
  TBuildingData,
  THeaderData,
} from "../../components/Importer/Pointcloud/hooks/usePointCloudImporter";
import axios from "axios";
import { SmbToastContext } from "../../context/SmbTabsContext";

export const usePointCloudImportProcess = (
  order: THeaderData | null,
  data: TBuildingData[] | null
) => {
  const [inProgress, setProgress] = useState(false);
  const { addToast } = useContext(SmbToastContext);

  useEffect(() => {
    if (!order || !data) {
      return;
    }
    setProgress(true);
    const process = async () => {
      try {
        const response = await axios.post<{
          error: string[];
          success: string[];
        }>("admin/import/point-cloud/", {
          data: order,
          orders: data,
        });

        if (addToast) {
          for (const message of response.data.error) {
            addToast({
              message,
              intent: "error",
              autoClose: 0,
            });
          }
        }
      } catch (error) {
        if (
          addToast &&
          axios.isAxiosError(error) &&
          error.response?.data.error &&
          Array.isArray(error.response?.data.error)
        ) {
          for (const val of error.response.data.error) {
            addToast({
              message: val,
              intent: "error",
              autoClose: 0,
            });
          }
        }
      }

      setProgress(false);
    };

    process();
  }, [order, data, setProgress, addToast]);

  return { inProgress };
};
