export type TReportType =
  | "company-project-state"
  | "global"
  | "mo-sync"
  | "office-project-state";

export type TReports = {
  text: string;
  report: TReportType;
};

export const SMB_REPORTS: TReports[] = [
  {
    text: "Globaler Report",
    report: "global",
  },
  {
    text: "Mo-Sync Report",
    report: "mo-sync",
  },
];

export const OFFICE_REPORTS: TReports[] = [
  {
    text: "Projekt Status",
    report: "office-project-state",
  },
];

export const COMPANY_REPORTS: TReports[] = [
  {
    text: "Projekt Status",
    report: "company-project-state",
  },
];
