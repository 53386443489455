import React, { useState } from "react";
import { connect } from "react-redux";
import { useUpdateNotification } from "../../hooks/useUpdateNotification";
import { RootState } from "../../store";
import { Changelog } from "./Changelog";
import { Button } from "../Button";

type TUpdateNotification = ReturnType<typeof mapStateToProps>;

const UpdateNotificationComponent: React.FunctionComponent<
  TUpdateNotification
> = ({ version }) => {
  const serverVersion = useUpdateNotification(version);
  const [isOpen, setIsOpen] = useState(false);

  if (serverVersion === version) {
    return null;
  }

  return (
    <>
      <div className="fixed top-9 left-0 right-0 h-10 pt-1 pl-5 pr-5 bg-red-600">
        <div className="flex justify-between">
          <div className="font-bold text-lg text-white">
            Eine neue Version der Software ist vorhanden. Laden Sie Ihren
            Browser neu.
          </div>
          <div>
            <Button
              className="mr-3"
              buttonStyle="elevated"
              onClick={() => setIsOpen(!isOpen)}
              text="Änderungen"
              iconRight="EYE_OPEN"
            />
            oder
            <Button
              className="ml-3"
              iconRight="SYNC_SOLID"
              intent="success"
              text="Neuladen"
              onClick={() => window.location.reload()}
            />
          </div>
        </div>
      </div>
      <Changelog isOpen={isOpen} />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  version: state.app.version,
});

export const UpdateNotification = connect(mapStateToProps)(
  UpdateNotificationComponent
);
