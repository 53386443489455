import Axios from "axios";
import React, { useCallback, useContext, useState } from "react";
import {
  MEASURE_STATE_PTS_CORRECTED,
  MEASURE_STATE_PTS_UPLOAD,
} from "../../../config/workflow";
import { TWorkflowType } from "../../../hooks/useLastHistoryStatus";
import { SmbToastContext } from "../../../context/SmbTabsContext";
import { Modal } from "../../Modal";
import { Button } from "../../Button";

type TPtsModalProps = {
  isOpen: boolean;
  state: typeof MEASURE_STATE_PTS_UPLOAD | typeof MEASURE_STATE_PTS_CORRECTED;
  workflowType: TWorkflowType;
  buildingId: number;
  onClose: () => void;
  forceFetch?: () => void;
};

export const PtsModal: React.FC<TPtsModalProps> = ({
  isOpen,
  state,
  workflowType,
  onClose,
  buildingId,
  forceFetch,
}) => {
  const [comment, setComment] = useState("");
  const { addToast } = useContext(SmbToastContext);

  const submit = useCallback(
    async (com: string) => {
      if (!buildingId) {
        return;
      }

      try {
        await Axios.post(
          `/workflow/measure/pts-upload/${workflowType}/${buildingId}/`,
          {
            state,
            comment: com,
          }
        );
        if (addToast) {
          addToast({
            message: "PTS Status erfolgreich geändert",
            intent: "success",
            autoClose: 3000,
          });
        }

        if (typeof forceFetch === "function") {
          forceFetch();
        }
      } catch (e) {
        if (addToast) {
          addToast({
            message: "Fehler beim ändern des Status",
            intent: "error",
            autoClose: 0,
          });
        }
      }

      onClose();
    },
    [buildingId, workflowType, state, forceFetch, onClose, addToast]
  );
  if (!isOpen) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} width="800px">
      <textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className="w-full h-28 resize-none"
        placeholder="Informationen für die Plausibilitätskontrolle oder die QS hier eingeben (optional)"
      />
      <div className="text-right">
        <Button
          icon="TIMES_SOLID"
          intent="danger"
          text="Abbrechen"
          onClick={onClose}
          className="mr-2"
        />
        <Button
          intent="success"
          text="Speichern"
          onClick={() => submit(comment)}
          icon="SAVE_REGULAR"
        />
      </div>
    </Modal>
  );
};
