import React, { useState } from "react";
import { PopoverContent } from "./PopoverContent";

type TPopoverProps = {
  isOpen?: boolean;
};

export const Popover: React.FunctionComponent<TPopoverProps> = ({
  children,
  isOpen,
}) => {
  const [open, setOpen] = useState(!!isOpen);
  const [rect, setRect] = useState<DOMRect | null>(null);
  const childs = React.Children.toArray(children);

  return (
    <div
      className="popover"
      onClick={(e) => {
        if (isOpen === undefined) {
          setOpen(!open);
        }
        setRect(e.currentTarget.getBoundingClientRect());
      }}
    >
      {childs[0] ?? ""}
      <PopoverContent rect={rect} isOpen={open}>
        {childs[1]}
      </PopoverContent>
    </div>
  );
};
