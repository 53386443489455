import React from "react";
import { ToolTip } from "../ToolTip";

type TNrfPriceProps = {
  priceGross: number | null;
  priceNet: number | null;
  color?: "base" | "measured" | "checked";
};

export const NrfPrice: React.FunctionComponent<TNrfPriceProps> = ({
  priceGross,
  priceNet,
  color = "base",
}) => {
  const sectionColor: { [key: string]: string } = {
    base: "#0E5A8A",
    measured: "#3DCC91",
    checked: "#0A6640",
  };

  const formatPrice = (price: number | null) => {
    if (!price) {
      return "---";
    }

    return price.toLocaleString("de-De", {
      style: "currency",
      currency: "EUR",
      maximumFractionDigits: 20,
    });
  };

  return (
    <>
      <div
        className="flex overflow-ellipsis overflow-hidden"
        style={{ paddingRight: "25px" }}
      >
        <div style={{ width: "50px" }}>Netto:</div>
        <ToolTip content={formatPrice(priceNet)}>
          <div
            className="text-left font-bold flex-1 overflow-hidden overflow-ellipsis"
            style={{ color: sectionColor[color], paddingLeft: "15px" }}
          >
            {formatPrice(priceNet)}
          </div>
        </ToolTip>
      </div>
      <div
        className="flex overflow-hidden overflow-ellipsis"
        style={{ paddingRight: "25px" }}
      >
        <div style={{ width: "50px" }}>Brutto:</div>
        <ToolTip content={formatPrice(priceGross)}>
          <div
            className="text-left font-bold flex-1  overflow-hidden overflow-ellipsis"
            style={{ color: sectionColor[color], paddingLeft: "15px" }}
          >
            {formatPrice(priceGross)}
          </div>
        </ToolTip>
      </div>
    </>
  );
};
