import React, { useState } from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";
import Tile from "../Tile";
import boardImage from "./board.png";
import boardIcon from "./board_icon.png";
import tabImage from "./tabs.png";
import backButton from "./back.png";
import orderTabs from "./orderTabs.png";
import { Button } from "../Button";

const content: JSX.Element[] = [
  <>
    <h1>Alle Projekte eines Bauwerks</h1>
    <p>
      In DORA gab es grundlegende Änderungen. Es ist umfassender geworden, da
      DORA nun nicht mehr auf den Auftrag des Aufmaßes fokussiert ist, sondern
      auf das Bauwerk. Alle an einem Bauwerk vorhandenen Projekte werden
      angezeigt. Neben dem Neuaufmaß ist jetzt die Punktwolke hinzugekommen.
      Weitere Aufträge werden demnächst folgen, z. B. QS-Bestandsprüfung.
    </p>
    <p className={"mt-2"}>
      <img src={orderTabs} alt="Auftrags Tabs" />
    </p>
  </>,
  <>
    <h1>Mehrere Bauwerke gleichzeitig bearbeiten</h1>
    <p>
      Bauwerke, die Sie über ein Board auswählen, werden jetzt in einem 'neuen
      Tab' geöffnet. Dadurch können Sie mehrere Bauwerke/Aufträge gleichzeitigt
      bearbeiten
    </p>
    <p className={"mt-2"}>
      <img src={tabImage} alt="Tabs" />
    </p>
    <p className={"mt-2"}>
      Ob ein Auftrag in einem neuen Tab geöffnet wurde, erkennen Sie am 'zurück
      Button'. Dieser hat das Label 'zum Board' und schließt den entsprechenden
      Tab beim Klicken.
    </p>
    <p className={"mt-2"}>
      <img src={backButton} alt="Tab close Button" />
    </p>
  </>,
  <>
    <h1>Projekt Board</h1>
    <p>
      Sie können nun über die Navigation{" "}
      <img
        style={{ width: "30px", height: "30px", display: "inline" }}
        src={boardIcon}
        alt="Icon in der Navigation"
      />
      das Projekt-Board aufrufen. Dieses zeigt Ihnen übersichtlich den Status
      Ihrer Gebäude an, die nach Dringlichkeit sortiert sind. Mit der farblichen
      Darstellung werden Sie außerdem auf die anstehende Bearbeitung
      hingewiesen:
    </p>
    <br />
    <ul>
      <li>Rot: bereits überfällig</li>
      <li>
        Gelb: noch im Rahmen des Bearbeitungszeitraums (Frist nach Vorgang
        „demnächst“)
      </li>
      <li>Grün: innerhalb des Bearbeitungszeitraums</li>
    </ul>

    <br />
    <p>
      Der Status eines Gebäudes kann einfach durch Drag & Drop in die nächste
      anstehende Spalte geändert werden. Der Vorgang wird dementsprechend beim
      der Plausibilitätsprüfung oder der Qualitätsprüfung vermerkt und ggf. ein
      Statuseintrag erzeugt.
    </p>
    <p>
      Die Details zum Bauwerk öffnen sich mit "Klick auf das jeweilige Objekt."
    </p>
    <br />
    <p>
      <img src={boardImage} alt="Projekt Board" />
    </p>
  </>,
  <>
    <h1>Änderungen</h1>
    <p>Sie wollen wissen, was sich in der aktuellen Version geändert hat?</p>
    <p>
      Dann klicken Sie entweder auf die Versionsnummer unten rechts oder gehen
      über das Menü Einstellungen &gt; Versionsänderungen.
    </p>
  </>,
  <>
    <h1>Passwort ändern</h1>
    <p>
      Über das Menü Einstellungen &gt; Ihr Account, können Sie ihr Passwort
      ändern.
    </p>
  </>,
];

type TDidYouKnow = ReturnType<typeof mapStateToProps>;

const DidYouKnowComponent: React.FunctionComponent<TDidYouKnow> = ({
  showTip,
}) => {
  const [tip, setTip] = useState(0);

  if (showTip === false) {
    return null;
  }

  return (
    <div>
      <Tile title="Wussten Sie ?">
        <>
          <div>{content[tip]}</div>

          <div className="flex justify-end">
            <Button
              className="mr-2"
              buttonStyle="elevated"
              text="letzter Tipp"
              disabled={tip === 0 ? true : false}
              onClick={() => setTip(tip - 1)}
            />
            <Button
              buttonStyle="elevated"
              text="nächster Tipp"
              disabled={content.length === tip + 1 ? true : false}
              onClick={() => setTip(tip + 1)}
            />
          </div>
        </>
      </Tile>
    </div>
  );
};
const mapStateToProps = (state: RootState) => ({
  showTip: state.settings.homeTip,
});

export const DidYouKnow = connect(mapStateToProps)(DidYouKnowComponent);
