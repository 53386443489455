import { TCommentResult } from "../../hooks/useGetComments";
type TNoCommentsProps = {
  comments: TCommentResult[];
};

export const NoComments: React.FC<TNoCommentsProps> = ({ comments }) => {
  if (comments.length) {
    return null;
  }

  return (
    <div className="w-full  text-lg font-bold text-center">
      Keine Kommentare vorhanden
    </div>
  );
};
