import * as React from "react";

type TInputErrorProps = {
  testId: string;
  error: string;
};

export const InputError: React.FunctionComponent<TInputErrorProps> = ({
  testId,
  error = "",
}) => {
  if (error === "") {
    return null;
  }

  return (
    <div data-testid={`error-${testId}`} className="text-danger text-sm">
      {error}
    </div>
  );
};
