import { TMigrateItem } from "../../config/migration";
import { ProcessIndicator } from "../ProcessIndicator/ProcessIndicator";
import { useMigration } from "../../hooks/useMigration";

import React from "react";
import { MigrationPointCloudSuccess } from "./MigrationPointCloudSuccess";
import { BackendError } from "../BackendSqlError/BackendError";
import { MigrationWorkflowSucces } from "./MigrationWorkflowSucces";
import { MigrationMessage } from "./MigrationMessage";
import { Button } from "../Button";

type TMigrationProcessProps = {
  onClose: () => void;
} & TMigrateItem;

export const MigrationProcess: React.FC<TMigrationProcessProps> = ({
  route,
  message,
  template,
  onClose,
}) => {
  const { process, migrated, errors } = useMigration(route);
  return (
    <div>
      <ProcessIndicator process={process}>
        <div className="mt-3">{message} wird ausgeführt, bitte warten</div>
      </ProcessIndicator>
      <Button
        intent={"success"}
        buttonStyle={"outline"}
        disabled={process}
        onClick={onClose}
        className="mr-7"
      >
        zurück
      </Button>
      <MigrationPointCloudSuccess
        progress={process}
        data={migrated}
        errors={errors}
        template={template}
      />

      <MigrationWorkflowSucces
        progress={process}
        data={migrated}
        errors={errors}
        template={template}
      />

      <MigrationMessage
        progress={process}
        data={migrated}
        errors={errors}
        template={template}
      />

      <BackendError errors={errors} />
    </div>
  );
};
