import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { LabeledText } from "../../LabeledText/LabeledText";
import { DataRow } from "./DataRow";
import { usePointCloudImportProcess } from "../../../hooks/importer/usePointCloudImporterProcess";
import {
  TBuildingData,
  usePointCloudImporter,
} from "./hooks/usePointCloudImporter";
import { Button } from "../../Button";

export const PointCloud: React.FC<{
  workBook: XLSX.WorkBook | null;
  isWorkBook: (state: boolean) => void;
}> = ({ workBook, isWorkBook }) => {
  const { headerData, buildingData, isPointCloutSheet } =
    usePointCloudImporter(workBook);
  const [force, setForce] = useState(Date.now());
  const [selectAll, setSelectAll] = useState(false);
  const [toImport, setToImport] = useState<string[]>([]);
  const [importData, setImportData] = useState<TBuildingData[] | null>(null);
  const { inProgress } = usePointCloudImportProcess(headerData, importData);

  useEffect(() => {
    if (isPointCloutSheet) {
      isWorkBook(true);
    }
  }, [isPointCloutSheet, isWorkBook]);

  const toggleImport = useCallback(
    (path: string) => {
      if (toImport.includes(path)) {
        setToImport(toImport.filter((val) => val !== path));
      } else {
        setToImport([...toImport, path]);
      }
    },
    [toImport, setToImport]
  );

  const processImportData = () => {
    if (!buildingData) {
      return;
    }
    setImportData(
      buildingData?.filter((val) => toImport.includes(val.path ?? ""))
    );
  };

  useEffect(() => {
    if (importData && !inProgress) {
      setForce(Date.now());
      setImportData(null);
      setToImport([]);
    }
  }, [inProgress, importData]);

  // toggle all data to import
  useEffect(() => {
    if (!buildingData) {
      return;
    }

    if (!selectAll) {
      setToImport([]);
    } else {
      setToImport(buildingData.map((val) => val.path ?? ""));
    }
  }, [selectAll, buildingData, setToImport]);

  if (!headerData || !buildingData || !isPointCloutSheet) {
    return null;
  }

  return (
    <div>
      <div className="flex">
        <h1 className="mr-4">Punktwolken Import</h1>
        <Button
          disabled={!toImport.length || inProgress}
          buttonStyle="outline"
          onClick={() => processImportData()}
          intent="success"
        >
          Importieren
        </Button>
      </div>
      <h2>Allgemeine Auftragsdaten:</h2>
      <div className="flex justify-between" style={{ width: "600px" }}>
        <LabeledText label="Los:" value={headerData.los} />
        <LabeledText label="Abruf-Nr:" value={headerData.retrievalNumber} />
        <LabeledText label="Firma:" value={headerData.company} />
      </div>
      <table className="table-auto border-collapse border border-border">
        <thead>
          <tr>
            <th className="border border-border p-1">
              <input
                type="checkbox"
                checked={selectAll}
                onChange={() => setSelectAll(!selectAll)}
              />
            </th>
            <th className="border border-border p-1">Pfad</th>
            <th className="border border-border p-1">WE</th>
            <th className="border border-border p-1">GE</th>
            <th className="border border-border p-1">Kategorie</th>
            <th className="border border-border p-1">Cluster</th>
            <th className="border border-border p-1">Tiefe</th>
            <th className="border border-border p-1">Preis</th>
            <th colSpan={2}>Prüfungen</th>
            <th className="border border-border p-1">Punktwolke vorhanden</th>
          </tr>
        </thead>
        <tbody>
          {buildingData.map((data, index) => (
            <DataRow
              force={force}
              {...data}
              selectedImports={toImport}
              toggleImport={toggleImport}
              key={`import-${index}`}
              company={headerData.company}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
