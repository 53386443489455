import * as React from "react";
import { TModalProps } from ".";
import { Backdrop } from "./Backdorop";
import { ModalClose } from "./ModalClose";

export const ModalContent: React.FunctionComponent<TModalProps> = ({
  onClose,
  children,
  isOpen = false,
  backdrop = true,
  closeOnEscape = true,
  title = "",
  top = "200px",
  closeOnClick = false,
  width = "400px",
}) => {
  React.useLayoutEffect(() => {
    const escDetect = (e: KeyboardEvent) => {
      if (!onClose) {
        return;
      }

      if (e.key === "Escape" || e.code === "Escape" || e?.keyCode === 27) {
        onClose();
      }
    };

    if (closeOnEscape === true && typeof onClose === "function") {
      if (isOpen === true) {
        document.body.addEventListener("keyup", escDetect);
      } else {
        document.body.removeEventListener("keyup", escDetect);
      }
    }

    return () => {
      document.body.removeEventListener("keyup", escDetect);
    };
  }, [isOpen, closeOnEscape, onClose]);

  return (
    <div className={!isOpen ? "hidden" : ""}>
      <Backdrop
        onClick={onClose}
        closeOnClick={closeOnClick}
        backdrop={backdrop}
      >
        <div
          role="dialog"
          data-testid="modal"
          className={`bg-white rounded border border-gray-800 -translate-y-1/2 -translate-x-1/2 absolute`}
          style={{
            top: top,
            left: "50%",
            transform: "translate(-50%, -50%)",
            width,
          }}
        >
          <div className="flex justify-between pl-5 bg-gray-600 text-white">
            <div>{title}</div>
            <ModalClose onClose={onClose} />
          </div>
          <div className="p-5 ">{children}</div>
        </div>
      </Backdrop>
    </div>
  );
};
