import React from "react";
import { connect } from "react-redux";
import { TUiSettings } from "../../config/uiSettings";
import { RootState } from "../../store";
import { BoolSettings } from "./BoolSettings";
import { MenuSelection } from "./MenuSelection";

type TSettingElementProps = TUiSettings & ReturnType<typeof mapStateToProps>;

const SettingElementComponent: React.FunctionComponent<TSettingElementProps> = (
  props
) => {
  switch (props.type) {
    case "selection":
      return <MenuSelection {...props} />;
    case "boolean":
      return (
        <BoolSettings
          value={props.settings[props.valueKey]}
          valueKey={props.valueKey}
        />
      );
    default:
      throw new Error(`No Settings Editor found for ${props.type}`);
  }
};

const mapStateToProps = (state: RootState) => ({
  settings: state.settings,
});

export const SettingElement = connect(mapStateToProps)(SettingElementComponent);
