import { useEffect, useState } from "react";
import axios from "axios";
import { leadingZeroFormatter } from "../../helper/formatter";

export type TCardResponse = {
  buildingNumber: number;
  companyName: string;
  designation: string;
  economicUnit: number;
  lastUpdate: string;
  los: number;
  office: string;
  officeName: string;
  path: string;
  formattedBuildingNumber: string;
  formattedEconomicUnit: string;
};

export type TPointCloudBoardResponse = {
  notDelivered: TCardResponse[];
  delivered: TCardResponse[];
  received: TCardResponse[];
  checked: TCardResponse[];
  billed: TCardResponse[];
};
const intervall = 3 * 60 * 1000;

export const useGetPointCloudBoard = (force = 0) => {
  const [data, setData] = useState<TPointCloudBoardResponse | null>(null);

  useEffect(() => {
    const process = async () => {
      try {
        const response = await axios.get<TPointCloudBoardResponse>(
          "/board/point-cloud/"
        );

        for (const key of Object.keys(response.data)) {
          const k = key as keyof typeof response.data;
          for (const d of response.data[k]) {
            d.formattedBuildingNumber = leadingZeroFormatter(d.buildingNumber);
            d.formattedEconomicUnit = leadingZeroFormatter(d.economicUnit);
          }
        }

        setData(response.data);
      } catch (err) {}
    };

    process();
    const timer = window.setInterval(() => {
      setData(null);
      process();
    }, intervall);

    return () => {
      window.clearInterval(timer);
    };
  }, [force, setData]);

  return data;
};
