type TWorkflowNotStartedProps = {
  isLoading: boolean;
  isEmpty: boolean;
};

export const WorkflowNotStarted: React.FunctionComponent<
  TWorkflowNotStartedProps
> = ({ isLoading, isEmpty }) => {
  if (isLoading || !isEmpty) {
    return null;
  }

  return (
    <div
      className="w-full text-lg font-bold flex flex-col justify-center"
      style={{ height: "55px" }}
    >
      <div className="h-6 text-center">Keine Einträge vorhanden</div>
    </div>
  );
};
