import Axios from "axios";
import { useEffect, useState } from "react";
import { TOrderTypes } from "../types/order";
type TGetCommentsState = {
  loading: boolean;
  comments: TCommentResult[];
  count: number;
};

export type TCommentResult = {
  id: number;
  user: number;
  username: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
};

// TODO: force con boolean zu number ändern
export const useGetComments = (
  buildingId: number | null | undefined,
  type: TOrderTypes,
  force: number
) => {
  const [state, setState] = useState<TGetCommentsState>({
    loading: false,
    comments: [],
    count: 0,
  });

  useEffect(() => {
    if (!buildingId) {
      return;
    }

    const fetchData = async (fetchId: number) => {
      const result = await Axios.get<TCommentResult[]>(
        `/comments/${type}/${fetchId}/`
      );

      const comments: TCommentResult[] = result.data;

      setState({
        loading: false,
        comments,
        count: comments.length,
      });
    };

    setState({ comments: [], count: 0, loading: true });
    fetchData(buildingId);
  }, [buildingId, type, force, setState]);

  return {
    comments: state.comments,
    loading: state.loading,
    count: state.count,
  };
};
