import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBorderAll,
  faCheckSquare,
  faCloudDownloadAlt,
  faCogs,
  faHome,
  faSignOutAlt,
  faTable,
  faUserCog,
  faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import { TSidebarConf } from "../components/Sidebar/types";
import {
  ROUTE_ABOUT,
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_SETTINGS,
  ROUTE_ADMIN,
  ROUTE_MEASURE_BOARD,
  ROUTE_BUILDING_LIST,
  ROUTE_CHANGELOG,
  ROUTE_CSV_IMPORT,
  ROUTE_DOWNLOADS,
  ROUTE_FILE_ADMIN,
  ROUTE_INDEX,
  ROUTE_LOGOUT,
  ROUTE_MIGRATE_ADMIN,
  ROUTE_REPORTS,
  ROUTE_SMB,
  ROUTE_SMB_BOARD,
  ROUTE_TOKEN_MANAGEMENT,
  ROUTE_UI_SETTINGS,
  ROUTE_USER_MANAGEMENT,
  ROUTE_POINT_CLOUD_BOARD,
  ROUTE_BOARDS,
  ROUTE_VACATION_ADMIN,
} from "./routes";
library.add(
  faBorderAll,
  faCheckSquare,
  faCloudDownloadAlt,
  faCogs,
  faHome,
  faSignOutAlt,
  faTable,
  faUserCog,
  faWindowRestore
);

export const SMB_SIDEBAR_BOARD_CONFIG: TSidebarConf = {
  text: "SMB-Board",
  icon: "border-all",
  link: ROUTE_BOARDS + ROUTE_SMB + ROUTE_SMB_BOARD,
  tooltip: "Board nach Team und Bearbeiter",
};

export const SIDEBAR_CONFIG: TSidebarConf[] = [
  {
    text: "Startseite",
    link: ROUTE_INDEX,
    icon: faHome,
    tooltip: "Startseite",
  },
  {
    text: "Daten",
    link: ROUTE_BUILDING_LIST,
    icon: "table",
    tooltip: "Daten Übersicht aller Gebäude",
  },
  {
    text: "Projektboard",
    active: ROUTE_BOARDS,
    icon: "window-restore",
    tooltip: "Projektboards für den schnellen Überblick",
    children: [
      {
        text: "Aufmaß",
        link: ROUTE_BOARDS + ROUTE_MEASURE_BOARD,
      },
      {
        text: "Punktwolke",
        link: ROUTE_BOARDS + ROUTE_POINT_CLOUD_BOARD,
      },
    ],
  },
  {
    text: "Einstellungen",
    icon: "user-cog",
    active: ROUTE_ACCOUNT,
    children: [
      {
        text: "Ihr Account",
        link: ROUTE_ACCOUNT + ROUTE_ACCOUNT_SETTINGS,
      },
      {
        text: "Anwendungseinstellungen",
        link: ROUTE_ACCOUNT + ROUTE_UI_SETTINGS,
      },
      {
        text: "Versionsänderungen",
        link: ROUTE_ACCOUNT + ROUTE_CHANGELOG,
      },
      {
        text: "Über Dora",
        link: ROUTE_ACCOUNT + ROUTE_ABOUT,
      },
    ],
  },
  {
    text: "Downloads",
    icon: "cloud-download-alt",
    link: ROUTE_DOWNLOADS,
    tooltip: "Downloads / Reports",
  },
  {
    text: "Abmelden",
    link: ROUTE_LOGOUT,
    icon: "sign-out-alt",
    tooltip: "Abmelden von Dora",
  },
];

export const ADMIN_SMB_SIDEBAR_CONFIG: TSidebarConf = {
  text: "Admin",
  icon: "cogs",
  active: ROUTE_ADMIN,
  children: [
    {
      text: "Excel Import",
      link: ROUTE_ADMIN + ROUTE_CSV_IMPORT,
    },
    {
      text: "Reports",
      link: ROUTE_ADMIN + ROUTE_REPORTS,
    },
    {
      text: "Benutzer",
      link: ROUTE_ADMIN + ROUTE_USER_MANAGEMENT,
    },
    {
      text: "Dateien",
      link: ROUTE_ADMIN + ROUTE_FILE_ADMIN,
    },
    {
      text: "Token",
      link: ROUTE_ADMIN + ROUTE_TOKEN_MANAGEMENT,
    },
    {
      text: "Migration",
      link: ROUTE_ADMIN + ROUTE_MIGRATE_ADMIN,
    },
    {
      text: "Urlaubstage",
      link: ROUTE_ADMIN + ROUTE_VACATION_ADMIN,
    },
  ],
};
