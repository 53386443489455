import { useEffect, useState } from "react";
import axios from "axios";
import { STATUS_CODES } from "../../config/app";

export const useVerifyOffice = (office = "", force = 0) => {
  const [isValid, setValid] = useState<null | boolean>(null);

  useEffect(() => {
    if (!office) {
      return;
    }

    const process = async () => {
      setValid(true);
      try {
        await axios.get(`/verify/office?office=${office}`);
        setValid(true);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response?.status === STATUS_CODES.Not_Acceptable) {
            setValid(false);
          } else {
            // toast(`Fehler bei der Überprüfung vom Amt: ${office}`, {
            //   intent: "danger",
            //   autoClose: null,
            // });
          }
        }
      }
    };

    process();
  }, [office, force, setValid]);

  return isValid;
};
