import * as React from "react";

type TWelcomeProps = {
  text: string;
};

export const Welcome: React.FunctionComponent<TWelcomeProps> = ({ text }) => {
  if (text === "") {
    return null;
  }

  return (
    <div className="text-center" data-testid="login-welcome">
      {text}
    </div>
  );
};
