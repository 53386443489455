import React from "react";
import { TPointCloudWorkflowResponse } from "../../hooks/pointcloud/usePointCloudWorkflow";
import { DataTable } from "../DataTable/DataTable";
import { QualityControlTableHeader } from "../../config/qualityTable";

type TPointCloudStatesProps = {
  data: TPointCloudWorkflowResponse[];
};

export const PointCloudStates: React.FC<TPointCloudStatesProps> = ({
  data,
}) => {
  if (!data.length) {
    return <>Workflow wurde noch nicht gestartet</>;
  }

  return (
    <DataTable
      orderDirection="ASC"
      config={QualityControlTableHeader}
      data={data}
      limit={data.length}
      orderField="deliveryDate"
      count={10}
    />
  );
};
