import { bytesToSize } from "../../helper/bytesTosize";
import * as XLSX from "xlsx";
import { useEffect, useState } from "react";
import moment from "moment";

type TFileInfoProps = { file: File | null; workBook: XLSX.WorkBook | null };

export const FileInfo: React.FC<TFileInfoProps> = ({ file, workBook }) => {
  const [createDate, setCreateDate] = useState("");
  const [updateDate, setUpdateDate] = useState("");

  useEffect(() => {
    if (workBook === null) {
      return;
    }

    setCreateDate(moment(workBook.Props?.CreatedDate).format("DD.MM.YYYY"));
    setUpdateDate(moment(workBook.Props?.ModifiedDate).format("DD.MM.YYYY"));
  }, [workBook, setCreateDate, setUpdateDate]);

  if (!file) {
    return null;
  }

  return (
    <div className="flex">
      <div className="p-1">Filename:</div>
      <div className="p-1">{file.name}</div>
      <div className="ml-3 p-1">Größe:</div>
      <div className="p-1">{bytesToSize(file.size)}</div>
      <div className="p-1">Erstellt am:</div>
      <div className="p-1">{createDate}</div>
      <div className="p-1">Letztes Update</div>
      <div className="p-1">{updateDate}</div>
    </div>
  );
};
