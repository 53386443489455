import { Reducer } from "redux";
import { TMeasureDetailResponse } from "../editOrder/types";
import {
  ADD_DATA,
  RESET_SERVERDATA,
  SERVER_DATA_FORCE,
  TServerDataAction,
} from "./action";
export type TServerState = {
  count: number | null;
  data: TMeasureDetailResponse[];
  force: boolean;
};

const initialState = {
  count: 0,
  data: [],
  force: false,
};

export const serverDataReducer: Reducer<TServerState, TServerDataAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ADD_DATA:
      return {
        force: false,
        count: action.payload.count === 0 ? null : action.payload.count,
        data: [...action.payload.rows],
      };
    case RESET_SERVERDATA:
      return {
        force: false,
        count: 0,
        data: [],
      };
    case SERVER_DATA_FORCE:
      return {
        ...state,
        force: true,
      };
    default:
      return state;
  }
};
