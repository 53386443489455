import React from "react";
import {
  QualityControlTableHeader,
  TQualityResponse,
} from "../../config/qualityTable";
import { DataTable } from "../DataTable/DataTable";

type TDisplayStatusProps = {
  data: TQualityResponse[];
};

export const WorkflowStatusTable: React.FunctionComponent<
  TDisplayStatusProps
> = ({ data }) => {
  const limit = data.length;
  if (data.length === 0) {
    return null;
  }
  return (
    <DataTable
      orderDirection="ASC"
      config={QualityControlTableHeader}
      data={data}
      limit={limit}
      orderField="deliveryDate"
      count={10}
    />
  );
};
