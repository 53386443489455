import Axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { ROUTE_INDEX } from "../../config/routes";
import { RootState } from "../../store";
import { setShowLogin } from "../../store/appState/action";
import { fetchUser, setUser } from "../../store/user/action";
import { IUserState } from "../../store/user/types";
import { TLoginError, Login } from "../../components/Login";
type TLoginProps = ReturnType<typeof mapDispatachToProps> &
  ReturnType<typeof mapStateToProps>;

const doLogin = async (username: string, password: string) => {
  try {
    const result = await Axios.post("/login", {
      email: username,
      password,
    });
    return result.data as IUserState;
  } catch (error) {
    return false;
  }
};

const LoginComponent: React.FunctionComponent<TLoginProps> = ({
  hasData,
  ensureUser,
  disableLogin,
  getUser,
}) => {
  const rememberKey = "doraLoginUser";
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState<TLoginError | null>(null);
  const [isRequest, setRequest] = useState(false);
  const [isRemember, setRemember] = useState(false);
  const rememberUsername = localStorage.getItem(rememberKey);

  useEffect(() => {
    if (rememberUsername) {
      setRemember(true);
      setUsername(rememberUsername);
    }
  }, [rememberUsername, setUsername, setRemember]);

  const login = async () => {
    setRequest(true);
    const response: IUserState | false = await doLogin(username, password);

    if (response === false) {
      setRequest(false);
      setError({
        userError: "",
        passwordError: "Bitte überprüfen Sie Benutzernamen und Passwort",
      });
      return;
    }
    if (window.location.pathname === "/") {
      window.location.href = ROUTE_INDEX;
    }
    ensureUser(response);
    getUser();
    disableLogin();
  };

  const onUsernameChange = (v: string) => {
    if (v === username) {
      return;
    }

    if (isRemember) {
      localStorage.setItem(rememberKey, v);
    }
    setUsername(v);
  };
  if (!hasData) {
    return null;
  }

  return (
    <div className="w-96 mt-28 ml-auto mr-auto">
      <Login
        onLogin={() => login()}
        username={username}
        onUserChange={(u: string) => onUsernameChange(u)}
        password={password}
        onPasswordChange={(p: string) => setPassword(p)}
        error={error}
        isLoading={isRequest}
        isRemember={isRemember}
        onRememberChange={() => setRemember(!isRemember)}
        showRemember={true}
      />
    </div>
  );
};
const mapDispatachToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  ensureUser: (data: IUserState) => dispatch(setUser(data)),
  disableLogin: () => dispatch(setShowLogin(false)),
  getUser: () => dispatch(fetchUser()),
});

const mapStateToProps = (state: RootState) => ({
  hasData: state.app.hasData,
});

export const DoraLogin = connect(
  mapStateToProps,
  mapDispatachToProps
)(LoginComponent);
