import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { SmbToastContext } from "../../context/SmbTabsContext";

export type TMoSyncVerifyResponse = {
  dwgArchive: string | null;
  dwgSyncDate: string | null;
  moSync: string | null;
  neAllocation: string | null;
  nrf: number;
};

export const useVerifyMoSyncImport = (path?: string, force?: number) => {
  const [data, setData] = useState<null | TMoSyncVerifyResponse>(null);

  const { addToast } = useContext(SmbToastContext);
  useEffect(() => {
    if (!path) {
      return;
    }

    const process = async () => {
      try {
        const response = await axios.get<TMoSyncVerifyResponse>(
          `/verify/mo-sync?path=${path}`
        );

        setData(response.data);
      } catch (err) {
        if (addToast) {
          addToast({
            message: `Fehler beim Laden der Daten für ${path}`,
            intent: "warning",
            autoClose: 5000,
          });
        }
      }
    };

    process();
  }, [path, force, setData, addToast]);

  return data;
};
