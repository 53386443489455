import { TOAST_CLEAR, TOAST_TRIGGER } from "./action";
import { ToastActionTypes } from "./types";

const initialState = {
  message: "",
  icon: null,
  intent: "none",
  timeout: 5000,
};

export function toastStateReducer(
  state = initialState,
  action: ToastActionTypes
) {
  switch (action.type) {
    case TOAST_TRIGGER:
      return {
        ...state,
        ...action.payload,
      };
    case TOAST_CLEAR:
      return {
        ...initialState,
      };

    default:
      return state;
  }
}
