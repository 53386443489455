import * as XLSX from "xlsx";
import { useEffect, useState } from "react";
import { getJsDateFromExcel } from "excel-date-to-js";
import moment from "moment";

const DATA_START = 2;
const SHEET_NAME = "Übersicht";

const DATA_MAPPING: Record<keyof TDeadlineImportData, string> = {
  path: "A",
  economicUnit: "B",
  buildingNumber: "C",
  description: "D",
  deadline: "E",
};

export type TDeadlineImportData = {
  path?: string;
  economicUnit?: number;
  buildingNumber?: number;
  description?: number;
  deadline?: string;
};

export const useDeadlineImporter = (workBook: XLSX.WorkBook | null) => {
  const [isDeadlineSheet, setIsDeadlineSheet] = useState(false);

  const [importData, setImportData] = useState<TDeadlineImportData[] | null>(
    null
  );

  useEffect(() => {
    setIsDeadlineSheet(workBook?.SheetNames.includes(SHEET_NAME) ?? false);
  }, [workBook, setIsDeadlineSheet]);

  useEffect(() => {
    if (!workBook || !isDeadlineSheet) {
      return;
    }

    let index = DATA_START;
    const sheetData = workBook.Sheets[SHEET_NAME];

    const impData: TDeadlineImportData[] = [];
    const keys = Object.keys(DATA_MAPPING);
    while (sheetData[`${DATA_MAPPING.path}${index}`]) {
      const t: TDeadlineImportData = {};

      for (const key of keys) {
        const k = key as keyof TDeadlineImportData;
        if (key !== "deadline") {
          t[k] = sheetData[`${DATA_MAPPING[k]}${index}`].v;
        } else {
          t.deadline = moment(
            getJsDateFromExcel(sheetData[`${DATA_MAPPING[k]}${index}`].v)
          ).format("YYYY-MM-DD");
        }
      }

      impData.push(t);
      ++index;
    }
    setImportData(impData);
  }, [isDeadlineSheet, workBook, setImportData]);

  return { isDeadlineSheet, importData };
};
