import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { BuildingTabsContext } from "../../context/BuildingTabs";
import { Comments } from "../Comments/Comments";
import { useGetEditData } from "../../hooks/measure/useGetEditData";
import { RootState } from "../../store";
import { TTabs } from "../../config/tabs";
import { AccordionPane } from "../AccordionPane/AccordionPane";
import { GridCell } from "./GridCell";
import {
  currencyFormatter,
  dateFormatter,
  formatReadonly,
  leadingZeroFormatter,
  measurePriorityFormatter,
  numberFormatter,
  requiredTextFormatter,
  yesNoFormatter,
} from "../../helper/formatter";
import TeamSelection from "../TeamSelection";
import { TeamPersonSelection } from "../TeamPersonSelection/TeamPersonSelection";
import { NrfPrice } from "../NrfPrice/NrfPrice";
import { MeasureWorkflow } from "../MeasureWorkflow/MeasureWorkflow";
import { InputDate } from "../InputDate/InputDate";
import { InputText } from "../InputText/InputText";
import { InputCheckbox } from "../InputCheckbox/InputCheckbox";
import { InputNumber } from "../InputNumber/InputNumber";
import { InputTextarea } from "../../InputTextarea";
import axios from "axios";
import { SmbToastContext, TSmbToast } from "../../context/SmbTabsContext";
import { ButtonCopy } from "../ButtonCopy/ButtonCopy";
import { QualityBadges } from "../QualityBadges/QualityBadges";
import { InputSelect } from "../InputSelect/InputSelect";
import { Button } from "../Button";

type TNrfDetailProps = { tab?: TTabs } & ReturnType<typeof mapStateToProps>;

export type TSaveData = Record<string, any>;

const MeasureEditComponent: React.FunctionComponent<TNrfDetailProps> = ({
  companyType,
  companyId,
  tab,
}) => {
  const { building, addDisplayTab, displayTabs, activeTab } =
    React.useContext(BuildingTabsContext);
  const [force, setForce] = useState(Date.now());
  const [buildingId, setBuildingId] = useState<number>();
  const data = useGetEditData(buildingId, force);
  const [saveData, setSaveData] = useState<TSaveData | null>();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const { addToast } = React.useContext(SmbToastContext);

  const processSaveData = useCallback(async () => {
    if (!companyType || !saveData || !buildingId) {
      return;
    }

    let message: TSmbToast = {
      message: "Speichern erfolgreich",
      intent: "success",
      autoClose: 3000,
    };
    try {
      await axios.put(`/measure/${companyType}/${buildingId}/`, {
        data: saveData,
      });
      setSaveData(null);
      setForce(Date.now());
      setSaveDisabled(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        message = {
          message: "Fehler beim Speichern",
          intent: "error",
          autoClose: 0,
        };
      }
    }
    if (addToast) {
      addToast(message);
    }
  }, [
    saveData,
    buildingId,
    companyType,
    setForce,
    setSaveData,
    setSaveDisabled,
    addToast,
  ]);
  const updateSaveData = useCallback(
    (value: TSaveData) => {
      let toSave = { ...value };
      if (saveData) {
        toSave = { ...saveData, ...value };
      }

      setSaveData(toSave);
      setSaveDisabled(false);
    },
    [saveData, setSaveData, setSaveDisabled]
  );

  useEffect(() => {
    const detectKeyBoard = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === "s") {
        e.preventDefault();
        const target = e.target as HTMLInputElement;
        target.blur();

        if (!saveDisabled) {
          processSaveData();
        }

        target.focus();
        return false;
      }
    };
    document.body.addEventListener("keydown", detectKeyBoard, {
      passive: false,
    });

    return () => document.body.removeEventListener("keydown", detectKeyBoard);
  }, [saveDisabled, processSaveData]);

  useEffect(() => {
    if (building) {
      setBuildingId(building);
    }
  }, [building, setBuildingId]);

  useEffect(() => {
    if (data && tab && addDisplayTab && displayTabs?.includes(tab)) {
      addDisplayTab(tab);
    }
  }, [data, tab, displayTabs, addDisplayTab]);

  if (!building || data === null || activeTab !== tab) {
    return null;
  }

  return (
    <div className="h-full overflow-hidden relative">
      <div
        className="w-28 text-right fixed z-50"
        style={{ top: "60px", right: "40px" }}
      >
        <Button
          intent="success"
          disabled={saveDisabled}
          onClick={processSaveData}
        >
          <FontAwesomeIcon icon={faSave} className={"z-50"} /> Speichern
        </Button>
      </div>

      <div className="h-full mt-1 pb-10 " style={{ minWidth: "780px" }}>
        {
          // Details uim Auftrag
        }
        <AccordionPane
          headline={"Auftrag"}
          widgetName={"aufmass-auftrag"}
          headlineElements={
            <>
              <QualityBadges
                type="plausibilityCheck"
                buildingId={data?.buildingId}
              />
              <QualityBadges type="qsCheck" buildingId={data?.buildingId} />
            </>
          }
        >
          <GridCell label={"Abruf-Nr."}>{data.orderNumber}</GridCell>
          <GridCell label={"Datum Abruf"}>
            {dateFormatter(data.dateRetrieval)}
          </GridCell>

          <GridCell label={"Beauftragung durch Amt am"} colSpan={3}>
            <InputDate
              value={data.instructedOffice ?? ""}
              onChange={(val) => updateSaveData({ instructedOffice: val })}
              canEdit={
                (companyType === "amt" || companyType === "smb") &&
                !data.instructedOffice
              }
            />
          </GridCell>

          <GridCell label={"Los"}>{data.los}</GridCell>

          <GridCell label={"Firma"}>{data.companyName}</GridCell>

          <GridCell label={"Leistung"}>{data.displayedName}</GridCell>

          <GridCell label={"Qualitätsprüfung-Betreuung durch Team"} colSpan={2}>
            <TeamSelection
              canEdit={companyType === "smb"}
              onSelect={(team: string) => updateSaveData({ qsTeam: team })}
              value={data.qsTeam ?? ""}
            />
          </GridCell>
        </AccordionPane>

        {
          // Auftragspositionen
        }
        <AccordionPane
          widgetName={"aufmass-auftragspositionen"}
          headline={"Auftragspositionen"}
        >
          <GridCell label={"vereinbarter Liefertermin"}>
            <InputDate
              value={data.deadline ?? ""}
              onChange={(val: string | null) =>
                updateSaveData({ deadline: val })
              }
              canEdit={companyType === "smb"}
            />
          </GridCell>
          <GridCell label={"Verzögerung Aufmaß weil"} colSpan={2}>
            <InputText
              onChange={(val: string) => updateSaveData({ delayReason: val })}
              value={data.delayReason}
              canEdit={companyType === "smb"}
            />
          </GridCell>
          <GridCell label={"Letzte Bearbeitung"}>
            {formatReadonly(dateFormatter(data.updated))}
          </GridCell>
          <GridCell label={"Qualitätsprüfung durch"}>
            <TeamPersonSelection
              canEdit={companyType === "smb"}
              qsPersonId={data.qsPersonId ?? -1}
              qsOfficeId={data.qsOfficeId ?? -1}
              onChange={updateSaveData}
              readOnlyValue={data.qsPerson ?? ""}
            />
          </GridCell>

          <GridCell label={"Pilotobjekt"}>
            <InputCheckbox
              value={data.isPilot === "yes"}
              canEdit={companyType === "smb"}
              readonlyValue={yesNoFormatter(data.isPilot)}
              onChange={(val: boolean) => {
                updateSaveData({
                  isPilot: val ? "yes" : "no",
                });
              }}
            />
          </GridCell>
          <GridCell label={"Kat."}>{data.category}</GridCell>
          <GridCell label={"FC."}>{data.areaCluster}</GridCell>
          <GridCell label={"Detailtiefe"}>{data.detailLevel}</GridCell>
          <GridCell label={"Schnitte / Ansichten"}>
            <InputSelect
              canEdit={companyType === "smb"}
              value={data.cutsViews}
              readonlyValue={requiredTextFormatter(data.cutsViews)}
              selection={{ Yes: "Erforderlich", No: "Nicht erforderlich" }}
              onChange={(val) => updateSaveData({ cutsViews: val })}
            />
          </GridCell>
          <GridCell label={""}>
            <ButtonCopy
              toCopy={`${data.office}: WE: ${leadingZeroFormatter(
                data.economicUnit
              )} Geb: ${leadingZeroFormatter(data.buildingNumber)}, ${
                data.designation ?? ""
              }`}
            >
              Auftrags String
            </ButtonCopy>
          </GridCell>
          <GridCell label={"NRF"}>
            {numberFormatter(data.nrfEstimated)} m²
          </GridCell>
          <GridCell label={"NRF Erfassung"}>
            <InputNumber
              value={data.nrfMeasurement ?? 0}
              onChange={(val) => updateSaveData({ nrfMeasurement: val })}
              readonlyValue={currencyFormatter(
                numberFormatter(data.nrfMeasurement)
              )}
              canEdit={companyType === "firma"}
            />{" "}
            m²
          </GridCell>
          <GridCell label={"NRF Geprüft"} colSpan={2}>
            <InputNumber
              value={data.nrfChecked ?? 0}
              onChange={(val) => updateSaveData({ nrfChecked: val })}
              readonlyValue={numberFormatter(data.nrfChecked)}
              canEdit={
                ((companyType === "smb" && !data.qsOfficeId) ||
                  data.qsOfficeId === companyId) &&
                data.nrfMeasurement !== null &&
                data.nrfMeasurement > 0
              }
            />{" "}
            m²
          </GridCell>
          <GridCell label={"€/m²"}>
            <InputNumber
              value={data.priceUnit ?? 0}
              onChange={(val) => updateSaveData({ priceUnit: val })}
              readonlyValue={currencyFormatter(data.priceUnit)}
              canEdit={companyType === "smb"}
            />
          </GridCell>
          <GridCell label={"NRF"}>
            <NrfPrice
              priceGross={data.priceGross}
              priceNet={data.priceNet}
              color={"base"}
            />
          </GridCell>
          <GridCell label={"NRF Erfassung"}>
            <NrfPrice
              priceGross={data.priceMeasuredGross}
              priceNet={data.priceMeasuredNet}
              color={"measured"}
            />
          </GridCell>
          <GridCell label={"NRF Geprüft"}>
            <NrfPrice
              priceGross={data.priceCheckedGross}
              priceNet={data.priceCheckedNet}
              color={"checked"}
            />
          </GridCell>
          <GridCell label={"Priorität"}>
            <InputSelect
              canEdit={companyType === "smb"}
              value={data.priority}
              readonlyValue={measurePriorityFormatter(data.priority)}
              selection={{ "99": "Nicht festgelegt", "10": "1", "20": "2" }}
              onChange={(val) => updateSaveData({ priority: val })}
            />
          </GridCell>
        </AccordionPane>

        <AccordionPane
          widgetName={"aufmass-kommentare"}
          headline={"Kommentare"}
          gridItems={1}
        >
          <Comments buildingId={data?.buildingId} orderType="measure" />
        </AccordionPane>

        <AccordionPane
          widgetName={"ps-check"}
          headline={"Plausibilitätsprüfung"}
          gridItems={1}
        >
          <div>
            <MeasureWorkflow
              workflowType="plausibilityCheck"
              buildingId={data?.buildingId}
              orderType="measure"
              force={force}
              companyType={companyType}
              forceFetch={() => {
                setForce(Date.now());
              }}
            />
          </div>
        </AccordionPane>

        <AccordionPane
          widgetName={"qs-check"}
          gridItems={1}
          headline={"Qualitätsprüfung"}
        >
          <div>
            <MeasureWorkflow
              companyType={companyType}
              force={force}
              workflowType="qsCheck"
              buildingId={data?.buildingId}
              orderType="measure"
              companyId={companyId}
              qsOffice={data.qsOfficeId}
              forceFetch={() => {
                setForce(Date.now());
              }}
            />
          </div>
        </AccordionPane>

        <AccordionPane
          widgetName={"aufmass-delete"}
          headline={"Löschprotokoll Flächenerfasser"}
        >
          <GridCell label={"Name Mitarbeiter"}>
            <InputText
              onChange={(val) => updateSaveData({ erasePerson: val })}
              value={data.erasePerson}
              canEdit={
                companyType === "firma" &&
                (!data.erasePerson ||
                  !data.eraseDate ||
                  !data.eraseEmailSendDate)
              }
            />
          </GridCell>

          <GridCell label={"Daten gelöscht"}>
            <InputDate
              value={data.eraseDate ?? ""}
              onChange={(val) => updateSaveData({ eraseDate: val })}
              canEdit={
                companyType === "firma" &&
                (!data.erasePerson ||
                  !data.eraseDate ||
                  !data.eraseEmailSendDate)
              }
            />
          </GridCell>

          <GridCell label={"E-Mail gesendet"}>
            <InputDate
              value={data.eraseEmailSendDate ?? ""}
              onChange={(val) => updateSaveData({ eraseEmailSendDate: val })}
              canEdit={
                companyType === "firma" &&
                (!data.erasePerson ||
                  !data.eraseDate ||
                  !data.eraseEmailSendDate)
              }
            />
          </GridCell>
        </AccordionPane>

        <AccordionPane
          widgetName={"aufmass-bill"}
          headline={"Rechnung Flächenerfasser"}
        >
          <GridCell label={"Re-Nr"}>
            <InputText
              onChange={(val) => updateSaveData({ billNumber: val })}
              value={data.billNumber}
              canEdit={
                companyType === "firma" && (!data.billNumber || !data.billDate)
              }
            />
          </GridCell>

          <GridCell label={"Datum"} colSpan={4}>
            <InputDate
              value={data.billDate ?? ""}
              onChange={(val) => updateSaveData({ billDate: val })}
              canEdit={
                companyType === "firma" && (!data.billNumber || !data.billDate)
              }
            />
          </GridCell>

          <GridCell label={"Rechnung bezahlt"}>
            <InputDate
              value={data.billingDate ?? ""}
              onChange={(val) => updateSaveData({ billingDate: val })}
              canEdit={companyType === "amt"}
            />
          </GridCell>

          <GridCell label={"Kommentar"} colSpan={4}>
            <InputTextarea
              value={data.billingComment ?? ""}
              onChange={(val) => updateSaveData({ billingComment: val })}
              canEdit={companyType === "amt"}
            />
          </GridCell>
        </AccordionPane>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyType: state.user.companyType,
  companyId: state.user.companyId,
  userType: state.user.type,
});

export const MeasureEdit = connect(mapStateToProps)(MeasureEditComponent);
