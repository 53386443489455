import { RouteComponentProps } from "@reach/router";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ROUTE_BOARDS,
  ROUTE_DOWNLOADS,
  ROUTE_LOGOUT,
} from "../../config/routes";
import {
  ADMIN_SMB_SIDEBAR_CONFIG,
  SIDEBAR_CONFIG,
  SMB_SIDEBAR_BOARD_CONFIG,
} from "../../config/sidebar";
import { RootState } from "../../store";
import { UserRole } from "../../store/user/types";
import { Sidebar } from "../Sidebar/Sidebar";
import { TSidebarConf } from "../Sidebar/types";
type TNavigationProps = ReturnType<typeof mapStateToProps> &
  RouteComponentProps;

const NavigationComponent: React.FunctionComponent<TNavigationProps> = ({
  userType,
  companyType,
}) => {
  const [navItem, setNavItem] = useState<TSidebarConf[]>([]);

  useEffect(() => {
    let ni = [...SIDEBAR_CONFIG];
    if (companyType === "smb") {
      for (const item of ni) {
        if (item.active === ROUTE_BOARDS) {
          if (item.children) {
            item.children.push(SMB_SIDEBAR_BOARD_CONFIG);
          }
        }
      }
    }

    if (companyType === "smb" && userType === UserRole[UserRole.admin]) {
      ni = ni.map((item) => {
        if (item.link === ROUTE_LOGOUT) {
          return ADMIN_SMB_SIDEBAR_CONFIG;
        } else {
          return item;
        }
      });

      ni.push(SIDEBAR_CONFIG[SIDEBAR_CONFIG.length - 1]);
    }

    if (companyType === "smb" || companyType === "leitung") {
      ni = ni.filter((item) => item.link !== ROUTE_DOWNLOADS);
    }

    setNavItem(ni);
  }, [companyType, userType, setNavItem]);

  return <Sidebar config={navItem} />;
};

const mapStateToProps = (state: RootState) => ({
  userType: state.user.type,
  companyType: state.user.companyType,
});

export const Navigation = connect(mapStateToProps)(NavigationComponent);
