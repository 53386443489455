import React from "react";
import { Icon, TIconNames } from "../../Icon";
import { Tooltip } from "../../../Tooltip";

type TQsStateProps = {
  psFails?: number;
  qsFails?: number;
  tooltip: string;
  icon: TIconNames;
  iconSize: number;
};

export const QsState: React.FC<TQsStateProps> = ({
  psFails,
  qsFails,
  tooltip,
  icon,
  iconSize,
}) => {
  if (typeof qsFails === "undefined" || typeof psFails === "undefined") {
    return null;
  }

  return (
    <div className="flex flex-row mt-0.5">
      <div className="px-2 cursor-help">
        <Tooltip content={tooltip}>
          <Icon icon={icon} size={iconSize} />
        </Tooltip>
      </div>
      <div className="w-1/2">QS Amt Fehler: {psFails}</div>
      <div className="w-1/2">QS Fehler: {qsFails}</div>
    </div>
  );
};
