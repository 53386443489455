import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useBillImporter } from "./hooks/useBillImporter";
import { DataRow } from "./DataRow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { useQsBillImporterProcess } from "../../../hooks/importer/useQsBillImporterProcess";
import { Button } from "../../Button";

export const Bill: React.FC<{
  workBook: XLSX.WorkBook | null;
  isWorkBook: (state: boolean) => void;
}> = ({ workBook, isWorkBook }) => {
  const { isSheet, importData } = useBillImporter(workBook);
  const [force, setForce] = useState(Date.now());
  const [isImportable, setImportable] = useState<string[]>([]);
  const [toImport, setImport] = useState<string[]>([]);
  useEffect(() => {
    if (isSheet) {
      isWorkBook(true);
    }
  }, [isSheet, isWorkBook]);

  const setBillImportable = useCallback(
    (path: string, remove = false) => {
      if (remove) {
        setImportable(isImportable.filter((val) => val !== path));
      } else if (!remove && !isImportable.includes(path)) {
        setImportable([...isImportable, path]);
      }
    },
    [isImportable, setImportable]
  );

  const process = useQsBillImporterProcess(() => {
    setImportable([]);
    setImport([]);
    setTimeout(() => setForce(Date.now()), 1000);
  });

  if (!isSheet) {
    return null;
  }

  return (
    <>
      <div className={"flex"}>
        <h1>SMB Rechnung Import</h1>
        <Button
          className={"ml-2"}
          buttonStyle={"elevated"}
          onClick={() => {
            setImportable([]);
            setTimeout(() => setForce(Date.now()), 600);
          }}
        >
          <FontAwesomeIcon icon={faSync} />
        </Button>
        <Button
          className={"ml-2"}
          intent={"success"}
          disabled={!toImport.length}
          onClick={() => {
            setImportable([]);
            process(
              importData.filter((val) => toImport.includes(val.path ?? ""))
            );
          }}
        >
          Importieren
        </Button>
      </div>
      <table className="table-auto border-collapse border border-border w-full overflow-x-auto text-xs">
        <thead>
          <tr>
            <th className="border border-border p-1 w-16">
              <input
                className={"mr-1"}
                type="checkbox"
                checked={toImport.length === isImportable.length}
                onChange={(e) => {
                  if (e.target.checked) {
                    setImport([...isImportable]);
                  } else {
                    setImport([]);
                  }
                }}
              />
              ({toImport.length})
            </th>
            <th className="border border-border p-1">Pfad</th>
            <th className="border border-border p-1">WE</th>
            <th className="border border-border p-1">GE</th>
            <th className="border border-border p-1">Bezeichnung</th>
            <th className="border border-border p-1">Datum Rechnung 5.3</th>
            <th className="border border-border p-1">Bemerkung / Rechn.-Nr</th>
            <th className="border border-border p-1">Info</th>
          </tr>
        </thead>
        <tbody>
          {importData.map((v, i) => (
            <DataRow
              {...v}
              key={`bill-row-${i}`}
              force={force}
              setImportable={setBillImportable}
              isChecked={toImport.includes(v.path ?? "")}
              isImportable={isImportable.includes(v.path ?? "")}
              setChecked={(checked: boolean, path: string) => {
                if (checked && !toImport.includes(path)) {
                  setImport([...toImport, path]);
                } else {
                  setImport(toImport.filter((val) => val !== path));
                }
              }}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};
