import axios from "axios";
import { useEffect, useState } from "react";

export const useDeleteFile = (id: null | number) => {
  const [success, setSuccess] = useState<null | boolean>(null);
  useEffect(() => {
    if (id === null) {
      return;
    }

    const process = async () => {
      try {
        await axios.delete("/admin/files/" + id);
        // toast("Datei erfolgreich gelöscht", {
        //   intent: "success",
        // });
        setSuccess(true);
      } catch (e) {
        // toast("Fehler beim löschen der Datei", {
        //   intent: "danger",
        // });
      }
    };

    process();
  }, [id]);

  return success;
};
