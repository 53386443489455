import { RouteComponentProps, useLocation } from "@reach/router";
import React from "react";
import { Button } from "../../components/Button";

export const NotFound: React.FunctionComponent<RouteComponentProps> = () => {
  const location = useLocation();
  if (location.pathname === "/") {
    return null;
  }

  return (
    <div className="h-full w-full text-center">
      <div className="mx-auto" style={{ width: "500px" }}>
        <div className="font-bold text-xl">
          404 - Ups ich konnte die Seite nicht finden ¯\_(ツ)_/¯
        </div>
        <div className="mt-5">
          Die Seite <b>{location.pathname} </b>
          scheint es nicht zu geben
        </div>
        <div className="mt-5">
          <Button
            text="Zurück"
            buttonStyle="elevated"
            onClick={() => window.history.back()}
          />
        </div>
      </div>
    </div>
  );
};
