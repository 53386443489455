import React from "react";
import { statusFormatter } from "../../helper/formatter";
import { useLastHistoryStatus } from "../../hooks/useLastHistoryStatus";
import "./index.css";

const badgeLabel = {
  plausibilityCheck: "Plausibilitätsprüfung",
  qsCheck: "QS-Prüfung",
};

type TWorkBadgesProps = {
  type: "plausibilityCheck" | "qsCheck";
  buildingId?: number | null;
};

export const QualityBadges: React.FunctionComponent<TWorkBadgesProps> = ({
  type,
  buildingId,
}) => {
  const qsState = useLastHistoryStatus(type, buildingId);
  if (!buildingId) {
    return null;
  }

  return (
    <div
      className={`flex font-normal mr-3 mt-1 justify-between rounded smb-work-badge smb-${qsState} text-xs truncate`}
    >
      <div className="smb-work-badge-label">{badgeLabel[type]}</div>
      <div className="smb-work-badge-status">
        {statusFormatter(qsState ?? 0)}
      </div>
    </div>
  );
};
