import * as XLSX from "xlsx";
import { useEffect, useState } from "react";
const DATA_START = 2;
export type TPriorityData = {
  path?: string;
  economicUnit?: string;
  buildingNumber?: string;
  description?: string;
  priority?: 1 | 2;
};

export const SHEET_NAME = "Priority";
const DATA_MAPPING: Record<keyof TPriorityData, string> = {
  path: "A",
  economicUnit: "B",
  buildingNumber: "C",
  description: "D",
  priority: "E",
};
export const usePriorityImporter = (workBook: XLSX.WorkBook | null) => {
  const [isPrioritySheet, setIsPrioritySheet] = useState(false);
  const [buildingData, setBuildingData] = useState<TPriorityData[] | null>(
    null
  );

  useEffect(() => {
    setIsPrioritySheet(workBook?.SheetNames.includes(SHEET_NAME) ?? false);
  }, [workBook, setIsPrioritySheet]);

  useEffect(() => {
    if (!workBook || !workBook.Sheets[SHEET_NAME]) {
      return;
    }
    const sheetData = workBook.Sheets[SHEET_NAME];

    const bData: TPriorityData[] = [];
    let index = DATA_START;

    const keys = Object.keys(DATA_MAPPING);
    while (sheetData[`${DATA_MAPPING.path}${index}`]) {
      const t: TPriorityData = {};
      for (const key of keys) {
        const k = key as keyof TPriorityData;
        t[k] = sheetData[`${DATA_MAPPING[k]}${index}`].v;
      }

      bData.push(t);
      ++index;
    }

    setBuildingData(bData);
  }, [workBook, setBuildingData]);

  return { isPrioritySheet, buildingData };
};
