import React, { useCallback, useState } from "react";
import * as XLSX from "xlsx";
import { FileInfo } from "../FileInfo/FileInfo";
import { PointCloud } from "../Importer/Pointcloud/PointCloud";
import { Deadline } from "../Importer/Deadline/Deadline";
import { Neuaufmass } from "../Importer/Neuaufmass/Neuaufmass";
import { SmbToastContext } from "../../context/SmbTabsContext";
import { MoSync } from "../Importer/MoSync/MoSync";
import { Bill } from "../Importer/Bill/Bill";
import { AufmassLoeschen } from "../Importer/AufmassLoeschen/AufmassLoeschen";
import { Button } from "../Button";
import { Priority } from "../Importer/Priority/Priority";
import { NoImportDetected } from "./NoImportDetected";

const MIME_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
const TEN_SECONDS = 10 * 1000;

export const ExcelImporter = () => {
  const [workBook, setWorkBook] = useState<XLSX.WorkBook | null>(null);
  const [fileInfo, setFileInfo] = useState<File | null>(null);
  const [isValidWorkBook, setValidWorkbook] = useState(false);
  const { addToast } = React.useContext(SmbToastContext);

  const onFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const toImport = event.target.files?.[0];

      if (toImport?.type !== MIME_TYPE) {
        if (addToast) {
          addToast({
            message: "Fehler beim lesen der Datei oder es ist kein Excel File",
            intent: "error",
            autoClose: TEN_SECONDS,
          });
        }

        return;
      }

      setFileInfo(toImport);
      const reader = new FileReader();
      reader.onload = (e) => {
        setWorkBook(XLSX.read(reader.result, { type: "binary" }));
      };

      reader.readAsArrayBuffer(toImport);
    },
    [setWorkBook, setFileInfo, addToast]
  );

  return (
    <div>
      <div>
        <input
          type="file"
          onChange={onFileChange}
          className={workBook ? "hidden" : ""}
        />
        <Button
          className={workBook ? "" : "hidden"}
          onClick={() => {
            window.location.reload();
          }}
        >
          Zurücksetzen
        </Button>
      </div>
      <FileInfo file={fileInfo} workBook={workBook} />
      <PointCloud workBook={workBook} isWorkBook={setValidWorkbook} />
      <Deadline workBook={workBook} isWorkBook={setValidWorkbook} />
      <Neuaufmass workBook={workBook} isWorkBook={setValidWorkbook} />
      <MoSync workBook={workBook} isWorkBook={setValidWorkbook} />
      <Bill workBook={workBook} isWorkBook={setValidWorkbook} />
      <AufmassLoeschen workBook={workBook} isWorkBook={setValidWorkbook} />
      <Priority workBook={workBook} isWorkBook={setValidWorkbook} />
      <NoImportDetected workBook={workBook} isWorkBook={isValidWorkBook} />
    </div>
  );
};
