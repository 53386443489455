import { orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { userTable } from "../../config/userTable";
import { TSystemUser, useGetUsers } from "../../hooks/useGetUsers";
import { DataTable } from "../DataTable/DataTable";
import { UserDetailModal } from "./UserDetailModal";

type TUserTableProps = {
  force: boolean;
  setForce: () => void;
};

const UserTable: React.FunctionComponent<TUserTableProps> = ({
  force = false,
  setForce,
}) => {
  const { user } = useGetUsers(force);
  const [searchTerm, setSearchTerm] = useState("");
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(30);
  const [data, setData] = useState<TSystemUser[]>([]);
  const [order, setOrderBy] = useState<{
    order: "DESC" | "ASC";
    field: string;
  }>({
    order: "DESC",
    field: "firstname",
  });

  const [detailData, setDetailData] = useState<TSystemUser | null>(null);

  useEffect(() => {
    let source = user;

    if (searchTerm) {
      const term = searchTerm.toLowerCase();
      source = user.filter(
        (u) =>
          u.firstname.toLowerCase().includes(term) ||
          u.lastname.toLowerCase().includes(term) ||
          u.email.toLowerCase().includes(term)
      );
    }

    setCount(source.length);

    const ordered = orderBy(
      source,
      order.field,
      order.order === "DESC" ? "desc" : "asc"
    );

    setData(
      [...ordered].splice(currentPage * limit, limit * (currentPage + 1))
    );
  }, [limit, user, searchTerm, currentPage, order, setData, setCount]);

  return (
    <>
      <div className="w-96 mb-2">
        <input
          type="text"
          className="w-full"
          placeholder="Name, Email"
          value={searchTerm}
          onChange={(e) => {
            setCurrentPage(0);
            setSearchTerm(e.target.value);
          }}
        />
      </div>
      <DataTable
        config={userTable}
        data={data}
        count={count}
        pagination={true}
        limit={limit}
        updateLimit={(nlimit: number) => setLimit(nlimit)}
        updatePage={(page: number) => setCurrentPage(page)}
        orderField={order.field}
        currentPage={currentPage}
        orderDirection={order.order}
        onCellClick={(index: number) => setDetailData(data[index])}
        onSort={(field: string) =>
          setOrderBy({
            order: order.order === "DESC" ? "ASC" : "DESC",
            field,
          })
        }
      />
      <UserDetailModal
        setForce={setForce}
        userDetail={detailData}
        onClose={() => setDetailData(null)}
      />
    </>
  );
};

export default UserTable;
