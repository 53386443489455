import { RouteComponentProps } from "@reach/router";

import React, { useCallback, useState } from "react";
import { useGetFiles } from "../../../hooks/useGetFiles";
import { TFileResult } from "../../../types/files";
import { AddFile } from "./AddFile";
import { FileTable } from "./FileTable";
import { Spinner } from "../../../components/Spinner";
import { Button } from "../../../components/Button";

export const FileAdmin: React.FC<RouteComponentProps> = () => {
  const [forceId, setForceId] = useState(0);
  const files = useGetFiles(forceId);
  const [addFile, setAddFile] = useState(false);
  const [editData, setEditData] = useState<TFileResult | null>(null);

  const force = useCallback(() => {
    setForceId(Date.now());
  }, [setForceId]);

  if (files === null) {
    return (
      <div className="w-full text-center">
        <div className="mx-auto w-28  h-48 relative">
          <Spinner center={true} />
        </div>
        Lade FIles
      </div>
    );
  }

  if (addFile) {
    return (
      <AddFile
        onClose={() => {
          force();
          setEditData(null);
          setAddFile(false);
        }}
        force={force}
        fileData={editData}
      />
    );
  }

  return (
    <>
      <div className="text-right">
        <Button
          icon="PLUS_SOLID"
          className="pr-5"
          onClick={() => setAddFile(true)}
        >
          Neue Datei
        </Button>
      </div>
      <FileTable
        files={files}
        force={force}
        setEditData={(id: number) => {
          setAddFile(true);
          setEditData(files.find((f) => f.id === id) ?? null);
        }}
      />
    </>
  );
};
