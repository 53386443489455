import * as React from "react";
import { TContentType } from ".";

type TPasswordRequestTriggerProps = {
  passwordRequest: boolean;
  setContent: (val: TContentType) => void;
};

export const PasswordRequestTrigger: React.FunctionComponent<
  TPasswordRequestTriggerProps
> = ({ passwordRequest, setContent }) => {
  if (passwordRequest === false) {
    return null;
  }

  return (
    <div
      data-testid="password-request-trigger"
      className="absolute cursor-pointer text-blue-700 text-xs z-10"
      style={{ right: "8px", top: "8px" }}
      onClick={() => {
        setContent("request");
      }}
    >
      Passwort vergessen?
    </div>
  );
};
