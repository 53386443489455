import moment from "moment-business-days";
import React, { useCallback, useEffect, useState } from "react";
import { dateFormatter, formatReadonly } from "../../helper/formatter";
type TDateInputProps = {
  value: string;
  onChange: (v: string | null) => void;
  canEdit?: boolean;
};

export const InputDate: React.FC<TDateInputProps> = ({
  value,
  canEdit = true,
  onChange,
}) => {
  const [currentValue, setValue] = useState(value);

  const inputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    if (!inputRef.current) {
      return;
    }

    inputRef.current.value = currentValue;
  }, [currentValue, inputRef]);

  const onBlurCallback = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      const blutValue = e.target.value;
      if (moment(blutValue).isValid()) {
        setValue(blutValue);
        onChange(blutValue);
      }
    },
    [setValue, onChange]
  );

  if (!canEdit) {
    return <>{formatReadonly(dateFormatter(value))}</>;
  }

  return (
    <div className="relative">
      <input
        max={moment().add(1, "year").format("YYYY-MM-DD")}
        min={moment().add(-1, "year").format("YYYY-MM-DD")}
        ref={inputRef}
        type="date"
        className={`pt-1 pb-1`}
        onBlur={onBlurCallback}
        onPaste={(e) => {
          const data = moment(e.clipboardData.getData("Text"), "DD.MM.YYYY");
          if (data.isValid() && inputRef.current) {
            inputRef.current.value = data.format("YYYY-MM-DD");
          }
        }}
      />
    </div>
  );
};
