import { Router } from "@reach/router";
import React from "react";
import { Logout } from "./components/Logout";
import {
  ROUTE_ABOUT,
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_SETTINGS,
  ROUTE_ADMIN,
  ROUTE_MEASURE_BOARD,
  ROUTE_BUILDING,
  ROUTE_BUILDING_LIST,
  ROUTE_CHANGELOG,
  ROUTE_CSV_IMPORT,
  ROUTE_DOWNLOADS,
  ROUTE_FILE_ADMIN,
  ROUTE_INDEX,
  ROUTE_LOGOUT,
  ROUTE_MIGRATE_ADMIN,
  ROUTE_REPORTS,
  ROUTE_SMB,
  ROUTE_SMB_BOARD,
  ROUTE_TOKEN_MANAGEMENT,
  ROUTE_UI_SETTINGS,
  ROUTE_USER_MANAGEMENT,
  ROUTE_BOARDS,
  ROUTE_POINT_CLOUD_BOARD,
  ROUTE_VACATION_ADMIN,
} from "./config/routes";
import { About } from "./pages/About";
import { ExcelImportPage } from "./pages/Admin/Excelmport/ExcelImportPage";
import { FileAdmin } from "./pages/Admin/FileAdmin/FileAdmin";
import { Reports } from "./pages/Admin/Reports";
import TokenManagement from "./pages/Admin/TokenManagement";
import { UserManagement } from "./pages/Admin/UserManagement";
import { MeasureBoard } from "./pages/Board/MeasureBoard";
import { Building } from "./pages/Building/Building";
import { BuildingTable } from "./pages/BuildingTableList/BuildingTable";
import { Changelog } from "./pages/Changelog";
import { Downloads } from "./pages/Downloads/Downloads";
import { HomePage } from "./pages/home/Home";
import { MyAccount } from "./pages/MyAccount";
import { UiSettings } from "./pages/MyAccount/UiSettings";
import { NotFound } from "./pages/Notfound";
import { SmbBoard } from "./pages/Smb/Board";
import { AdminOnlyGroup } from "./routerGroups/AdminOnlyGroup";
import { MyAccountGroup } from "./routerGroups/MyAccountGroup";
import { SmbOnlyGroup } from "./routerGroups/SmbOnlyGroup";
import { Migration } from "./pages/Admin/Migration/Migration";
import { BoardsGroup } from "./routerGroups/BoardsGroup";
import { PointCloudBoard } from "./pages/Board/PointCloudBoard";
import { Vacation } from "./pages/Admin/Vacation/Vacation";

type TRoutingProps = {
  showLogin: boolean;
};

export const Routing: React.FunctionComponent<TRoutingProps> = ({
  showLogin,
}) => {
  if (showLogin) {
    return null;
  }

  return (
    <div className="h-full overflow-hidden">
      <Router className="h-full overflow-y-auto relative">
        <Logout path={ROUTE_LOGOUT} />
        <HomePage path={ROUTE_INDEX} />
        <BuildingTable path={ROUTE_BUILDING_LIST} />
        <Building path={`${ROUTE_BUILDING}:id/:tab`} />
        <Building path={`${ROUTE_BUILDING}:id`} />
        <Downloads path={ROUTE_DOWNLOADS} />

        <BoardsGroup path={ROUTE_BOARDS}>
          <MeasureBoard path={ROUTE_MEASURE_BOARD} />
          <PointCloudBoard path={ROUTE_POINT_CLOUD_BOARD} />
          <SmbOnlyGroup path={ROUTE_SMB}>
            <SmbBoard path={ROUTE_SMB_BOARD} />
          </SmbOnlyGroup>
        </BoardsGroup>

        <MyAccountGroup path={ROUTE_ACCOUNT}>
          <MyAccount path={ROUTE_ACCOUNT_SETTINGS} />
          <UiSettings path={ROUTE_UI_SETTINGS} />
          <Changelog path={ROUTE_CHANGELOG} />
          <About path={ROUTE_ABOUT} />
        </MyAccountGroup>

        <AdminOnlyGroup path={ROUTE_ADMIN}>
          <ExcelImportPage path={ROUTE_CSV_IMPORT} />
          <FileAdmin path={ROUTE_FILE_ADMIN} />
          <TokenManagement path={ROUTE_TOKEN_MANAGEMENT} />
          <UserManagement path={ROUTE_USER_MANAGEMENT} />
          <Reports path={ROUTE_REPORTS} />
          <Migration path={ROUTE_MIGRATE_ADMIN} />
          <Vacation path={ROUTE_VACATION_ADMIN} />
        </AdminOnlyGroup>
        <NotFound default />
      </Router>
    </div>
  );
};
