import React, { useEffect, useState } from "react";
import { TPriorityData } from "./hooks/usePriorityImporter";
import { BuildingLink } from "../../BuildingLink/BuildingLink";
import { useVerifyBuilding } from "../../../hooks/verify/useVerifyBuilding";
import { leadingZeroFormatter } from "../../../helper/formatter";
import { useVerifyMeasureOrder } from "../../../hooks/verify/useVerifyMeasureOrder";
import { useVerifyPriority } from "../../../hooks/verify/useVerifyPriority";

type TDataRowProps = {
  force: number;
  selected: string[];
  setSelected: (path: string) => void;
} & TPriorityData;

export const DataRow: React.FC<TDataRowProps> = ({
  force,
  path,
  economicUnit,
  buildingNumber,
  description,
  priority,
  selected,
  setSelected,
}) => {
  const { buildingExists, buildingId } = useVerifyBuilding(path, force);
  const [statusStyle, setStatusStyle] = useState("bg-red-600 text-white");
  const hasOrder = useVerifyMeasureOrder(path, force);
  const currentPriority = useVerifyPriority(path ?? "", force);
  const [comment, setComment] = useState("");

  useEffect(() => {
    setComment("");
  }, [setComment, force]);

  useEffect(() => {
    if (buildingExists === null) {
      return;
    }

    if (!buildingExists || !hasOrder) {
      setStatusStyle("bg-red-600 text-white");
      setComment("Gebäude / Auftrag nicht im bestand gefunden");
    } else {
      setStatusStyle("bg-green-200");
      setComment("");
    }
  }, [buildingExists, hasOrder, setStatusStyle]);

  useEffect(() => {
    if (currentPriority === null || comment.includes("Jetzige Prio:")) {
      return;
    }
    if (currentPriority !== priority) {
      setStatusStyle("bg-green-500");
    } else if (currentPriority === priority) {
      setStatusStyle("bg-green-200");
    }

    setComment(`Jetzige Prio: ${currentPriority} ${comment}`);
  }, [
    currentPriority,
    comment,
    buildingExists,
    priority,
    setStatusStyle,
    setComment,
  ]);

  return (
    <tr className={`text-xs ${statusStyle}`}>
      <td className="border border-border p-1">
        <input
          type="checkbox"
          checked={selected.includes(path ?? "")}
          onChange={() => {
            if (!path) {
              return;
            }
            setSelected(path);
          }}
        />
      </td>
      <td className="border border-border p-1">
        <BuildingLink buildingId={buildingId} blank={true}>
          {path}
        </BuildingLink>
      </td>
      <td className="border border-border p-1">
        {leadingZeroFormatter(economicUnit)}
      </td>
      <td className="border border-border p-1">
        {leadingZeroFormatter(buildingNumber)}
      </td>

      <td className="border border-border p-1">{description}</td>
      <td className="border border-border p-1">{priority}</td>
      <td className="border border-border p-1">{comment}</td>
    </tr>
  );
};
