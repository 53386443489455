import {
  faBuilding,
  faCloudShowersHeavy,
  faRulerCombined,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "../../Tooltip";

type TOrderTypeIconProps = {
  order: string;
  display: boolean;
};

const allowdIcons: Record<string, IconDefinition> = {
  measureOrder: faRulerCombined,
  pointCloud: faCloudShowersHeavy,
  stockCheck: faBuilding,
};

const iconTooltip: Record<string, string> = {
  measureOrder: "Neuaufmaß/Nachaufmaß",
  pointCloud: "Punktwolke",
  stockCheck: "Bestandsprüfung",
};

export const OrderTypeIcons: React.FC<TOrderTypeIconProps> = ({
  order,
  display,
}) => {
  if (!display || !Object.keys(allowdIcons).includes(order)) {
    return null;
  }

  return (
    <div className="mr-1 w-4">
      <Tooltip content={iconTooltip[order]}>
        <FontAwesomeIcon icon={allowdIcons[order]} />
      </Tooltip>
    </div>
  );
};
