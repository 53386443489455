import { RouteComponentProps } from "@reach/router";
import React from "react";
import license from "../../config/license.json";
import { LicencesList } from "../../components/LicensesList";

export const About: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <>
      <h1>Dora - Dokumentation Rahmenvertragsaufträge</h1>

      <div className="ml-2">
        <p>ist eine Software der</p>
        <p>
          <strong>F.M. digital GmbH</strong>
        </p>
        Kantstraße 28
        <br />
        80809 München
        <div className="grid-cols-4 grid mt-4 mb-4" style={{ width: "300px" }}>
          <div>Telefon:</div>
          <div className="col-span-3">+49 - 89 - 35 82 74 30</div>
          <div>FAX:</div>
          <div className="col-span-3">+49 - 89 - 35 82 74 40</div>
          <div>E-Mail:</div>
          <div className="col-span-3">
            <a
              href={`mailto:info@smbag.de?subject=${encodeURIComponent(
                "Anfrage über Dora"
              )}`}
            >
              info@smbag.de
            </a>
          </div>
          <div>Internet</div>
          <div className="col-span-3">
            <a href="https://www.smbag.de">www.smbag.de</a>
          </div>
        </div>
        <LicencesList licenses={license} />
      </div>
    </>
  );
};
