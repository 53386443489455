export type TChangelog = {
  version: string;
  date: string;
  changes: string[];
};

export const CHANGELOG: TChangelog[] = [
  {
    version: "3.3.0",
    date: "2023-01-08",
    changes: [
      "UI-Fixes:",
      "- Ladeanimation im Board, wurde angezeigt, auch wenn die Spalte eingeklappt war",
      "Features",
      "- Verwaltung der Urlaubs/Feiertage, für die Berechnung der Zeit, jetzt über die Oberfläche möglich",
      "- Versand der Emails auf fm-digital.gmbh umgestellt",
      "- Wenn beim Import von Daten, keine Arbeitsmappe erkannt wird, wird jetzt eine Fehlermeldung ausgegeben",
      "Bugs",
      "- Link zur internen Dokumentation angepasst",
      "- Reminder Email für Ämter gefixt",
      "- Es werden keine Reminder mehr an Feiertagen / Urlaubstagen versendet",
    ],
  },
  {
    version: "3.2.0",
    date: "2023-01-08",
    changes: [
      "UI-Fixes:",
      "- Pfeile in Pagination",
      "- Board bei einklappen einer Spalte, wird die NRF ausgeblendet",
      "- Wechsel von SMB AG zu F.M. digital GmbH",
      "- Das Kommentar Modal wird jetzt über den Überschriften der Spalten angezeigt",
      "Features",
      "- Es ist jetzt möglich eine Priorität bei den Gebäuden zu hinterlegen",
      "- Anzeige des Lieferdatums (Flächenerfasser)",
      "- Sortierung nach der Priorität in der Spalte `Wartet auf PTS Upload`",
      "- Import für die Priorität der Gebäude",
      "- Berechnung der verbleibenden Werktage für die Gebäude vereinheitlicht",
      "Bugs",
      "- Berechnung der Werktage bei der Qualitätsprüfung, wenn ein Gebäude von Erfasser korrigiert wurde",
    ],
  },
  {
    version: "3.1.2",
    date: "2022-12-28",
    changes: ["Feiertage"],
  },
  {
    version: "3.1.1",
    date: "2022-07-10",
    changes: [
      'Im Board zum Aufmaß wurde unter "Qualitätsprüfung Erfolgreich" an jedem Bauwerk das tagesaktuelle Datum angezeigt. Dies ist nicht notwendig.',
    ],
  },
  {
    version: "3.1.0",
    date: "2022-05-10",
    changes: [
      "Status Nach/Neuaufmaß zeigt jetzt unter Prüfung bestanden zeigt jetzt die ganze Summe der Datensätze an",
      "Importer zum löschen vom Aufträgen an Gebäuden",
      "Netto Preis berechnung von NRF Geprüft",
    ],
  },
  {
    version: "3.0.1",
    date: "2022-05-10",
    changes: [
      "Typos",
      "Scrollbars im Board",
      "Datum letzte Bearbeitung vom Gebäude",
      "Workflows mit Status Info wurden nicht im Board angezeigt",
      "Berechnung der NRF im Aufmaß Board",
      "Reloadbutton im Aufmaß Board",
    ],
  },
  {
    version: "3.0.0",
    date: "2022-05-08",
    changes: [
      "Dora ist Gebäude Zentriert",
      "Anpassungen an der UX",
      "Performance verbessert",
      "Punktwolken Auftrag zum Gebäude hinzugefügt",
      "Punktwolken Auftrag Board",
      "Punktwolken Auftrag Workflow",
    ],
  },
  {
    version: "2.10.3",
    date: "2021-11-16",
    changes: ["Sidebar fixes", "Sidebar mit Tooltip"],
  },
  {
    version: "2.10.2",
    date: "2021-11-11",
    changes: [
      "Feiertage werden jetzt bei der Berechnung der Werktage berücksichtigt",
    ],
  },
  {
    version: "2.10.1",
    date: "2021-11-05",
    changes: ["Textänderungen", "Datumseingabe fix"],
  },
  {
    version: "2.10.0",
    date: "2021-11-04",
    changes: [
      "Ämter sehen jetzt auch im Board, welche Gebäude noch kein PTS-Upload haben",
      "Spalten im Board lassen sich einklappen",
      "Beim setzen des Status PTS-Upload, haben FE jetzt die möglichkeit einen Kommentar zu verfassen",
      "Ämter können jetzt im Downloadbereich einen Excel-Report herunterladen",
    ],
  },
  {
    version: "2.9.0",
    date: "2021-10-29",
    changes: [
      "Kleiner Fixes in Dora",
      "Board wurde um folgende Funktionen erweitert:",
      "- Datum der Fertigstellung wird auf Karte angezeigt",
      "- Tooltip in den Karten",
      "- Wenn ein Gebäude auf fehlerhaft gezogen wird, erscheint eine Eingabe für den Fehlerbericht",
      "- Autorefresh",
      "- Wenn aus der Detailansicht zurück auf das Board gegangen wird, erfolgt ein refresh",
      "- Rendering Optimiert",
      "Amt kann jetzt in der Detail-Ansicht bei 'Rechnung Flächenerfasser':",
      "- ein Datum eintragen, wann Rechnung bezahlt wurde",
      "- Einen Kommentar für hinterlegen",
    ],
  },
  {
    version: "2.8.0",
    date: "2021-10-17",
    changes: [
      "Build der App optimiert um kleinere Filesizes zu erhalten",
      "Dateiverwaltung für Downloads",
      "kleinere fixes",
    ],
  },
  {
    version: "2.7.1",
    date: "2021-10-17",
    changes: [
      "Gebäude mit unvollständigen Daten, haben Board nicht anzeigen lassen",
    ],
  },
  {
    version: "2.7.0",
    date: "2021-10-13",
    changes: ["Projekt Board, zur Darstellung des zeitlichen Ablaufs"],
  },
  {
    version: "2.6.2",
    date: "2021-10-07",
    changes: [
      "Kommentare können jetzt mehrzeilig sein",
      "Performance Verbesserungen",
    ],
  },
  {
    version: "2.6.1",
    date: "2021-09-16",
    changes: ["Beschreibungen der Tooltips geändert"],
  },
  {
    version: "2.6.0",
    date: "2021-09-13",
    changes: [
      "'PTS Upload erfolgt' Button jetzt im Vorgang 'Plausibilitäts Check'",
      "'PTS erneut hochgeladen' jetzt im entsprechenden Vorgang",
      "'PTs Upload erfolgt' wird jetzt im 'Plausibilitäts Check' dargestellt",
    ],
  },
  {
    version: "2.5.0",
    date: "2021-07-29",
    changes: [
      "Darstellung des Feldes 'Schnitte und Ansichten' auf der Detailseite",
      "'Schnitte und Ansichten' auch im Excel-Report",
      "Importer um vorhandene Datensätze mit 'Schnitte und Ansichten' anzureichern",
    ],
  },
  {
    version: "2.4.0",
    date: "2021-07-28",
    changes: [
      "Navigation um Downloads erweitert für Firmen",
      "Firmen können sich jetzt einen Status Report, des Projekts herunterladen",
    ],
  },
  {
    version: "2.3.1",
    date: "2021-07-15",
    changes: [
      "Fehler in Texteingaben bei Verwendung von Punkt im Text",
      "Positionierung des Buttons auf der Detailansicht 'zur Übersicht'",
    ],
  },
  {
    version: "2.3.0",
    date: "2021-06-22",
    changes: [
      "Pfeilnavigation in Inputs",
      "Cursor springen in Inputs",
      "Excel Datums Format für Report",
      "Spalten in Übersichtstabelle",
    ],
  },
  {
    version: "2.2.0",
    date: "2021-06-14",
    changes: [
      "Bug bei Felder mit Nummerneingabe",
      "Amt kann jetzt NRF-Geprüft ändern, wenn es die Qualitätsprüfung hat",
    ],
  },
  {
    version: "2.2.1",
    date: "2021-06-16",
    changes: ["Bug bei Copy&Pase in Nummernfelder"],
  },
  {
    version: "2.2.0",
    date: "2021-06-14",
    changes: [
      "Bug bei Felder mit Nummerneingabe",
      "Amt kann jetzt NRF-Geprüft ändern, wenn es die Qualitätsprüfung hat",
    ],
  },
  {
    version: "2.1.2",
    date: "2021-05-07",
    changes: [
      "Detailseite für kleine Bildschirme optimiert",
      "Neues Feld: Pilotobjekt",
    ],
  },
  {
    version: "2.1.1",
    date: "2021-05-06",
    changes: [
      "In der Übersichtstabelle, kann man sich jetzt des Datum der letzte Bearbeitung anzeigen lassen",
      "In der Detailansicht, wird jetzt das Datum der letzten Bearbeitung des Gebäudes angezeigt",
      "Tabelle kann jetzt auch nach letzter Bearbeitung sortiert werden",
      "Ämter haben neuen Filter: In Plausibilitätsprüfung (alle Objekte). Hier werden alle Objekte gezählt und angezeigt die auf Bearbeitung warten",
      "Meine Favoriten, wird das Datum der letzten Bearbeitung angezeigt",
      "Fehler in der Preisberechnung behoben",
    ],
  },
  {
    version: "2.1.0",
    date: "2021-05-05",
    changes: [
      "Ämter die Qualitätsprüfung-Verantwortlich sind, können Qualitätsprüfung-Status setzen",
      "User mit der Rolle admin, können den letzten Status von Plausibilitätsprüfung und Qualitätsprüfung löschen",
      "Alle Werte von NRF, können in der Übersichtstabelle angezeigt werden",
      "Suche bei WE-Nr und Geb-Nr ignoriert führende Nullen",
      "Qualitätsprüfung-Person / Qualitätsprüfung-Amt in der Übersichtstabelle",
    ],
  },
  {
    version: "2.0.0",
    date: "2021-04-22",
    changes: [
      "Überarbeitung der Oberfläche",
      "Speichern auf Detail-Seite über STRG + S",
      "Navigieren in der Detail-Seite mit Pfeilen",
      "Einstellungen in der Oberfläche, werden sich gemerkt",
      "Externe abhängigkeiten minimiert",
    ],
  },
  {
    version: "1.13.0",
    date: "2021-03-09",
    changes: [
      "Status der Prüfung wird als Badges Angezeigt",
      "Prüfungen sind jetzt Accordions",
      "Nur Aktuelle Prüfung, die noch nicht abgeschlossen ist, wird automatisch geöffnet",
      "Fehler leeres Datum speichern behoben",
      "Datum wird nach paste nun korrekt übernommen",
      "Farben der Icons geändert",
    ],
  },
  {
    version: "1.12.0",
    date: "2021-03-04",
    changes: [
      "Amt auf der Detailseite entfernt",
      "Grund für verzögerung auf Detailseite",
      "Amt kann jetzt auch Qualitätsprüfung Verantwortlich sein",
    ],
  },
  {
    version: "1.11.2",
    date: "2021-03-02",
    changes: ["About Seite im User Menü"],
  },
  {
    version: "1.11.1",
    date: "2021-02-26",
    changes: [
      "Anpassungen auf der Auftragspositionen Seite",
      "Prüfungseinträge fixiert",
    ],
  },
  {
    version: "1.11.0",
    date: "2021-02-25",
    changes: [
      "Schnellfilter für Plausibilitätsprüfung und Qualitätsprüfung in Bearbeitung",
      "Ergebnisse der Schnellfilter verfeinert",
      "Filter in der Tabelle angepasst",
      "Anwendermenü Typo",
      "Kleinere Verbesserungen an der Application",
    ],
  },
  {
    version: "1.10.3",
    date: "2021-02-16",
    changes: ["Kommentare fixed"],
  },
  {
    version: "1.10.2",
    date: "2021-02-16",
    changes: ["Kommentare wegen performance entfernt"],
  },
  {
    version: "1.10.1",
    date: "2021-02-15",
    changes: [
      "In der Detailansicht sind jetzt Kommentare sichtbar",
      "Kommentare können von SMB zu jedem Gebäude erstellt werden",
      "Toast hinter header gefixt",
      "Caching von Browser auf die version.json ausgeschaltet",
    ],
  },
  {
    version: "1.10.0",
    date: "2021-02-11",
    changes: [
      "Jedem Gebäude wird jetzt ein Qualitätsprüfung-Mitarbeiter zugewiesen durch SMB",
      "Preis pro m² kann jetzt nachträglich durch SMB geändert werden ",
      "Das Datum der letzten PTS Aktualisierung ist jetzt in der Übersichtstabelle",
      "Mwst berechnung angepasst",
      "Kleine Bugfixes in der API",
      "Anpassungen an Testsystem",
    ],
  },
  {
    version: "1.9.0",
    date: "2021-02-05",
    changes: [
      "Zugriff auf die Änderungshistorie über die Versionsnummer oder Eintrag im Menü",
      'Startseite "zuletzt bearbeitet" in "meine Favoriten"',
      'Konfiguration von "meine Favoriten" im Account',
      'Widget "Wussten Sie" hinzugefügt',
    ],
  },
  {
    version: "1.8.1",
    date: "2021-02-04",
    changes: [
      "Meldung, wenn eine neue Version der Software vorhanden ist",
      "Ansicht des Changelogs in der Meldung",
    ],
  },
];
