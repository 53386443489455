import React, { useEffect, useState } from "react";
import { TCalculateResult } from "../../components/KanbanBoard/useBoardFilter";
import { TCols } from "../../config/measureBoard";
import { MEASURE_STATE_DECLINED } from "../../config/workflow";
import { leadingZeroFormatter } from "../../helper/formatter";
import { Modal } from "../../components/Modal";
import { Button } from "../../components/Button";

type TErrorDescriptionProps = {
  data: TCalculateResult | null;
  dropConfig: TCols | null;
  onCancel: () => void;
  onSubmit: (comment: string) => void;
};

export const MeasureErrorDescription: React.FC<TErrorDescriptionProps> = ({
  data,
  dropConfig,
  onCancel,
  onSubmit,
}) => {
  const [message, setMessage] = useState("");
  const [headline, setHeadline] = useState("");

  useEffect(() => {
    if (dropConfig === null) {
      return;
    }

    if (dropConfig.historyState === MEASURE_STATE_DECLINED) {
      setHeadline("Fehlerbeschreibung für:");
    } else {
      setHeadline("Kommentar zum PTS-Upload:");
    }
  }, [dropConfig, setHeadline]);

  if (data === null) {
    return null;
  }

  return (
    <Modal isOpen={true} width="800px">
      <div className="relative" style={{ width: "800px" }}>
        <h1>{headline}</h1>
        <div style={{ width: "750px" }}>
          <div>
            {data.address} WE: {leadingZeroFormatter(data.economicUnit)} GE:{" "}
            {leadingZeroFormatter(data.buildingNumber)}
          </div>
          <textarea
            className="resize-none h-40 w-full"
            value={message}
            placeholder="Ihr Kommentar Optional"
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          <div className="relative text-right mr-22">
            <Button
              text="Abbrechen"
              intent="danger"
              className="mr-4"
              onClick={() => {
                setMessage("");
                onCancel();
              }}
            />
            <Button
              text="Speichern"
              intent="success"
              onClick={() => {
                onSubmit(message);
                setMessage("");
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
