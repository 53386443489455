import React, { useContext, useState } from "react";
import { KanbanBoardContext } from "../KanbanBoard";
import { leadingZeroFormatter } from "../../../helper/formatter";
import { DragIndicator } from "../DragIndicator";
import { TMinimalBoardCardProps } from "./MeasureWaitPtsUploadBoardCard";

export const PointCloudStatusCard: React.FC<TMinimalBoardCardProps> = ({
  isOpen,
  buildingId,
  buildingNumber,
  companyName,
  designation,
  economicUnit,
  losNumber,
  office,
  isDraggable = false,
  onDragStart,
  onDragEnd,
}) => {
  const [isHover, setHover] = useState(false);
  const { onClick } = useContext(KanbanBoardContext);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={{
        width: "278px",
        height: "110px",
      }}
      onClick={() => {
        if (typeof onClick === "function") {
          onClick(buildingId);
        }
      }}
      onDragStart={(e) => {
        if (typeof onDragStart === "function") {
          onDragStart();
        }
      }}
      onDragEnd={(e) => {
        if (typeof onDragEnd === "function") {
          onDragEnd();
        }
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      draggable={isDraggable}
      className={`text-xs flex flex-row rounded m-1 hover:border hover:bg-gray-400 py-1 pl-1 bg-gray-300 border-4`}
    >
      <div className="w-full overflow-hidden">
        <div className="h-4 mt-1 overflow-ellipsis truncate">{designation}</div>
        <div className="flex flex-row mt-0.5">
          <div className="w-1/2">WE: {leadingZeroFormatter(economicUnit)}</div>
          <div className="w-1/2">
            GE: {leadingZeroFormatter(buildingNumber)}
          </div>
        </div>
        <div className="flex flex-row mt-0.5">
          <div className="w-1/2">Amt: {office}</div>
          <div className="w-1/2">Los: {losNumber}</div>
        </div>
        <div className="overflow-hidden h-4 overflow-ellipsis truncate mt-0.5">
          <div>Firma: {companyName}</div>
        </div>
      </div>
      <DragIndicator isDraggable={isDraggable} isHover={isHover} />
    </div>
  );
};
