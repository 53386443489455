import React from "react";
import { TSettingsState } from "../store/settings/settingsReducer";
import { TQuickFilterConfig } from "./quickFilter";

type TSelections = "selection" | "boolean";

export type TUiSettings = {
  title: string;
  help: JSX.Element | null;
  type: TSelections;
  valueKey: keyof TSettingsState;
  selection?: {
    label: keyof TQuickFilterConfig; // key unter dem sich das label befindet
    value: keyof TQuickFilterConfig; // key unter dem sich das zu speichernde value befinded
  };
  data?: TQuickFilterConfig[];
};

export const uiSettings: TUiSettings[] = [
  {
    title: "Wussten Sie schon",
    help: (
      <>
        Hier können Sie das "Wussten Sie schon" Widget auf der Startseite An-
        oder Ausschalten
      </>
    ),
    valueKey: "homeTip",
    type: "boolean",
  },
];
