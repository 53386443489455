import React from "react";
import { Menu } from "../Menu";
import { MenuItem } from "../Menu/MenuItem";
import { Popover } from "../Popover";
import { IDataTableConfig } from "./DataTable";
import { Tooltip } from "../../Tooltip";
import { Icon } from "../Icon";

type TTogglePaginationCellsProps = {
  toggleCell?: (field: unknown) => void;
  config: IDataTableConfig[];
};

export const TogglePaginationCells: React.FunctionComponent<
  TTogglePaginationCellsProps
> = ({ toggleCell, config }) => {
  if (!toggleCell) {
    return null;
  }

  return (
    <Popover>
      <Tooltip content="Sichtbarkeit Spalten einstellen" position="left">
        <div className="w-7 h-7 opacity-80 cursor-pointer rounded p-1  border-gray-300 hover:bg-white border hover:border-hover">
          <Icon size={6} icon="COGS_SOLID" />
        </div>
      </Tooltip>
      <Menu>
        {config.map((conf, i) => (
          <MenuItem
            key={`t-conf-${i}`}
            text={
              <>
                <label>
                  <input
                    type="checkbox"
                    checked={!conf.hidden}
                    className="mr-1"
                    readOnly={true}
                  />
                  {conf.name}
                </label>
              </>
            }
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              toggleCell(conf.dataIndex);
            }}
          />
        ))}
      </Menu>
    </Popover>
  );
};
