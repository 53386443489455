import Axios from "axios";
import { useEffect, useState } from "react";
import { TFileResult } from "../types/files";

export const useGetFiles = (forceId = 0) => {
  const [files, setFiles] = useState<TFileResult[] | null>(null);

  useEffect(() => {
    const fetchFiles = async () => {
      const response = await Axios.get("/file-list/");
      const result = response.data as TFileResult[];

      setFiles(result);
    };
    fetchFiles();
  }, [forceId, setFiles]);
  return files;
};
