import React, { useState } from "react";
import { SmbToastContext, TSmbToast } from "../../context/SmbTabsContext";
import { ToastItem } from "./ToastItem";
export const SmbToast: React.FC = ({ children }) => {
  const [toast, setToasts] = useState<TSmbToast[]>([]);
  const addToast = (toAdd: TSmbToast) => {
    toAdd.id = Date.now();
    setToasts([...toast, toAdd]);
  };

  const onRemove = (id: number) => {
    setToasts(toast.filter((val) => val.id !== id));
  };

  return (
    <>
      <div style={{ right: "10px" }} className={"absolute z-50 mt-2"}>
        {toast.map((value, index) => (
          <ToastItem {...value} key={`toast-item-${index}`} remove={onRemove} />
        ))}
      </div>
      <SmbToastContext.Provider value={{ addToast }}>
        {children}
      </SmbToastContext.Provider>
    </>
  );
};
