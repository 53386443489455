import { useCallback, useContext } from "react";
import axios from "axios";
import { SmbToastContext } from "../../context/SmbTabsContext";

export const useAufmassPunktwolkeDeleteImporter = (callback: () => void) => {
  const { addToast } = useContext(SmbToastContext);
  return useCallback(
    async (data: string[]) => {
      try {
        const response = await axios.post<{ errors: string[] }>(
          "/admin/import/measure-delete/",
          {
            data,
          }
        );

        if (addToast && response.data.errors) {
          for (const message of response.data.errors) {
            addToast({
              message,
              intent: "error",
              autoClose: 0,
            });
          }
        }

        callback();
      } catch (err) {}
    },
    [callback, addToast]
  );
};
