import { ITableHeader } from "./dataTable";

export const LAST_EDIT_TABLE: ITableHeader[] = [
  {
    name: "Pfad",
    dataIndex: "path",
    sortAble: true,
  },

  {
    name: "WE-Nr",
    formatter: "LeadingZero",
    dataIndex: "economicUnit",
    sortAble: true,
    size: 90,
  },
  {
    name: "Geb-Nr",
    formatter: "LeadingZero",
    dataIndex: "buildingNumber",
    sortAble: true,
    size: 90,
  },
  {
    name: "Bezeichnung",
    dataIndex: "designation",
    sortAble: true,
  },

  {
    name: "Aufträge",
    dataIndex: "orders",
    sortAble: false,
    formatter: "orderTypes",
    size: 90,
  },
  {
    name: "Letzte Bearbeitung",
    formatter: "Datetime",
    dataIndex: "updated",
    sortAble: true,
    size: 150,
  },
];
