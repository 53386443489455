import React from "react";
import { Modal } from "../../Modal";

type TDeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
};
export const DeleteModal: React.FunctionComponent<TDeleteModalProps> = ({
  isOpen,
  children,
  onClose,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <Modal title="Eintrag löschen" isOpen={isOpen} onClose={onClose}>
      {children}
    </Modal>
  );
};
