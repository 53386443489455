import { useEffect, useState } from "react";

type THook = (cVersion: string) => string;

export const useUpdateNotification: THook = (cVersion) => {
  const [version, setVersion] = useState(cVersion);

  useEffect(() => {
    const pollingTime = 1 * 1000 * 60;
    const myHeaders = new Headers();
    myHeaders.append("pragma", "no-cache");
    myHeaders.append("cache-control", "no-cache");
    myHeaders.append("Expires", "0");
    const myInit = {
      method: "GET",
      headers: myHeaders,
    };
    const timer: number = window.setInterval(async () => {
      const response = await fetch("/version.json", myInit).then((res) =>
        res.json()
      );
      if (response.version !== version) {
        setVersion(response.version);
      }
    }, pollingTime);

    return () => window.clearInterval(timer);
  }, [setVersion, version]);

  return version;
};
