import { combineReducers } from "redux";
import { appStateReducer } from "./appState/appStateReducer";
import { serverDataReducer } from "./serverData/serverDataReducer";
import { settingReducer } from "./settings/settingsReducer";
import { toastStateReducer } from "./toast/toastReducer";
import { userStateReducer } from "./user/userReducer";

export const rootReducer = combineReducers({
  app: appStateReducer,
  user: userStateReducer,
  toast: toastStateReducer,
  serverData: serverDataReducer,
  settings: settingReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
