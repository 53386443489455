import React from "react";

import { leadingZeroFormatter } from "../../helper/formatter";

export type TPointCloudResult = {
  los: number;
  path: string;
  economicUnit: string;
  buildingNumber: number;
  designation: string;
  office: string;
  detailLevel: string;
};

export const MigrationPointCloudResult: React.FC<TPointCloudResult> = ({
  los,
  path,
  economicUnit,
  buildingNumber,
  designation,
  office,
  detailLevel,
}) => {
  return (
    <div className="grid grid-cols-12 p-1 hover:bg-hover">
      <div className="col-span-4">{path}</div>
      <div className="text-center">{detailLevel}</div>
      <div className="text-center">{leadingZeroFormatter(economicUnit)}</div>
      <div className="text-center">{leadingZeroFormatter(buildingNumber)}</div>
      <div className="text-center">{los}</div>
      <div className="text-center">{office}</div>
      <div className="col-span-3">{designation}</div>
    </div>
  );
};
