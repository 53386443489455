import * as React from "react";

type TInputHelpProps = {
  testId: string;
  text?: string;
};

export const InputHelp: React.FunctionComponent<TInputHelpProps> = ({
  text = "",
  testId,
}) => {
  if (text === "") {
    return null;
  }
  return (
    <div data-testid={`help-${testId}`} className="text-xs">
      {text}
    </div>
  );
};
