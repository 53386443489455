import React, { useState } from "react";
import { DataRow } from "./DataRow";
import { DataSearch } from "./DataSearch";
import { TDataValueProps } from "./DataValue";
import { NoResult } from "./NoResult";
import { Pagination, TPaginationProps } from "./Pagination";
import { RowIndex, TRowIndex } from "./RowIndex";

export type TDataTableFormatterTypes =
  | "Date"
  | "Datetime"
  | "LeadingZero"
  | "Success"
  | "Status"
  | "Text"
  | "Number"
  | "Surface"
  | "yesNo"
  | "requieredText"
  | "lineBreak"
  | "Currency"
  | "orderTypes"
  | "emailSubscription";

export interface IDataTableConfig {
  name: string;
  dataIndex: string;
  hidden?: boolean;
  size?: number;
  sortAble?: boolean;
  filter?: string;
  formatter?: TDataTableFormatterTypes;
}
type TDataTableProps = {
  orderDirection: "DESC" | "ASC";
  orderField: string;
  config: IDataTableConfig[];
  data: any[];
  searchTerm?: string;
  pagination?: boolean;
  useSearch?: boolean;
  onCellClick?: TDataValueProps["onCellClick"];
  onSort?: (field: string) => void;
  onSearch?: (value: string) => void;
  force?: () => void;
} & TPaginationProps &
  TRowIndex;

export const DataTable: React.FunctionComponent<TDataTableProps> = ({
  data,
  config,
  limit,
  count,
  orderField,
  orderDirection,
  pagination = false,
  currentPage = 0,
  displayIndex = true,
  useSearch = false,
  searchTerm = "",
  toggleCell,
  updateLimit,
  updatePage,
  onCellClick,
  onSort,
  onSearch,
  force,
}) => {
  const [hover, setHover] = useState<number | null>(null);

  return (
    <div className="h-full">
      <DataSearch
        useSearch={useSearch}
        dataSearch={onSearch}
        force={force}
        term={searchTerm}
      />
      <div
        className="flex border-l border-t border-border overflow-y-auto static"
        style={{
          maxHeight: pagination ? "calc(100% - 135px)" : "100%",
        }}
      >
        <RowIndex count={limit} displayIndex={displayIndex} />

        {config.map((conf, index) => {
          return (
            <DataRow
              config={conf}
              data={data}
              limit={limit}
              orderField={orderField}
              orderDirection={orderDirection}
              hover={hover}
              setHover={(i: number | null) => setHover(i)}
              key={`${conf.name}-${index}`}
              onCellClick={onCellClick}
              onSort={onSort}
            />
          );
        })}
      </div>
      <Pagination
        count={count}
        config={config}
        limit={limit}
        pagination={pagination}
        currentPage={currentPage}
        toggleCell={toggleCell}
        updateLimit={updateLimit}
        updatePage={updatePage}
      />
      <NoResult count={count} />
    </div>
  );
};
