import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { ICON_SIZE } from "./Sidebar";
import { SidebarTooltip } from "./SidebarTooltip";
import { SubLevels } from "./SubLevel";
import { TSidebarConf } from "./types";
import { useCategory } from "./useCategory";

export const Category: React.FunctionComponent<TSidebarConf> = (props) => {
  const ref = React.createRef<HTMLLIElement>();
  const {
    borderOpacity,
    opacity,
    isActive,
    isOpen,
    popover,
    left,
    top,
    onItemClick,
    onMouseEnter,
    onMouseLeave,
  } = useCategory(props, ref);
  const { text, icon, tooltip } = props;

  return (
    <SidebarTooltip isOpen={props.children ? true : isOpen} tip={tooltip}>
      <li
        ref={ref}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={`cursor-pointer w-full text-sm transition-none`}
        onClick={onItemClick}
      >
        <div
          style={{ height: ICON_SIZE }}
          className={`flex flex-wrap flex-row opacity-${opacity} h-full overflow-hidden hover:bg-border hover:opacity-100
        border-black border-l-4 border-opacity-${borderOpacity} hover:border-opacity-100`}
        >
          <div
            className="inline-block relative"
            style={{
              width: ICON_SIZE,
            }}
          >
            <FontAwesomeIcon
              className="opacity-40 hover:opacity-100 absolute top-1/2 left-1/2"
              style={{ transform: "translate(-50%, -50%)" }}
              icon={icon as IconProp}
            />
          </div>
          <div
            style={{ lineHeight: ICON_SIZE }}
            className={`whitespace-nowrap overflow-hidden h-full flex-1`}
          >
            {text}
          </div>
        </div>
        <SubLevels
          left={left}
          top={top}
          items={props.children}
          popover={popover}
          isOpen={isOpen && isActive}
        />
      </li>
    </SidebarTooltip>
  );
};
