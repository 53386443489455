import * as XLSX from "xlsx";
import { useEffect, useState } from "react";

export const SHEET_NAME = "FE_Nachtrag_Punktwolke";

export type THeaderData = {
  los: number;
  company: string;
  retrievalNumber: string;
};

const HEADER_MAPPING = {
  los: "C6",
  company: "C7",
  retrievalNummer: "C8",
};

const DATA_MAPPING: Record<keyof TBuildingData, string> = {
  path: "A",
  economicUnit: "B",
  buildingNumber: "C",
  category: "G",
  areaCluster: "H",
  detailDeep: "I",
  price: "R",
};
const DATA_START = 15;

export type TBuildingData = {
  path?: string;
  economicUnit?: string;
  buildingNumber?: string;
  category?: number;
  areaCluster?: number;
  detailDeep?: string;
  price?: number;
};

export const usePointCloudImporter = (workBook: XLSX.WorkBook | null) => {
  const [headerData, setHeaderData] = useState<THeaderData | null>(null);
  const [buildingData, setBuildingData] = useState<TBuildingData[] | null>(
    null
  );
  const [isPointCloutSheet, setIsPointCloudSheet] = useState(false);

  useEffect(() => {
    setIsPointCloudSheet(workBook?.SheetNames.includes(SHEET_NAME) ?? false);
  }, [workBook, setIsPointCloudSheet]);

  useEffect(() => {
    if (!workBook || !workBook.Sheets[SHEET_NAME]) {
      return;
    }
    const sheetData = workBook.Sheets[SHEET_NAME];

    setHeaderData({
      los: sheetData[HEADER_MAPPING.los].v,
      company: sheetData[HEADER_MAPPING.company].v,
      retrievalNumber: sheetData[HEADER_MAPPING.retrievalNummer].v,
    });

    const bData: TBuildingData[] = [];
    let index = DATA_START;

    const keys = Object.keys(DATA_MAPPING);
    while (sheetData[`${DATA_MAPPING.path}${index}`]) {
      const t: TBuildingData = {};
      for (const key of keys) {
        const k = key as keyof TBuildingData;
        t[k] = sheetData[`${DATA_MAPPING[k]}${index}`].v;
      }

      bData.push(t);
      ++index;
    }

    setBuildingData(bData);
  }, [workBook, setBuildingData]);

  return { headerData, buildingData, isPointCloutSheet };
};
