import Axios from "axios";
import { useEffect, useState } from "react";

type TQsPersonResponse = {
  userId: number;
  qsPerson: string;
  isUser: 0 | 1;
};

type TState = {
  fetching: boolean;
  data: TQsPersonResponse[];
};

export const useGetQsPersons = (canFetch: boolean) => {
  const [state, setState] = useState<TState>({
    fetching: false,
    data: [],
  });
  useEffect(() => {
    if (!canFetch) {
      return;
    }
    if (state.data.length === 0 && state.fetching === false) {
      setState({
        ...state,
        fetching: true,
      });

      (async () => {
        let rData: TQsPersonResponse[] = [];
        const response = await Axios.get(`/user/qs-team`);

        if (response.status === 200) {
          rData = response.data as TQsPersonResponse[];
        }

        setState({ data: rData, fetching: false });
      })();
    }
  }, [state, canFetch, setState]);

  return state.data;
};
