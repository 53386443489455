import React, { useEffect, useState } from "react";
import { Bar, BarChart, Cell, Tooltip, XAxis, YAxis } from "recharts";
import axiosStatistics from "../../helper/axiosStatistics";

type MyProps = {
  type: "qs" | "pt";
};

type TChartData = {
  [key: string]: number | string;
};

const dataSource: { [key: string]: string[] } = {
  qs: ["all", "qsOpen", "qsReject", "qsResolved", "qsSuccess"],
  pt: [
    "all",
    "pts",
    "plausOpen",
    "plausReject",
    "plausResolved",
    "plausSuccess",
  ],
};

const dataKey = {
  qs: ["Gesamt", "Wartet", "Fehler", "Behoben", "Erfolgreich"],
  pt: ["Gesamt", "PTS", "Wartet", "Fehler", "Behoben", "Erfolgreich"],
};
const color = {
  qs: ["#BFCCD6", "#0E5A8A", "#A82A2A", "#0E5A8A", "#15B371"],
  pt: ["#BFCCD6", "#2B95D6", "#0E5A8A", "#A82A2A", "#0E5A8A", "#15B371"],
};
const ProgressChart: React.FunctionComponent<MyProps> = (props) => {
  const [isInit, setIsInit] = useState(false);
  const [chartData, setChartData] = useState<TChartData[]>([]);

  useEffect(() => {
    async function getData() {
      const data: TChartData[] = [];
      const promises: Promise<any>[] = [];

      dataSource[props.type].forEach((action) => {
        promises.push(axiosStatistics(action));
      });

      Promise.all(promises).then((values) => {
        for (let i = 0; i < values.length; i++) {
          data.push({ name: dataKey[props.type][i], Anzahl: values[i].count });
        }
        setChartData(data);
      });
    }

    if (isInit === false) {
      setIsInit(true);
      getData();
    }
  }, [isInit, setChartData, setIsInit, props]);

  return (
    <>
      <BarChart width={500} height={350} data={chartData}>
        <XAxis dataKey="name" stroke="#8884d8" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="Anzahl">
          {chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={color[props.type][index]} />
          ))}
        </Bar>
      </BarChart>
    </>
  );
};

export default ProgressChart;
