import * as React from "react";
import { TLoginProps } from ".";
import { Input } from "../Input";

type TUserInputProps = {
  onUserChange: TLoginProps["onUserChange"];
  username: string;
  error: string;
  help: string;
};

export const UserInput: React.FunctionComponent<TUserInputProps> = ({
  onUserChange,
  username,
  error = "",
  help = "",
}) => {
  return (
    <Input
      onChange={onUserChange}
      value={username}
      placeholder="Benutzername"
      label="Benutzername"
      labelTop={true}
      displayOnValue={true}
      icon="USER_REGULAR"
      onIconClick={() => {
        onUserChange("");
      }}
      error={error}
      help={help}
      testId="usernameLogin"
    />
  );
};
