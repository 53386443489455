import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button } from "../Button";
type TWriteCommentProps = {
  isOpen: boolean;
  saveComment: (val: string) => void;
  onCancel: () => void;
};

export const WriteComment: React.FC<TWriteCommentProps> = ({
  isOpen,
  saveComment,
  onCancel,
}) => {
  const [value, setValue] = useState("");
  const ref = React.createRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    setTimeout(() => {
      ref?.current?.focus();
    }, 0);
  }, [ref]);

  if (!isOpen) {
    return null;
  }
  return (
    <>
      <div className="w-full mt-2">
        <textarea
          ref={ref}
          value={value}
          className="w-full h-24 resize-none"
          onChange={(e) => setValue(e.target.value)}
        ></textarea>
      </div>
      <div className="w-full flex justify-end mt-3">
        <Button
          buttonStyle="outline"
          intent="danger"
          className="mr-3"
          onClick={onCancel}
        >
          <FontAwesomeIcon icon={faTimes} /> Abbrechen
        </Button>
        <Button
          intent="success"
          buttonStyle="outline"
          onClick={() => saveComment(value)}
        >
          <FontAwesomeIcon icon={faSave} /> Speichern
        </Button>
      </div>
    </>
  );
};
