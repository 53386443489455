import * as React from "react";

type TRemembderProps = {
  showRemember?: boolean;
  isRemember?: boolean;
  onRememberChange?: () => void;
};

export const Remember: React.FunctionComponent<TRemembderProps> = ({
  showRemember = false,
  isRemember = false,
  onRememberChange,
}) => {
  if (showRemember === false || typeof onRememberChange !== "function") {
    return null;
  }

  return (
    <div className="text-xs mt-2" data-testid="password-remember">
      <input
        className="mr-2"
        type="checkbox"
        checked={isRemember}
        onChange={onRememberChange}
      />
      Benutzer merken
    </div>
  );
};
