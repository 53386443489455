import { RouteComponentProps } from "@reach/router";
import React from "react";
import { Settings } from "../../../components/Settings";
import Tile from "../../../components/Tile";
import { uiSettings } from "../../../config/uiSettings";

export const UiSettings: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <>
      <h1>Einstellungen für den Benutzer</h1>
      {uiSettings.map((setting, i) => (
        <div style={{ maxHeight: "200px" }} key={`settings-${i}`}>
          <Tile title={setting.title}>
            <Settings {...setting} />
          </Tile>
        </div>
      ))}
    </>
  );
};
