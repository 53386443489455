import { RouteComponentProps } from "@reach/router";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import TokenList from "./TokenList";
import TokenModal from "./TokenModal";
import { Button } from "../../../components/Button";
export type IToken = {
  id: number;
  description: string;
  createdAt: string;
  read: "YES" | "NO";
  write: "YES" | "NO";
};

const TokenManagement: React.FunctionComponent<RouteComponentProps> = () => {
  const [token, setToken] = useState<IToken[]>([]);
  const [loaded, setLoaded] = useState(false);
  const [progress, setProgress] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  function getToken() {
    setProgress(true);
    Axios.get("/admin/token/").then((response) => {
      setToken(response.data);
      setProgress(false);
    });
  }

  function closeModal() {
    setModalOpen(false);
    getToken();
  }

  useEffect(() => {
    if (loaded === false) {
      setLoaded(true);
      getToken();
    }
  }, [loaded, setLoaded]);
  return (
    <div style={{ maxWidth: "800px" }}>
      <TokenModal isOpen={modalOpen} onClose={() => closeModal()} />

      <h1>Persönliche access tokens</h1>
      <div className="text-right">
        <Button
          icon="PLUS"
          onClick={() => setModalOpen(true)}
          buttonStyle="elevated"
        />
      </div>

      <TokenList token={token} progress={progress} />
    </div>
  );
};

export default TokenManagement;
