import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TDetailClosed } from "../../store/settings/settingsReducer";
import {
  faAngleDown,
  faAngleUp,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { AccordionRowContext } from "../../context/AccordionRow";

type TAccordionPaneProps = {
  headline?: string;
  headlineElements?: JSX.Element;
  className?: string;
  widgetName: TDetailClosed;
  children?: React.ReactNode;
  gridItems?: number;
};

const STORAGE_KEY = "dataWidgets";

export const AccordionPane: React.FunctionComponent<TAccordionPaneProps> = ({
  headline,
  headlineElements,
  children,
  className = "",
  widgetName,
  gridItems = 5,
}) => {
  const [itemCount, setItemCount] = useState<number | null>(null);
  const [isOpen, setOpen] = useState(true);
  const [toggleIcon, setToggleIcon] = useState(faAngleDown);
  const accordionRef = useRef<HTMLDivElement>(null);

  /**
   * Widgets aus dem storage holen und eigenen open state setzen
   */
  useEffect(() => {
    const storedWidgets = JSON.parse(localStorage.getItem(STORAGE_KEY) ?? "[]");
    const wasOpen = !storedWidgets.includes(widgetName);
    setOpen(wasOpen);
    if (wasOpen) {
      setToggleIcon(faAngleDown);
    } else {
      setToggleIcon(faAngleUp);
    }
  }, [widgetName, setOpen]);

  /**
   * maxHeight anhand von isOpen am element setzen für animation
   */
  useEffect(() => {
    if (!accordionRef.current) {
      return;
    }
    let maxHeight = 0;
    if (isOpen) {
      maxHeight = 800;
    }
    setTimeout(() => {
      if (!accordionRef.current) {
        return;
      }
      accordionRef.current.style.maxHeight = `${maxHeight}px`;
    }, 0);
  }, [accordionRef, isOpen]);

  const toggleOpen = useCallback(() => {
    let storedWidgets: string[] = JSON.parse(
      localStorage.getItem(STORAGE_KEY) ?? "[]"
    );
    let icon: IconDefinition;
    let open: boolean;
    if (storedWidgets.includes(widgetName)) {
      storedWidgets = storedWidgets.filter((val) => val !== widgetName);
      icon = faAngleDown;
      open = true;
    } else {
      open = false;
      storedWidgets.push(widgetName);
      icon = faAngleUp;
    }
    localStorage.setItem(STORAGE_KEY, JSON.stringify(storedWidgets));
    setOpen(open);
    setToggleIcon(icon);
  }, [widgetName, setOpen, setToggleIcon]);

  return (
    <AccordionRowContext.Provider
      value={{
        setCount: setItemCount,
      }}
    >
      <div
        className={`order-area w-full mt-5 mb-5 border-border border rounded ${className}`}
      >
        <div
          onClick={toggleOpen}
          className={`bg-header cursor-pointer h-8  pl-2 text-base font-semibold leading-9 align-middle flex justify-between`}
        >
          <div className="flex">
            <span className="mr-5">
              {headline}
              {itemCount !== null ? `(${itemCount})` : ""}
            </span>
            {headlineElements}
          </div>
          <button className="w-8 mr-2 relative" style={{ lineHeight: "100%" }}>
            <FontAwesomeIcon icon={toggleIcon} size={"lg"} />
          </button>
        </div>
        <div
          ref={accordionRef}
          className="overflow-hidden w-full transition-all duration-300"
        >
          <div
            className={`grid gap-x-5 gap-y-2 grid-cols-${gridItems} w-full p-2`}
          >
            {children}
          </div>
        </div>
      </div>
    </AccordionRowContext.Provider>
  );
};
