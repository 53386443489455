import Axios from "axios";
import { TQuickFilterConfig } from "../config/quickFilter";

export type TAxiosStatistic =
  | TQuickFilterConfig["action"]
  | "officeFail"
  | "billRelease"
  | "plausSuccess"
  | "all";

const axiosStatistics = (type: string = "billRelease") => {
  return Axios.get(`/statistic/${type}`).then((response) => {
    return response.data;
  });
};

export default axiosStatistics;
