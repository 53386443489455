import { Licenses } from "npm-license-crawler";
import * as React from "react";
import { LicenseRow } from "./LicenseRow";

export type TLicensesListProps = {
  licenses: Licenses;
};

export const LicencesList: React.FunctionComponent<TLicensesListProps> = ({
  licenses,
}) => {
  const licenseType: { [key: string]: React.ReactNode[] } = {};

  let rowKey = 0;
  for (const key of Object.keys(licenses)) {
    const current = licenses[key];
    if (!licenseType[current.licenses]) {
      licenseType[current.licenses] = [];
    }
    ++rowKey;
    licenseType[current.licenses].push(
      <LicenseRow
        project={key}
        key={`license-row-${rowKey}`}
        license={current}
      />
    );
  }

  return (
    <div data-testid="license-list">
      <div data-testid="license-list-header">
        Verwendete Opensource Module und Lizenzen:
      </div>
      {Object.keys(licenseType)
        .sort()
        .map((key, i) => {
          return (
            <div key={`license-list-${i}`}>
              <h1 data-testid="license-type">{key}</h1>
              {licenseType[key]}
            </div>
          );
        })}
    </div>
  );
};
