import * as React from "react";
import { Icon } from "../Icon";
import { Icons, TIconNames } from "../Icon/IconNames";

export type TInputIconProps = {
  size: number;
  isLeft: boolean;
  testId: string;
  icon?: TIconNames | React.ReactNode;
  className?: string;
  onClick?: () => void;
};

export const InputIcon: React.FunctionComponent<TInputIconProps> = ({
  size,
  isLeft,
  testId,
  icon,
  className,
  onClick,
}) => {
  if (React.isValidElement(icon)) {
    return icon;
  } else if (typeof icon === "string" && Object.keys(Icons).includes(icon)) {
    if (typeof onClick === "function") {
      className += " cursor-pointer";
    }

    return (
      <div
        data-testid={testId}
        className={className}
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          left: isLeft ? "5px" : "",
          right: !isLeft ? "5px" : "",
        }}
        onClick={() => {
          if (onClick && typeof onClick === "function") {
            onClick();
          }
        }}
      >
        <Icon icon={icon as TIconNames} size={size} />
      </div>
    );
  }

  return null;
};
