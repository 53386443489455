import React, { useEffect, useLayoutEffect, useState } from "react";
import { Portal } from "../Portal";

type TPopoverContentProps = {
  isOpen: boolean;
  rect: DOMRect | null;
};

export const PopoverContent: React.FunctionComponent<TPopoverContentProps> = ({
  isOpen,
  children,
  rect,
}) => {
  const ref = React.createRef<HTMLDivElement>();
  const [adjust, setAdjust] = useState({
    h: 0,
    w: 0,
  });

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    const calculteX = (current: HTMLDivElement, rectBase: DOMRect) => {
      let width = current.scrollWidth / 2;

      if (window.innerWidth - rectBase.right < width) {
        width += width - rectBase.width;
      }

      return width;
    };

    const calculateY = (current: HTMLDivElement, rectBase: DOMRect) => {
      const height = current.scrollHeight;

      return height;
    };

    setTimeout(() => {
      if (
        ref.current &&
        rect &&
        (adjust.h === 0 || ref.current.scrollHeight !== adjust.h)
      ) {
        setAdjust({
          h: calculateY(ref.current, rect),
          w: calculteX(ref.current, rect),
        });
      }
    }, 50);
  }, [ref, adjust, setAdjust, rect, isOpen]);

  useLayoutEffect(() => {
    setTimeout(() => {
      if (ref.current) {
        ref.current.style.opacity = "1";
      }
    }, 50);
  }, [ref]);

  if (!isOpen || rect === null) {
    return null;
  }

  return (
    <Portal>
      <div className="static z-20 inset-0">
        <div
          ref={ref}
          className="absolute border border-border top-0 left-0 bg-white p-2 transition-opacity ease-linear duration-250"
          style={{
            top: `${rect.top - adjust.h}px`,
            left: `${rect.left - adjust.w}px`,
            opacity: 0,
          }}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
};
