import { TCompanyType } from "../../store/user/types";

type TOfficeMyQsProps = {
  filter: string;
  companyType: TCompanyType;
  onChange: (v: string) => void;
};

export const OfficeMyQs: React.FC<TOfficeMyQsProps> = ({
  companyType,
  filter,
  onChange,
}) => {
  if (companyType !== "amt") {
    return null;
  }

  return (
    <button
      className={`border border-gray-500 rounded py-1 px-2 hover:bg-primary${
        filter ? " bg-green-500" : ""
      }`}
      onClick={() => {
        let val = "";
        if (!filter) {
          val = "office|myQs";
        }

        onChange(val);
      }}
    >
      Nur QS Aufträge vom Amt
    </button>
  );
};
