import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";

type TMyDataProps = ReturnType<typeof mapStateToProps>;

const MyDataComponent: React.FunctionComponent<TMyDataProps> = ({
  firstname,
  lastname,
  email,
  companyName,
}) => {
  return (
    <div className="w-full h-full relative" style={{ minHeight: "250px" }}>
      <div className="flex mt-2">
        <div className="w-20">Vorname:</div>
        {firstname}
      </div>
      <div className="flex mt-2">
        <div className="w-20">Nachname:</div>
        {lastname}
      </div>
      <div className="flex mt-2">
        <div className="w-20">Email:</div>
        {email}
      </div>
      <div className="flex mt-2">
        <div className="w-20">Ihre Firma:</div>
        {companyName}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  firstname: state.user.firstname,
  lastname: state.user.lastname,
  email: state.user.email,
  companyName: state.user.companyName,
});

export const MyData = connect(mapStateToProps)(MyDataComponent);
