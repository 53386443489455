import React from "react";

type TMigrationWorkflowResult = {
  comment: string;
  state: number;
  commentOld: string;
  stateOld: number;
  id: number;
};
export const MigrationWorkflowResult: React.FC<TMigrationWorkflowResult> = ({
  comment,
  commentOld,
  state,
  stateOld,
  id,
}) => {
  return (
    <div className="grid grid-cols-12 p-1 hover:bg-hover">
      <div>{id}</div>
      <div className="col-span-5">{commentOld}</div>
      <div className="col-span-4">{comment}</div>
      <div className="text-center">{stateOld}</div>
      <div className="text-center">{state}</div>
    </div>
  );
};
