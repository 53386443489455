import { License } from "npm-license-crawler";
import * as React from "react";

type TLicenseRowProps = {
  license: License;
  project: string;
};

const cleanUrl = (url: string) => {
  if (url.startsWith("github:")) {
    return url.replace("github:", "https://");
  }
  return url;
};

export const LicenseRow: React.FunctionComponent<TLicenseRowProps> = ({
  license,
  project,
}) => {
  if (license.repository.startsWith("git@")) {
    return null;
  }

  return (
    <div
      className="w-full flex border-border border-b"
      data-testid="license-row"
    >
      <div className="w-56" data-testid="license-project">
        {project}
      </div>
      <div className="w-16" data-testid="license-project">
        <a
          href={cleanUrl(license.repository)}
          target="_blank"
          rel="noopener noreferrer"
        >
          Projekt
        </a>
      </div>
      <div className="flex-1" data-testid="license-licencse">
        <a href={license.licenseUrl} target="_blank" rel="noopener noreferrer">
          Lizenz
        </a>
      </div>
    </div>
  );
};
