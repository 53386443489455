import { useEffect, useState } from "react";
import axios from "axios";

export const useVerifyMeasureOrder = (path = "", force = 0) => {
  const [isValid, setValid] = useState<null | boolean>(null);

  useEffect(() => {
    if (!path) {
      return;
    }

    const process = async () => {
      try {
        await axios.get(`/verify/measure/?path=${path}`);
        setValid(true);
      } catch (e) {
        setValid(false);
      }
    };

    process();
  }, [path, force, setValid]);

  return isValid;
};
