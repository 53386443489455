import React from "react";
import { Spinner } from "../Spinner";

type TCommentSpinnerProps = {
  loading: boolean;
};

export const CommentSpinner: React.FunctionComponent<TCommentSpinnerProps> = ({
  loading,
}) => {
  if (!loading) {
    return null;
  }

  return (
    <div className="ml-auto mr-auto relative h-28">
      <Spinner center={true} size="normal" />
    </div>
  );
};
