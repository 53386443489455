export type TPositionValue = "bottom" | "left" | "right" | "top";

export const calcPosition = (
  rect: DOMRect,
  width: number,
  height: number,
  adjust = 0
): [TPositionValue, TPositionValue] => {
  const windowWidth = window.innerWidth - adjust;

  switch (true) {
    case rect.x > width / 2 && rect.y > height:
      return ["top", "bottom"];

    case rect.x > width && rect.y > height / 2:
      return ["left", "right"];

    case windowWidth - rect.x > width && rect.y > height / 2:
      return ["right", "left"];

    default:
      return ["bottom", "top"];
  }
};
