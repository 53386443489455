import { TCommentResult } from "../../hooks/useGetComments";
import { AddCommentButton } from "./AddCommentButton";
import { Comment } from "./Comment";
import { NoComments } from "./NoComments";

type TDisplayCommentsProps = {
  comments: TCommentResult[];
  isLoading: boolean;
  addComment: boolean;
  onClick: () => void;
};

export const DisplayComments: React.FC<TDisplayCommentsProps> = ({
  comments,
  isLoading,
  addComment,
  onClick,
}) => {
  if (isLoading || addComment) {
    return null;
  }

  return (
    <>
      <NoComments comments={comments} />
      {comments.map((comment, i) => (
        <Comment {...comment} key={`comment-${i}`} />
      ))}
      <AddCommentButton onClick={onClick} />
    </>
  );
};
