import React from "react";
import { Spinner } from "../Spinner";

type TPwRequestProps = {
  request: boolean;
};

export const PwRequest: React.FunctionComponent<TPwRequestProps> = ({
  request,
}) => {
  if (!request) {
    return null;
  }

  return (
    <div className="ml-auto mr-auto mt-10">
      <Spinner size="large" />
      <div className="mt-3 text-center font-bold">Ändere Passwort</div>
    </div>
  );
};
