import React, { useContext, useState } from "react";
import { KanbanBoardContext } from "../KanbanBoard";
import { leadingZeroFormatter } from "../../../helper/formatter";
import { DisplayNrf } from "../DisplayNrf";
import { DragIndicator } from "../DragIndicator";
import { TMinimalBoardCardProps } from "./MeasureWaitPtsUploadBoardCard";
import progressBg from "./images/P.png";
import waitingBg from "./images/W.png";

export const MeasureSmbAssignBoardCard: React.FC<TMinimalBoardCardProps> = ({
  isOpen,
  buildingId,
  buildingNumber,
  companyName,
  designation,
  economicUnit,
  losNumber,
  office,
  isDraggable = false,
  nrfMeasurement,
  displayNrf,
  onDragStart,
  onDragEnd,
  workdays,
  finishDate,
  waiting,
  cardColor,
}) => {
  const { onClick } = useContext(KanbanBoardContext);
  const [isHover, setHover] = useState(false);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      style={{
        width: "278px",
        height: "125px",
        backgroundImage: `url(${waiting ? waitingBg : progressBg})`,
      }}
      onClick={() => {
        if (typeof onClick === "function") {
          onClick(buildingId);
        }
      }}
      onDragStart={(e) => {
        if (typeof onDragStart === "function") {
          onDragStart();
        }
      }}
      onDragEnd={(e) => {
        if (typeof onDragEnd === "function") {
          onDragEnd();
        }
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      draggable={isDraggable}
      className={`text-xs flex flex-row rounded m-1 hover:border hover:bg-gray-400 py-1 pl-1 ${cardColor}
       border-4 bg-center bg-no-repeat bg-contain`}
    >
      <div className="w-full overflow-hidden">
        <div className="h-3.5 flex justify-between pr-2">
          <div>
            <strong>
              {workdays !== undefined ? (
                <>Verbleibende Werktage: ({workdays ?? ""})</>
              ) : null}
            </strong>
          </div>
          <div>{finishDate ?? ""}</div>
        </div>

        <div className="h-4 mt-1  overflow-ellipsis truncate">
          {designation}
        </div>
        <div className="flex flex-row mt-0.5">
          <div className="w-1/2">WE: {leadingZeroFormatter(economicUnit)}</div>
          <div className="w-1/2">
            GE: {leadingZeroFormatter(buildingNumber)}
          </div>
        </div>
        <div className="flex flex-row mt-0.5">
          <div className="w-1/2">Amt: {office}</div>
          <div className="w-1/2">Los: {losNumber}</div>
        </div>
        <div className="overflow-hidden h-8 overflow-ellipsis truncate mt-0.5">
          <div>Firma: {companyName}</div>
          <DisplayNrf displayNrf={displayNrf} nrf={nrfMeasurement} />
        </div>
      </div>
      <DragIndicator isDraggable={isDraggable} isHover={isHover} />
    </div>
  );
};
