import Axios, { AxiosResponse } from "axios";
import { Action, ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import { disableAjaxProgress } from "../appState/action";
import { TMeasureDetailResponse } from "../editOrder/types";
import { TFilterSettings } from "../settings/settingsReducer";
export type fo = number;

export const ADD_DATA = "server/ADD_DATA";
export const RESET_SERVERDATA = "server/RESET_SERVERDATA";
export const SERVER_DATA_FORCE = "server/FORCE";

type TServerResponse = {
  count: number;
  rows: TMeasureDetailResponse[];
};

interface IAddServerData extends Action<typeof ADD_DATA> {
  payload: TServerResponse;
}

interface IResetServerData extends Action<typeof RESET_SERVERDATA> {}

interface IForceServerData extends Action<typeof SERVER_DATA_FORCE> {}

export type TServerDataAction =
  | IAddServerData
  | IResetServerData
  | IForceServerData;

export function resetServerData(): TServerDataAction {
  return {
    type: RESET_SERVERDATA,
  };
}

export function addServerData(payload: TServerResponse): TServerDataAction {
  return {
    type: ADD_DATA,
    payload,
  };
}

export function forceServerData() {
  return {
    type: SERVER_DATA_FORCE,
  };
}

export const fetchServerData: ActionCreator<
  ThunkAction<
    Promise<TServerDataAction>,
    TMeasureDetailResponse,
    any,
    TServerDataAction
  >
> = (filter: TFilterSettings, limit: number, offset: number) => {
  return async (dispatch: Dispatch) => {
    return Axios.get("/data/", {
      params: {
        ...filter,
        limit,
        offset,
      },
    }).then((response: AxiosResponse) => {
      dispatch(disableAjaxProgress());

      return dispatch(addServerData(response.data as TServerResponse));
    });
  };
};
