import React from "react";
import { Icon } from "../Icon";
import { Button } from "../Button";

type TPwSuccessProps = {
  success: boolean;
  disableSuccess: () => void;
};

export const PwSuccess: React.FunctionComponent<TPwSuccessProps> = ({
  success,
  disableSuccess,
}) => {
  if (!success) {
    return null;
  }

  return (
    <div className="mt-5">
      <Icon icon="CHECK_SOLID" size={21} className="text-success" />
      <div className="text-center">Passwort erfolgreich geändert</div>
      <div className="mt-5 ml-auto mr-auto text-center">
        <Button text="zurück" onClick={disableSuccess} buttonStyle="elevated" />
      </div>
    </div>
  );
};
