import axios from "axios";
import FileSaver from "file-saver";
import { useEffect, useState } from "react";

export const useDownload = (id: null | number) => {
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    if (progress || id === null) {
      return;
    }
    const download = async () => {
      setProgress(true);
      const response = await axios.get(`/file-download/${id}/`, {
        responseType: "blob",
      });

      FileSaver.saveAs(response.data, response.headers["x-file-name"]);
      setProgress(false);
    };
    download();
  }, [id, progress, setProgress]);

  return progress;
};
