import { useEffect } from "react";
import {
  LOCAL_STORAGE_LIMIT,
  LOCAL_STORAGE_ORDER_DIRECTION,
  LOCAL_STORAGE_ORDER_FIELD,
  LOCAL_STORAGE_PAGE,
  LOCAL_STORAGE_SEARCH_TERM,
} from "../../config/dataTable";
type TLocalStorageRebuild = {
  setCurrentPage: (val: number) => void;
  setOrderField: (val: string) => void;
  setDirection: (val: "DESC" | "ASC") => void;
  setLimit: (val: number) => void;
  setSearchTerm: (val: string) => void;
};

export const useLocalStorageRebuild = (setter: TLocalStorageRebuild) => {
  const {
    setCurrentPage,
    setOrderField,
    setDirection,
    setLimit,
    setSearchTerm,
  } = setter;

  useEffect(() => {
    const storedPage = Number(localStorage.getItem(LOCAL_STORAGE_PAGE));
    if (storedPage) {
      setCurrentPage(storedPage);
    } else {
      setCurrentPage(0);
    }
  }, [setCurrentPage]);

  useEffect(() => {
    const storedSearchTerm = localStorage.getItem(LOCAL_STORAGE_SEARCH_TERM);
    if (storedSearchTerm) {
      setSearchTerm(storedSearchTerm);
    } else {
      setOrderField("");
    }
  }, [setSearchTerm, setOrderField]);

  useEffect(() => {
    const storedOrderField = localStorage.getItem(LOCAL_STORAGE_ORDER_FIELD);
    if (storedOrderField) {
      setOrderField(storedOrderField);
    } else {
      setOrderField("economicUnit");
    }
  }, [setOrderField]);

  useEffect(() => {
    const storedOrderDirection = localStorage.getItem(
      LOCAL_STORAGE_ORDER_DIRECTION
    );

    if (storedOrderDirection === "ASC" || storedOrderDirection === "DESC") {
      setDirection(storedOrderDirection);
    } else {
      setDirection("DESC");
    }
  }, [setDirection]);

  useEffect(() => {
    const storedLimit = Number(localStorage.getItem(LOCAL_STORAGE_LIMIT));
    if (isNaN(storedLimit) || storedLimit === 0) {
      localStorage.setItem(LOCAL_STORAGE_LIMIT, "30");
      setLimit(30);
    } else {
      setLimit(storedLimit);
    }
  }, [setLimit, setOrderField, setDirection, setCurrentPage]);
};
