import { useEffect, useState } from "react";
import axios from "axios";

export type TMigrationResponse = {
  success: any[];
  errors: any[];
};

export const useMigration = (url: string) => {
  const [process, setProcess] = useState(true);

  const [migrated, setMigrated] = useState<any[]>([]);
  const [errors, setErrors] = useState<any[]>([]);

  useEffect(() => {
    const doRequest = async () => {
      try {
        const response = await axios.get<TMigrationResponse>(url);

        setMigrated(response.data.success ?? []);
        setErrors(response.data.errors ?? []);
      } catch (error) {
        // toast("Fehler bei der Migration", {
        //   intent: "danger",
        //   autoClose: null,
        // });
      } finally {
        setProcess(false);
      }
    };

    doRequest();
  }, [url, setProcess, setMigrated, setErrors]);

  return { process, migrated, errors };
};
