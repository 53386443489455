import { formatReadonly } from "../../helper/formatter";
import { useState } from "react";

type TInputSelect = {
  canEdit: boolean;
  value: string;
  readonlyValue: string;
  selection: Record<string, string>;
  onChange: (val: string) => void;
};
export const InputSelect: React.FC<TInputSelect> = ({
  canEdit,
  value,
  readonlyValue,
  selection,
  onChange,
}) => {
  const [val, setVal] = useState(value);

  if (!canEdit) {
    return <>{formatReadonly(readonlyValue)}</>;
  }
  return (
    <>
      <select
        value={val}
        onChange={(e) => {
          onChange(e.target.value);
          setVal(e.target.value);
        }}
      >
        {Object.keys(selection).map((key) => (
          <option value={key} key={key}>
            {selection[key]}
          </option>
        ))}
      </select>
    </>
  );
};
