import { useEffect, useState } from "react";
import axios from "axios";

export type TVerifyQSBillStateResponse = {
  deliverDate: string | null;
  comment: string | null;
};

export const useVerifyQSBillState = (path?: string, force?: number) => {
  const [serverBillData, setServerBillData] =
    useState<TVerifyQSBillStateResponse>({
      deliverDate: null,
      comment: null,
    });

  useEffect(() => {
    if (!path) {
      return;
    }

    const process = async () => {
      try {
        const response = await axios.get<TVerifyQSBillStateResponse>(
          `/verify/status-bill?path=${path}`
        );
        setServerBillData(response.data);
      } catch (err) {}
    };

    process();
  }, [path, force, setServerBillData]);

  return serverBillData;
};
