import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { ICON_SIZE, NavigationContext } from "./Sidebar";
import { SidebarTooltip } from "./SidebarTooltip";
import { TTogglerProps } from "./types";
export const Toggler: React.FunctionComponent<TTogglerProps> = ({ toggle }) => {
  const { isOpen = false } = React.useContext(NavigationContext);

  return (
    <SidebarTooltip tip="Zum öffnen klicken" isOpen={isOpen}>
      <div
        style={{ height: ICON_SIZE }}
        className={`cursor-pointer hover:bg-border hover:bg-opacity-40 text-sm
      w-full flex flex-wrap overflow-hidden transition-none border-white border-t`}
        onClick={toggle}
      >
        <div
          style={{ width: ICON_SIZE, height: ICON_SIZE }}
          className="relative transition-transform duration-250"
        >
          <div
            className="absolute left-1/2 top-1/2"
            style={{
              transform: "translate(-50%, -50%)",
            }}
          >
            <FontAwesomeIcon
              className="opacity-40 hover:opacity-100"
              icon={faAngleDoubleLeft}
              size="2x"
              rotation={isOpen ? 180 : undefined}
            />
          </div>
        </div>
        <div
          style={{ lineHeight: ICON_SIZE }}
          className={`whitespace-nowrap overflow-hidden h-full flex-grow`}
        >
          Sidebar schliessen
        </div>
      </div>
    </SidebarTooltip>
  );
};
