import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useAufmassLoeschen } from "./hooks/useAufmassLoeschen";
import { DataRow } from "./DataRow";

import { useAufmassPunktwolkeDeleteImporter } from "../../../hooks/importer/useAufmassPunktwolkeDeleteImporter";
import { Button } from "../../Button";

export const AufmassLoeschen: React.FC<{
  workBook: XLSX.WorkBook | null;
  isWorkBook: (state: boolean) => void;
}> = ({ workBook, isWorkBook }) => {
  const { isSheet, importData } = useAufmassLoeschen(workBook);
  const [toImport, setToImport] = useState<string[]>([]);
  const [validPath, setValidPath] = useState<string[]>([]);
  const [force, setForce] = useState(Date.now());

  useEffect(() => {
    if (isSheet) {
      isWorkBook(true);
    }
  }, [isSheet, isWorkBook]);

  const process = useAufmassPunktwolkeDeleteImporter(() => {
    setToImport([]);
    setValidPath([]);
    setForce(Date.now());
  });
  const toggleToImport = (path: string) => {
    if (toImport.includes(path)) {
      setToImport(toImport.filter((p) => p !== path));
    } else {
      setToImport([...toImport, path]);
    }
  };

  if (!isSheet) {
    return null;
  }

  return (
    <>
      <div className={"flex mb-2"}>
        <h1>Aufmaß/Punktwolke löschen Import</h1>
        <Button
          className={"ml-2"}
          intent={"success"}
          disabled={!toImport.length}
          onClick={() => {
            process(toImport);
          }}
        >
          Importieren
        </Button>
      </div>
      <table className="table-auto border-collapse border border-border overflow-x-auto text-xs">
        <thead>
          <tr>
            <th className="border border-border p-1 w-16">
              <input
                className={"mr-1"}
                type="checkbox"
                checked={toImport.length === validPath.length}
                onChange={(e) => {
                  if (toImport.length !== validPath.length) {
                    setToImport([...validPath]);
                  } else {
                    setToImport([]);
                  }
                }}
              />
              ({toImport.length})
            </th>
            <th className="border border-border p-1">Pfad</th>
            <th className="border border-border p-1">Aufmaß</th>
            <th className="border border-border p-1">Punktwolke</th>
            <th className="border border-border p-1">Info</th>
          </tr>
        </thead>
        <tbody>
          {importData.map((item, index) => (
            <DataRow
              force={force}
              {...item}
              toggleChecked={toggleToImport}
              isChecked={toImport.includes(item.path ?? "")}
              key={`data-row-${index}`}
              setValid={(path: string) => {
                if (!validPath.includes(path)) {
                  setValidPath([...validPath, path]);
                }
              }}
            />
          ))}
        </tbody>
      </table>
    </>
  );
};
