import { Link } from "@reach/router";
import * as React from "react";
import { TSublevelItemProps } from "./types";
import { useSubLevel } from "./useSubLevel";
export const SubLevelItem: React.FunctionComponent<TSublevelItemProps> = ({
  text,
  link = "",
  isWrapper,
}) => {
  const { className, onClick } = useSubLevel(link, isWrapper);
  return (
    <li className="hover:bg-border">
      <Link to={link} className={className} onClick={onClick}>
        {text}
      </Link>
    </li>
  );
};
