import React from "react";
import { Content } from "./Content";
import { Modal } from "../../../components/Modal";

type TAddUserModal = {
  isOpen: boolean;
  onClose: () => void;
  setForce: () => void;
};

export const AddUserModal: React.FunctionComponent<TAddUserModal> = ({
  isOpen,
  onClose,
  setForce,
}) => {
  if (isOpen === false) {
    return <></>;
  }

  return (
    <Modal
      key="add-user-modal"
      isOpen={isOpen}
      top="400px"
      width="600px"
      onClose={onClose}
      title="Neuen Benutzer anlegen"
    >
      <Content onClose={onClose} setForce={setForce} />
    </Modal>
  );
};
