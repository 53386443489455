import { RouteComponentProps } from "@reach/router";
import { BuildingInfo } from "../../components/BuildingInfo/BuildingInfo";
import { OrderTabs } from "../../components/OrderTabs/OrderTabs";

type TBuildingProps = {
  id?: string;
  tab?: string;
} & RouteComponentProps;

export const Building: React.FC<TBuildingProps> = ({ id, tab }) => {
  if (!id || isNaN(Number(id))) {
    return null;
  }

  const buildingId = Number(id);

  return (
    <>
      <BuildingInfo id={buildingId} />
      <OrderTabs tab={tab} building={buildingId} />
    </>
  );
};
