import * as React from "react";
import { ButtonIcon } from "./ButtonIcon";
import { TIntent } from "../../config/colors";

export type TButtonStyle = "pill" | "outline" | "bordered" | "elevated";

export type TButtonProps = {
  onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void;
  text?: string;
  icon?: React.ReactNode;
  iconRight?: React.ReactNode;
  type?: "submit" | "reset" | "button";
  intent?: TIntent;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  buttonStyle?: TButtonStyle;
  full?: boolean;
  testId?: string;
  autoFocus?: boolean;
};

const disableStyle = (disabled: boolean) => {
  let style = "";
  if (disabled) {
    style = "opacity-50 cursor-not-allowed";
  }
  return style;
};

const getButtonStyle = (intent: TIntent, style?: TButtonStyle) => {
  switch (style) {
    case "pill":
      return `rounded-full text-t-${intent} bg-${intent} hover:bg-h-${intent}`;
    case "outline":
      return `bg-white rounded border border-${intent} text-${intent} hover:text-white hover:bg-${intent}`;
    case "bordered":
      return `rounded text-t-${intent} bg-${intent} hover:bg-h-${intent} border border-h-${intent}`;
    case "elevated":
      return "bg-white hover:bg-gray-100 text-gray-800 font-semibold border border-gray-400 rounded shadow";
    default:
      return `rounded text-t-${intent} bg-${intent} hover:bg-h-${intent}`;
  }
};

const getPaddingByIcons = (
  icon: TButtonProps["icon"] | undefined,
  iconRight: TButtonProps["iconRight"] | undefined,
  full: TButtonProps["full"]
) => {
  if (!full || (full && icon && iconRight) || (full && !icon && !iconRight)) {
    return "";
  }

  if (!icon) {
    return " ml-7";
  }

  return " mr-7";
};

export const Button: React.FunctionComponent<TButtonProps> = ({
  onClick,
  text = "",
  type = "button",
  icon,
  iconRight,
  intent = "primary",
  disabled = false,
  className = "",
  children,
  buttonStyle,
  full = false,
  testId = "button",
  autoFocus,
}) => {
  const propsIcon = {
    size: 4,
  };
  const ref = React.createRef<HTMLButtonElement>();
  React.useLayoutEffect(() => {
    if (ref.current && autoFocus) {
      ref.current.focus();
    }
  }, [ref, autoFocus]);

  let tailwindcss = `inline-flex font-bold py-1 px-2 ${getButtonStyle(
    intent,
    buttonStyle
  )} ${className} ${disableStyle(disabled)}`;

  if (full) {
    tailwindcss += " w-full";
  }

  return (
    <button
      ref={ref}
      data-testid={testId}
      className={tailwindcss.trim()}
      disabled={disabled}
      type={type}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        if (ref.current) {
          ref.current.focus();
        }
        if (typeof onClick === "function") {
          onClick(e);
        }
      }}
    >
      <ButtonIcon icon={icon} {...propsIcon} />
      <span
        className={`flex-1 text-center${getPaddingByIcons(
          icon,
          iconRight,
          full
        )}`}
      >
        {text ? text : children}
      </span>
      <ButtonIcon icon={iconRight} {...propsIcon} />
    </button>
  );
};
