import React, { useState } from "react";
import { formatReadonly } from "../../helper/formatter";

type TMyProps = {
  onSelect: (team: string) => void;
  value: string;
  canEdit?: boolean;
};

const TeamSelection: React.FunctionComponent<TMyProps> = ({
  onSelect,
  value,
  canEdit = false,
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  if (!canEdit) {
    return <>{formatReadonly(value)}</>;
  }

  return (
    <>
      <select
        onChange={(e) => {
          setCurrentValue(e.target.value);
          onSelect(e.target.value);
        }}
        value={currentValue}
      >
        <option value="">Team wählen</option>
        <option value="München">München</option>
        <option value="Stuttgart">Stuttgart</option>
      </select>
    </>
  );
};

export default TeamSelection;
