import React from "react";
import { TTabs } from "../config/tabs";
export type TOrderTabsContext = {
  building: number;
  displayTabs: string[];
  addDisplayTab: (tab: TTabs) => void;
  activeTab: TTabs;
};

export const BuildingTabsContext = React.createContext<
  Partial<TOrderTabsContext>
>({});
