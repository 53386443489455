import { Redirect, RouteComponentProps } from "@reach/router";
import { connect } from "react-redux";
import { RootState } from "../store";

type TAdminOnlyProps = { children: React.ReactNode } & RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

const component: React.FC<TAdminOnlyProps> = ({
  children,
  isAdmin,
  companyType,
}) => {
  if (companyType === null) {
    return null;
  }

  if (companyType !== "smb" || !isAdmin) {
    return <Redirect to="/home/" />;
  }

  return <>{children}</>;
};

const mapStateToProps = (state: RootState) => ({
  isAdmin: state.user.type === "admin",
  companyType: state.user.companyType,
});

export const AdminOnlyGroup = connect(mapStateToProps)(component);
