import React from "react";
import { IDataTableConfig } from "./DataTable";
import { DataValue, TDataValueProps } from "./DataValue";
import { FilterIndicator } from "./FilterIndicator";
import { SortIndicator } from "./SortIndicator";
type TDataRowProps = {
  config: IDataTableConfig;
  data: any[];
  limit: number;
  orderField: string;
  orderDirection: "ASC" | "DESC";
  hover: TDataValueProps["hover"];
  setHover: TDataValueProps["setHover"];
  onCellClick?: TDataValueProps["onCellClick"];
  onSort?: (field: string) => void;
};

export const DataRow: React.FunctionComponent<TDataRowProps> = ({
  config,
  data,
  limit,
  orderField,
  orderDirection,
  hover,
  setHover,
  onCellClick,
  onSort,
}) => {
  const dRow: JSX.Element[] = [];
  const hasData = data.length !== 0;

  let i = 0;
  while (i < limit) {
    if (hasData && data[i]) {
      dRow.push(
        <DataValue
          formatter={config.formatter}
          index={i}
          value={data?.[i]?.[config.dataIndex]}
          hover={hover}
          setHover={setHover}
          key={`${config.dataIndex}-${i}`}
          onCellClick={onCellClick}
        />
      );
    } else {
      dRow.push(
        <div
          key={`${config.dataIndex}-${i}`}
          className="h-6 border-b border-border p-1 text-xs truncate overflow-ellipsis overflow-hidden cell
          "
        >
          <div className={`${hasData ? "" : "smb-cell-loader rounded"}`}></div>
        </div>
      );
    }
    ++i;
  }

  return (
    <div
      className={`${
        config.hidden ? "" : "border-border border-r h-full relative"
      } relative overflow-hidden transition-all duration-100${
        config.size ? "" : " flex-1"
      }`}
      style={{
        minWidth: `${
          config.hidden ? 0 : config.size ? config.size + "px" : "200px"
        }`,
        maxWidth: `${config.hidden ? 0 : ""}`,
      }}
    >
      <div
        className={`h-6 w-full sticky border-border border-b pl-1 font-bold bg-gray-300 flex mr-12 overflow-hidden`}
      >
        {config.name}
        <div
          className="absolute flex"
          style={{
            right: "3px",
            top: "3px",
          }}
        >
          {
            <FilterIndicator
              filter={config.filter}
              dataIndex={config.dataIndex}
            />
          }
          <SortIndicator
            direction={orderDirection}
            isActive={config.dataIndex === orderField}
            isSortable={config.sortAble ?? false}
            orderField={config.dataIndex}
            onSort={onSort}
          />
        </div>
      </div>
      {dRow}
    </div>
  );
};
