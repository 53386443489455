import { useEffect, useState } from "react";
type TTabProps = {
  label: string;
  active: boolean;
  moveLeft: boolean;
  onClick: () => void;
};

export const Tab: React.FC<TTabProps> = ({
  label,
  active,
  moveLeft,
  onClick,
}) => {
  const [bg, setBg] = useState("");
  const [textColor, setTextColor] = useState("");
  const [zIndex, setZIndex] = useState("z-0");
  const [leftAllign, setLeftAllign] = useState("");

  useEffect(() => {
    if (active) {
      setBg("bg-primary");
      setTextColor("text-white");
      setZIndex("z-20");
    } else {
      setBg("bg-border");
      setTextColor("");
      setZIndex("z-0");
    }
  }, [active, setBg, setTextColor]);

  useEffect(() => {
    let className = "";
    if (moveLeft) {
      className = "-ml-5";
    }

    setLeftAllign(className);
  }, [moveLeft, setZIndex, setLeftAllign]);

  return (
    <div
      onClick={onClick}
      className={`border border-gray-700 px-6 py-2 rounded-t-lg ${zIndex} ${leftAllign} ${bg} ${textColor}
      hover:bg-opacity-90 hover:z-30 hover:bg-primary hover:text-white cursor-pointer`}
      style={{ maxWidth: "250px" }}
    >
      {label}
    </div>
  );
};
