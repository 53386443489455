import { useEffect, useState } from "react";
import axios from "axios";
import { STATUS_CODES } from "../../config/app";

export const useVerifyCompany = (company?: string, force: number = 0) => {
  const [isValid, setValid] = useState<null | boolean>(null);

  useEffect(() => {
    if (!company) {
      return;
    }
    const process = async () => {
      try {
        await axios.get(`/verify/company?company=${company}`);
        setValid(true);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (
            error.response?.status &&
            error.response.status === STATUS_CODES.Not_Acceptable
          ) {
            setValid(false);
          }
        }
      }
    };
    process();
  }, [company, force, setValid]);

  return isValid;
};
