import { Router } from "@reach/router";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Maintenance } from "./components/Maintanace";
import { Navigation } from "./components/Navigation/Navigation";
import AjaxProgress from "./components/Overlays/AjaxProgress";
import { UpdateNotification } from "./components/UpdateNotification";
import { SecureWrapper } from "./pages/Login";
import { Routing } from "./Routing";
import { RootState } from "./store";
import { fetchUser } from "./store/user/action";
import { SmbToast } from "./components/SmbToast/SmbToast";
type TAppProps = ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

const App: React.FunctionComponent<TAppProps> = ({ showLogin, getUser }) => {
  const [userFetched, setUserFetched] = useState(false);

  useEffect(() => {
    if (!userFetched) {
      setUserFetched(true);
      getUser();
    }
  }, [userFetched, setUserFetched, getUser]);

  return (
    <SmbToast>
      <Maintenance>
        <div className="h-full flex flex-col">
          <Header />
          <SecureWrapper showLogin={showLogin}>
            <div className="bg-gray-200 flex-1 flex overflow-y-auto h-full">
              <Router className="h-full">
                <Navigation default />
              </Router>
              <div className="p-5 overflow-x-auto overscroll-x-auto w-full h-full relative">
                <Routing showLogin={showLogin} />
              </div>
            </div>
          </SecureWrapper>
          <Footer />
        </div>
        <AjaxProgress />
        <UpdateNotification />
      </Maintenance>
    </SmbToast>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  getUser: () => dispatch(fetchUser()),
});

const mapStateToProps = (state: RootState) => ({
  showLogin: state.app.showLogin,
  currentPage: state.settings.detailTablePage,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
