type TFileDescriptionProps = {
  info: null | string;
};

export const FileDescription: React.FC<TFileDescriptionProps> = ({ info }) => {
  if (!info) {
    return null;
  }
  return <div className="pl-16 text-xs">{info}</div>;
};
