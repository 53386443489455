import React, { useState } from "react";
import { TTips } from "./Tip";
import { TipWrapper } from "./TipWrapper";

export type TToolTipProps = {
  content?: React.ReactNode;
  delay?: TTips["delay"];
  position?: TTips["position"];
  disabeld?: boolean;
  usePortal?: boolean;
};

type TState = {
  isOpen: boolean;
  rect: DOMRect | null;
};

export const ToolTip: React.FunctionComponent<TToolTipProps> = ({
  children,
  content,
  position = "auto",
  delay = 1000,
  disabeld = false,
  usePortal = true,
}) => {
  const [state, setState] = useState<TState>({
    isOpen: false,
    rect: null,
  });

  return (
    <div
      className="cursor-help"
      onMouseLeave={() => {
        setState({ isOpen: false, rect: null });
      }}
      onMouseEnter={(e) => {
        if (content && disabeld === false) {
          setState({
            isOpen: true,
            rect: e.currentTarget.getBoundingClientRect(),
          });
        }
      }}
      data-test-id="tooltip"
    >
      {children}
      <TipWrapper
        {...state}
        delay={delay}
        position={position}
        usePortal={usePortal}
      >
        {content}
      </TipWrapper>
    </div>
  );
};
