import { Redirect, RouteComponentProps } from "@reach/router";
import { connect } from "react-redux";
import { ROUTE_INDEX } from "../config/routes";
import { RootState } from "../store";

type TSmbOnlyProps = { children: React.ReactNode } & RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

const component: React.FC<TSmbOnlyProps> = ({ children, companyType }) => {
  if (companyType !== "smb") {
    return <Redirect to={ROUTE_INDEX} />;
  }

  return <>{children}</>;
};

const mapStateToProps = (state: RootState) => ({
  companyType: state.user.companyType,
});

export const SmbOnlyGroup = connect(mapStateToProps)(component);
