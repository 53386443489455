import * as React from "react";
import { createPortal } from "react-dom";
import { calcPosition } from "../components/utils/calcPosition";
import { calcStylePosition } from "../components/utils/calcStylePosition";

export type TTips = {
  isOpen: boolean;
  position: "auto" | "bottom" | "left" | "right" | "top";
  delay: 75 | 100 | 150 | 200 | 300 | 500 | 700 | 1000;
  rect: DOMRect | null;
};

export const Tip: React.FunctionComponent<TTips> = ({
  children,
  position,
  delay,
  rect,
}) => {
  const adjust = 15;
  const tipRef = React.createRef<HTMLDivElement>();
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);
  const [pos, setPos] = React.useState(position);
  const [arrow, setArrow] = React.useState("");
  const [style, setStyle] = React.useState<React.CSSProperties>();

  React.useEffect(() => {
    if (tipRef && tipRef.current && width === 0) {
      const w = tipRef.current.offsetWidth + 1;
      const h = tipRef.current.offsetHeight + 1;
      setWidth(w);
      setHeight(h);
    }
  }, [tipRef, width, setWidth, setHeight]);

  React.useEffect(() => {
    if (tipRef && tipRef.current && rect && position === "auto") {
      const [p, a] = calcPosition(rect, width, height, adjust);
      setPos(p);
      setArrow(a);
    }
  }, [tipRef, rect, position, setPos, width, height]);

  React.useEffect(() => {
    if (position !== "auto") {
      switch (position) {
        case "top":
          setArrow("bottom");
          break;
        case "bottom":
          setArrow("top");
          break;
        case "left":
          setArrow("right");
          break;
        case "right":
          setArrow("left");
          break;
      }
    }
  }, [position, setArrow]);

  React.useEffect(() => {
    if (pos !== "auto" && rect) {
      let s: React.CSSProperties = { opacity: 1 };
      setStyle(calcStylePosition(rect, width, height, pos, s, adjust));
    }
  }, [pos, setStyle, rect, height, width, adjust]);

  const content = (
    <div
      style={style}
      className={`tooltip absolute arrow-${arrow} p-2 bg-white border border-border rounded transition-opacity delay-${delay} text-sm`}
      ref={tipRef}
    >
      {children}
    </div>
  );

  return createPortal(content, document.body);
};
