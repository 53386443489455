import { useContext, useEffect, useState } from "react";
import { SmbToastContext } from "../../context/SmbTabsContext";
import { TImport } from "../../components/Importer/Priority/Priority";
import axios from "axios";

export const usePriorityImporterProcess = (data: TImport[]) => {
  const [inProgress, setProgress] = useState<boolean | null>(null);
  const { addToast } = useContext(SmbToastContext);

  useEffect(() => {
    if (!data.length) {
      setProgress(null);
    }
  }, [data]);

  useEffect(() => {
    if (!data.length) {
      return;
    }

    setProgress(true);

    const process = async () => {
      await axios.put("/admin/import/priority/", {
        data,
      });

      setProgress(false);
    };

    process();
  }, [data, setProgress, addToast]);

  return { inProgress };
};
