import Axios from "axios";
import React, { useState } from "react";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";

type TCreateTokenProps = {
  onClose: () => void;
  setResponseToken: (token: string) => void;
  token: string | null;
};

type TAccess = "YES" | "NO";

const CreateToken: React.FunctionComponent<TCreateTokenProps> = ({
  token,
  onClose,
  setResponseToken,
}) => {
  const [tokenName, setTokenName] = useState("");
  const [readAccess, setReadAccess] = useState<TAccess>("NO");
  const [writeAccess, setWriteAccess] = useState<TAccess>("NO");
  async function createToken() {
    const response = await Axios.post("/admin/token/", {
      tokenName,
      readAccess,
      writeAccess,
    });
    const rToken = response.data.token;
    setResponseToken(rToken);
  }

  if (token) {
    return null;
  }

  return (
    <>
      <Input
        placeholder="Beschreibung"
        value={tokenName}
        label="Bescreibung"
        onChange={(v: string) => setTokenName(v)}
      />
      <p>
        Leserechte: <br />
        <select
          value={readAccess}
          onChange={(e) => {
            if (e.target.value === "YES") {
              setReadAccess("YES");
            } else {
              setReadAccess("NO");
            }
          }}
        >
          <option value="NO">Nein</option>
          <option value="YES">Ja</option>
        </select>
      </p>
      <p>
        Screibrechte: <br />
        <select
          value={writeAccess}
          onChange={(e) => {
            if (e.target.value === "YES") {
              setWriteAccess("YES");
            } else {
              setWriteAccess("NO");
            }
          }}
        >
          <option value="NO">Nein</option>
          <option value="YES">Ja</option>
        </select>
      </p>
      <div className="flex justify-end mt-2">
        <Button
          className="mr-2"
          text="Abbrechen"
          onClick={onClose}
          buttonStyle="elevated"
        />
        <Button
          className=""
          buttonStyle="elevated"
          text="Erzeugen"
          icon="SAVE_REGULAR"
          disabled={tokenName.length < 3}
          onClick={() => createToken()}
        />
      </div>
    </>
  );
};

export default CreateToken;
