import Axios from "axios";
import { useEffect, useState } from "react";
import { TQualityResponse } from "../config/qualityTable";
import { TOrderTypes } from "../types/order";
import { TWorkflowType } from "./useLastHistoryStatus";
type TState = {
  loading: boolean;
  data: TQualityResponse[];
  isValid: boolean;
};

export const useGetWorkflowState = (
  orderType: TOrderTypes,
  workflowType: TWorkflowType,
  buildingId?: number,
  force?: number
) => {
  const [state, setState] = useState<TState>({
    loading: false,
    data: [],
    isValid: true,
  });

  useEffect(() => {
    if (!buildingId) {
      return;
    }

    const process = async () => {
      const response = await Axios.get(
        `/workflow/${orderType}/${workflowType}/${buildingId}/`
      );
      setState({
        loading: false,
        data: response.data.status,
        isValid: response.data.isActive,
      });
    };

    setState({
      loading: true,
      data: [],
      isValid: true,
    });
    process();
  }, [buildingId, workflowType, force, orderType, setState]);

  return state;
};
