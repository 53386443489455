import { useEffect, useState } from "react";
import axios from "axios";

export const useVerifyPriority = (path: string, force: number) => {
  const [priority, setPriority] = useState<number | null>(null);

  useEffect(() => {
    if (!path) {
      return;
    }
    setPriority(null);

    const process = async () => {
      try {
        const response = await axios.get(`/verify/priority/?path=${path}`);
        setPriority(response.data.priority);
      } catch (e) {
        console.error(`Can't get a priority for ${path}`);
      }
    };

    process();
  }, [path, force, setPriority]);

  return priority;
};
