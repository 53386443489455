import { AppActionTypes } from "./types";

export const APP_SET_DATA = "app/SET_DATA";
export const APP_SHOW_LOGIN = "app/SHOW_LOGIN";
export const APP_SET_IS_MOBILE = "app/SET_IS_MOBILE";
export const APP_ENABLE_AJAX_PROGRESS = "app/APP_ENABLE_AJAX_PROGRESS";
export const APP_DISABLE_AJAX_PROGRESS = "app/APP_DISABLE_AJAX_PROGRESS";
export const setAppHasData = (): AppActionTypes => {
  return {
    type: APP_SET_DATA,
  };
};

export const setShowLogin = (showLogin: boolean): AppActionTypes => {
  return {
    type: APP_SHOW_LOGIN,
    payload: showLogin,
  };
};

export const setIsMobile = (isMobile: boolean): AppActionTypes => {
  return {
    type: APP_SET_IS_MOBILE,
    payload: isMobile,
  };
};

export const enableAjaxProgress = (text: string): AppActionTypes => {
  return {
    type: APP_ENABLE_AJAX_PROGRESS,
    payload: text,
  };
};

export const disableAjaxProgress = (): AppActionTypes => {
  return {
    type: APP_DISABLE_AJAX_PROGRESS,
  };
};
