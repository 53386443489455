import React from "react";
import { Icon } from "../Icon";

type TFilterIndicatorProps = {
  filter?: string;
  dataIndex: any;
};

const FilterIndicatorComponent: React.FunctionComponent<
  TFilterIndicatorProps
> = ({ filter, dataIndex }) => {
  if (!filter) {
    return null;
  }
  return (
    <div
      className={`cursor-pointer opacity-25 hover:opacity-100 border border-border mr-1`}
    >
      <Icon icon="FILTER_SOLID" />
    </div>
  );
};

export const FilterIndicator = FilterIndicatorComponent;
