import { Action } from "redux";
import { USER_RESET, USER_SET, USER_UPDATE } from "./action";

export enum UserRole {
  admin,
  company_admin,
  user,
}

export type TCompanyType = "smb" | "leitung" | "amt" | "firma" | null;

export interface IUserState {
  email: string | null;
  firstname: string | null;
  lastname: string | null;
  lastIp: string | null;
  lastLogin: number | null;
  type: string;
  company: string | null;
  companyType: TCompanyType;
  companyName: string | null;
  companyId: number | null;
}

export interface IUserUpdate {
  email?: string;
  firstname?: string;
  lastname?: string;
  lastIp?: string;
  lastLogin?: number;
  type?: string;
  company?: string;
  companyType?: TCompanyType;
  companyName?: string;
}

interface ISetUser extends Action<typeof USER_SET> {
  payload: IUserState;
}

interface IResetUser extends Action<typeof USER_RESET> {}

interface IUpdateUser extends Action<typeof USER_UPDATE> {
  payload: IUserUpdate;
}

export type UserActionTypes = ISetUser | IResetUser | IUpdateUser;
