import React from "react";

// todo: replace mit TextInput canEdit false

export const LabeledText: React.FC<{
  label: string;
  value: any;
  className?: string;
}> = ({ label, value, className = "" }) => {
  return (
    <div style={{ minHeight: "47px" }} className={className}>
      <label className="mr-2 font-medium border-b">{label}</label>
      <div>{value ? value : "k.A."}</div>
    </div>
  );
};
