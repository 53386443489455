import { MeasureEdit } from "../components/MeasureEdit/MeasureEdit";
import { PointCloudOrder } from "../components/PointCloud/PointCloudOrder";

export const MEASURE_TAB = "measure"; // Neuaufmaß / Nachaufmaß
export const POINT_CLOUD_TAB = "point-cloud"; // Punktwolke
export const PASS_CHECK = "pass-check"; // Bestandsprüfung
export const ALLOWED_TABS = [MEASURE_TAB, POINT_CLOUD_TAB] as const;

export const DEFAULT_TAB = MEASURE_TAB;

export type TTabs = typeof ALLOWED_TABS[number];

type TTabConfig = {
  tab: TTabs;
  label: string;
  component?: JSX.Element;
};

export const TAB_CONFIG: TTabConfig[] = [
  {
    tab: MEASURE_TAB,
    label: "Aufmaß",
    component: <MeasureEdit tab={MEASURE_TAB} />,
  },
  {
    tab: POINT_CLOUD_TAB,
    label: "Punktwolke",
    component: <PointCloudOrder tab={POINT_CLOUD_TAB} />,
  },
];
