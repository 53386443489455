import { DoraLogin } from "./showLogin";

type TSecureWrapper = {
  showLogin: boolean;
};

export const SecureWrapper: React.FunctionComponent<TSecureWrapper> = ({
  children,
  showLogin,
}) => {
  if (showLogin) {
    return (
      <div className="h-full">
        <DoraLogin />
      </div>
    );
  }

  return <div className="h-full overflow-hidden">{children}</div>;
};
