import * as React from "react";
import { TIconNames } from "../Icon/IconNames";
import { getId } from "../utils/getId";
import { InputError } from "./InputError";
import { InputHelp } from "./InputHelp";
import { InputIcon } from "./InputIcon";
import { Label } from "./Label";

export type TInputProps = {
  value: string | number;
  type?: "text" | "number" | "password";
  label?: string | React.ReactNode;
  labelTop?: boolean;
  placeholder?: string;
  displayOnValue?: boolean;
  help?: string;
  error?: string;
  icon?: TIconNames | React.ReactNode;
  disabled?: boolean;
  rightIcon?: TIconNames | React.ReactNode;
  testId?: string;
  cursorPos?: boolean;
  onChange: (value: string) => void;
  onRightIconClick?: () => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onIconClick?: () => void;
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export const Input: React.FunctionComponent<TInputProps> = ({
  value,
  icon,
  rightIcon,
  type = "text",
  label = "",
  labelTop = true,
  placeholder = "",
  displayOnValue = false,
  help = "",
  error = "",
  disabled = false,
  testId = "input-group",
  onChange,
  onIconClick,
  onRightIconClick,
  onBlur,
  onPaste,
  onKeyDown,
  onKeyUp,
  onFocus,
}) => {
  const [id] = React.useState(getId());

  let inputClassName = "w-full pt-1 pb-1 ";
  let wrapperClassName = "";

  if (icon) {
    inputClassName += " pl-8 disabled:opacity-50";
  }

  if (rightIcon) {
    inputClassName += " pr-8";
  }

  if (error !== "") {
    inputClassName += " border border-danger";
  }

  if (!labelTop) {
    wrapperClassName += " flex";
  }

  return (
    <div data-testid={testId}>
      <div className={wrapperClassName.trim()}>
        <Label
          htmlFor={id ?? ""}
          displayOnValue={displayOnValue}
          show={value !== ""}
          testId={testId}
        >
          {label}
        </Label>
        <div className="relative w-full">
          <InputIcon
            icon={icon}
            onClick={onIconClick}
            size={6}
            className="absolute left-0"
            isLeft={true}
            testId={`icon-left-${testId}`}
          />
          <input
            onBlur={(e) => {
              if (typeof onBlur === "function") {
                onBlur(e);
              }
            }}
            onPaste={(e) => {
              if (onPaste) {
                onPaste(e);
              }
            }}
            onKeyDown={(e) => {
              if (onKeyDown) {
                onKeyDown(e);
              }
            }}
            onKeyUp={(e) => {
              if (onKeyUp) {
                onKeyUp(e);
              }
            }}
            onFocus={(e) => {
              if (onFocus) {
                onFocus(e);
              }
            }}
            disabled={disabled}
            value={value}
            type={type}
            placeholder={placeholder}
            onChange={(event) => onChange(event.target.value)}
            className={inputClassName.trim()}
            id={id ?? ""}
          />
          <InputIcon
            icon={rightIcon}
            onClick={onRightIconClick}
            size={6}
            className="absolute right-0"
            isLeft={false}
            testId={`icon-right-${testId}`}
          />
        </div>
        <div>
          <InputHelp text={help} testId={testId} />
          <InputError error={error} testId={testId} />
        </div>
      </div>
    </div>
  );
};
