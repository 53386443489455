import { RouteComponentProps } from "@reach/router";
import React, { useCallback, useState } from "react";
import { MIGRATION, TMigrateItem } from "../../../config/migration";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { MigrationProcess } from "../../../components/MigrationProcess/MigrationProcess";
import { Button } from "../../../components/Button";

export const Migration: React.FC<RouteComponentProps> = () => {
  const [migrate, setMigrate] = useState<TMigrateItem | null>(null);

  const closeResult = useCallback(() => {
    setMigrate(null);
  }, [setMigrate]);

  const buttonCallback = useCallback(
    (index: number) => {
      setMigrate(MIGRATION[index]);
    },
    [setMigrate]
  );

  if (migrate) {
    return <MigrationProcess {...migrate} onClose={closeResult} />;
  }

  return (
    <div>
      <h1> Datenmigration</h1>

      <p>
        Diese Funktionen sollten mit bedacht angewendet werden, da sie unter
        umständen das Datenmodel ändern
      </p>

      <div className="flex flex-row border-b border-border">
        <div className="flex-1 border-r border-border p-2">Beschreibung</div>
        <div className="w-32 p-2">Ausführen</div>
      </div>
      {MIGRATION.map((item, index) => (
        <div className="flex border-b border-border" key={`mogration-${index}`}>
          <div className="flex-1 border-r border-border p-2">
            {item.description}
          </div>
          <div className="w-32 p-2">
            <Button intent="warning" onClick={() => buttonCallback(index)}>
              <FontAwesomeIcon
                icon={faExclamation}
                className="inline-block mr-2"
              />
              Ausführen
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};
