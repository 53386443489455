import { useEffect, useState } from "react";
import axios from "axios";
import { TPointCloudStates } from "../../config/workflow";

export type TPointCloudWorkflowResponse = {
  comment: string;
  createdAt: string;
  deliverDate: string;
  orderId: number;
  status: TPointCloudStates;
  updatedAt: string;
  useId: number;
};

export const usePointCloudWorkflow = (
  orderId: number,
  nrf: number | null,
  force = 0
) => {
  const [data, setData] = useState<TPointCloudWorkflowResponse[] | null>(null);

  useEffect(() => {
    if (!nrf) {
      return;
    }

    const process = async () => {
      try {
        const response = await axios.get<{
          data: TPointCloudWorkflowResponse[];
        }>(`/workflow/point-cloud/${orderId}/`);

        setData(response.data.data);
      } catch (err) {
        if (axios.isAxiosError(err)) {
          // toast("Fehler beim abrufen der Daten", {
          //   intent: "warning",
          //   autoClose: 5000,
          // });
        } else {
          // toast("Fehler beim verarbeiten der Daten", {
          //   intent: "warning",
          //   autoClose: 5000,
          // });
        }
      }
    };

    process();
  }, [orderId, nrf, force]);
  return data;
};
