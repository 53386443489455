import Axios from "axios";
import { useEffect, useState } from "react";
export type TWorkflowType = "plausibilityCheck" | "qsCheck";

export const useLastHistoryStatus = (
  type: TWorkflowType,
  buildingId?: number | null,
  force?: number
) => {
  const [qsState, setQsState] = useState<number>();

  useEffect(() => {
    if (!buildingId) {
      return;
    }

    const process = async () => {
      try {
        const response = await Axios.get<{ status: number }>(
          `/workflow/measure/state/${type}/${buildingId}/`
        );

        setQsState(response.data.status);
      } catch (error) {}
    };

    process();
  }, [type, buildingId, force]);

  return qsState;
};
