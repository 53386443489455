import { useLocation, useNavigate } from "@reach/router";
import * as React from "react";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { navigationSmallDeviceWidth } from "./Sidebar";
import { useAnimatedWidth } from "../../hooks/useAnimatedWidth";

const defaultOpenWidth = 240;

export const defaultClosedWidth = 50;
const mobileClosedWidth = 0;

export const useSidebarComponent = (ref: React.RefObject<HTMLDivElement>) => {
  const bodySize = useResizeObserver(document.body);
  const [isSmallDevice, setIsSmallDevice] = React.useState(
    window.innerWidth < navigationSmallDeviceWidth
  );
  const location = useLocation();
  const navigate = useNavigate();
  const [isOpen, setOpen] = React.useState(false);
  const [currentActive, setActive] = React.useState("");
  const [closeWidth, setCloseWidth] = React.useState(defaultClosedWidth);

  useAnimatedWidth(ref, isOpen, defaultOpenWidth, closeWidth);
  // @ts-ignore
  if (!window.smb) {
    // @ts-ignore
    window.smb = {};
  }

  React.useEffect(() => {
    if (!location) {
      return;
    }
    setActive(location.pathname);
  }, [location, setActive]);

  React.useLayoutEffect(() => {
    if (!bodySize) {
      return;
    }

    setIsSmallDevice(bodySize.width < navigationSmallDeviceWidth);
  }, [bodySize, setIsSmallDevice]);

  React.useLayoutEffect(() => {
    if (isSmallDevice) {
      setCloseWidth(mobileClosedWidth);
    } else {
      setCloseWidth(defaultClosedWidth);
    }
  }, [isSmallDevice]);

  const toggleOpen = React.useCallback(() => {
    setOpen(!isOpen);
  }, [setOpen, isOpen]);

  // @ts-ignore
  window.smb.toggleSidebar = toggleOpen;

  const onClick = React.useCallback(
    (link: string) => {
      if (isSmallDevice) {
        toggleOpen();
      }
      navigate(link);
    },
    [isSmallDevice, toggleOpen, navigate]
  );

  return {
    isOpen,
    isSmallDevice,
    currentActive,
    toggleOpen,
    onClick,
  };
};
