import React, { useEffect, useState } from "react";
import { usePointCloudWorkflow } from "../../hooks/pointcloud/usePointCloudWorkflow";
import { PointCloudWorkflowButtons } from "./PointCloudWorkflowButtons";
import { TPointCloudStates, WORKFLOW_INFO } from "../../config/workflow";
import { PointCloudStates } from "./PointCloudStates";

type TPointCloudWorkflowProps = {
  orderId: number;
  nrf: number | null;
};

export const PointCloudWorkflow: React.FC<TPointCloudWorkflowProps> = ({
  orderId,
  nrf,
}) => {
  const [force, setForce] = useState(Date.now());
  const data = usePointCloudWorkflow(orderId, nrf, force);
  const [currentState, setCurrentState] = useState<TPointCloudStates | null>(
    null
  );

  useEffect(() => {
    const noCommentsState = data?.filter((val) => val.status !== WORKFLOW_INFO);
    if (noCommentsState && noCommentsState.length) {
      setCurrentState(noCommentsState[noCommentsState.length - 1].status);
    }
  }, [data]);

  if (!nrf) {
    return (
      <div>
        Es liegt noch keine bestätigte NRF vor, der Vorgang kann nicht gestartet
        werden.
      </div>
    );
  }

  if (data === null) {
    return <>"Lade Daten"</>;
  }

  return (
    <div>
      <PointCloudStates data={data} />
      <PointCloudWorkflowButtons
        orderId={orderId}
        currentState={currentState}
        setForce={() => setForce(Date.now())}
      />
    </div>
  );
};
