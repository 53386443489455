import * as React from "react";
import { ModalContent } from "./ModalContent";
import { Portal } from "../Portal";

export type TModalProps = {
  onClose?: () => void;
  isOpen?: boolean;
  title?: string | React.ReactNode;
  backdrop?: boolean;
  closeOnEscape?: boolean;
  top?: string;
  width?: string;
  closeOnClick?: boolean;
  root?: HTMLElement;
};

export const Modal: React.FunctionComponent<TModalProps> = (props) => {
  return (
    <Portal root={props.root}>
      <ModalContent {...props} />
    </Portal>
  );
};
