import React from "react";
import { ToolTip } from "../ToolTip";
import { Icon } from "../Icon";

type THelpProps = {
  text?: string | JSX.Element;
};

export const Help: React.FunctionComponent<THelpProps> = ({
  text,
  children,
}) => {
  if (!text && !children) {
    return null;
  }

  return (
    <ToolTip content={<div className="p-1.5">{text ? text : children}</div>}>
      <div className="pl-2 pt-1 pr-3">
        <Icon
          size={5}
          icon="QUESTION_CIRCLE_SOLID"
          className="mr-1 text-primary fill-current"
        />
      </div>
    </ToolTip>
  );
};
