import { useEffect, useState } from "react";
import axios from "axios";

export const useVerifyDeadlineImport = (path?: string, force = 0) => {
  const [verified, setVerified] = useState({
    deadline: "",
    isBuilding: false,
    hasOrder: false,
  });

  useEffect(() => {
    if (!path) {
      return;
    }

    const process = async () => {
      try {
        const response = await axios.get<{ deadline: string; losId: number }>(
          `/verify/deadline/?path=${path.trim()}`
        );
        setVerified({
          deadline: response.data.deadline,
          isBuilding: true,
          hasOrder: !!response.data.losId,
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          setVerified({
            deadline: "",
            isBuilding: false,
            hasOrder: false,
          });
        }
      }
    };

    process();
  }, [path, force, setVerified]);

  return verified;
};
