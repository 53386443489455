import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { RootState } from "../../store/index";
import { Button } from "../Button";

type TAddCommentButtonComponentProps = { onClick: () => void } & ReturnType<
  typeof mapStateToProps
>;

const AddCommentButtonComponent: React.FC<TAddCommentButtonComponentProps> = ({
  canAddComment,
  onClick,
}) => {
  if (!canAddComment) {
    return null;
  }

  return (
    <div className="text-right mt-3">
      <Button onClick={onClick}>
        <FontAwesomeIcon icon={faPlus} /> Neuer Kommentar
      </Button>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  canAddComment: state.user.companyType === "smb",
});

export const AddCommentButton = connect(mapStateToProps)(
  AddCommentButtonComponent
);
