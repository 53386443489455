import React, { useEffect } from "react";
import { BuildingTabsContext } from "../../context/BuildingTabs";
import { TTabs } from "../../config/tabs";
import { usePointcloudData } from "../../hooks/pointcloud/usePointcloudData";

import { PointCloudWorkflow } from "../PointCloudWorkflow/PointCloudWorkflow";
import {
  currencyFormatter,
  formatReadonly,
  numberFormatter,
} from "../../helper/formatter";
import { AccordionPane } from "../AccordionPane/AccordionPane";
import { GridCell } from "../MeasureEdit/GridCell";

type TPointCloudOrderProps = {
  tab: TTabs;
};

export const PointCloudOrder: React.FC<TPointCloudOrderProps> = ({ tab }) => {
  const { building, addDisplayTab, displayTabs, activeTab } =
    React.useContext(BuildingTabsContext);
  const data = usePointcloudData(building);

  useEffect(() => {
    if (
      !tab ||
      !displayTabs ||
      !addDisplayTab ||
      displayTabs.includes(tab) ||
      !data
    ) {
      return;
    }

    addDisplayTab(tab);
  }, [tab, data, displayTabs, addDisplayTab]);

  if (tab !== activeTab || !data || !building) {
    return null;
  }

  return (
    <div className="pt-1">
      <AccordionPane
        widgetName={"punktwolke-auftrag"}
        headline={"Auftragsdaten"}
      >
        <GridCell label={"Abrufnummer"}>
          {formatReadonly(data.retrievalNumber)}
        </GridCell>
        <GridCell label={"Firma"}>{formatReadonly(data.companyName)}</GridCell>

        <GridCell label={"Los"}>{formatReadonly(data.los)}</GridCell>

        <GridCell label={"Flächencluster"}>
          {formatReadonly(data.areaCluster)}
        </GridCell>
        <GridCell label={"Kategorie"}>{formatReadonly(data.category)}</GridCell>

        <GridCell label={"Detailtiefe"}>
          {formatReadonly(data.detailLevel)}
        </GridCell>

        <GridCell label={"Preis / m²"}>
          {formatReadonly(currencyFormatter(data.price))}
        </GridCell>

        <GridCell label={"NRF"}>{numberFormatter(data.nrf)} m²</GridCell>
        <GridCell label={"Preis Netto"}>
          {data.price
            ? currencyFormatter((data.nrf ?? 0) * (data.price ?? 0))
            : "Im Aufmaß enthalten"}
        </GridCell>

        <GridCell label={"Preis Brutto"}>
          {data.price
            ? currencyFormatter((data.nrf ?? 0) * (data.price ?? 0) * 1.19)
            : "Im Aufmaß enthalten"}
        </GridCell>
      </AccordionPane>

      <AccordionPane
        widgetName={"punktwolke-workflow"}
        headline={"Workflow"}
        gridItems={1}
      >
        <div>
          <PointCloudWorkflow orderId={data.orderId} nrf={data.nrf} />
        </div>
      </AccordionPane>
    </div>
  );
};
