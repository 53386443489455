import * as React from "react";
import { Icon, Icons, TIconNames } from "../Icon";

export type TButtonIconProps = {
  icon?: TIconNames | React.ReactNode;
  size?: number;
  className?: string;
};

export const ButtonIcon: React.FunctionComponent<TButtonIconProps> = ({
  icon,
  className,
  size = 4,
}) => {
  if (React.isValidElement(icon)) {
    return icon;
  } else if (typeof icon === "string" && Object.keys(Icons).includes(icon)) {
    return (
      <div className="p-1 ml-1 mr-1">
        <Icon icon={icon as TIconNames} size={size} className={className} />
      </div>
    );
  }

  return null;
};
