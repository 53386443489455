import * as React from "react";
import { Icons, TIconNames } from "./IconNames";

export * from "./IconNames";

export type TIconProps = {
  icon?: TIconNames;
  className?: string;
  size?: number;
  onClick?: () => void;
  style?: React.CSSProperties;
};

export const Icon: React.FunctionComponent<TIconProps> = ({
  icon,
  className = "",
  size = 4,
  style,
  onClick,
}) => {
  if (!icon || Object.keys(Icons).includes(icon) === false) {
    return null;
  }

  let iconSize = size;

  if (icon.includes("SOLID") === true) {
    --iconSize;
  }

  const cIcon = Icons[icon];
  return (
    <div
      style={style}
      data-testid={`icon-${icon}`}
      onClick={() => {
        if (typeof onClick === "function") {
          onClick();
        }
      }}
      className={`w-${iconSize} fill ml-auto mr-auto mt-0 ${className}`}
    >
      {cIcon}
    </div>
  );
};
