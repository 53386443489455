import * as React from "react";
import { TLoginError } from ".";
import { Input } from "../Input";

type TPasswordInputProps = {
  onPasswordChange: (p: string) => void;
  value: string;
  error?: TLoginError | null;
};

export const PasswordInput: React.FunctionComponent<TPasswordInputProps> = ({
  onPasswordChange,
  value,
  error,
}) => {
  const [passwordType, setPasswordType] = React.useState<"password" | "text">(
    "password"
  );
  return (
    <Input
      testId="password"
      onChange={onPasswordChange}
      value={value}
      placeholder="Passwort"
      label="Passwort"
      labelTop={true}
      displayOnValue={true}
      icon={passwordType === "password" ? "EYE_SLASH_REGULAR" : "EYE_REGULAR"}
      onIconClick={() =>
        passwordType === "password"
          ? setPasswordType("text")
          : setPasswordType("password")
      }
      type={passwordType}
      error={error?.passwordError ?? ""}
    />
  );
};
