import React from "react";
import { TFilterSettings } from "../../store/settings/settingsReducer";
import { Icon, TIconNames } from "../Icon";

type TSortIndicatorProps = {
  direction: TFilterSettings["order"];
  isActive: boolean;
  isSortable: boolean;
  orderField: any;
  onSort?: (field: string) => void;
};
const getIconByState = (
  active: boolean,
  direction: TFilterSettings["order"]
): TIconNames => {
  if (active === false) {
    return "ARROWS_ALT_V_SOLID";
  } else if (direction === "DESC") {
    return "SORT_AMOUNT_DOWN_SOLID";
  }

  return "SORT_AMOUNT_UP_SOLID";
};

const SortIndicatorComponent: React.FunctionComponent<TSortIndicatorProps> = ({
  direction,
  isActive,
  isSortable,
  orderField,
  onSort,
}) => {
  if (!isSortable || typeof onSort !== "function") {
    return null;
  }

  const callback = () => {
    onSort(orderField);
  };

  return (
    <div
      className={`cursor-pointer hover:opacity-100 border border-border${
        !isActive ? " opacity-20" : ""
      }`}
      onClick={callback}
    >
      <Icon icon={getIconByState(isActive, direction)} />
    </div>
  );
};

export const SortIndicator = SortIndicatorComponent;
