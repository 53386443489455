import { createContext } from "react";
import { TCols } from "../../config/measureBoard";
import { Board } from "./Board";
import { TCalculateResult } from "./useBoardFilter";

type TKanbanBoardContext = {
  dropCallback: (data: TCalculateResult, config: TCols) => void;
  onClick?: (id: number) => void;
};

type TKanbanBoardProps = {
  data: any[] | null;
  config: TCols[] | null;
  displayHelp?: boolean;
  onDrop?: (data: TCalculateResult, config: TCols) => void;
  onClick?: (id: number) => void;
};

export const KanbanBoardContext = createContext<Partial<TKanbanBoardContext>>(
  {}
);

export const KanbanBoard: React.FC<TKanbanBoardProps> = ({
  data,
  config,
  displayHelp,
  children,
  onDrop,
  onClick,
}) => {
  const dropCallback = (dropData: TCalculateResult, dropConfig: TCols) => {
    if (typeof onDrop === "function") {
      onDrop(dropData, dropConfig);
    }
  };

  return (
    <KanbanBoardContext.Provider value={{ dropCallback, onClick }}>
      <div className="h-full w-full">
        <Board data={data} config={config} displayHelp={displayHelp}>
          {children}
        </Board>
      </div>
    </KanbanBoardContext.Provider>
  );
};
