import { RouteComponentProps } from "@reach/router";
import axios from "axios";
import moment from "moment-business-days";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { KanbanBoard } from "../../components/KanbanBoard/KanbanBoard";
import { TCalculateResult } from "../../components/KanbanBoard/useBoardFilter";
import { TCols } from "../../config/measureBoard";
import { ROUTE_BUILDING } from "../../config/routes";
import { numberFormatter } from "../../helper/formatter";
import { TBoardResponse } from "../../hooks/measure/useGetMeasureBoardData";
import { useGetBoardSmbData } from "../../hooks/board/useGetBoardSmbData";
import { AssignMessage } from "./AssignMessage";
import { SmbToastContext, TSmbToast } from "../../context/SmbTabsContext";

const notAssigned: TCols = {
  header: `Kein Bearbeiter`,
  dataKey: "qsNotAssigned",
  targetId: "notAssigned",
  isDraggable: true,
  dragTargets: [],
  canSetTransferId: true,
  canSetTarget: true,
  displayNrf: true,
  displayBgStatus: true,
  cardType: "smbAssign",
};

const noTeam: TCols = {
  header: `Kein Team`,
  dataKey: "qsNoTeam",
  targetId: "noTeam",
  cardType: "smbAssign",
};

export const SmbBoard: React.FC<RouteComponentProps> = () => {
  const [config, setConfig] = useState<TCols[] | null>(null);
  const [team, setTeam] = useState<"München" | "Stuttgart">("München");
  const [wrapperHeight, setWrapperHeight] = useState(300);
  const [boardData, setBoardData] = useState<TBoardResponse | null>(null);
  const [processId, setProcessId] = useState(Date.now());
  const boardResponse = useGetBoardSmbData(processId);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [targetUser, setTargetUser] = useState<number | null>(null);
  const [targetBuilding, setTargetBuilding] = useState<TCalculateResult | null>(
    null
  );

  const onClick = useCallback((buildingId: number) => {
    window
      .open(
        `${window.location.origin}${ROUTE_BUILDING}${buildingId}/measure/?close`,
        "_blank"
      )
      ?.focus();
  }, []);
  const { addToast } = React.useContext(SmbToastContext);

  const onDrop = useCallback(
    async (data: TCalculateResult, dropConfig: TCols) => {
      const user = Number(
        dropConfig.targetId.toLocaleLowerCase().replace("user", "")
      );

      if (isNaN(user) && addToast) {
        addToast({
          message: "Fehler beim zuweisen des Bearbeiters",
          intent: "error",
          autoClose: 0,
        });

        return;
      }

      setTargetBuilding(data);
      setTargetUser(user);
    },
    [addToast]
  );

  useEffect(() => {
    if (!boardResponse) {
      return;
    }
    const bData: TBoardResponse = {};

    for (const data of boardResponse) {
      bData[data.dataKey] = data.data;
    }

    bData.qsNotAssigned = boardResponse[0].data.filter(
      (v) => v.qsTeam === team
    );

    bData.qsNoTeam = boardResponse[0].data.filter((v) => !v.qsTeam);

    setBoardData(bData);
  }, [boardResponse, setBoardData, team]);

  useEffect(() => {
    if (!boardResponse) {
      return;
    }
    const targets: any[] = [];

    for (const data of boardResponse) {
      targets.push(data.dataKey.replace("qs", ""));
    }

    const conf = [{ ...noTeam }, { ...notAssigned, dragTargets: targets }];

    for (const data of boardResponse) {
      if (
        data.dataKey !== "qsNotAssigned" &&
        data.dataKey !== "qsNoTeam" &&
        team === data?.user?.qsTeam
      ) {
        const targetId = data.dataKey.replace("qs", "");
        conf.push({
          header: `${data?.user?.firstname ?? ""} ${
            data?.user?.lastname ?? ""
          } NRF:  ${numberFormatter(data?.nrfSum ?? 0)} m²`,
          dataKey: data.dataKey,
          targetId,
          isDraggable: true,
          dragTargets: targets.filter((v) => v !== targetId),
          canSetTransferId: true,
          canSetTarget: true,
          displayNrf: true,
          displayBgStatus: true,
          cardType: "smbAssign",
        });
      }
    }

    setConfig(conf);
  }, [boardResponse, team, setConfig]);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    setWrapperHeight(
      window.innerHeight - wrapperRef.current.getBoundingClientRect().top - 50
    );
  }, [wrapperRef]);

  const submit = async (comment: string, name: string) => {
    if (!targetBuilding || !targetUser) {
      return;
    }
    let message = `Gebäude ${targetBuilding.address} WE: ${targetBuilding.economicUnit} GE: ${targetBuilding.buildingNumber}. `;
    let intent: TSmbToast["intent"] = "success";
    try {
      await axios.post("/smb/assing-user/", {
        building: targetBuilding.associatedId,
        targetUser,
        comment,
        deadline: moment()
          .businessAdd(targetBuilding.remaningTime ?? 0)
          .format("DD.MM.YYYY"),
      });
      message += `Erfolgreich Bearbeiter auf ${name} gesetzt`;
    } catch (e) {
      message += `Fehler beim Bearbeiter setzen auf ${name}`;
      intent = "error";
    }

    if (addToast) {
      addToast({
        message,
        intent,
        autoClose: 5000,
      });
    }

    setTargetBuilding(null);
    setTargetUser(null);
    setProcessId(Date.now());
  };

  return (
    <div className="flex flex-col">
      <h1>Dora Projekt Board</h1>
      <div
        ref={wrapperRef}
        className="overflow-hidden"
        style={{ height: wrapperHeight + "px" }}
      >
        <KanbanBoard
          onDrop={onDrop}
          data={boardData as any}
          config={config}
          onClick={onClick}
        >
          <div className={"ml-4"}>
            <select
              className={"h-8"}
              value={team}
              onChange={(e) => {
                if (e.target.value === "München") {
                  setTeam("München");
                } else {
                  setTeam("Stuttgart");
                }
              }}
            >
              <option value="München">Team München</option>
              <option value="Stuttgart">Team Stuttgart</option>
            </select>
          </div>
        </KanbanBoard>
      </div>
      <AssignMessage
        onClose={() => {
          setTargetBuilding(null);
          setTargetUser(null);
        }}
        onSubmit={submit}
        response={boardResponse}
        userId={targetUser}
        building={targetBuilding}
      />
    </div>
  );
};
