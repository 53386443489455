import { useGetFiles } from "../../hooks/useGetFiles";
import { DownloadRow } from "./DownloadRow";

export const UserDownloads: React.FC = () => {
  const files = useGetFiles();
  if (files === null) {
    return null;
  }
  return (
    <>
      <div className="flex p-1 text-lg font-bold">Dateien</div>
      <div className="divide-y divide-gray-500">
        <div className="flex font-bold">
          <div className="px-2 flex-1">Datei</div>
          <div className="px-2 w-28 text-center">Größe</div>
          <div className="px-2 w-28 text-center">Geändert</div>
          <div style={{ width: "160px" }} className="text-right">
            &nbsp;
          </div>
        </div>
        {files.map((f, i) => (
          <DownloadRow {...f} key={`download-row-${i}`} />
        ))}
      </div>
    </>
  );
};
