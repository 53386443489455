import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { TFilterActions } from "../../config/quickFilter";
import { TUiSettings } from "../../config/uiSettings";
import { RootState } from "../../store";
import { setHomeData } from "../../store/settings/action";

type TMenuSelection = TUiSettings &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const MenuSelectionComponent: React.FunctionComponent<TMenuSelection> = ({
  selection,
  data,
  companyType,
  valueKey,
  settings,
  updateHomeData,
}) => {
  if (!data || Array.isArray(data) === false || data.length === 0) {
    console.error(`Data is not a valid data source for menu selection`);
    return null;
  }

  if (!selection?.label || !selection?.value || !data) {
    console.error(
      `Need a selection property with label and value for a menu and data`,
      selection,
      data
    );
    return null;
  }

  if (Object.keys(data[0]).includes(selection.label) === false) {
    console.error(`Can't find label ${selection.label} in data`);
    return null;
  }

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (valueKey === "homeData") {
      updateHomeData(e.target.value as TFilterActions);
    }
  };

  return (
    <select value={settings[valueKey].toString()} onChange={onChange}>
      {data.map((o, i) => {
        if (o.allowed.length && o.allowed.includes(companyType) === false) {
          return null;
        }

        return (
          <option
            key={`selection-${o[selection.label]}-${i}`}
            value={o[selection.value]?.toString()}
          >
            {o[selection.label]}
          </option>
        );
      })}
    </select>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateHomeData: (value: TFilterActions) => dispatch(setHomeData(value)),
});

const mapStateToProps = (state: RootState) => ({
  settings: state.settings,
  companyType: state.user.companyType,
});

export const MenuSelection = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuSelectionComponent);
