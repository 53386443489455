import { useCallback, useEffect, useRef, useState } from "react";
import { leadingZeroFormatter } from "../../helper/formatter";
import { TDataTableFormatterTypes } from "../DataTable/DataTable";
import { Spacer } from "./Spacer";

type TInfoLabelProps = {
  label: string;
  data: unknown;
  copy: boolean;
  formatter?: TDataTableFormatterTypes;
  colspan?: number;
  cols: number;
};

const spanClassNames = {
  2: "col-span-2",
  3: "col-span-3",
  4: "col-span-4",
  5: "col-span-5",
};

export const InfoLabel: React.FC<TInfoLabelProps> = ({
  label,
  copy,
  data,
  formatter,
  colspan,
  cols,
}) => {
  const [text, setText] = useState("");
  const [spanClassName, setSpanClassName] = useState("");
  const [displayCopy, setDisplayCopy] = useState(false);
  const [copyText, setCopyText] = useState("");
  const copyRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!formatter && data) {
      setText(data as string);
    } else if (formatter === "LeadingZero") {
      setText(leadingZeroFormatter(data));
    } else {
      setText("k.A.");
    }
  }, [data, formatter]);

  useEffect(() => {
    if (!colspan) {
      return;
    }

    if (Object.keys(spanClassNames).includes(colspan.toString())) {
      setSpanClassName(spanClassNames[colspan as keyof typeof spanClassNames]);
    }
  }, [colspan]);

  useEffect(() => {
    if (!copyRef.current || !copy) {
      return;
    }

    if (displayCopy) {
      copyRef.current.style.height = "20px";
    } else {
      copyRef.current.style.height = "0px";
      setCopyText("Kopieren");
    }
  }, [copyRef, displayCopy, copy, setCopyText]);

  const copyClipboard = useCallback(async () => {
    if (!copy) {
      return;
    }

    try {
      await navigator.clipboard.writeText(text);
      setCopyText("Kopiert");
    } catch (e) {
      setCopyText("Fehler beim Kopieren");
    }
  }, [text, copy, setCopyText]);

  return (
    <>
      <div
        className={`p-2 relative ${spanClassName}`.trim()}
        onMouseEnter={() => {
          if (copy) {
            setDisplayCopy(true);
          }
        }}
        onMouseLeave={() => setDisplayCopy(false)}
      >
        <div
          ref={copyRef}
          className="text-blue-600 text-xs absolute left-0 overflow-hidden cursor-pointer"
          style={{ height: 0, left: "8px", top: "-5px" }}
          onClick={copyClipboard}
        >
          {copyText}
        </div>
        <label className={`mr-2 text-sm font-medium border-b`}>{label}</label>
        <div>{text}</div>
      </div>
      <Spacer display={cols % 5 === 0} />
    </>
  );
};
