import React from "react";

export type TTileProps = {
  title: string | JSX.Element;
  content?: JSX.Element[] | JSX.Element | string;
  children?: JSX.Element[] | JSX.Element;
};

const Tile: React.FunctionComponent<TTileProps> = (props) => {
  return (
    <div className="border rounded overflow-hidden border-border m-2 h-full flex flex-col">
      <div className="flex bg-border justify-between pt-1 pl-2 h-7 font-bold">
        {props.title}
      </div>
      <div className="overflow-hidden flex-1 flex flex-col justify-between p-2">
        {props.content || props.children || <></>}
      </div>
    </div>
  );
};
export default Tile;
