import { useCallback, useEffect, useState } from "react";
import {
  ITableHeader,
  LOCAL_STORAGE_HIDDEN_COLUMNS,
} from "../config/dataTable";

export const useTableConfig = (baseConfig: ITableHeader[]) => {
  const [currentConfig, setCurrentConfig] = useState<ITableHeader[]>([]);
  const [hiddenColumns, setHidden] = useState<string[]>();

  const setStorage = useCallback(
    (val: string[]) => {
      try {
        localStorage.setItem(LOCAL_STORAGE_HIDDEN_COLUMNS, JSON.stringify(val));
        setHidden(val);
      } catch (error) {
        localStorage.setItem(LOCAL_STORAGE_HIDDEN_COLUMNS, JSON.stringify([]));
        setHidden([]);
      }
    },
    [setHidden]
  );

  useEffect(() => {
    const storedHiddenColumns = localStorage.getItem(
      LOCAL_STORAGE_HIDDEN_COLUMNS
    );
    if (!storedHiddenColumns) {
      setStorage([]);
    } else {
      try {
        setHidden(JSON.parse(storedHiddenColumns));
      } catch (err) {
        setStorage([]);
      }
    }
  }, [baseConfig, setStorage, setHidden]);

  useEffect(() => {
    if (!hiddenColumns) {
      return;
    }

    setCurrentConfig(
      baseConfig.map((item) => {
        if (hiddenColumns.includes(item.dataIndex)) {
          return { ...item, hidden: true };
        }
        return { ...item, hidden: false };
      })
    );
  }, [hiddenColumns, baseConfig, setCurrentConfig]);

  const setHiddenColumn = useCallback(
    (val: any) => {
      if (!hiddenColumns) {
        return;
      }
      let hidden: string[] = [];
      if (hiddenColumns.includes(val)) {
        hidden = hiddenColumns.filter((item) => item !== val);
      } else {
        hidden = [...hiddenColumns, val];
      }
      setStorage(hidden);
    },
    [hiddenColumns, setStorage]
  );

  return { currentConfig, setHiddenColumn };
};
