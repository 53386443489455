import React, { useEffect, useState } from "react";
import { Modal } from "../Modal";
type TChangelog = {
  isOpen: boolean;
};

type TResponse = {
  version: string;
  changes: string[];
};

export const Changelog: React.FunctionComponent<TChangelog> = ({
  isOpen = false,
}) => {
  const [content, setContent] = useState<TResponse | null>(null);

  useEffect(() => {
    if (isOpen === true) {
      (async () => {
        const response = await fetch("/change.json");
        if (response.status !== 200) {
          return;
        }
        const rcontent = await response.json();
        setContent(rcontent);
      })();
    }
  }, [isOpen, setContent]);
  if (isOpen === false || content === null) {
    return <></>;
  }
  return (
    <Modal isOpen={isOpen} title="Versionsänderungen">
      <div className="">
        <strong>Version: {content.version}</strong>
        <ul>
          {content.changes.map((entry, i) => (
            <li key={`entry-${i}`}>{entry}</li>
          ))}
        </ul>
      </div>
    </Modal>
  );
};
