import { IDataTableConfig } from "../components/DataTable/DataTable";
import { TSystemUser } from "../hooks/useGetUsers";

export interface ISystemUser extends IDataTableConfig {
  dataIndex: keyof TSystemUser;
}

export const userTable: ISystemUser[] = [
  {
    name: "Vorname",
    dataIndex: "firstname",
    sortAble: true,
  },
  {
    name: "Nachname",
    dataIndex: "lastname",
    sortAble: true,
  },
  {
    name: "Email",
    dataIndex: "email",
  },
  {
    name: "Rolle",
    dataIndex: "role",
    hidden: false,
    size: 100,
  },
  {
    name: "Firma / Amt",
    dataIndex: "companyName",
    sortAble: true,
    size: 300,
  },
  {
    name: "Typ",
    dataIndex: "companyType",
    size: 120,
    sortAble: true,
  },
  {
    name: "Workflowmail",
    dataIndex: "workflowReminder",
    size: 120,
    sortAble: true,
    formatter: "emailSubscription",
  },
];
