import {
  faCheckCircle,
  faCircle,
  faCloudUploadAlt,
  faEnvelope,
  faEuroSign,
  faExclamationTriangle,
  faInfoCircle,
  faLock,
  faShippingFast,
  faUnlock,
  faUpload,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import { OrderTypeIcons } from "../components/OrderTypeIcon/OrderTypeIcon";
import { ToolTip } from "../components/ToolTip";
import { IFormatterTypes } from "../config/dataTable";
import {
  MEASURE_STATE_BILLING,
  MEASURE_STATE_DECLINED,
  WORKFLOW_INFO,
  MEASURE_STATE_PROCESS,
  MEASURE_STATE_PTS_CORRECTED,
  MEASURE_STATE_PTS_UPLOAD,
  MEASURE_STATE_RDB_SYNC,
  MEASURE_STATE_SUCCESS,
  POINT_CLOUD_BILLED,
  POINT_CLOUD_CHECKED,
  POINT_CLOUD_DELIVERED,
  POINT_CLOUD_RECEIVE,
} from "../config/workflow";
import { Icon } from "../components/Icon";

const dateFormat = "L";
const loadingZero = 8;

export function FormatValue(
  formatter: IFormatterTypes,
  value: any
): string | JSX.Element {
  switch (formatter) {
    case "Date":
      return dateFormatter(value);
    case "Datetime":
      return datetimeFormatter(value);
    case "LeadingZero":
      return leadingZeroFormatter(value);
    case "Success":
      return successFormatter(value as boolean);
    case "Status":
      return statusFormatter(value as number);
    case "Currency":
      return currencyFormatter(value);
    case "Number":
      return numberFormatter(value);
    case "Text":
      return value ? value.toString() : "";
    case "Surface":
      return surfaceFormatter(value);
    case "yesNo":
      return yesNoFormatter(value);
    case "requieredText":
      return requiredTextFormatter(value);
    case "lineBreak":
      return value ? (
        <>
          {value
            .toString()
            .split("\n")
            .map((v: string) => (
              <p>{v}</p>
            ))}
        </>
      ) : (
        ""
      );
    case "orderTypes":
      return (
        <div className="flex justify-start">
          {value && typeof value === "object"
            ? Object.keys(value as object).map((val) => (
                <OrderTypeIcons
                  order={val}
                  display={value[val]}
                  key={`order-icon-${val}`}
                />
              ))
            : ""}
        </div>
      );
    case "emailSubscription":
      return emailSubscription(value);
    default:
      return "";
  }
}

export function requiredTextFormatter(value: any) {
  if (value === "Yes") {
    return "Erforderlich";
  }

  return "Nicht erforderlich";
}

export function measurePriorityFormatter(value: any) {
  if (value === "1" || value === "2") {
    return value;
  }

  return "Nicht festgelegt";
}

export function surfaceFormatter(value: any) {
  return <div className="text-right">{numberFormatter(value)} m²</div>;
}

export function yesNoFormatter(value: any) {
  if (!value || value === "No") {
    return "Nein";
  }

  return "Ja";
}

export function leadingZeroFormatter(value: any): string {
  if (!value) {
    return "";
  }

  return String(value).padStart(loadingZero, "0");
}

export function datetimeFormatter(value: any): string {
  const date = moment.parseZone(value);
  if (date.isValid()) {
    return date.local().format("DD.MM.YYYY HH:mm");
  }
  return "";
}

export function dateFormatter(value: any): string {
  const date = moment.parseZone(value);
  if (date.isValid()) {
    return date.format(dateFormat);
  }
  return "";
}

export function currencyFormatter(value: any) {
  try {
    return value.toLocaleString("de-De", {
      style: "currency",
      maximumFractionDigits: 4,
      currency: "EUR",
    });
  } catch (error) {
    return "";
  }
}

export function numberFormatter(value: any) {
  try {
    return value.toLocaleString("de-De", {
      style: "decimal",
      maximumFractionDigits: 2,
      useGrouping: false,
    });
  } catch (error) {
    return "0";
  }
}

function successFormatter(value: boolean): JSX.Element {
  return (
    <div style={{ textAlign: "center" }}>
      <Icon
        icon={value === true ? "ANGLE_RIGHT_SOLID" : "ANGLE_DOUBLE_RIGHT_SOLID"}
      />
    </div>
  );
}

export function statusFormatter(value: number): JSX.Element {
  let icon: FontAwesomeIconProps["icon"] = faCircle;
  let color = "#8a8a8a";
  let tip = "Kein Status vorhanden";

  switch (value) {
    case POINT_CLOUD_CHECKED:
      icon = faCheckCircle;
      tip = "Prüfung erfolgreich";
      color = "#65A30D";
      break;

    case POINT_CLOUD_DELIVERED:
      icon = faShippingFast;
      tip = "Status geliefert";
      color = "#65A30D";
      break;

    case WORKFLOW_INFO:
      icon = faInfoCircle;
      tip = "Information";
      color = "#0369A1";
      break;

    case POINT_CLOUD_RECEIVE:
      icon = faWarehouse;
      tip = "Status empfangen";
      color = "#0369A1";
      break;

    case MEASURE_STATE_DECLINED:
      icon = faExclamationTriangle;
      tip = "Daten fehlerhaft";
      color = "#B91C1C";
      break;

    case MEASURE_STATE_PTS_CORRECTED:
      color = "#84CC16";
      icon = faCheckCircle;
      tip = "Fehler behoben";
      break;

    case MEASURE_STATE_SUCCESS:
      icon = faLock;
      tip = "Prüfung erfolgreich";
      color = "#65A30D";
      break;

    case MEASURE_STATE_RDB_SYNC:
      color = "#65A30D";
      icon = faCloudUploadAlt;
      tip = "RDB Sync";
      break;
    case POINT_CLOUD_BILLED:
    case MEASURE_STATE_BILLING:
      icon = faEuroSign;
      color = "#16A34A";
      tip = "Vorgang abgerechnet";
      break;

    case MEASURE_STATE_PTS_UPLOAD:
      icon = faUpload;
      color = "#65A30D";
      tip = "PTS Upload ist erfolgt";
      break;

    case MEASURE_STATE_PROCESS:
      icon = faUnlock;
      color = "#65A30D";
      tip = "Vorgang ist gestartet";
      break;
  }

  return (
    <ToolTip content={tip}>
      <div className="text-center far" style={{ color: color ? color : "" }}>
        <FontAwesomeIcon icon={icon} size="lg" />
      </div>
    </ToolTip>
  );
}

export const emailSubscription = (state: "YES" | "NO" | null) => {
  if (!state) {
    return <></>;
  }
  return (
    <FontAwesomeIcon
      icon={faEnvelope}
      size={"lg"}
      className={state === "YES" ? "text-success" : "text-danger"}
    />
  );
};

export const formatReadonly = (value: string | null | number) => {
  if (!value) {
    return "k.A.";
  }

  return value;
};
