import Axios from "axios";
import React, { useState } from "react";
import { PwForm } from "./PwForm";
import { PwRequest } from "./PwRequest";
import { PwSuccess } from "./PwSuccess";

export const PasswordChange: React.FunctionComponent = () => {
  const [success, setSuccess] = useState(false);
  const [request, setRequest] = useState(false);
  const [error, setError] = useState("");
  async function changePassword(oldPw: string, newPw: string) {
    setRequest(true);

    try {
      await Axios.put("user/change-password", {
        data: {
          pw: oldPw,
          change: newPw,
        },
      });
      setSuccess(true);
      setError("");
    } catch (error) {
      setError("Bitte Prüfen Sie Ihr altes Passwort");
    } finally {
      setRequest(false);
    }
  }

  return (
    <div className="relative w-full h-full" style={{ minHeight: "260px" }}>
      <PwRequest request={request} />
      <PwSuccess success={success} disableSuccess={() => setSuccess(false)} />
      <PwForm
        request={request}
        error={error}
        success={success}
        passwordChange={(oldPw: string, newPw: string) =>
          changePassword(oldPw, newPw)
        }
      />
    </div>
  );
};
