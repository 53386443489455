import { TBillImportData } from "./hooks/useBillImporter";
import React, { useEffect, useState } from "react";
import moment from "moment-business-days";
import { leadingZeroFormatter } from "../../../helper/formatter";
import { useVerifyBuilding } from "../../../hooks/verify/useVerifyBuilding";
import { useVerifyMeasureOrder } from "../../../hooks/verify/useVerifyMeasureOrder";
import { useVerifyQSBillState } from "../../../hooks/verify/useVerifyQSBillState";

type TDataRowProps = {
  isImportable: boolean;
  isChecked: boolean;
  setImportable: (path: string, remove: boolean) => void;
  setChecked: (checked: boolean, path: string) => void;
  force?: number;
} & TBillImportData;

export const DataRow: React.FC<TDataRowProps> = ({
  path,
  billDate,
  buildingNumber,
  billNumber,
  description,
  economicUnit,
  isImportable,
  isChecked,
  setImportable,
  force,
  setChecked,
}) => {
  const { buildingId, buildingExists } = useVerifyBuilding(path, force);
  const [comments, setComments] = useState<
    Record<string, string | JSX.Element>
  >({});
  useEffect(() => {
    if (!force) {
      return;
    }
    setComments({});
  }, [force, setComments]);
  const [classNames, setClassNames] = useState("");
  const isOrderValid = useVerifyMeasureOrder(path, force);
  const serverBillData = useVerifyQSBillState(path, force);

  useEffect(() => {
    if (buildingExists === null || isOrderValid === null) {
      return;
    }
    if (!buildingExists || !isOrderValid) {
      setClassNames("bg-red-500");
    } else {
      setClassNames("bg-green-200");
    }
  }, [buildingExists, isOrderValid, setClassNames]);

  useEffect(() => {
    if (
      serverBillData.deliverDate === null ||
      serverBillData.comment === null ||
      !path ||
      Object.keys(comments).includes("sameData") ||
      !isOrderValid ||
      !buildingExists
    ) {
      return;
    }

    let comment = "";
    if (serverBillData.deliverDate === "" && buildingExists && isOrderValid) {
      setImportable(path, false);
    } else if (
      (serverBillData.deliverDate && serverBillData.deliverDate !== billDate) ||
      (serverBillData.comment && serverBillData.comment !== billNumber)
    ) {
      comment = "Bitte zuerst Status löschen";
      setImportable(path, true);
    } else {
      comment = "Daten sind gleich";
      setImportable(path, true);
    }

    setComments({ ...comments, sameData: comment });
  }, [
    isOrderValid,
    buildingExists,
    path,
    serverBillData,
    billDate,
    billNumber,
    setImportable,
    comments,
    setComments,
    force,
  ]);

  // Statusmeldung bzw link für das Gebäude
  useEffect(() => {
    if (
      buildingExists === null ||
      Object.keys(comments).includes("billBuilding")
    ) {
      return;
    }

    let tmp: string | JSX.Element = "Kein Gebäude gefunden";
    if (buildingExists) {
      tmp = (
        <a
          href={`/building/${buildingId}/measure/`}
          className="smb-link"
          target={"_blank"}
          rel={"noreferrer"}
        >
          zum Gebäude
        </a>
      );
    }

    setComments({ ...comments, billBuilding: tmp });
  }, [buildingId, buildingExists, comments, setComments]);

  useEffect(() => {
    if (
      isOrderValid === null ||
      Object.keys(comments).includes("order") ||
      isOrderValid
    ) {
      return;
    }

    setComments({ ...comments, order: "Kein Auftrag zum Gebäude gefunden" });
  }, [comments, isOrderValid, setComments]);

  useEffect(() => {
    if (isOrderValid === null || isOrderValid) {
      return;
    }

    setClassNames("bg-red-500");
  }, [isOrderValid, setClassNames]);

  return (
    <tr className={classNames}>
      <td className="border border-border p-1 ">
        <input
          type="checkbox"
          disabled={!isImportable}
          checked={isChecked}
          className={isImportable ? "" : "opacity-20"}
          onChange={(e) => setChecked(e.target.checked, path ?? "")}
        />
      </td>
      <td
        className={`border border-border p-1${
          buildingExists === false ? " bg-red-800" : ""
        }`}
      >
        {path}
      </td>
      <td className="border border-border p-1">
        {leadingZeroFormatter(economicUnit)}
      </td>
      <td className="border border-border p-1">
        {leadingZeroFormatter(buildingNumber)}
      </td>
      <td className="border border-border p-1">{description}</td>
      <td
        className={`border border-border p-1${
          billDate === serverBillData.deliverDate ? " bg-green-800" : ""
        }`}
      >
        <div>{moment(billDate).format("DD.MM.YYYY")}</div>
        <div>
          {moment(serverBillData.deliverDate).isValid()
            ? moment(serverBillData.deliverDate).format("DD.MM.YYYY")
            : ""}
        </div>
      </td>
      <td
        className={`border border-border p-1${
          serverBillData.comment &&
          serverBillData.comment.includes(billNumber ?? "")
            ? " bg-green-800"
            : ""
        }`}
      >
        <div>{billNumber}</div>
        <div>{serverBillData.comment}</div>
      </td>
      <td className="border border-border p-1">
        {Object.values(comments).map((v, i) => (
          <div key={`comment-${i}`}>{v}</div>
        ))}
      </td>
    </tr>
  );
};
