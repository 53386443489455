import axios from "axios";
import { useEffect, useState } from "react";

const intervall = 3 * 60 * 1000;

export type TStateResponse = {
  address: string;
  associatedId: number;
  buildingId: number;
  buildingNumber: number;
  calcStart?: string;
  companyName: string;
  designation: string;
  duration?: number;
  economicUnit: string;
  losNumber: number;
  office: string;
  path: string;
  plausiDuration: number;
  plausiEnd: string;
  plausiStart: string;
  psFails: number;
  qsFails: number;
  qsOffice: number | null;
  reCalc?: string;
  threshold?: number;
  qsEditor?: number | null;
  nrfMeasurement?: number;
  waiting?: "waiting";
  qsTeam?: null | string;
  orderId?: number;
  formattedBuildingNumber: string;
  formattedEconomicUnit: string;
  workdays: number;
  cardColor: string;
  finishDate: string;
  priority: "10" | "20" | "99";
};

export type TBoardResponse = {
  [key: string]: TStateResponse[];
};

export const useGetMeasureBoardData = (
  processId: number,
  filter?: string,
  force?: number
) => {
  const [buildings, setBuildings] = useState<TBoardResponse | null>(null);

  useEffect(() => {
    setBuildings(null);
    const process = async () => {
      let uri = `/board/measure/`;

      if (filter) {
        uri += `${filter}/`;
      }
      const result = await axios.get(uri);
      setBuildings(result.data as TBoardResponse);
    };
    process();
    const timer = window.setInterval(() => {
      setBuildings(null);
      process();
    }, intervall);

    return () => {
      window.clearInterval(timer);
    };
  }, [filter, processId, force, setBuildings]);

  return buildings;
};
