import React, { useEffect, useState } from "react";
import PasswordStrength from "../PasswordStrength";
import { Input } from "../Input";
import { Button } from "../Button";

type TPwFormProps = {
  request: boolean;
  error: string;
  passwordChange: (pldPW: string, newPw: string) => void;
  success: boolean;
};

export const PwForm: React.FunctionComponent<TPwFormProps> = ({
  request,
  error,
  success,
  passwordChange,
}) => {
  const [oldPw, setOldPw] = useState("");
  const [oldPwType, setOldPwType] = useState<"password" | "text">("password");
  const [newPw, setNewPw] = useState("");
  const [newPwType, setNewPwType] = useState<"password" | "text">("password");
  const [verifyPw, setVerifyPw] = useState("");
  const [verifyPwType, setVerifyPwType] = useState<"password" | "text">(
    "password"
  );
  const [pwStrength, setPwStrength] = useState(false);
  const [pwError, setPwError] = useState("");

  useEffect(() => {
    if (success) {
      setOldPw("");
      setNewPw("");
      setVerifyPw("");
      setPwError("");
    }
  }, [success]);

  if (request || success) {
    return null;
  }

  return (
    <>
      <Input
        value={oldPw}
        onChange={(v: string) => setOldPw(v)}
        rightIcon={
          oldPwType === "password" ? "EYE_SLASH_REGULAR" : "EYE_REGULAR"
        }
        type={oldPwType}
        placeholder="Ihr altes Passwort"
        labelTop={true}
        displayOnValue={true}
        label="Ihr altes Passwort"
        help="Dieses haben Sie entweder per EMail erhalten oder bereits geändert"
        error={error}
        onRightIconClick={() =>
          setOldPwType(oldPwType === "password" ? "text" : "password")
        }
      />
      <Input
        onChange={(v: string) => setNewPw(v)}
        value={newPw}
        placeholder="Neues Passwort"
        help="Das Passwort muß mindestens 8 Zeichen lang sein"
        label="Neues Passwort"
        type={newPwType}
        rightIcon={
          newPwType === "password" ? "EYE_SLASH_REGULAR" : "EYE_REGULAR"
        }
        displayOnValue={true}
        disabled={oldPw === ""}
        error={pwError}
        onBlur={() => {
          if (!pwStrength) {
            setPwError(
              "Ihr Passwort ist unsicher. Buchstaben, Zahlen und Sonderzeichen sollten enthalten sein und Groß- und Kleinbuchstaben verwenden "
            );
            return;
          }
          setPwError("");
        }}
        onRightIconClick={() =>
          setNewPwType(newPwType === "password" ? "text" : "password")
        }
      />
      <PasswordStrength
        value={newPw}
        setExists={(strength: boolean) => setPwStrength(strength)}
      />
      <Input
        onChange={(v: string) => setVerifyPw(v)}
        value={verifyPw}
        placeholder="Passwort wiederholen"
        label="Passwort wiederholen"
        type={verifyPwType}
        rightIcon={
          verifyPwType === "password" ? "EYE_SLASH_REGULAR" : "EYE_REGULAR"
        }
        displayOnValue={true}
        disabled={newPw.length < 8 && pwStrength === false}
        onRightIconClick={() =>
          setVerifyPwType(verifyPwType === "password" ? "text" : "password")
        }
        onBlur={() => {
          if (newPw !== verifyPw) {
            setPwError("Passwörter stimmen nicht überein");
          }
        }}
      />
      <div className="absolute left-0 right-0 bottom-0">
        <Button
          className="mt-5"
          icon="SAVE_REGULAR"
          buttonStyle="outline"
          intent="success"
          text="Ändern"
          full={true}
          disabled={
            newPw.length < 8 ||
            verifyPw.length < 8 ||
            newPw !== verifyPw ||
            pwStrength === false
          }
          onClick={() => passwordChange(oldPw, newPw)}
        />
      </div>
    </>
  );
};
