import React from "react";
import { Icon } from "../../../components/Icon";
import { Button } from "../../../components/Button";
type MyProps = {
  token: string | null;
  onClose: () => void;
};

const DisplayToken: React.FunctionComponent<MyProps> = (props) => {
  if (props.token === null) return <></>;

  function copyToken() {
    navigator.clipboard.writeText(props.token ?? "");
  }

  return (
    <>
      <div className="modal-token-warning">
        Bitte Kopieren Sie diesen Token. Sie werden diesen Token nie wieder
        sehen
      </div>
      <div className="modal-token-info flex">
        <div className="token-icon">
          <Icon icon="CHECK_SOLID" className="text-green-800 w-5 h-5 mr-1" />
        </div>
        <div className="token flex-1 ml-2">{props.token}</div>
        <div>
          <Button icon="COPY_REGULAR" onClick={() => copyToken()} />
        </div>
      </div>
      <div className="token-modal-buttons justify-end flex mt-3">
        <Button text="Schließen" icon="TIMES_SOLID" onClick={props.onClose} />
      </div>
    </>
  );
};

export default DisplayToken;
