import { useNavigate } from "@reach/router";
import React from "react";
import { LAST_EDIT_TABLE } from "../../../config/lastEditTable";
import { ROUTE_BUILDING } from "../../../config/routes";
import { MEASURE_TAB } from "../../../config/tabs";
import { useBuildingsList } from "../../../hooks/useBuildingsList";
import { useTableConfig } from "../../../hooks/useTableConfig";
import { TCompanyType } from "../../../store/user/types";
import { DataTable } from "../../DataTable/DataTable";
import { Help } from "../../Help";
import Tile from "../../Tile";
type TLastEditProps = {
  companyType: TCompanyType;
};

export const LastEdit: React.FunctionComponent<TLastEditProps> = ({
  companyType,
}) => {
  const navigate = useNavigate();
  const { currentConfig } = useTableConfig(LAST_EDIT_TABLE);

  const { data } = useBuildingsList(companyType, 10, 0, "updatedAt", "DESC");
  return (
    <div className="col-span-2">
      <Tile
        title={
          <>
            <div>Gebäude kürzlich geändert </div>
            <div>
              <Help>
                <div className="text-xs mt-1">
                  <p>
                    Hier sehen Sie die letzten 10 Gebäude, bei denen sich Daten
                    geändert haben
                  </p>
                </div>
              </Help>
            </div>
          </>
        }
        key="last_edit"
      >
        <DataTable
          config={currentConfig}
          data={data}
          count={data.length}
          limit={10}
          orderField="orderNumber"
          orderDirection="ASC"
          displayIndex={false}
          onCellClick={(index: number) => {
            navigate(`${ROUTE_BUILDING}${data[index].id}/${MEASURE_TAB}/`);
          }}
        />
      </Tile>
    </div>
  );
};
