import { Link } from "@reach/router";
import { ROUTE_BOARDS, ROUTE_MEASURE_BOARD } from "../../../config/routes";
import { TMeasureWidgetStates } from "./MeasureOrder";
import { Spinner } from "../../Spinner";
type TStateColumnsProps = {
  count: null | number;
} & TMeasureWidgetStates;

export const StateColumn: React.FC<TStateColumnsProps> = ({ label, count }) => {
  return (
    <Link to={`${ROUTE_BOARDS}${ROUTE_MEASURE_BOARD}`}>
      <div className="flex justify-between p-1 h-7 leading-5 border-border border-b">
        <div>{label}</div>
        <div>{count !== null ? count : <Spinner size="small" />}</div>
      </div>
    </Link>
  );
};
