import { RouteComponentProps } from "@reach/router";
import Axios from "axios";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { KanbanBoard } from "../../components/KanbanBoard/KanbanBoard";
import { OfficeMyQs } from "../../components/KanbanBoard/OfficeMyQS";
import { SelectionFilter } from "../../components/KanbanBoard/SelectionFilter";
import { TCalculateResult } from "../../components/KanbanBoard/useBoardFilter";
import { MEASURE_BOARD_CONFIG, TCols } from "../../config/measureBoard";
import { ROUTE_BUILDING } from "../../config/routes";
import {
  MEASURE_STATE_DECLINED,
  MEASURE_STATE_PTS_CORRECTED,
  MEASURE_STATE_PTS_UPLOAD,
} from "../../config/workflow";
import { leadingZeroFormatter, numberFormatter } from "../../helper/formatter";
import {
  TBoardResponse,
  useGetMeasureBoardData,
} from "../../hooks/measure/useGetMeasureBoardData";
import { RootState } from "../../store";
import { MeasureErrorDescription } from "./MeasureErrorDescription";
import { SmbToastContext, TSmbToast } from "../../context/SmbTabsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../components/Button";

type TBoardProps = {
  children?: React.ReactNode;
} & RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

export const Component: React.FC<TBoardProps> = ({ companyType }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [filter, setFilter] = useState("");
  const [processId, setProcessId] = useState(Date.now());
  const [wrapperHeight, setWrapperHigh] = useState(300);
  const [config, setConfig] = useState<TCols[] | null>(null);
  const [configHeader, setConfigHeader] = useState<TCols[] | null>(null);
  const [transferConfig, setTransferConfig] = useState<TCols | null>(null);
  const [transferData, setTransferData] = useState<TCalculateResult | null>(
    null
  );
  const [force, setForce] = useState(Date.now());
  const boardResponse = useGetMeasureBoardData(processId, filter, force);
  const [boardData, setBoardData] = useState<null | TBoardResponse>(null);
  const { addToast } = useContext(SmbToastContext);

  const onClick = useCallback((buildingId: number) => {
    window
      .open(
        `${window.location.origin}${ROUTE_BUILDING}${buildingId}/measure/?close`,
        "_blank"
      )
      ?.focus();
  }, []);

  useEffect(() => {
    if (!boardResponse || !config) {
      setBoardData(null);
      return;
    }

    const currentConfig = config.map((v) => ({ ...v }));

    for (const conf of currentConfig) {
      let nrf = 0;
      if (!boardResponse[conf.dataKey]) {
        continue;
      }

      for (const measureData of boardResponse[conf.dataKey]) {
        nrf += measureData.nrfMeasurement ?? 0;
        measureData.formattedBuildingNumber = leadingZeroFormatter(
          measureData.buildingNumber
        );
        measureData.formattedEconomicUnit = leadingZeroFormatter(
          measureData.economicUnit
        );
      }
      conf.headerSub = `NRF: ${numberFormatter(nrf)} m²`;
    }

    setBoardData(boardResponse);
    setConfigHeader(currentConfig);
  }, [config, boardResponse, setBoardData, setConfigHeader]);

  useEffect(() => {
    if (
      typeof companyType === "string" &&
      MEASURE_BOARD_CONFIG.hasOwnProperty(companyType)
    ) {
      setConfig(MEASURE_BOARD_CONFIG[companyType]);
    }
  }, [companyType, setConfig]);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }

    setWrapperHigh(
      window.innerHeight - wrapperRef.current.getBoundingClientRect().top - 50
    );
  }, [wrapperRef]);

  const transfer = useCallback(
    async (data: TCalculateResult, dropConfig: TCols, comment = "") => {
      if (
        !dropConfig.historyType ||
        typeof dropConfig.historyState === "undefined"
      ) {
        return;
      }
      let message = "Staus erfolgreich geändert:";
      let intent: TSmbToast["intent"] = "success";
      setTransferData(null);
      setTransferConfig(null);

      try {
        await Axios.post(
          `/workflow/measure/${dropConfig.historyType}/${data.buildingId}/`,
          {
            workflowState: dropConfig.historyState,
            state: dropConfig.historyState,
            comment,
          }
        );
        setProcessId(Date.now());
      } catch (e) {
        intent = "error";
        message = "Fehler beim Status ändern:";
      }

      if (addToast) {
        addToast({
          message: `${message} ${data.address} WE: ${leadingZeroFormatter(
            data.economicUnit
          )} GE: ${leadingZeroFormatter(data.buildingNumber)}`,
          intent,
          autoClose: 5000,
        });
      }
    },
    [setTransferConfig, setTransferData, setProcessId, addToast]
  );

  const onDrop = useCallback(
    async (data: TCalculateResult, dropConfig: TCols) => {
      if (
        !data.associatedId ||
        !dropConfig.historyType ||
        typeof dropConfig.historyState === "undefined"
      ) {
        return;
      }
      if (
        dropConfig.historyState === MEASURE_STATE_DECLINED ||
        dropConfig.historyState === MEASURE_STATE_PTS_UPLOAD ||
        dropConfig.historyState === MEASURE_STATE_PTS_CORRECTED
      ) {
        setTransferData(data);
        setTransferConfig(dropConfig);
      } else {
        transfer(data, dropConfig);
      }
    },
    [setTransferData, setTransferConfig, transfer]
  );

  return (
    <div className="flex flex-col">
      <h1>Dora Projekt Board Aufmaß</h1>
      <div
        ref={wrapperRef}
        className="overflow-hidden"
        style={{ height: wrapperHeight + "px" }}
      >
        <KanbanBoard
          onDrop={onDrop}
          data={boardData as any}
          config={configHeader}
          onClick={onClick}
          displayHelp={true}
        >
          <div className={"flex"}>
            <Button
              className={"h-8 mr-5"}
              buttonStyle="elevated"
              onClick={() => {
                setForce(Date.now());
              }}
            >
              <FontAwesomeIcon icon={faSync} />
            </Button>
            <SelectionFilter
              filter={filter}
              companyType={companyType}
              onChange={(e) => setFilter(e.target.value)}
            />
            <OfficeMyQs
              filter={filter}
              companyType={companyType}
              onChange={(val: string) => setFilter(val)}
            />
          </div>
        </KanbanBoard>
      </div>

      <MeasureErrorDescription
        dropConfig={transferConfig}
        data={transferData}
        onCancel={() => {
          setTransferData(null);
          setTransferConfig(null);
        }}
        onSubmit={(comment: string) => {
          if (transferData === null || transferConfig === null) {
            return;
          }
          transfer(transferData, transferConfig, comment);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyType: state.user.companyType,
});

export const MeasureBoard = connect(mapStateToProps)(Component);
