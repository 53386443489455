import { RouteComponentProps } from "@reach/router";
import React, { useEffect, useState } from "react";
import UserTable from "../../../components/UserTable";
import { AddUserModal } from "./AddUserModal";
import { Button } from "../../../components/Button";

export const UserManagement: React.FunctionComponent<RouteComponentProps> = (
  props
): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [force, setForce] = useState(false);

  useEffect(() => {
    if (force) {
      setTimeout(() => {
        setForce(false);
      }, 0);
    }
  }, [force]);

  return (
    <div className="relative">
      <div className="font-bold text-lg mb-3">Benutzerverwaltung</div>
      <div className="absolute" style={{ right: 10, top: 0 }}>
        <Button
          buttonStyle="elevated"
          icon="PLUS"
          onClick={() => setIsOpen(true)}
        />
      </div>
      <AddUserModal
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        setForce={() => setForce(true)}
      />
      <div className="user-container">
        <UserTable force={force} setForce={() => setForce(true)} />
      </div>
    </div>
  );
};
