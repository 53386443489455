import Axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useGetComments } from "../../hooks/useGetComments";
import { TOrderTypes } from "../../types/order";
import { CommentSpinner } from "./CommentSpinner";
import { DisplayComments } from "./DisplayComments";
import { WriteComment } from "./WriteComment";
import { AccordionRowContext } from "../../context/AccordionRow";
import { SmbToastContext, TSmbToast } from "../../context/SmbTabsContext";

type TCommentProps = {
  buildingId?: number | null;
  orderType: TOrderTypes;
};

export const Comments: React.FunctionComponent<TCommentProps> = ({
  buildingId,
  orderType: commentType,
}) => {
  const { addToast } = React.useContext(SmbToastContext);
  const [addComment, setAddComment] = useState(false);
  const [force, setForce] = useState(Date.now());
  const [isLoading, setLoading] = useState(false);
  const { comments, loading, count } = useGetComments(
    buildingId,
    commentType,
    force
  );
  const { setCount } = React.useContext(AccordionRowContext);

  useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    if (typeof setCount === "function") {
      setCount(count);
    }
  }, [count, setCount]);

  const onSave = useCallback(
    async (comment: string) => {
      if (!comment) {
        setAddComment(false);
        return;
      }

      setLoading(true);
      setAddComment(false);
      let message: TSmbToast = {
        message: "Kommentar erfolgreich gespeichert",
        intent: "success",
        autoClose: 3000,
      };
      try {
        await Axios.post(`/comments/${commentType}/${buildingId}/`, {
          comment,
        });
      } catch (error) {
        message = {
          message: "Fehler beim Speichern des Kommentars",
          intent: "error",
          autoClose: 0,
        };
      }

      if (addToast) {
        addToast(message);
      }

      setForce(Date.now());
    },
    [commentType, buildingId, setForce, setAddComment, setLoading, addToast]
  );

  if (!buildingId) {
    return null;
  }

  return (
    <div className="">
      <CommentSpinner loading={isLoading} />

      <DisplayComments
        comments={comments}
        isLoading={isLoading}
        addComment={addComment}
        onClick={() => setAddComment(true)}
      />
      <WriteComment
        isOpen={addComment}
        onCancel={() => setAddComment(false)}
        saveComment={onSave}
      />
    </div>
  );
};
