type TSpacerProps = {
  display: boolean;
};

export const Spacer: React.FC<TSpacerProps> = ({ display }) => {
  if (!display) {
    return null;
  }

  return (
    <div className="grid-spacer col-span-5 border-border border-t w-full h-0"></div>
  );
};
