import { TPositionValue } from "./calcPosition";

export const calcStylePosition = (
  rect: DOMRect,
  width: number,
  height: number,
  pos: TPositionValue,
  style: React.CSSProperties = {},
  adjust = 0
): React.CSSProperties => {
  let prop = { ...style };
  switch (pos) {
    case "top":
      prop = {
        ...prop,
        top: `${rect.y - height - adjust}px`,
        left: `${rect.x + rect.width / 2 - width / 2}px`,
      };
      break;
    case "right":
      prop = {
        ...prop,
        top: `${rect.y + rect.height / 2 - height / 2}px`,
        left: `${rect.x + rect.width + adjust}px`,
      };
      break;
    case "left":
      prop = {
        ...prop,
        left: `${rect.x - width - adjust}px`,
        top: `${rect.y + rect.height / 2 - height / 2}px`,
      };
      break;
    case "bottom":
      prop = {
        ...prop,
        left: `${rect.x + rect.width / 2 - width / 2}px`,
        top: `${rect.y + rect.height + adjust}px`,
      };
  }
  return prop;
};
