import React, { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { SmbToastContext, TSmbToast } from "../../context/SmbTabsContext";

type TButtonCopy = {
  toCopy: string;
};

export const ButtonCopy: React.FC<TButtonCopy> = ({ toCopy, children }) => {
  const { addToast } = React.useContext(SmbToastContext);

  const onClick = useCallback(async () => {
    if (!toCopy) {
      return;
    }

    let message: TSmbToast = {
      message: "Text in die Zwischenablage kopiert",
      intent: "success",
      autoClose: 3000,
    };
    try {
      await navigator.clipboard.writeText(toCopy);
    } catch (err) {
      message = {
        message: "Fehler beom kopieren",
        intent: "error",
        autoClose: 5000,
      };
    }

    if (addToast) {
      addToast(message);
    }
  }, [toCopy, addToast]);

  return (
    <button
      onClick={onClick}
      className={
        "rounded-md border-gray-500 bg-gray-300 p-2 hover:bg-hover hover:text-white"
      }
    >
      <FontAwesomeIcon
        icon={faCopy}
        size={"lg"}
        className={"mr-2 text-gray-400"}
      />
      {children}
    </button>
  );
};
