import { useEffect, useState } from "react";
import axios from "axios";

export type TPointcloudData = {
  orderId: number;
  areaCluster: number;
  category: number;
  comment: string;
  companyName: string;
  detailLevel: string;
  los: number;
  nrf: number | null;
  price: number | null;
  retrievalNumber: string | null;
};

export const usePointcloudData = (buildingId?: number) => {
  const [data, setData] = useState<TPointcloudData>();
  useEffect(() => {
    if (!buildingId) {
      return;
    }

    const process = async () => {
      const response = await axios.get<TPointcloudData>(
        `/point-cloud/${buildingId}/`
      );

      if (response.data) {
        setData(response.data);
      }
    };

    process();
  }, [buildingId, setData]);

  return data;
};
