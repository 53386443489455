import { TSequelizeError } from "./typeGuards/sequelizeGuard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
export const SqlError: React.FC<TSequelizeError> = ({
  name,
  sql,
  original,
  parent,
}) => {
  return (
    <div className="mb-2">
      <div>
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          size="1x"
          className={original.fatal ? "text-danger" : "text-warning"}
        />
        {name}
      </div>
      <div>{sql.replaceAll("\\", "")}</div>
      <div>Originalcode: {original.code}</div>
      <div>Parrentcode: {parent.code}</div>
    </div>
  );
};
