import React, { useContext, useEffect, useState } from "react";
import { leadingZeroFormatter } from "../../../helper/formatter";
import { DragIndicator } from "../DragIndicator";
import { KanbanBoardContext } from "../KanbanBoard";
import moment from "moment";
import { TCalculateResult } from "../useBoardFilter";
import { TCols } from "../../../config/measureBoard";

export type TMinimalBoardCardProps = {
  isDraggable?: boolean;
  isOpen: boolean;
  onDragStart?: () => void;
  onDragEnd?: () => void;
} & TCalculateResult &
  TCols;

export const MeasureWaitPtsUploadBoardCard: React.FC<
  TMinimalBoardCardProps
> = ({
  isOpen,
  buildingId,
  buildingNumber,
  companyName,
  designation,
  economicUnit,
  losNumber,
  office,
  isDraggable = false,
  onDragStart,
  onDragEnd,
  priority,
}) => {
  const [isHover, setHover] = useState(false);
  const { onClick } = useContext(KanbanBoardContext);
  const [priorityText, setPriorityText] = useState("-");
  const [priorityColor, setPriorityColor] = useState("bg-gray-300");

  useEffect(() => {
    if (priority === "99") {
      return;
    }

    if (priority === "10") {
      setPriorityText("1");
    } else {
      setPriorityText("2");
    }
  }, [setPriorityText, priority]);

  useEffect(() => {
    if (priority === "99") {
      return;
    }

    if (priority === "10") {
      if (moment().isBefore("2023-02-01")) {
        setPriorityColor("bg-green-300");
      } else if (moment().isBefore("2023-04-01")) {
        setPriorityColor("bg-yellow-300");
      } else {
        setPriorityColor("bg-red-300");
      }
    } else if (priority === "20" && moment().isAfter("2023-03-31")) {
      if (moment().isAfter("2023-12-31")) {
        setPriorityColor("bg-red-300");
      } else if (moment().isAfter("2023-10-31")) {
        setPriorityColor("bg-yellow-300");
      } else if (moment().isAfter("2023-03-31")) {
        setPriorityColor("bg-green-300");
      }
    }
  }, [priority, setPriorityColor]);

  if (!isOpen) {
    return null;
  }
  return (
    <div
      style={{
        width: "278px",
        height: "110px",
      }}
      onClick={() => {
        if (typeof onClick === "function") {
          onClick(buildingId);
        }
      }}
      onDragStart={(e) => {
        if (typeof onDragStart === "function") {
          onDragStart();
        }
      }}
      onDragEnd={(e) => {
        if (typeof onDragEnd === "function") {
          onDragEnd();
        }
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      draggable={isDraggable}
      className={`text-xs flex flex-row rounded m-1 hover:border hover:bg-gray-400 py-1 pl-1 ${priorityColor} border-4`}
    >
      <div className="w-full overflow-hidden">
        <div className="h-4 mt-1 overflow-ellipsis truncate">{designation}</div>
        <div className="flex flex-row mt-0.5">
          <div className="w-1/2">WE: {leadingZeroFormatter(economicUnit)}</div>
          <div className="w-1/2">
            GE: {leadingZeroFormatter(buildingNumber)}
          </div>
        </div>
        <div className="flex flex-row mt-0.5">
          <div className="w-1/2">Amt: {office}</div>
          <div className="w-1/2">Los: {losNumber}</div>
        </div>
        <div className="overflow-hidden h-4 overflow-ellipsis truncate mt-0.5">
          <div>Firma: {companyName}</div>
        </div>
        <div>Priorität: {priorityText} </div>
      </div>
      <DragIndicator isDraggable={isDraggable} isHover={isHover} />
    </div>
  );
};
