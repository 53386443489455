import { faSync, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { ToolTip } from "../ToolTip";
import { Input } from "../Input";
import { Button } from "../Button";

type TDataSearchProps = {
  useSearch: boolean;
  term: string;
  dataSearch?: (val: string) => void;
  force?: () => void;
};

export const DataSearch: React.FunctionComponent<TDataSearchProps> = ({
  useSearch,
  term,
  dataSearch,
  force,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (!term) {
      return;
    }

    setSearchTerm(term);
  }, [term, setSearchTerm]);

  const onSubmit = useCallback(
    (val: string) => {
      if (!dataSearch) {
        return;
      }
      dataSearch(val);
    },
    [dataSearch]
  );

  if (!useSearch || !DataSearch) {
    return null;
  }

  return (
    <div
      className="w-full bg-gray-300 border-l border-t border-r border-border pl-3 pb-1 flex rounded-t-lg justify-auto
    "
    >
      <div className="w-96 flex">
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onSubmit(searchTerm);
          }}
          onKeyDown={({ code, key, keyCode, which }) => {
            const byName = "Escape";
            const byCode = 27;
            if (
              code === byName ||
              key === byName ||
              keyCode === byCode ||
              which === byCode
            ) {
              setSearchTerm("");
              onSubmit("");
            }
          }}
          className="flex w-full"
        >
          <Input
            value={searchTerm}
            onChange={(val: string) => setSearchTerm(val)}
            help="Volltextsuche über WE-Nr, Geb-Nr und Bezeichnung"
            icon={"SEARCH_SOLID"}
            placeholder="Suche..."
            label="Suche"
            labelTop={true}
            displayOnValue={true}
            onIconClick={() => onSubmit(searchTerm)}
          />
        </form>
      </div>
      <div className="h-8 mt-6 ml-0 flex">
        <Button
          buttonStyle="elevated"
          disabled={!searchTerm.length}
          onClick={() => {
            setSearchTerm("");
            onSubmit("");
            if (force) {
              force();
            }
          }}
        >
          <FontAwesomeIcon icon={faTimesCircle} />
        </Button>
        <ToolTip content="Daten neu laden">
          <Button
            className="h-8"
            buttonStyle="elevated"
            onClick={() => {
              if (force) {
                force();
              }
            }}
          >
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </ToolTip>
      </div>
    </div>
  );
};
