import React from "react";
import { IToken } from ".";
import TokenRow from "./TokenRow";
import { Spinner } from "../../../components/Spinner";

type MyProps = {
  token: IToken[];
  progress: boolean;
};
const TokenList: React.FunctionComponent<MyProps> = (props) => {
  if (props.progress === true)
    return (
      <div className="token-progress">
        <Spinner />
      </div>
    );
  return (
    <>
      <div className="flex mt-2 font-bold border-b border-border py-2">
        <div className="flex-1">Token beschreibung</div>
        <div className="w-24">Leserechte</div>
        <div className="w-28">Schreibrechte</div>
        <div className="w-24">Erstellt am</div>
      </div>
      {props.token.map((token, i) => (
        <TokenRow {...token} key={`token-list-${i}`} />
      ))}
    </>
  );
};

export default TokenList;
