import React, { useCallback, useEffect, useState } from "react";
import { TAufmassImportData } from "./hooks/useNeuaufmassImporter";
import {
  currencyFormatter,
  leadingZeroFormatter,
  numberFormatter,
  yesNoFormatter,
} from "../../../helper/formatter";
import { useVerifyBuilding } from "../../../hooks/verify/useVerifyBuilding";
import { useVerifyMeasureOrder } from "../../../hooks/verify/useVerifyMeasureOrder";
import moment from "moment";
import { useVerifyCompany } from "../../../hooks/verify/useVerifyCompany";
import { useVerifyOffice } from "../../../hooks/verify/useVerifyOffice";

type TNeuaufmassRow = {
  isChecked: boolean;
  setCheckable: (path: string, remove?: boolean) => void;
  isCheckable: boolean;
  onChange: (path: string, checked: boolean) => void;
  force: number;
} & TAufmassImportData;

export const NeuaufmassRow: React.FC<TNeuaufmassRow> = ({
  force,
  path,
  economicUnit,
  buildingNumber,
  description,
  address,
  nrfEstimated,
  category,
  areaCluster,
  detailLevel,
  los,
  dateRetrieval,
  orderNumber,
  company,
  nrfMeasurement,
  deadline,
  priceUnit,
  office,
  cutsViews,
  setCheckable,
  isCheckable,
  onChange,
  isChecked,
  orderType,
}) => {
  const { buildingExists, buildingId } = useVerifyBuilding(path, force);
  const isCompany = useVerifyCompany(company, force);
  const isOffice = useVerifyOffice(office, force);
  const hasOrder = useVerifyMeasureOrder(path, force);
  const [hasError, setError] = useState(false);
  const [comment, setComment] = useState<Record<string, JSX.Element>>({});
  const [statusStyle, setStatusStyle] = useState("");
  const [requiredCheck, setRequiredCheck] = useState<string[]>([]);

  useEffect(() => {
    if (hasError) {
      setStatusStyle("bg-red-600");
    } else if (hasOrder) {
      setStatusStyle("bg-red-300");
    } else if (!buildingExists) {
      setStatusStyle("bg-green-500");
    } else if (buildingExists) {
      setStatusStyle("bg-green-200");
    }
  }, [buildingExists, hasOrder, hasError, setStatusStyle]);

  useEffect(() => {
    if (hasError && isCheckable) {
      setCheckable(path ?? "", hasError);
    }
  }, [isCheckable, path, hasError, setCheckable]);

  // Link zum Gebäude in comments setzen
  useEffect(() => {
    if (buildingId && !Object.keys(comment).includes("buildingLink")) {
      setComment({
        ...comment,
        buildingLink: (
          <a
            href={`/building/${buildingId}/measure/`}
            className="smb-link"
            target={"_blank"}
            rel={"noreferrer"}
          >
            zum Gebäude
          </a>
        ),
      });
    }
  }, [buildingId, comment, setComment]);

  const checkRequired = useCallback(
    (fieldName: keyof TAufmassImportData, type: "string" | "number") => {
      switch (true) {
        case fieldName === "detailLevel" &&
          typeof detailLevel === "string" &&
          !!detailLevel.length:
          return;

        case fieldName === "nrfEstimated" &&
          !isNaN(Number(nrfEstimated)) &&
          (nrfEstimated ?? 0) > 1:
          return;

        case fieldName === "priceUnit" &&
          !isNaN(Number(priceUnit)) &&
          (priceUnit ?? 0) > 0:
          return;

        default:
          if (!requiredCheck.includes(fieldName)) {
            setRequiredCheck([...requiredCheck, fieldName]);
            setError(true);
          }
          return " bg-red-800";
      }
    },
    [
      requiredCheck,
      setRequiredCheck,
      detailLevel,
      nrfEstimated,
      priceUnit,
      setError,
    ]
  );

  useEffect(() => {
    if (
      buildingExists === false &&
      !Object.keys(comment).includes("newBuilding")
    ) {
      setComment({
        ...comment,
        newBuilding: <>Gebäude ist noch nicht vorhanden</>,
      });
    }
  }, [buildingExists, comment, setComment]);

  useEffect(() => {
    if (isCompany === false && !Object.keys(comment).includes("companyError")) {
      setError(true);
      setComment({
        ...comment,
        companyError: <>Firma ist nicht vorhanden</>,
      });
    }
  }, [isCompany, comment, setComment, setError]);

  useEffect(() => {
    if (isOffice === false && !Object.keys(comment).includes("officeError")) {
      setError(true);
      setComment({
        ...comment,
        officeError: <>Amt ist nicht vorhanden</>,
      });
    }
  }, [isOffice, comment, setComment, setError]);

  useEffect(() => {
    if (path && hasOrder && !Object.keys(comment).includes("orderExists")) {
      setCheckable(path, true);
      setComment({
        ...comment,
        orderExists: <>Auftrag bereits vorhanden!</>,
      });
    }
  }, [path, hasOrder, comment, setComment, setCheckable]);

  if (buildingExists === null) {
    return null;
  }

  return (
    <tr className={`text-xs ${statusStyle}`}>
      <td className="border border-border p-1">
        <input
          type="checkbox"
          checked={isChecked}
          disabled={!isCheckable}
          className={isCheckable ? "" : "opacity-20"}
          onChange={(event) => {
            if (!path) {
              return;
            }
            onChange(path, event.target.checked);
          }}
        />
      </td>
      <td className="border border-border p-1 whitespace-nowrap">{path}</td>
      <td className="border border-border p-1 whitespace-nowrap text-right">
        {leadingZeroFormatter(economicUnit)}
      </td>
      <td className="border border-border p-1 whitespace-nowrap text-right">
        {leadingZeroFormatter(buildingNumber)}
      </td>
      <td className="border border-border p-1 whitespace-nowrap">
        {description}
      </td>
      <td className="border border-border p-1 whitespace-nowrap">{address}</td>
      <td
        className={`border border-border p-1 whitespace-nowrap text-right${checkRequired(
          "nrfEstimated",
          "number"
        )}`}
      >
        {numberFormatter(nrfEstimated)}
      </td>
      <td className="border border-border p-1 whitespace-nowrap text-right">
        {numberFormatter(nrfMeasurement)}
      </td>
      <td className="border border-border p-1 whitespace-nowrap text-center">
        {category}
      </td>
      <td className="border border-border p-1 whitespace-nowrap text-center">
        {areaCluster}
      </td>
      <td
        className={`border border-border p-1 whitespace-nowrap text-right${checkRequired(
          "detailLevel",
          "string"
        )}`}
      >
        {detailLevel}
      </td>
      <td className="border border-border p-1 whitespace-nowrap text-right">
        {los}
      </td>
      <td className="border border-border p-1 whitespace-nowrap">
        {orderType}
      </td>
      <td className="border border-border p-1 whitespace-nowrap text-right">
        {moment(dateRetrieval).format("DD.MM.YYYY")}
      </td>
      <td className="border border-border p-1 whitespace-nowrap">
        {orderNumber}
      </td>
      <td className="border border-border p-1 whitespace-nowrap">{company}</td>
      <td className="border border-border p-1 whitespace-nowrap">
        {moment(deadline).format("DD.MM.YYYY")}
      </td>
      <td
        className={`border border-border p-1 whitespace-nowrap text-right${checkRequired(
          "priceUnit",
          "number"
        )}`}
      >
        {currencyFormatter(priceUnit)}
      </td>
      <td className="border border-border p-1 whitespace-nowrap text-right">
        {office}
      </td>
      <td className="border border-border p-1 whitespace-nowrap text-center">
        {yesNoFormatter(cutsViews)}
      </td>
      <td className="border border-border p-1 whitespace-nowrap">
        {Object.values(comment).map((val, index) => (
          <div key={`comment-${path}-${index}`}>{val}</div>
        ))}
      </td>
    </tr>
  );
};
