export const MEASURE_STATE_PTS_UPLOAD = 10 as const;
export const WORKFLOW_INFO = 5 as const;
export const MEASURE_STATE_PROCESS = 30 as const;
export const MEASURE_STATE_DECLINED = 40;
export const MEASURE_STATE_PTS_CORRECTED = 50 as const;
export const MEASURE_STATE_SUCCESS = 99 as const;
export const MEASURE_STATE_RDB_SYNC = 110;
export const MEASURE_STATE_BILLING = 120;

export type TMeasureQualityStates =
  | typeof MEASURE_STATE_PTS_UPLOAD
  | typeof WORKFLOW_INFO
  | typeof MEASURE_STATE_PROCESS
  | typeof MEASURE_STATE_DECLINED
  | typeof MEASURE_STATE_PTS_CORRECTED
  | typeof MEASURE_STATE_SUCCESS
  | typeof MEASURE_STATE_RDB_SYNC
  | typeof MEASURE_STATE_BILLING;

export const POINT_CLOUD_DELIVERED = 300 as const;
export const POINT_CLOUD_RECEIVE = 320 as const;
export const POINT_CLOUD_CHECKED = 340 as const;
export const POINT_CLOUD_BILLED = 360;

export const ALLOWED_POINT_CLOUD_STATES = [
  POINT_CLOUD_DELIVERED,
  POINT_CLOUD_RECEIVE,
  POINT_CLOUD_CHECKED,
  POINT_CLOUD_BILLED,
  WORKFLOW_INFO,
];

export type TPointCloudStates =
  | typeof WORKFLOW_INFO
  | typeof POINT_CLOUD_DELIVERED
  | typeof POINT_CLOUD_RECEIVE
  | typeof POINT_CLOUD_CHECKED
  | typeof POINT_CLOUD_BILLED;
