import React, { useState } from "react";
import { formatReadonly } from "../../helper/formatter";

type TTextInputProps = {
  onChange: (value: any) => void;
  value: string | null;
  canEdit?: boolean;
};

export const InputText: React.FunctionComponent<TTextInputProps> = ({
  value,
  onChange,
  canEdit = true,
}) => {
  const [currentValue, setValue] = useState(value ?? "");

  if (!canEdit) {
    return <>{formatReadonly(value)}</>;
  }

  return (
    <>
      <input
        className="w-full"
        type="text"
        value={currentValue}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onBlur={() => {
          onChange(currentValue);
        }}
      />
    </>
  );
};
