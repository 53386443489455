import { RouteComponentProps, useNavigate } from "@reach/router";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { JWT_SESSION_KEY } from "../../config/app";
import { setShowLogin } from "../../store/appState/action";

type TLogoutProps = RouteComponentProps &
  ReturnType<typeof mapDispatachToProps>;

const LogoutComponent: React.FunctionComponent<TLogoutProps> = ({
  enableLogin,
}) => {
  localStorage.removeItem(JWT_SESSION_KEY);

  const navigate = useNavigate();

  useEffect(() => {
    enableLogin();
    navigate("/");
  }, [navigate, enableLogin]);

  return null;
};

const mapDispatachToProps = (dispatch: ThunkDispatch<any, any, any>) => ({
  enableLogin: () => dispatch(setShowLogin(true)),
});

export const Logout = connect(null, mapDispatachToProps)(LogoutComponent);
