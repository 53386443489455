import { TAufmassImportData } from "../../components/Importer/Neuaufmass/hooks/useNeuaufmassImporter";
import { useCallback, useContext } from "react";
import axios from "axios";
import { SmbToastContext } from "../../context/SmbTabsContext";

export const useMeasureOrderImporterProcess = (done: () => void) => {
  const { addToast } = useContext(SmbToastContext);

  return useCallback(
    async (data: TAufmassImportData[]) => {
      if (!data.length) {
        done();
        return;
      }

      try {
        const response = await axios.post("/admin/import/measure/", {
          data,
        });

        if (!addToast) {
          return;
        }

        if (response.data.success) {
          addToast({
            message: `Erfolgreich importiere Datensätze: ${response.data.success}`,
            intent: "success",
            autoClose: 8000,
          });
        }

        for (const message of response.data.error) {
          addToast({
            message,
            intent: "error",
            autoClose: 0,
          });
        }
      } catch (error) {
        if (axios.isAxiosError(error) && addToast) {
          addToast({
            message: "Kritischer Fehler beim Import",
            intent: "error",
            autoClose: 0,
          });
        }
      }
      done();
    },
    [done, addToast]
  );
};
