import React, { useEffect, useState } from "react";
import { TDeadlineImportData } from "./hooks/useDeadlineImporter";
import { useVerifyDeadlineImport } from "../../../hooks/verify/useVerifyDeadlineImport";
import moment from "moment";

type TDataRowProps = {
  checked: boolean;
  checkable: boolean;
  setCheckable: (path: string) => void;
  toggleChecked: (path: string, state: boolean) => void;
  force: number;
} & TDeadlineImportData;

export const DataRow: React.FC<TDataRowProps> = ({
  path,
  deadline,
  buildingNumber,
  description,
  economicUnit,
  checked,
  checkable,
  setCheckable,
  force,
  toggleChecked,
}) => {
  const verified = useVerifyDeadlineImport(path, force);
  const [isEqual, setEqual] = useState(false);
  const [dbDate, setDbDate] = useState("");
  const [buildingError, setBuildingError] = useState(false);
  const [orderError, setOrderError] = useState(false);

  useEffect(() => {
    if (!verified.isBuilding) {
      setBuildingError(true);
      return;
    } else if (!verified.hasOrder) {
      setOrderError(true);
      return;
    }
    setBuildingError(false);
    setOrderError(false);

    const verifyDate = moment(verified.deadline);
    if (verifyDate.isValid()) {
      setDbDate(verifyDate.format("DD.MM.YYYY"));
    }

    if (verified.deadline === deadline) {
      setEqual(true);
    } else {
      setCheckable(path ?? "");
      setEqual(false);
    }
  }, [
    deadline,
    path,
    checkable,
    verified,
    setBuildingError,
    setDbDate,
    setEqual,
    setOrderError,
    setCheckable,
  ]);

  return (
    <tr
      className={
        isEqual
          ? "bg-green-200"
          : buildingError || orderError
          ? "bg-red-500"
          : ""
      }
    >
      <td className="border border-border p-1">
        <input
          type="checkbox"
          disabled={!checkable}
          checked={checked}
          className={checkable ? "" : "opacity-20"}
          onChange={(event) =>
            toggleChecked(path ?? "", event.currentTarget.checked)
          }
        />
      </td>
      <td className="border border-border p-1">{path}</td>
      <td className="border border-border p-1">{economicUnit}</td>
      <td className="border border-border p-1">{buildingNumber}</td>
      <td className="border border-border p-1">{description}</td>
      <td className="border border-border p-1">
        {moment(deadline).format("DD.MM.YYYY")}
      </td>
      <td className="border border-border p-1">{dbDate}</td>
      <td className="border border-border p-1">
        <div className={isEqual ? "" : "hidden"}>
          Datum ist gleich wie in der Datenbank
        </div>
        <div className={buildingError ? "" : "hidden"}>
          Kein Gebäude mit entsprechendem Pfad gefunden
        </div>
        <div className={orderError ? "" : "hidden"}>
          Kein Auftrag zum Pfad gefunden
        </div>
      </td>
    </tr>
  );
};
