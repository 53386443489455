import * as React from "react";
import { NavigationContext } from "./Sidebar";

const defaultStyle = "w-full block py-1";
const activeStyle = " font-bold bg-border bg-opacity-40";

export const useSubLevel = (link: string, isWrapper = false) => {
  const [className, setClassName] = React.useState(defaultStyle);
  const { currentActive, toggleOpen, isSmallDevice } =
    React.useContext(NavigationContext);

  const onClick = React.useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (isSmallDevice && toggleOpen) {
        toggleOpen();
      }
    },
    [isSmallDevice, toggleOpen]
  );

  React.useEffect(() => {
    if (!currentActive || !link) {
      return;
    }
    let style = defaultStyle;
    if (currentActive.startsWith(link)) {
      style += activeStyle;
    }

    if (isWrapper) {
      style += " p-2";
    } else {
      style += " p-1 pl-12";
    }

    setClassName(style);
  }, [currentActive, isWrapper, link, setClassName]);

  return { className, onClick };
};
