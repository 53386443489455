import { useEffect, useState } from "react";
import axios from "axios";

export const useGetVacation = (force: number) => {
  const [vacation, setVacation] = useState<string[]>();

  useEffect(() => {
    const process = async () => {
      const response = await axios.get<string[]>("/admin/vacation");

      setVacation(response.data);
    };

    process();
  }, [force, setVacation]);

  return vacation;
};
