import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, TButtonProps } from "../../Button";

type TWorkflowButtonProps = {
  icon: FontAwesomeIconProps["icon"];
  text: string;
  intent?: TButtonProps["intent"];
  disabled: boolean;
  onClick: () => void;
  hidden?: boolean;
};
export const WorkflowButton: React.FC<TWorkflowButtonProps> = ({
  icon,
  text,
  intent = "primary",
  disabled,
  onClick,
  hidden,
}) => {
  if (hidden) {
    return null;
  }

  return (
    <Button
      className="ml-2"
      intent={intent}
      buttonStyle="outline"
      disabled={disabled}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon} /> {text}
    </Button>
  );
};
