import { RouteComponentProps } from "@reach/router";
import React from "react";
import { MyData } from "../../components/MyData";
import { PasswordChange } from "../../components/PasswordChange";
import Tile from "../../components/Tile";

export const MyAccount: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <div className="grid grid-cols-2 gap-2">
      <Tile title="Meine Daten">
        <MyData />
      </Tile>
      <Tile title="Passwort ändern">
        <PasswordChange />
      </Tile>
    </div>
  );
};
