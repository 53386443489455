import { ALLOWED_POINT_CLOUD_STATES } from "../../config/workflow";
import { useEffect, useState } from "react";
import axios from "axios";

export const useSavePointCloudState = (
  orderId: number | null,
  workflowState: number | null,
  comment: string | null
) => {
  const [saving, setSaving] = useState<null | boolean>(null);

  useEffect(() => {
    if (
      !workflowState ||
      !orderId ||
      comment === null ||
      !ALLOWED_POINT_CLOUD_STATES.includes(workflowState)
    ) {
      setSaving(null);
      return;
    }

    const process = async () => {
      setSaving(true);
      try {
        await axios.post("/workflow/point-cloud/", {
          orderId,
          workflowState,
          comment,
        });
        // toast("Status erfolgreich gespeichert", { intent: "success" });
      } catch (err) {
        if (axios.isAxiosError(err)) {
          // toast("Fehler beim speichern des Status", { intent: "danger" });
        }
      } finally {
        setSaving(false);
      }
    };

    process();
  }, [orderId, workflowState, comment, setSaving]);
  return saving;
};
