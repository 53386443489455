import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { KanbanBoard } from "../../components/KanbanBoard/KanbanBoard";
import { TCols } from "../../config/measureBoard";
import { POINT_CLOUD_BOARD_CONFIG } from "../../config/pointCloudBoard";
import { useGetPointCloudBoard } from "../../hooks/board/useGetPointCloudBoard";
import { ROUTE_BUILDING } from "../../config/routes";
import { useSavePointCloudState } from "../../hooks/pointcloud/useSavePointCloudState";
import { TCalculateResult } from "../../components/KanbanBoard/useBoardFilter";
import { WorkflowComment } from "../../components/workflow/WorkflowComment";
import { connect } from "react-redux";
import { RootState } from "../../store";

type TPointCloudBoardProps = RouteComponentProps &
  ReturnType<typeof mapStateToProps>;

const mapStateToProps = (state: RootState) => ({
  companyType: state.user.companyType,
});

const PointCloudBoardComponent: React.FC<TPointCloudBoardProps> = ({
  companyType,
}) => {
  const [force, setForce] = useState(Date.now);
  const [orderId, setOrderId] = useState<number | null>(null);
  const [workflowState, setWorkflowState] = useState<number | null>(null);
  const [commentOpen, setCommentOpen] = useState(false);
  const [comment, setComment] = useState<null | string>(null);
  const [boardConfig, setBoardConfig] = useState<TCols[]>([]);
  const data = useGetPointCloudBoard(force);
  const saving = useSavePointCloudState(orderId, workflowState, comment);

  const onClick = useCallback((buildingId: number) => {
    window
      .open(
        `${window.location.origin}${ROUTE_BUILDING}${buildingId}/measure/?close`,
        "_blank"
      )
      ?.focus();
  }, []);

  useEffect(() => {
    if (companyType && POINT_CLOUD_BOARD_CONFIG[companyType]) {
      setBoardConfig(POINT_CLOUD_BOARD_CONFIG[companyType]);
    }
  }, [companyType, setBoardConfig]);

  useEffect(() => {
    if (saving === false) {
      setOrderId(null);
      setWorkflowState(null);
      setComment(null);
      setForce(Date.now());
    }
  }, [saving, setOrderId, setWorkflowState, setComment, setForce]);

  const onDrop = useCallback(
    (dropData: TCalculateResult, dropConfig: TCols) => {
      setOrderId(dropData?.orderId ?? null);
      setWorkflowState(dropConfig?.historyState ?? null);
      setCommentOpen(true);
    },
    [setCommentOpen]
  );

  if (data === null) {
    return null;
  }

  return (
    <div className="flex flex-col">
      <h1>Dora Projekt Board Punktwolke</h1>
      <WorkflowComment
        isOpen={commentOpen}
        setComment={setComment}
        onClose={() => setCommentOpen(false)}
        onCancel={() => setCommentOpen(false)}
      />
      <KanbanBoard
        onDrop={onDrop}
        data={data as any}
        config={boardConfig}
        onClick={onClick}
      />
    </div>
  );
};

export const PointCloudBoard = connect(mapStateToProps)(
  PointCloudBoardComponent
);
