import { RouteComponentProps } from "@reach/router";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReportDownload } from "../../components/ReportDownload/ReportDownload";
import { UserDownloads } from "../../components/UserDownload/UserDownload";
import {
  COMPANY_REPORTS,
  OFFICE_REPORTS,
  TReports,
} from "../../config/reports";
import { RootState } from "../../store";

type TDownloadProps = RouteComponentProps & ReturnType<typeof mapStateToProps>;

const DownloadsComponent: React.FunctionComponent<TDownloadProps> = ({
  companyName,
  companyType,
}) => {
  const [reports, setReports] = useState<TReports[]>([]);

  useEffect(() => {
    if (companyType === "smb") {
      return;
    }

    if (companyType === "firma") {
      setReports(COMPANY_REPORTS);
    } else if (companyType === "amt") {
      setReports(OFFICE_REPORTS);
    }
  }, [companyType, setReports]);

  if (companyType === "smb") {
    return null;
  }

  return (
    <>
      <h1>Downloads für {companyName}</h1>
      <ReportDownload reports={reports} />
      <UserDownloads />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyName: state.user.companyName,
  companyType: state.user.companyType,
});

export const Downloads = connect(mapStateToProps)(DownloadsComponent);
