import React from "react";
import { connect } from "react-redux";
import { RootState } from "../../store";

type TUsernameProps = ReturnType<typeof mapStateToProps>;

const UsernameComponent: React.FunctionComponent<TUsernameProps> = ({
  username,
  showLogin,
}) => {
  if (showLogin) {
    return null;
  }

  return <>Angemeldet als: {username}</>;
};

const mapStateToProps = (state: RootState) => ({
  username: `${state.user.firstname ?? ""} ${state.user.lastname ?? ""}`,
  showLogin: state.app.showLogin,
});
export const Username = connect(mapStateToProps)(UsernameComponent);
