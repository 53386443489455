import { IToastState, ToastActionTypes } from "./types";
export const TOAST_TRIGGER = "toaster/TRIGGER";
export const TOAST_CLEAR = "toaster/CLEAR";

export const setToastMessage = (data: IToastState): ToastActionTypes => {
  return {
    type: TOAST_TRIGGER,
    payload: data,
  };
};

export const clearToast = (): ToastActionTypes => {
  return {
    type: TOAST_CLEAR,
  };
};
