import React from "react";
import { TMigrationSuccessProps } from "./MigrationPointCloudSuccess";
import { MigrationWorkflowResult } from "./MigrationWorkflowResult";

export const MigrationWorkflowSucces: React.FC<TMigrationSuccessProps> = ({
  progress,
  data,
  template,
  errors,
}) => {
  if (progress || template !== "workflow") {
    return null;
  }

  if (data.length === 0) {
    return <h1>Es wurden keine Datensätze migriert</h1>;
  }
  return (
    <>
      Es wurden {data.length} Datensätze migriert. Es sind {errors.length}{" "}
      Fehler aufgetreten
      <a
        href="#errors"
        className={`smb-link}`}
        style={{
          display: !errors.length ? "none" : "",
        }}
      >
        Fehler
      </a>
      <div className="grid grid-cols-12 bg-head text-white p-1">
        <div>ID</div>
        <div className="col-span-5">Kommentar alt</div>
        <div className="col-span-4">Kommentar neu</div>
        <div className="text-center">Status alt</div>
        <div className="text-center">Status neu</div>
      </div>
      <div className="divide-y divide-gray-500">
        {data.map((item, index) => (
          <MigrationWorkflowResult
            {...item}
            key={`workflow-migration-${index}`}
          />
        ))}
      </div>
    </>
  );
};
