import * as React from "react";
import { NavigationContext } from "./Sidebar";
import { TSidebarConf } from "./types";

const noOpacity = 100;
const fullOpacity = 0;
const lightOpacity = 50;
export const useCategory = (
  props: TSidebarConf,
  ref: React.RefObject<HTMLLIElement>
) => {
  const {
    onClick,
    isOpen = false,
    currentActive,
    isSmallDevice = false,
  } = React.useContext(NavigationContext);

  const [popover, setPopover] = React.useState(false);
  const [top, setTop] = React.useState(0);
  const [left, setLeft] = React.useState(0);
  const [isActive, setActive] = React.useState(false);
  const [opacity, setOpacity] = React.useState<50 | 100>(lightOpacity);
  const [borderOpacity, setBorderOpacity] = React.useState<0 | 100>(
    fullOpacity
  );
  const onItemClick = React.useCallback(() => {
    if (onClick) {
      const link = props.children ? props.children[0].link : props.link;
      if (!link) {
        return;
      }
      onClick(link);
      setPopover(false);
    }
  }, [props, onClick, setPopover]);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }

    const pos = ref.current.getBoundingClientRect();
    setTop(pos.top);
    setLeft(pos.right);
  }, [ref, setTop]);

  React.useEffect(() => {
    if (!currentActive) {
      return;
    }

    if (
      (props.active && currentActive.startsWith(props.active)) ||
      (props.link && currentActive.startsWith(props.link))
    ) {
      setActive(true);
      setOpacity(noOpacity);
      setBorderOpacity(noOpacity);
    } else {
      setOpacity(lightOpacity);
      setActive(false);
      setBorderOpacity(fullOpacity);
    }
  }, [
    props.link,
    props.active,
    currentActive,
    setActive,
    setOpacity,
    setBorderOpacity,
  ]);

  const onMouseEnter = React.useCallback(() => {
    if ((isActive && isOpen) || isSmallDevice) {
      return;
    }

    setPopover(true);
  }, [isActive, isSmallDevice, isOpen]);

  const onMouseLeave = React.useCallback(() => {
    if (isSmallDevice) {
      return;
    }

    setPopover(false);
  }, [isSmallDevice]);

  return {
    borderOpacity,
    opacity,
    isActive,
    isOpen,
    popover,
    left,
    top,
    onItemClick,
    onMouseEnter,
    onMouseLeave,
  };
};
