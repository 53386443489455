export type TMigrationResultTemplate = "pointCloud" | "workflow" | "message";

export type TMigrateItem = {
  route: string;
  description: JSX.Element;
  message: string;
  template: TMigrationResultTemplate;
};

export const MIGRATION: TMigrateItem[] = [
  {
    route: "/admin/migration/point-cloud/",
    description: (
      <>
        Diese Script durchsucht alle Neu/Nachaufmaßaufträge ob diese einen
        Punktwolkenauftrag benötigt. Das Kriterium ist, ob der Auftrag eine
        Detailstufe III hat.
      </>
    ),
    message: "Punktwolken migration",
    template: "pointCloud",
  },
  {
    route: "/admin/migration/workflow-comment/",
    description: (
      <>
        Dieses Script migriert die history Tabelle zur workflow Tabelle und
        entfernt die Benutzernamen dabei aus den Kommentaren
      </>
    ),
    message: "Workflowkommentare",
    template: "workflow",
  },
  {
    route: `/admin/migration/test-data/`,
    description: (
      <>
        Dieses Script anonymisiert die Daten der realen User um einen
        Datenbestand zu bekommen, der dem Live System entspricht, aber DSVGO
        konform. Ist nur auf Testing und Dev verfügbar.
      </>
    ),
    message: "Anonymisierung",
    template: "message",
  },
];
