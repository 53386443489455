import React from "react";
import { ROUTE_BUILDING } from "../../../config/routes";
import { POINT_CLOUD_TAB } from "../../../config/tabs";

type TPointCloudLinkProps = {
  buildingId?: number;
  exists?: boolean;
};

export const PointCloudLink: React.FC<TPointCloudLinkProps> = ({
  buildingId,
  exists,
}) => {
  if (!buildingId || !exists) {
    return <></>;
  }

  return (
    <a
      className="smb-link"
      href={`${ROUTE_BUILDING}${buildingId}/${POINT_CLOUD_TAB}/`}
      target="_blank"
      rel="noreferrer"
    >
      Zum Objekt
    </a>
  );
};
