import * as React from "react";
import { Category } from "./Category";
import { NavigationMobileWrapper } from "./MobileWrapper";
import { Toggler } from "./Toggler";
import { TNavigationContext, TSidebarConf, TSidebarProps } from "./types";
import { defaultClosedWidth, useSidebarComponent } from "./useSidebarComponent";
export const navigationSmallDeviceWidth = 640;
export const NavigationContext = React.createContext<
  Partial<TNavigationContext>
>({});

export * from "./types";
export const ICON_SIZE = "45px";
export const ICON_SIZE_CLASS_NAME = 4;

export const Sidebar: React.FunctionComponent<TSidebarProps> = ({ config }) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { isOpen, isSmallDevice, currentActive, toggleOpen, onClick } =
    useSidebarComponent(ref);

  const content = (
    <div
      ref={ref}
      style={{ width: isSmallDevice ? 0 : `${defaultClosedWidth}px` }}
      data-testid="sidebar"
      className={`border-r border-border smb-nav-bg h-full transition-all overflow-hidden flex flex-col relative duration-250 delay-75`}
    >
      <NavigationContext.Provider
        value={{
          isOpen,
          isSmallDevice,
          currentActive,
          onClick,
          toggleOpen,
        }}
      >
        <div className="flex-grow overflow-y-auto overflow-x-hidden">
          <ul className="transition-none">
            {config.map((c: TSidebarConf, i) => (
              <Category {...c} key={`sidebar-navitem-${i}`} />
            ))}
          </ul>
        </div>
        <Toggler toggle={() => toggleOpen()} />
      </NavigationContext.Provider>
    </div>
  );
  if (isSmallDevice) {
    return (
      <NavigationMobileWrapper isOpen={isOpen}>
        {content}
      </NavigationMobileWrapper>
    );
  }

  return content;
};
