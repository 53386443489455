import { TBoardResponse } from "../hooks/measure/useGetMeasureBoardData";
import {
  MEASURE_STATE_DECLINED,
  MEASURE_STATE_PROCESS,
  MEASURE_STATE_PTS_CORRECTED,
  MEASURE_STATE_PTS_UPLOAD,
  MEASURE_STATE_SUCCESS,
} from "./workflow";
export type TDataKey = keyof TBoardResponse;

export type TCols = {
  header: string;
  headerSub?: string;
  dataKey: TDataKey;
  targetId: any;
  historyType?: string;
  historyState?: number;
  transferId?: number | null;
  dragTargets?: any[];
  isDraggable?: boolean;
  useCondition?: "companyId";
  canSetTarget?: boolean;
  canSetTransferId?: boolean;
  force?: boolean;
  displayNoEditor?: boolean;
  displayNrf?: boolean;
  displayBgStatus?: boolean;
  cardType: "ptsUpload" | "measureState" | "pointCloud" | "smbAssign";
};

export type TBoardConfig = {
  firma: TCols[];
  amt: TCols[];
  leitung: TCols[];
  smb: TCols[];
};

const ptsUploaded: TCols = {
  header: `PTS Upload `,
  dataKey: "psPtsUploaded",
  targetId: "ptsUploded",
  cardType: "measureState",
};

const qsOffice: TCols = {
  header: `Plausibilitätsprüfung`,
  dataKey: "psStarted",
  targetId: "psStart",
  cardType: "measureState",
};

const qsOfficeRejected: TCols = {
  header: `Plausibilitätsprüfung Fehlerhaft`,
  dataKey: "psRejected",
  targetId: "psReject",
  cardType: "measureState",
};

const qsWaiting: TCols = {
  header: `Wartet auf Qualitätsprüfung`,
  dataKey: "qsWaiting",
  targetId: "psSuccess",
  cardType: "measureState",
};

const qsWorking: TCols = {
  header: `Qualitätsprüfung in Bearbeitung`,
  dataKey: "qsStarted",
  targetId: "qsStart",
  cardType: "measureState",
};

const qsRejected: TCols = {
  header: `Qualitätsprüfung Fehlerhaft`,
  dataKey: "qsRejected",
  targetId: "qsReject",
  cardType: "measureState",
};

const qsSuccess: TCols = {
  header: `Qualitätsprüfung Erfolgreich`,
  dataKey: "success",
  targetId: "qsSuccess",
  cardType: "measureState",
};

export const MEASURE_BOARD_CONFIG: TBoardConfig = {
  firma: [
    {
      header: `Wartet auf PTS Upload `,
      dataKey: "notStarted",
      targetId: "notStarted",
      isDraggable: true,
      dragTargets: ["ptsUploded"],
      canSetTransferId: true,
      canSetTarget: true,
      cardType: "ptsUpload",
    },
    {
      ...ptsUploaded,
      historyType: "pts-upload/plausibilityCheck",
      historyState: MEASURE_STATE_PTS_UPLOAD,
      canSetTransferId: true,
      canSetTarget: true,
      force: true,
    },
    {
      ...qsOffice,
      historyType: "pts-upload/plausibilityCheck",
      historyState: MEASURE_STATE_PTS_CORRECTED,
      force: true,
    },
    {
      ...qsOfficeRejected,
      isDraggable: true,
      dragTargets: ["psStart"],
      canSetTransferId: true,
      canSetTarget: true,
    },
    {
      ...qsWaiting,
    },
    {
      ...qsWorking,
      historyType: "pts-upload/qsCheck",
      historyState: MEASURE_STATE_PTS_CORRECTED,
      force: true,
    },
    {
      ...qsRejected,
      isDraggable: true,
      dragTargets: ["qsStart"],
      canSetTransferId: true,
      canSetTarget: true,
      force: true,
    },
    {
      ...qsSuccess,
    },
  ],
  amt: [
    {
      header: `Wartet auf PTS Upload `,
      dataKey: "notStarted",
      targetId: "notStarted",
      cardType: "ptsUpload",
    },
    {
      ...ptsUploaded,
      isDraggable: true,
      dragTargets: ["psStart"],
      canSetTarget: true,
      canSetTransferId: true,
    },
    {
      ...qsOffice,
      isDraggable: true,
      dragTargets: ["psReject", "psSuccess"],
      canSetTransferId: true,
      canSetTarget: true,
      historyType: "plausiCheck",
      historyState: MEASURE_STATE_PROCESS,
      force: true,
    },
    {
      ...qsOfficeRejected,
      historyType: "plausiCheck",
      historyState: MEASURE_STATE_DECLINED,
      force: true,
    },
    {
      ...qsWaiting,
      historyType: "plausiCheck",
      historyState: MEASURE_STATE_SUCCESS,
      force: true,
      useCondition: "companyId",
    },
    {
      ...qsWorking,
      useCondition: "companyId",
    },
    {
      ...qsRejected,
    },
    {
      ...qsSuccess,
    },
  ],
  leitung: [
    {
      ...ptsUploaded,
    },
    {
      ...qsOffice,
    },
    {
      ...qsOfficeRejected,
    },
    {
      ...qsWaiting,
    },
    {
      ...qsWorking,
    },
    {
      ...qsRejected,
    },
    {
      ...qsSuccess,
    },
  ],
  smb: [
    {
      header: `Wartet auf PTS Upload `,
      dataKey: "notStarted",
      targetId: "notStarted",
      cardType: "ptsUpload",
    },
    {
      ...ptsUploaded,
    },
    {
      ...qsOffice,
    },
    {
      ...qsOfficeRejected,
    },
    {
      ...qsWaiting,
      dragTargets: ["qsStart"],
      isDraggable: false,
      useCondition: "companyId",
      displayNoEditor: true,
      canSetTarget: true,
      canSetTransferId: true,
    },
    {
      ...qsWorking,
      isDraggable: false,
      useCondition: "companyId",
      dragTargets: ["qsSuccess", "qsReject"],
      canSetTransferId: true,
      canSetTarget: true,
      historyType: "qsCheck",
      historyState: MEASURE_STATE_PROCESS,
      force: true,
      displayNoEditor: true,
    },
    {
      ...qsRejected,
      historyType: "qsCheck",
      historyState: MEASURE_STATE_DECLINED,
      force: true,
    },
    {
      ...qsSuccess,
      historyType: "qsCheck",
      historyState: MEASURE_STATE_SUCCESS,
      force: true,
    },
  ],
};
