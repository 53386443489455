import Axios from "axios";
import { useEffect, useState } from "react";

export type TSystemUser = {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  companyName: string;
  companyType: string;
  workflowReminder: "YES" | "NO" | null;
};

export const useGetUsers = (force = false) => {
  const [isFetch, setFetch] = useState(false);
  const [state, setState] = useState<{ user: TSystemUser[]; count: number }>({
    user: [],
    count: 0,
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        const user = await Axios.get("/admin/user");
        setFetch(true);
        setState({
          user: user.data.map((u: TSystemUser) =>
            u.companyType === "amt"
              ? {
                  ...u,
                }
              : { ...u, workflowReminder: null }
          ),
          count: user.data.length,
        });
      } catch (e) {}
    };

    if (state.user.length === 0 || force) {
      fetch();
    }
  }, [force, state, setState, isFetch]);

  return state;
};
