type TGridCellProps = {
  label: string;
  colSpan?: number;
};
export const GridCell: React.FC<TGridCellProps> = ({
  label,
  colSpan = 0,
  children,
}) => {
  return (
    <div className={`col-span-${colSpan}`} style={{ minHeight: "47px" }}>
      <div className={"mr-2 font-medium border-b"}>{label}</div>
      {children}
    </div>
  );
};
