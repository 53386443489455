import React, { useState } from "react";

type TInputCheckboxProps = {
  value: boolean;
  canEdit: boolean;

  onChange: (val: boolean) => void;
  readonlyValue?: string;
};
export const InputCheckbox: React.FC<TInputCheckboxProps> = ({
  value,
  canEdit,
  readonlyValue = "",
  onChange,
}) => {
  const [isChecked, setChecked] = useState(value);

  if (!canEdit) {
    return <>{readonlyValue}</>;
  }

  return (
    <>
      <input
        type={"checkbox"}
        checked={isChecked}
        onChange={(e) => {
          setChecked(e.target.checked);
          onChange(e.target.checked);
        }}
      />
    </>
  );
};
