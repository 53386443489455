import React, { useCallback, useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { useGetVacation } from "../../../hooks/admin/useGetVacation";
import { InputDate } from "../../../components/InputDate/InputDate";
import { Button } from "../../../components/Button";
import moment from "moment";
import axios from "axios";

export const Vacation: React.FC<RouteComponentProps> = () => {
  const [force, setForce] = useState(Date.now());
  const vacation = useGetVacation(force);
  const [saveActive, setSaveActive] = useState(false);
  const [newVacation, setNewVacation] = useState<string | null>(null);

  const saveVacation = useCallback(async () => {
    if (!newVacation) {
      setSaveActive(false);
      return;
    }
    await axios.post("/admin/vacation/", {
      day: newVacation,
    });

    setNewVacation(null);
    setSaveActive(false);
    setForce(Date.now());
  }, [newVacation, setSaveActive, setNewVacation, setForce]);

  const deleteVacation = useCallback(
    async (date: string) => {
      const reqDate = moment(date, "DD.MM.YYYY").format("YYYY-MM-DD");

      await axios.delete(`/admin/vacation/${reqDate}/`);
      setForce(Date.now());
    },
    [setForce]
  );

  return (
    <>
      <h1>Urlaubstage</h1>
      <div>
        Folgende Tage, werden bei der Berechnung der Arbeitszeit nicht
        berücksichtigt
      </div>
      <div className={"flex my-5"}>
        <InputDate
          value={newVacation ?? ""}
          onChange={(data) => {
            setNewVacation(data);
            setSaveActive(!!data);
          }}
        />
        <Button
          disabled={!saveActive}
          className={"ml-2"}
          onClick={saveVacation}
        >
          Speichern
        </Button>
      </div>

      <div className={"flex w-full overflow-hidden flex-wrap"}>
        {vacation?.map((data, index) => (
          <div
            onClick={() => deleteVacation(data)}
            key={`vacation-${index}`}
            className={
              "odd:bg-gray-300 even:bg-white flex border border-gray-500 hover:bg-red-500 hover:cursor-pointer"
            }
            style={{ width: "160px" }}
          >
            <div className={"p-2"}>{data}</div>
            <div className={"p-2"}>Delete</div>
          </div>
        ))}
      </div>
    </>
  );
};
