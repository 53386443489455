import { useCallback } from "react";
import {
  LOCAL_STORAGE_LIMIT,
  LOCAL_STORAGE_ORDER_DIRECTION,
  LOCAL_STORAGE_ORDER_FIELD,
  LOCAL_STORAGE_PAGE,
  LOCAL_STORAGE_SEARCH_TERM,
} from "../../config/dataTable";
type TTableCallbackProps = {
  setLimit: (val: number) => void;
  setCurrentPage: (val: number) => void;
  setDirection: (val: "DESC" | "ASC") => void;
  setOrderField: (val: string) => void;
  setSearchTerm: (val: string) => void;
};

export const useTableCallbacks = (callbacks: TTableCallbackProps) => {
  const {
    setLimit,
    setCurrentPage,
    setDirection,
    setOrderField,
    setSearchTerm,
  } = callbacks;

  const updateLimit = useCallback(
    (newLimit: number) => {
      localStorage.setItem(LOCAL_STORAGE_LIMIT, newLimit.toString());
      setLimit(newLimit);
    },
    [setLimit]
  );

  const updateCurrentPage = useCallback(
    (page: number) => {
      localStorage.setItem(LOCAL_STORAGE_PAGE, page.toString());
      setCurrentPage(page);
    },
    [setCurrentPage]
  );

  const updateOrderDirection = useCallback(
    (value: "ASC" | "DESC") => {
      localStorage.setItem(LOCAL_STORAGE_ORDER_DIRECTION, value);
      setDirection(value);
    },
    [setDirection]
  );

  const updateOrderField = useCallback(
    (value: string) => {
      localStorage.setItem(LOCAL_STORAGE_ORDER_FIELD, value);
      setOrderField(value);
    },
    [setOrderField]
  );

  const updateSearchTerm = useCallback(
    (value: string) => {
      localStorage.setItem(LOCAL_STORAGE_SEARCH_TERM, value);
      updateCurrentPage(0);
      setSearchTerm(value);
    },
    [setSearchTerm, updateCurrentPage]
  );

  return {
    updateLimit,
    updateCurrentPage,
    updateOrderDirection,
    updateOrderField,
    updateSearchTerm,
  };
};
