import React from "react";
import { isSequelizeError } from "./typeGuards/sequelizeGuard";
import { SqlError } from "./SqlError";

export const DisplayError: React.FC<{ error: any }> = ({ error }) => {
  let display;

  if (typeof error === "string") {
    display = JSON.parse(error);
  } else {
    display = error;
  }

  if (isSequelizeError(display)) {
    return <SqlError {...display} />;
  }
  if (Object.keys(display).includes("message")) {
    return (
      <div className="text-center mt-4 bg-warning p-10 font-bold text-lg">
        {error.message}
      </div>
    );
  }
  return <pre>{JSON.stringify(error, null, 4)}</pre>;
};
