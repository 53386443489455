import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BUILDING_INFORMATION } from "../../config/buildingInfo";
import { useGetBuildingById } from "../../hooks/useBuildingById";
import { InfoLabel } from "./InfoLabel";
import { useCallback, useEffect, useState } from "react";
import { Button } from "../Button";
type TBuildingInfoProps = {
  id: number;
};

export const BuildingInfo: React.FC<TBuildingInfoProps> = ({ id }) => {
  const buildingData = useGetBuildingById(id);
  const [buttonText, setButtonText] = useState("zurück");

  useEffect(() => {
    if (!id) {
      return;
    }

    if (window.location.search === "?close") {
      setButtonText("zum Board");
    }
  }, [id, setButtonText]);

  const goBack = useCallback(() => {
    if (window.location.search && window.location.search === `?close`) {
      window.close();
    } else {
      window.history.back();
    }
  }, []);

  if (!buildingData) {
    return null;
  }

  let cols = 0;
  return (
    <div className="w-full border-border border rounded relative">
      <div className="absolute" style={{ left: "2px", top: "1px" }}>
        <Button intent="success" onClick={goBack}>
          <FontAwesomeIcon icon={faAngleDoubleLeft} className="py-0" />{" "}
          {buttonText}
        </Button>
      </div>
      <div className="bg-header h-8 pl-28 text-base font-semibold leading-9 overflow-hidden">
        Gebäude
      </div>

      <div className="grid gap-5 grid-cols-5 p-2">
        {BUILDING_INFORMATION.map((conf, i) => {
          cols += conf.colspan ?? 1;
          return (
            <InfoLabel
              key={`building-info-${i}`}
              label={conf.label}
              data={buildingData[conf.data]}
              copy={conf.copy}
              formatter={conf.formatter}
              colspan={conf.colspan}
              cols={cols}
            />
          );
        })}
      </div>
    </div>
  );
};
