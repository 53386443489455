import { TReports, TReportType } from "../../config/reports";
import { Button } from "../Button";

type TReportRow = {
  report: TReports;
  inProgress: boolean;
  onClick: (type: TReportType) => void;
};

export const ReportRow: React.FunctionComponent<TReportRow> = ({
  report,
  inProgress,
  onClick,
}) => {
  return (
    <div className="flex p-1">
      <div className="flex-1">{report.text}</div>
      <div style={{ width: "150px" }}>
        <Button
          icon="CLOUD_DOWNLOAD_ALT_SOLID"
          buttonStyle="elevated"
          onClick={() => onClick(report.report)}
          disabled={inProgress}
          text="Herunterladen"
        />
      </div>
    </div>
  );
};
