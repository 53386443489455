import React from "react";
import { IFormatterTypes } from "../../config/dataTable";
import { FormatValue } from "../../helper/formatter";

export type TDataValueProps = {
  index: number;
  formatter?: IFormatterTypes;
  value: any;
  hover: number | null;
  setHover: (i: number | null) => void;
  onCellClick?: (i: number) => void;
};

export const DataValue: React.FunctionComponent<TDataValueProps> = ({
  index,
  formatter = "Text",
  value,
  hover,
  setHover,
  onCellClick,
}) => {
  const hasCellClick = typeof onCellClick === "function";

  return (
    <div
      className={`h-6 border-b border-border p-1 text-xs truncate overflow-ellipsis overflow-hidden cell${
        hover === index ? " bg-hover cell-hover" : ""
      }${hasCellClick ? " cursor-pointer" : ""}`}
      onMouseOver={() => {
        if (typeof setHover === "function") {
          setHover(index);
        }
      }}
      onMouseOut={() => {
        if (typeof setHover === "function") {
          setHover(null);
        }
      }}
      onClick={() => {
        if (typeof onCellClick === "function") {
          onCellClick(index);
        }
      }}
      title={formatter === "Text" ? value : ""}
    >
      {FormatValue(formatter, value)}
    </div>
  );
};
