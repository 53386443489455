import { TDataTableFormatterTypes } from "../components/DataTable/DataTable";
import { TBuildingResponse } from "../hooks/useBuildingById";

type TBuildingInfo = {
  label: string;
  data: keyof TBuildingResponse;
  copy: boolean;
  formatter?: TDataTableFormatterTypes;
  colspan?: number;
};

export const BUILDING_INFORMATION: TBuildingInfo[] = [
  {
    label: "MORADA Pfad",
    data: "path",
    copy: true,
    colspan: 3,
  },
  {
    label: "Adresse",
    data: "address",
    copy: false,
    colspan: 2,
  },
  {
    label: "Bezeichnung",
    data: "designation",
    copy: false,
    colspan: 2,
  },
  {
    label: "WE-Nr",
    data: "economicUnit",
    copy: true,
    formatter: "LeadingZero",
  },
  {
    label: "Geb-Nr",
    data: "buildingNumber",
    copy: true,
    formatter: "LeadingZero",
  },

  {
    label: "Zuständige Abteilung",
    data: "responsibleDepartment",
    copy: false,
  },
];
