import moment from "moment";
import React from "react";
import { IToken } from ".";

const TokenRow: React.FunctionComponent<IToken> = ({
  description,
  createdAt,
  read,
  write,
}) => {
  return (
    <div className="flex py-2 border-b border-border">
      <div className="flex-1">{description}</div>
      <div className="w-24">{read}</div>
      <div className="w-28">{write}</div>
      <div className="w-24">
        {moment.parseZone(createdAt).format("DD.MM.YYYY")}
      </div>
    </div>
  );
};

export default TokenRow;
