import { useEffect, useState } from "react";
import axios from "axios";

export const useVerifyBuilding = (path = "", force = 0) => {
  const [exists, setExists] = useState<null | boolean>(null);
  const [buildingId, setBuildingId] = useState<number>();

  useEffect(() => {
    if (!path) {
      return;
    }

    const process = async () => {
      try {
        const response = await axios.get<{ buildingId: number }>(
          `/verify/building/?path=${path}`
        );
        setBuildingId(response.data.buildingId);
        setExists(true);
      } catch (e) {
        setExists(false);
      }
    };

    process();
  }, [path, force, setExists]);

  return { buildingExists: exists, buildingId };
};
