import * as React from "react";
import "../css/spinner.css";

type TSpinnerWidth = {
  [key: string]: number;
};

const SPINNER_WIDTH: TSpinnerWidth = {
  small: 1,
  large: 7,
  xlarge: 10,
};

export const SPINNER_SIZE = {
  small: 15,
  normal: 80,
  large: 150,
  xlarge: 250,
};

export type TSpinnerProps = {
  size?: keyof typeof SPINNER_SIZE;
  color?: string;
  animationColor?: string;
  center?: boolean;
  testId?: string;
};

export const Spinner: React.FunctionComponent<TSpinnerProps> = ({
  size = "normal",
  color = "#fff",
  animationColor = "#000",
  center = false,
  testId = "spinner",
}) => {
  const spinnerSize = SPINNER_SIZE[size];
  let borderWidth = 5;
  if (Object.keys(SPINNER_WIDTH).includes(size)) {
    borderWidth = SPINNER_WIDTH[size];
  }

  let style: React.CSSProperties = {};
  if (center) {
    style = {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      position: "absolute",
    };
  }

  return (
    <div
      data-testid={testId}
      style={{
        width: `${spinnerSize}px`,
        height: `${spinnerSize}px`,
        ...style,
      }}
    >
      <div
        id="spinner"
        style={{
          border: `${borderWidth}px solid ${color}`,
          borderTop: `${borderWidth}px solid ${animationColor}`,
        }}
      ></div>
    </div>
  );
};
