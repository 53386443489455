import { ITableHeader, TABLE_CONFIG } from "../../config/dataTable";
import { TFilterActions } from "../../config/quickFilter";
import { axiosSaveUserSettings } from "../../helper/axiosSaveUserSettings";
import { TMeasureDetailResponse } from "../editOrder/types";
import {
  SETTINGS_DETAIL_CLOSE,
  SETTINGS_DETAIL_OPEN,
  SETTINGS_DETAIL_TABLE_CELL_VISIBILITY,
  SETTINGS_DETAIL_TABLE_PAGE,
  SETTINGS_FILTER,
  SETTINGS_FILTER_ORDER,
  SETTINGS_HOME_DATA,
  SETTINGS_HOME_TIP,
  SETTINGS_LIMIT_DATA_TABLE,
  SETTINGS_RESTORE,
  TSettingActions,
} from "./types";

export type TSettingsState = {
  homeTip: boolean;
  homeData: TFilterActions | "default";
  filter: TFilterSettings;
  dataTableLimit: number;
  detailClosed: TDetailClosed[];
  detailTableConf: ITableHeader[];
  detailTablePage: number;
};

export type TDetailClosed = string;
// | "Order"
// | "Position"
// | "Comments"
// | "DataExchange"
// | "PCheck"
// | "QCheck"
// | "Delete"
// | "Bill" ;

export type TFilterOperator =
  | "like"
  | "notlike"
  | "gt"
  | "gte"
  | "lt"
  | "lte"
  | "eq"
  | "ne"
  | "startsWith"
  | "endsWith"
  | "";

export type TFilterFields =
  | keyof TMeasureDetailResponse
  | "qsWork"
  | "qsTeam"
  | "qsMyWork"
  | "fulltext"
  | "plausiWork"
  | null;

export type TOrderFields =
  | keyof TMeasureDetailResponse
  | "lastPtsUpload"
  | "orderNumber"
  | "los"
  | "dataExchangeDate"
  | "plausibilityCheck"
  | "qsSuccess";

export type TFilterSettings = {
  filterField: TFilterFields;
  filterTerm: string | number | null;
  useFilter: boolean;
  filterOpen?: boolean;
  operator: TFilterOperator;
  order: "DESC" | "ASC";
  orderField: TOrderFields;
};

export const DEFAULT_FILTER: Readonly<TFilterSettings> = {
  filterField: null,
  filterTerm: null,
  useFilter: false,
  filterOpen: false,
  operator: "",
  order: "DESC",
  orderField: "orderNumber",
};

const initialState: TSettingsState = {
  homeTip: true,
  homeData: "pts",
  dataTableLimit: 30,
  filter: { ...DEFAULT_FILTER },
  detailClosed: ["Comments"],
  detailTableConf: TABLE_CONFIG,
  detailTablePage: 0,
};

export function settingReducer(
  state = initialState,
  action: TSettingActions
): TSettingsState {
  const newState = { ...state };
  switch (action.type) {
    case SETTINGS_RESTORE:
      return {
        ...state,
        ...action.payload,
        detailTableConf: TABLE_CONFIG.map((val) => {
          if (action.payload.detailTableConf) {
            const stored = action.payload.detailTableConf.find(
              (v) => v.dataIndex === val.dataIndex
            );
            if (stored) {
              return {
                ...stored,
                formatter: val.formatter,
                dataIndex: val.dataIndex,
                size: val.size,
                sortAble: val.sortAble,
                name: val.name,
              };
            }
          }
          return val;
        }),
      };

    case SETTINGS_HOME_TIP:
      newState.homeTip = action.payload;
      break;

    case SETTINGS_HOME_DATA:
      newState.homeData = action.payload;
      break;

    case SETTINGS_FILTER:
      newState.filter = action.payload;
      break;

    case SETTINGS_FILTER_ORDER:
      newState.filter = {
        ...newState.filter,
        ...action.payload,
      };
      break;

    case SETTINGS_DETAIL_OPEN:
      newState.detailClosed = state.detailClosed.filter(
        (item) => item !== action.payload
      );
      break;

    case SETTINGS_DETAIL_CLOSE:
      newState.detailClosed = [...state.detailClosed, action.payload];
      break;
    // Detailtable
    case SETTINGS_LIMIT_DATA_TABLE:
      newState.dataTableLimit = action.payload;
      break;

    case SETTINGS_DETAIL_TABLE_PAGE:
      newState.detailTablePage = action.payload;
      break;

    case SETTINGS_DETAIL_TABLE_CELL_VISIBILITY:
      newState.detailTableConf = state.detailTableConf.map((item) => {
        if (item.dataIndex === action.payload) {
          return {
            ...item,
            hidden: !item.hidden,
          };
        }

        return item;
      });
      break;
    default:
      return state;
  }
  axiosSaveUserSettings(newState);
  return newState;
}
