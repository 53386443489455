import moment from "moment";
import { useCallback, useEffect, useState } from "react";

type TMaintanace = {
  date: string;
  duration: number;
  end?: string;
};

const maintanaceInterval = 1000 * 60 * 5; // 5 Minutes

const myHeaders = new Headers();
myHeaders.append("pragma", "no-cache");
myHeaders.append("cache-control", "no-cache");
myHeaders.append("Expires", "0");
const myInit = {
  method: "GET",
  headers: myHeaders,
};

const storageKey = "maintainanceBanner";

export const useMaintanace = (fetchId = "") => {
  const [maintenance, setMaintenance] = useState<TMaintanace | null>(null);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [isBanner, setIsBanner] = useState(false);
  const [intervalId, setIntervalId] = useState<number>();

  const fetchMaintenance = useCallback(async () => {
    const response = await fetch("/maintanace.json", myInit).then((res) =>
      res.json()
    );

    if (
      response.hasOwnProperty("date") &&
      response.hasOwnProperty("duration")
    ) {
      setMaintenance({
        date: response.date,
        duration: response.duration,
      });
    }
  }, [setMaintenance]);

  useEffect(() => {
    if (!sessionStorage.getItem(storageKey)) {
      fetchMaintenance();
      setIntervalId(window.setInterval(fetchMaintenance, maintanaceInterval));
    }
    return () => {
      window.clearInterval(maintanaceInterval);
    };
  }, [fetchId, setIsBanner, fetchMaintenance]);

  const closeBanner = useCallback(() => {
    clearInterval(intervalId);
    setIntervalId(undefined);
    setIsBanner(false);
    sessionStorage.setItem(storageKey, "closed");
  }, [intervalId, setIntervalId, setIsBanner]);

  useEffect(() => {
    if (maintenance === null || maintenance.end) {
      return;
    }

    if (moment(maintenance.date).isAfter(moment())) {
      setIsBanner(true);
      setIsMaintenance(false);
    } else if (
      moment(maintenance.date).add(maintenance.duration, "h").isAfter(moment())
    ) {
      setMaintenance({
        ...maintenance,
        end: moment(maintenance.date)
          .add(maintenance.duration, "h")
          .format("HH:mm"),
      });
      setIsBanner(false);
      setIsMaintenance(true);
    } else {
      setIsBanner(false);
      setIsMaintenance(false);
    }
  }, [maintenance, setIsBanner, setIsMaintenance, setMaintenance]);

  return {
    maintenance,
    isMaintenance,
    isBanner,
    closeBanner,
    setIsMaintenance,
  };
};
