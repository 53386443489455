import React from "react";

export type TRowIndex = {
  count?: number | null;
  displayIndex?: boolean;
};

export const RowIndex: React.FunctionComponent<TRowIndex> = ({
  count,
  displayIndex,
}) => {
  if (!count || count === 0 || !displayIndex) {
    return null;
  }

  const rows = Array.from(Array(count).keys());

  return (
    <div className="border-border border-r">
      <div className="w-6 h-6 border-border border-b"></div>
      {rows.map((row, i) => (
        <div
          key={`data-row-${i}`}
          className="w-6 h-6 text-center border-border border-b p-1 bg-gray-300 text-xs"
        >
          {row + 1}
        </div>
      ))}
    </div>
  );
};
