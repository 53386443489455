import React from "react";
import { numberFormatter } from "../../helper/formatter";

type TDisplayNrfProps = {
  displayNrf?: boolean;
  nrf?: number;
};

export const DisplayNrf: React.FC<TDisplayNrfProps> = ({
  displayNrf,
  nrf = 0,
}) => {
  if (!displayNrf) {
    return null;
  }

  return <div>NRF: {numberFormatter(nrf)}m²</div>;
};
