import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { bytesToSize } from "../../../helper/bytesTosize";
import { TFileResult } from "../../../types/files";
import { Spinner } from "../../../components/Spinner";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";

const roles: TFileResult["forRole"][] = ["smb", "amt", "firma", "leitung"];

type TAddFileProps = {
  onClose: () => void;
  force: () => void;
  fileData: TFileResult | null;
};

function isValidRule(val: string): TFileResult["forRole"] {
  if (roles.includes(val as TFileResult["forRole"])) {
    return val as TFileResult["forRole"];
  }

  return null;
}

export const AddFile: React.FC<TAddFileProps> = ({
  fileData,
  onClose,
  force,
}) => {
  const [displayedName, setDisplayedName] = useState("");
  const [fileSize, setFileSize] = useState<number>(0);
  const [fileContent, setFileContent] = useState<string | undefined>();
  const [fileType, setFileType] = useState("");
  const [progress, setProgress] = useState(false);
  const [uploaded, setUploaded] = useState(0);
  const [fileProcess, setFileProcess] = useState(false);
  const [forRole, setRole] = useState<TFileResult["forRole"]>(null);
  const [fileInfo, setFileInfo] = useState("");

  useEffect(() => {
    if (fileData === null) {
      return;
    }
    setFileSize(fileData.size);
    setRole(fileData.forRole);
    setDisplayedName(fileData.fileName);
    setFileType("");
    setRole(fileData.forRole);
    setFileInfo(fileData.info ?? "");
  }, [fileData, setFileSize, setRole, setFileType]);

  const close = useCallback(() => {
    setDisplayedName("");
    setFileSize(0);
    setFileContent(undefined);
    onClose();
  }, [onClose, setDisplayedName, setFileSize, setFileContent]);

  const onFileLoad = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files;
      if (!files || files.length > 1) {
        return;
      }
      const file = files[0];
      const reader = new FileReader();

      reader.onload = (re: ProgressEvent<FileReader>) => {
        if (!re.target) {
          return;
        }
        setFileContent(reader.result as string);
        setFileProcess(false);
      };
      setFileProcess(true);
      reader.readAsDataURL(file);
      setDisplayedName(file.name);
      setFileSize(file.size);
      setFileType(file.type);
    },
    [setDisplayedName, setFileSize, setFileType, setFileContent]
  );

  const upload = () => {
    const process = async () => {
      await axios.post(
        "/admin/files/",
        {
          displayedName,
          fileSize,
          fileContent,
          fileType,
          forRole,
          fileInfo,
        },
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploaded(percentCompleted);
          },
        }
      );

      force();
      setUploaded(0);
      setProgress(false);
      onClose();
    };

    process();
  };

  const update = () => {
    const process = async () => {
      if (!fileData) {
        return;
      }

      await axios.put("/admin/files/", {
        displayedName,
        fileSize,
        fileContent,
        fileType,
        forRole,
        id: fileData.id,
        fileInfo,
      });
      setProgress(false);
      close();
    };
    process();
  };

  if (progress) {
    return (
      <div className="border mx-auto" style={{ width: "500px" }}>
        <div style={{ width: "80px" }} className="mx-auto">
          <Spinner />
        </div>
        <div className="w-full text-center">{uploaded}%</div>
        <div className="bg-green-600 w-full" style={{ width: `${uploaded}%` }}>
          &nbsp;
        </div>
      </div>
    );
  }

  return (
    <div style={{ width: "500px" }}>
      <h1>{fileData === null ? "Datei neu hochladen" : "Datensatz ändern"}:</h1>
      <div className="p-2">
        <input
          type="file"
          onChange={onFileLoad}
          className={fileProcess ? "hidden" : ""}
        />
        <div className={!fileProcess ? "hidden" : ""}>Verarbeite Datei</div>
      </div>
      <div className="p-2">
        <Input
          type="text"
          value={displayedName}
          onChange={(v) => setDisplayedName(v)}
          placeholder="Angezeigter Name"
          label="Angezeigter Name"
          displayOnValue={true}
          help="Name mit der die Datei Angezeigt wird und auf dem Client gespeichert wird"
        />
      </div>
      <div className="p-2">Größe: {bytesToSize(fileSize)}</div>
      <div className="p-2">
        Für Rolle:
        <br />
        <select
          value={forRole ?? ""}
          onChange={(e) => setRole(isValidRule(e.target.value))}
        >
          <option value="">Alle Rollen</option>
          {roles.map((r, i) => (
            <option value={r ?? ""} key={`file-role-${i}`}>
              {r}
            </option>
          ))}
        </select>
      </div>
      <div className="p-2">
        Fileinfo
        <br />
        <textarea
          style={{ width: "600px", height: "300px" }}
          value={fileInfo}
          onChange={(e) => setFileInfo(e.target.value)}
        ></textarea>
        <br />
        <div className="text-xs">
          Wird im Admin, bzw bei dem User als Beschreibung angezeigt.
        </div>
      </div>
      <div className="p-2 text-right">
        <Button intent="danger" text="zurück" onClick={close} />
        <Button
          className="ml-2"
          intent="success"
          text={fileData === null ? "Upload" : "Ändern"}
          onClick={() => {
            setProgress(true);
            setTimeout(() => {
              if (fileData === null) {
                upload();
              } else {
                update();
              }
            }, 0);
          }}
          disabled={!fileContent && fileData === null}
        ></Button>
      </div>
    </div>
  );
};
