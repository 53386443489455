import { TAB_CONFIG, TTabs } from "../../config/tabs";
import { Tab } from "./Tab";
type TTabsProps = {
  activeTab: TTabs;
  setActive: (t: TTabs) => void;
  displayedTabs: TTabs[];
};

export const TabBar: React.FC<TTabsProps> = ({
  activeTab,
  setActive,
  displayedTabs,
}) => {
  let counter = -1;

  if (displayedTabs.length < 2) {
    return null;
  }

  return (
    <div className="flex">
      {TAB_CONFIG.map((t) => {
        if (!displayedTabs.includes(t.tab)) {
          return null;
        }
        counter++;
        return (
          <Tab
            key={`tab-${t.tab}`}
            label={t.label}
            active={activeTab === t.tab}
            moveLeft={counter !== 0}
            onClick={() => setActive(t.tab)}
          />
        );
      })}
    </div>
  );
};
