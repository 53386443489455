import * as React from "react";
import { TContentType, TLoginProps } from ".";
import { Button } from "../Button";
import { PasswordInput } from "./PasswordInput";
import { PasswordRequestTrigger } from "./PasswordRequestTrigger";
import { Remember } from "./Remember";
import { UserInput } from "./UserInput";
import { Welcome } from "./Welcome";

type TLoginFormProps = {
  content: TContentType;
  setContent: (val: TContentType) => void;
} & TLoginProps;

export const LoginForm: React.FunctionComponent<TLoginFormProps> = ({
  username,
  password,
  content,
  userHelp = "",
  error = null,
  text = "",
  passwordRequest = false,
  showRemember,
  isRemember,
  setContent,
  onRememberChange,
  onPasswordChange,
  onLogin,
  onUserChange,
}) => {
  if (content !== "login") {
    return null;
  }

  return (
    <div className="w-full">
      <Welcome text={text} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (password === "" || username === "") {
            return;
          }
          onLogin();
        }}
      >
        <div className="w-full mt-2">
          <UserInput
            onUserChange={onUserChange}
            username={username}
            help={userHelp}
            error={error?.userError ?? ""}
          />
        </div>

        <div className="w-full mt-2 relative">
          <PasswordRequestTrigger
            passwordRequest={passwordRequest}
            setContent={setContent}
          />
          <PasswordInput
            value={password}
            onPasswordChange={onPasswordChange}
            error={error}
          />
          <Remember
            showRemember={showRemember}
            isRemember={isRemember}
            onRememberChange={onRememberChange}
          />
        </div>
        <div className="w-full mt-7">
          <Button
            disabled={password === "" || username === ""}
            intent="success"
            iconRight="SIGN_IN_ALT_SOLID"
            text="Login"
            full={true}
            type="submit"
            testId="do-login"
          />
        </div>
      </form>
    </div>
  );
};
