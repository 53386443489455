import { useEffect, useState } from "react";
import axios from "axios";

export type TVerifyAufmassDelete = {
  buildingId: number | null;
  hasAufmass: boolean;
  hasPunktwolke: boolean;
};

export const useVerifyAufmassDelete = (path = "", force = 0) => {
  const [orderState, setOrderState] = useState<TVerifyAufmassDelete>({
    buildingId: null,
    hasAufmass: false,
    hasPunktwolke: false,
  });

  useEffect(() => {
    if (!path) {
      return;
    }

    const process = async () => {
      try {
        const response = await axios.get<{
          buildingId: number | null;
          hasAufmass: number | null;
          hasPunktwolke: number | null;
        }>(`/verify/measure-delete/?path=${path}`);
        setOrderState({
          buildingId: response.data.buildingId,
          hasAufmass: !!response.data.hasAufmass,
          hasPunktwolke: !!response.data.hasPunktwolke,
        });
      } catch (e) {
        setOrderState({
          buildingId: null,
          hasAufmass: false,
          hasPunktwolke: false,
        });
      }
    };

    process();
  }, [path, force, setOrderState]);

  return orderState;
};
