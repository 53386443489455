import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { TMeasureQualityStates } from "../../../config/workflow";
import { Button } from "../../Button";

type TModalContentProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (comment: string) => void;
  commentState?: TMeasureQualityStates;
};

export const ModalContent: React.FunctionComponent<TModalContentProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [comment, setComment] = useState("");
  const ref = React.createRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (ref?.current) {
      setTimeout(() => {
        if (ref?.current) {
          ref.current.focus();
        }
      }, 0);
    }
  }, [ref]);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div>
        Kommentar / Info zum Status
        <textarea
          ref={ref}
          autoFocus
          className="w-full h-28 resize-none"
          onChange={(e) => {
            setComment(e.target.value);
          }}
          value={comment}
        />
        <div className="text-right">
          <Button
            intent="danger"
            text="Abbrechen"
            onClick={onClose}
            className="mr-4"
          />
          <Button onClick={() => onSubmit(comment)} intent="success">
            <FontAwesomeIcon icon={faSave} /> Speichern
          </Button>
        </div>
      </div>
    </>
  );
};
