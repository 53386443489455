import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useMoSyncImporter } from "./hooks/useMoSyncImporter";
import { DataRow } from "./DataRow";
import { useMoSyncImporterProcess } from "../../../hooks/importer/useMoSyncImporterProcess";
import { Button } from "../../Button";

export const MoSync: React.FC<{
  workBook: XLSX.WorkBook | null;
  isWorkBook: (state: boolean) => void;
}> = ({ workBook, isWorkBook }) => {
  const { isSheet, importData } = useMoSyncImporter(workBook);
  const [force, setForce] = useState(Date.now());
  const [isImportable, setImportable] = useState<string[]>([]);
  const [toImport, setImport] = useState<string[]>([]);

  useEffect(() => {
    if (isSheet) {
      isWorkBook(true);
    }
  }, [isSheet, isWorkBook]);

  const processImport = useMoSyncImporterProcess(() => {
    setImport([]);
    setForce(Date.now());
  });

  const setPathImportable = useCallback(
    (path: string, remove = false) => {
      if (remove) {
        setImportable(isImportable.filter((val) => val !== path));
      } else if (!remove && !isImportable.includes(path)) {
        setImportable([...isImportable, path]);
      }
    },
    [isImportable, setImportable]
  );

  const selectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setImport([...isImportable]);
      } else {
        setImport([]);
      }
    },
    [isImportable, setImport]
  );

  const setChecked = useCallback(
    (checked: boolean, path: string) => {
      if (checked) {
        setImport([...toImport, path]);
      } else {
        setImport(toImport.filter((v) => v !== path));
      }
    },
    [toImport, setImport]
  );

  if (!isSheet) {
    return null;
  }

  return (
    <div>
      <div className={"flex"}>
        <h1>MoSync Import</h1>
        <Button
          intent={"success"}
          buttonStyle="outline"
          className="ml-4"
          disabled={!toImport.length}
          onClick={() =>
            processImport(
              importData.filter((v) => toImport.includes(v.path ?? ""))
            )
          }
        >
          Importieren
        </Button>
      </div>
      <table className="table-auto border-collapse border border-border w-full overflow-x-auto text-xs">
        <thead>
          <tr>
            <th className="border border-border p-1 flex">
              <input
                className={"mr-1"}
                type="checkbox"
                checked={toImport.length === isImportable.length}
                onChange={selectAll}
              />
              ({toImport.length})
            </th>
            <th className="border border-border p-1">Pfad</th>
            <th className="border border-border p-1">WE</th>
            <th className="border border-border p-1">GE</th>
            <th className="border border-border p-1">Bezeichnung</th>
            <th className="border border-border p-1">AbrufNr</th>
            <th className="border border-border p-1">Firma</th>
            <th className="border border-border p-1">Los</th>
            <th className="border border-border p-1">NRF Geprüft</th>
            <th className="border border-border p-1">Datum MoSync</th>
            <th className="border border-border p-1">NE-Zuweisung</th>
            <th className="border border-border p-1">DWG-Archiv</th>
            <th className="border border-border p-1">Datum DWG-Upload PTS</th>
            <th className="border border-border p-1">Bemerkungen</th>
          </tr>
        </thead>
        <tbody>
          {importData.map((v, index) => (
            <DataRow
              force={force}
              isChecked={toImport.includes(v.path ?? "")}
              isImportable={isImportable.includes(v.path ?? "")}
              setImportable={setPathImportable}
              {...v}
              key={index}
              setChecked={setChecked}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
