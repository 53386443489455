import { RouteComponentProps } from "@reach/router";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { CHANGELOG, TChangelog } from "../../config/changelog";
import { RootState } from "../../store";

type TChangelogProps = ReturnType<typeof mapStateToProps> & RouteComponentProps;

const LogEntry: React.FunctionComponent<TChangelog> = ({
  version,
  date,
  changes,
}) => {
  return (
    <div className="smb-version-wrapper border-border border-b mb-4 pt-2">
      <div className="text-lg ">
        Version: {version} vom {moment(date).format("L")}
      </div>
      <div>
        <ul className="ml-3">
          {changes.map((el, i) => (
            <li className="mt-1 mb-1" key={`${version}-${i}`}>
              {el}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const ChangelogComponent: React.FunctionComponent<TChangelogProps> = (
  props
) => {
  if (props.email === null) {
    return null;
  }
  return (
    <>
      <h1>Versionsänderungen:</h1>
      <div>
        {CHANGELOG.map((log, i) => (
          <LogEntry {...log} key={`log-entry-${i}`} />
        ))}
      </div>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  email: state.user.email,
});

export const Changelog = connect(mapStateToProps)(ChangelogComponent);
