import Axios, { AxiosResponse } from "axios";
import { ActionCreator, Dispatch } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  disableAjaxProgress,
  setAppHasData,
  setShowLogin,
} from "../appState/action";
import { restoreSettings } from "../settings/action";
import { TSettingsState } from "../settings/settingsReducer";
import { IUserState, IUserUpdate, UserActionTypes } from "./types";

export const USER_SET = "user/USER_SET";
export const USER_RESET = "user/USER_RESET";
export const USER_UPDATE = "user/USER_UPDATE";
export const USER_SETTINGS = "user/USER_SETTINGS";

export const setUser = (userdata: IUserState): UserActionTypes => {
  return {
    type: USER_SET,
    payload: userdata,
  };
};

export const resetUser = (): UserActionTypes => {
  return {
    type: USER_RESET,
  };
};

export const updateUser = (data: IUserUpdate): UserActionTypes => {
  return {
    type: USER_UPDATE,
    payload: data,
  };
};

export const fetchUser: ActionCreator<
  ThunkAction<Promise<UserActionTypes>, IUserState, null, UserActionTypes>
> = () => {
  return async (dispatch: Dispatch) => {
    return await Axios.get("/user/")
      .then((response: AxiosResponse) => {
        const settings: TSettingsState = { ...response.data.settings };
        delete response.data.setting;
        dispatch(restoreSettings(settings));
        const userData: IUserState = { ...response.data };
        dispatch(setShowLogin(false));
        return dispatch(setUser(userData));
      })
      .finally(() => {
        dispatch(setAppHasData());
      });
  };
};

export const updateUserActionCreator: ActionCreator<
  ThunkAction<Promise<UserActionTypes>, IUserState, null, UserActionTypes>
> = (data: IUserUpdate) => {
  return async (dispatch: Dispatch) => {
    return await Axios.put("/user/", data).then((response: AxiosResponse) => {
      const userData = response.data as IUserUpdate;
      dispatch(disableAjaxProgress());
      return dispatch(updateUser(userData));
    });
  };
};
