import React, { useEffect, useState } from "react";
import { TCalculateResult } from "../../components/KanbanBoard/useBoardFilter";
import { leadingZeroFormatter } from "../../helper/formatter";
import { TSmbBoardResponse } from "../../hooks/board/useGetBoardSmbData";
import { Modal } from "../../components/Modal";
import { Button } from "../../components/Button";

type TAssignMessageProps = {
  response: TSmbBoardResponse[] | null;
  userId: number | null;
  building: TCalculateResult | null;
  onClose: () => void;
  onSubmit: (comment: string, username: string) => void;
};

export const AssignMessage: React.FC<TAssignMessageProps> = ({
  response,
  userId,
  building,
  onClose,
  onSubmit,
}) => {
  const [user, setUser] = useState<TSmbBoardResponse["user"] | null>(null);
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (!userId || !response) {
      return;
    }
    const data = response.find((val) => val?.user?.id === userId);
    if (!data?.user) {
      return;
    }
    setUser(data.user);
  }, [response, userId, setUser]);

  if (!building || !response || !userId) {
    return null;
  }

  return (
    <Modal isOpen={true} width="800px">
      <div style={{ width: "750px" }}>
        <p>
          Gebäude: {building.address} WE:{" "}
          {leadingZeroFormatter(building.economicUnit)} GE:{" "}
          {leadingZeroFormatter(building.buildingNumber)}, NRF:{" "}
          {building.nrfMeasurement} m²
        </p>
        <p>
          Benutzer: {user?.firstname} {user?.lastname} zuweisen
        </p>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Nachricht für Benutzer:"
          className="w-full h-40 resize-none"
        />
        <div className="text-right">
          <Button
            text="Abbrechen"
            intent="danger"
            onClick={() => {
              onClose();
              setMessage("");
            }}
            className="mr-4"
          />
          <Button
            text="Speichern"
            intent="success"
            onClick={() => {
              onSubmit(message, `${user?.firstname} ${user?.lastname}`);
              setMessage("");
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
