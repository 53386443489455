import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useGetCompanies } from "../../../hooks/useGetCompanies";
import { ICompany } from "../../../types/Company";
import { TUserRole } from "../../../types/User";
import { Input } from "../../../components/Input";
import { Button } from "../../../components/Button";

type TContentProps = {
  onClose: () => void;
  setForce: () => void;
};

export const Content: React.FunctionComponent<TContentProps> = ({
  onClose,
  setForce,
}) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState<number | null>(null);
  const [userRole, setUserRole] = useState<TUserRole>("user");
  const [emailError, setEmailError] = useState("");
  const [isEmailVerify, setEmailVerify] = useState(false);
  const [userReady, setUserReady] = useState(false);
  const companies = useGetCompanies();

  useEffect(() => {
    if (isEmailVerify && firstname && lastname && email && company) {
      setUserReady(true);
    } else {
      setUserReady(false);
    }
  }, [firstname, lastname, email, company, isEmailVerify, setUserReady]);

  async function verifyEmail() {
    const mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    setEmailVerify(false);
    if (email.match(mailformat) === null) {
      setEmailError("Dies ist keine gültige Email-Adresse");
      return;
    }

    try {
      await Axios.get("/admin/verify-email", {
        params: { email },
      });
      setEmailError("");
      setEmailVerify(true);
    } catch (err) {
      setEmailError("Dies Email-Adresse ist bereits im System");
    }
  }

  async function createUser() {
    try {
      await Axios.post("/admin/user", {
        firstname,
        lastname,
        email,
        company,
        userRole,
      });
      onClose();
      setForce();
    } catch (err) {
      alert("Fehler beim User anlegen");
    }
  }

  return (
    <>
      <Input
        key="firstname"
        label="Vorname"
        value={firstname}
        onChange={(v: string) => setFirstname(v)}
        placeholder="Vorname"
        displayOnValue={true}
        help="Wird benötigt"
      />
      <Input
        label="Nachname"
        value={lastname}
        onChange={(v: string) => setLastname(v)}
        help="Wird benötigt"
        placeholder="Nachname"
        displayOnValue={true}
      />
      <Input
        label="Email"
        value={email}
        onChange={(v: string) => setEmail(v)}
        placeholder="Email"
        help="Wird benötig"
        error={emailError}
        onBlur={() => verifyEmail()}
      />
      <div className="flex">
        <div className="p-2 pl-0">
          <div>Firma / Amt</div>
          <select
            value={company ?? -1}
            onChange={(e) => setCompany(Number(e.currentTarget.value))}
          >
            <option>Bitte wählen</option>
            {companies.map((item: ICompany) => (
              <option value={item.id} key={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
        <div className="p-2">
          <div>Benutzer Rolle</div>
          <select
            disabled={company !== 1}
            value={userRole}
            onChange={(e) => setUserRole(e.target.value as TUserRole)}
          >
            <option value="user">Benutzer</option>
            <option value="admin">Admin</option>
          </select>
        </div>
      </div>
      <div className="flex justify-end mt-3">
        <Button
          className="mr-3"
          buttonStyle="elevated"
          text="Abbrechen"
          onClick={onClose}
        />
        <Button
          buttonStyle="outline"
          intent="success"
          iconRight="SAVE_REGULAR"
          text="Speichern"
          onClick={createUser}
          disabled={!userReady}
        />
      </div>
    </>
  );
};
