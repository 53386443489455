import { TBuildingData } from "./hooks/usePointCloudImporter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { useVerifyBuilding } from "../../../hooks/verify/useVerifyBuilding";
import { useEffect, useState } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ToolTip } from "../../ToolTip";
import { useVerifyMeasureOrder } from "../../../hooks/verify/useVerifyMeasureOrder";
import { BuildingLink } from "../../BuildingLink/BuildingLink";
import { useVerifyPontCloudByPathCompany } from "../../../hooks/verify/useVerifyPontCloudByPathCompany";
import { PointCloudLink } from "./PointCloudLink";

type TDataRowProps = {
  selectedImports: string[];
  toggleImport: (path: string) => void;
  force: number;
  company: string;
} & TBuildingData;

type TIconState = {
  icon: IconProp;
  spin: boolean;
  className?: string;
};

export const DataRow: React.FC<TDataRowProps> = ({
  path,
  economicUnit,
  buildingNumber,
  category,
  areaCluster,
  detailDeep,
  price,
  selectedImports,
  toggleImport,
  force,
  company,
}) => {
  const { buildingExists, buildingId } = useVerifyBuilding(path, force);
  const measureOrderExists = useVerifyMeasureOrder(path, force);
  const pointCloudOrderExists = useVerifyPontCloudByPathCompany(
    company,
    path,
    force
  );
  const [isDisabled, setDisabled] = useState(true);
  const [disabledClass, setDisabledClass] = useState("opacity-20");
  const [buildingState, setBuildingState] = useState<TIconState>({
    icon: faSpinner,
    spin: true,
  });
  const [className, setClassName] = useState("bg-green-200");

  const [measureState, setMeasureState] = useState<TIconState>({
    icon: faSpinner,
    spin: true,
  });

  useEffect(() => {
    if (
      buildingExists === true &&
      measureOrderExists === true &&
      pointCloudOrderExists === false
    ) {
      setDisabled(false);
      setDisabledClass("");
      setClassName("bg-green-500");
    } else {
      setDisabled(true);
      setDisabledClass("opacity-20");
      setClassName("bg-green-200");
    }
  }, [
    pointCloudOrderExists,
    buildingExists,
    measureOrderExists,
    setDisabled,
    setDisabledClass,
    setClassName,
  ]);

  useEffect(() => {
    if (buildingExists === true) {
      setBuildingState({
        icon: faCheckCircle,
        spin: false,
        className: "text-success",
      });
    } else if (buildingExists === false) {
      setBuildingState({
        icon: faExclamationCircle,
        spin: false,
        className: "text-danger",
      });
    }
  }, [buildingExists, setBuildingState]);

  useEffect(() => {
    if (measureOrderExists === true) {
      setMeasureState({
        icon: faCheckCircle,
        spin: false,
        className: "text-success",
      });
    } else if (measureOrderExists === false) {
      setMeasureState({
        icon: faExclamationCircle,
        spin: false,
        className: "text-danger",
      });
    }
  }, [measureOrderExists, setMeasureState]);

  return (
    <tr className={`${className}`}>
      <td className="border border-border p-1">
        <input
          type="checkbox"
          disabled={isDisabled}
          className={disabledClass}
          checked={selectedImports.includes(path ?? "")}
          onChange={() => {
            if (!path) {
              return;
            }
            toggleImport(path);
          }}
        />
      </td>
      <td className="border border-border p-1">
        <BuildingLink buildingId={buildingId} blank={true}>
          {path}
        </BuildingLink>
      </td>
      <td className="border border-border p-1">{economicUnit}</td>
      <td className="border border-border p-1">{buildingNumber}</td>
      <td className="border border-border p-1">{category}</td>
      <td className="border border-border p-1">{areaCluster}</td>
      <td className="border border-border p-1">{detailDeep}</td>
      <td className="border border-border p-1">{price}</td>
      <td className="border border-border p-1 text-center">
        <ToolTip content="Aufmaß Auftrag vorhanden">
          <FontAwesomeIcon {...measureState} />
        </ToolTip>
      </td>
      <td className="border border-border p-1 text-center">
        <ToolTip content="Gebäude vorhanden">
          <FontAwesomeIcon {...buildingState} />
        </ToolTip>
      </td>
      <td className="border border-border p-1 text-right">
        <PointCloudLink
          exists={pointCloudOrderExists}
          buildingId={buildingId}
        />
      </td>
    </tr>
  );
};
