import { useCallback, useEffect, useState } from "react";
import { TCols } from "../../config/measureBoard";
import {
  TBoardResponse,
  TStateResponse,
} from "../../hooks/measure/useGetMeasureBoardData";

// TODO Look at SMB BOARD
export type TCalculateResult = {
  remaningTime?: number;
} & TStateResponse;

function filterResponse(term: string, toFilter?: TStateResponse[]) {
  if (!toFilter) {
    return null;
  }
  try {
    const toCompare = term.toLowerCase();

    return toFilter.filter(
      (v) =>
        (v.address ?? "").toLowerCase().includes(toCompare) ||
        (v.designation ?? "").toLowerCase().includes(toCompare) ||
        (v.formattedBuildingNumber ?? "").includes(toCompare) ||
        (v.formattedEconomicUnit ?? "").includes(toCompare)
    );
  } catch (e) {
    return toFilter;
  }
}

export type TBoardData = {
  accessKey: keyof TBoardResponse;
  data: TCalculateResult[] | null;
};

export const useBoardFilter = (
  config: TCols[] | null,
  boardResponse: TBoardResponse | null
) => {
  const [target, setTarget] = useState<any[]>([]);
  const [transferData, setTransferData] = useState<TCalculateResult | null>(
    null
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [boardData, setBoardData] = useState<TBoardData[]>([]);

  useEffect(() => {
    if (boardResponse === null) {
      setBoardData([]);
      return;
    }

    const data: TBoardData[] = [];

    function typedKeys<T>(o: T): (keyof T)[] {
      return Object.keys(o) as (keyof T)[];
    }

    for (const key of typedKeys(boardResponse)) {
      const filteredData = filterResponse(searchTerm, boardResponse[key]);
      data.push({
        accessKey: key,
        data: filteredData,
      });
    }

    setBoardData(data);
  }, [boardResponse, searchTerm, setBoardData]);

  const dragSetTransferData = useCallback(
    (data: TCalculateResult | null) => {
      setTransferData(data);
    },
    [setTransferData]
  );

  const onTermChange = useCallback(
    (value: string) => {
      setSearchTerm(value);
    },
    [setSearchTerm]
  );

  return {
    transferData,
    target,
    searchTerm,
    config,
    boardData,
    onTermChange,
    setTarget,
    dragSetTransferData,
  };
};
