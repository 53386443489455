import { RouteComponentProps } from "@reach/router";
import React from "react";
import { ExcelImporter } from "../../../components/ExcelImporter/ExecelImporter";

export const ExcelImportPage: React.FunctionComponent<
  RouteComponentProps
> = () => {
  return (
    <div>
      <div className="flex mb-4">
        <h1 className={"mr-4"}>Excel Import</h1>
        <a
          rel={"noreferrer noopener"}
          target={"_blank"}
          className="smb-link text-xs m-1"
          href={
            "https://gitlab.com/smb-ag/dora13/fe-rv-vbbw-api/-/wikis/Excel-Import-Beispiel/Referenz-Dateien"
          }
        >
          Importer Doku
        </a>
      </div>

      <ExcelImporter />
    </div>
  );
};
