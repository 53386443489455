import { RouteComponentProps, useNavigate } from "@reach/router";
import React, { useState } from "react";
import { connect } from "react-redux";
import { DataTable } from "../../components/DataTable/DataTable";
import { TABLE_CONFIG } from "../../config/dataTable";
import { ROUTE_BUILDING } from "../../config/routes";
import { MEASURE_TAB } from "../../config/tabs";
import { useBuildingsList } from "../../hooks/useBuildingsList";
import { useTableConfig } from "../../hooks/useTableConfig";
import { RootState } from "../../store/index";
import { useLocalStorageRebuild } from "./useLocalStorageRebuild";
import { useTableCallbacks } from "./useTableCallbacks";

type TBuildingTable = ReturnType<typeof mapStateToProps> & RouteComponentProps;

export const BuildingTableComponent: React.FC<TBuildingTable> = ({
  companyType,
}) => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(30);
  const [searchTerm, setSearchTerm] = useState("");
  const [force, setForce] = useState(Date.now());
  const [currentPage, setCurrentPage] = useState(0);
  const [orderField, setOrderField] = useState("path");
  const [orderDirection, setDirection] = useState<"ASC" | "DESC">("DESC");
  const { data, count } = useBuildingsList(
    companyType,
    limit,
    currentPage,
    orderField,
    orderDirection,
    searchTerm,
    force
  );

  const { currentConfig, setHiddenColumn } = useTableConfig(TABLE_CONFIG);

  const {
    updateLimit,
    updateCurrentPage,
    updateOrderDirection,
    updateOrderField,
    updateSearchTerm,
  } = useTableCallbacks({
    setLimit,
    setCurrentPage,
    setDirection,
    setOrderField,
    setSearchTerm,
  });

  useLocalStorageRebuild({
    setCurrentPage,
    setOrderField,
    setDirection,
    setLimit,
    setSearchTerm,
  });

  return (
    <div className="overflow-hidden h-full">
      <DataTable
        config={currentConfig}
        count={count}
        limit={limit}
        orderDirection={orderDirection}
        pagination={true}
        toggleCell={setHiddenColumn}
        updateLimit={updateLimit}
        updatePage={updateCurrentPage}
        currentPage={currentPage}
        orderField={orderField}
        data={data}
        useSearch={true}
        searchTerm={searchTerm}
        onSearch={updateSearchTerm}
        onCellClick={(index: number) => {
          navigate(`${ROUTE_BUILDING}${data[index].id}/${MEASURE_TAB}/`);
        }}
        onSort={(field: string) => {
          updateOrderField(field);
          updateOrderDirection(orderDirection === "ASC" ? "DESC" : "ASC");
        }}
        force={() => setForce(Date.now())}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  companyType: state.user.companyType,
});

export const BuildingTable = connect(mapStateToProps)(BuildingTableComponent);
