import * as React from "react";
import { SubLevelItem } from "./SubLevelItem";
import { TSubLevelProps } from "./types";
import { useAnimatedHeight } from "../../hooks/useAnimatedHeight";
import { Portal } from "../Portal";

export const SubLevels: React.FunctionComponent<TSubLevelProps> = ({
  isOpen,
  items,
  popover,
  left,
  top,
}) => {
  const ref = React.createRef<HTMLUListElement>();
  useAnimatedHeight(ref, isOpen);
  if (!items) {
    return null;
  }

  if (popover && !isOpen) {
    return (
      <Portal>
        <div
          className="border-border border bg-white absolute"
          style={{
            left: `${left}px`,
            top: `${top}px`,
            zIndex: 9999999999999,
          }}
        >
          <ul>
            {items.map((item, i) => {
              return (
                <SubLevelItem
                  isWrapper={true}
                  key={`sub-level-${item.text}-${i}`}
                  {...item}
                />
              );
            })}
          </ul>
        </div>
      </Portal>
    );
  }

  return (
    <ul
      ref={ref}
      style={{ height: "0" }}
      className="overflow-hidden transition-all duration-250 delay-150 border-black border-l-4"
    >
      {items.map((item, i) => {
        return (
          <SubLevelItem
            isWrapper={false}
            key={`sub-level-${item.text}-${i}`}
            {...item}
          />
        );
      })}
    </ul>
  );
};
