import React, { useCallback, useEffect, useState } from "react";
import {
  ALLOWED_TABS,
  DEFAULT_TAB,
  MEASURE_TAB,
  TAB_CONFIG,
  TTabs,
} from "../../config/tabs";
import { BuildingTabsContext } from "../../context/BuildingTabs";
import { TabBar } from "./TabBar";
import { TabContent } from "./TabContent";

type TOrderTabsProps = {
  building: number;
  tab?: string;
};

export const OrderTabs: React.FC<TOrderTabsProps> = ({
  tab = DEFAULT_TAB,
  building,
}) => {
  const [activeTab, setActiveTab] = useState<TTabs>();
  const [displayTabs, setDisplayTabs] = useState<TTabs[]>([MEASURE_TAB]);

  const addDisplayTab = useCallback(
    (addTab: TTabs) => {
      if (displayTabs.includes(addTab)) {
        return;
      }
      setDisplayTabs([...displayTabs, addTab]);
    },
    [setDisplayTabs, displayTabs]
  );

  useEffect(() => {
    if (!ALLOWED_TABS.includes(tab as TTabs)) {
      setActiveTab(DEFAULT_TAB);
    } else {
      setActiveTab(tab as TTabs);
    }
  }, [tab, setActiveTab]);

  if (!activeTab) {
    return null;
  }

  return (
    <div className="mt-2">
      <TabBar
        activeTab={activeTab}
        setActive={setActiveTab}
        displayedTabs={displayTabs}
      />
      <div>
        <BuildingTabsContext.Provider
          value={{ building, addDisplayTab, displayTabs, activeTab }}
        >
          {TAB_CONFIG.map((t) => (
            <TabContent
              key={`content-tab-${t.tab}`}
              active={activeTab === t.tab}
            >
              {t.component}
            </TabContent>
          ))}
        </BuildingTabsContext.Provider>
      </div>
    </div>
  );
};
