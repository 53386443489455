import { useCallback, useContext } from "react";
import { SmbToastContext } from "../../context/SmbTabsContext";
import { TMoSyncImportData } from "../../components/Importer/MoSync/hooks/useMoSyncImporter";
import axios from "axios";

export const useMoSyncImporterProcess = (done: () => void) => {
  const { addToast } = useContext(SmbToastContext);

  return useCallback(
    async (data: TMoSyncImportData[]) => {
      try {
        const response = await axios.post("/admin/import/moSync/", {
          data,
        });

        if (addToast) {
          for (const message of response.data.errors) {
            addToast({
              message,
              intent: "error",
              autoClose: 0,
            });
          }
        }

        done();
      } catch (err) {}
    },
    [addToast, done]
  );
};
