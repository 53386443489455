import React, { useEffect } from "react";
import { useGetWorkflowState } from "../../hooks/useGetWorkflowState";
import { TWorkflowType } from "../../hooks/useLastHistoryStatus";
import { TOrderTypes } from "../../types/order";
import { DeleteLast } from "./DeleteLast/DeleteLast";
import { Loader } from "./Loader";
import { PlausibilityButtons } from "./PlausibilityButtons";
import { PtsStateButtons } from "./PtsUpload/PtsStateButtons";
import { QualityButtons } from "./QualityButtons";
import { WorkflowNotStarted } from "./WorkflowNotStarted";
import { WorkflowStatusTable } from "./WorkflowStatusTable";
import { AccordionRowContext } from "../../context/AccordionRow";
import { TCompanyType } from "../../store/user/types";

type TWorkflowStateProps = {
  workflowType: TWorkflowType;
  orderType: TOrderTypes;
  companyType: TCompanyType;
  force?: number;
  buildingId?: number;
  forceFetch?: () => void;
  userType?: string;
  qsOffice?: number | null;
  companyId?: number | null;
};

export const MeasureWorkflow: React.FunctionComponent<TWorkflowStateProps> = ({
  orderType,
  workflowType,
  buildingId,
  companyType,
  userType = "",
  force,
  forceFetch,
  qsOffice,
  companyId,
}) => {
  const {
    data: workflowResponse,
    loading,
    isValid,
  } = useGetWorkflowState(orderType, workflowType, buildingId, force);

  const { setCount } = React.useContext(AccordionRowContext);

  useEffect(() => {
    if (setCount) {
      setCount(workflowResponse.length);
    }
  }, [workflowResponse, setCount]);

  if (!buildingId) {
    return null;
  }

  return (
    <div>
      <Loader loading={loading} />
      <WorkflowStatusTable data={workflowResponse} />
      <WorkflowNotStarted
        isLoading={loading}
        isEmpty={workflowResponse.length === 0}
      />

      <div className="flex justify-between pt-3">
        <div>
          <DeleteLast
            {...workflowResponse?.[workflowResponse.length - 1]}
            userType={userType}
            companyType={companyType}
            forceFetch={forceFetch}
          />
        </div>
        <PtsStateButtons
          buildingId={buildingId}
          workflowType={workflowType}
          companyType={companyType}
          results={workflowResponse}
          forceFetch={forceFetch}
        />
        <PlausibilityButtons
          buildingId={buildingId}
          workflowType={workflowType}
          companyType={companyType}
          lastState={workflowResponse?.[workflowResponse.length - 1]?.status}
          forceFetch={forceFetch}
        />
        <QualityButtons
          workflowType={workflowType}
          companyType={companyType}
          isValid={isValid}
          buildingId={buildingId}
          lastState={workflowResponse?.[workflowResponse.length - 1]?.status}
          forceFetch={forceFetch}
          qsOffice={qsOffice}
          companyId={companyId}
        />
      </div>
    </div>
  );
};
