import * as React from "react";
export function useResizeObserver<T extends HTMLElement>(
  element: React.RefObject<T> | T | null,
  debounceTime?: 200
) {
  const [dimensions, setDimensions] = React.useState<DOMRectReadOnly | null>(
    null
  );

  React.useLayoutEffect(() => {
    let toObserve: HTMLElement;
    let resizeObserver: ResizeObserver;
    if (element) {
      if (element instanceof HTMLElement) {
        toObserve = element;
      } else if (element.current && element.current instanceof HTMLElement) {
        toObserve = element.current;
      } else {
        throw new Error(
          `element is not a valid HTMLElement or React.RefObject<HTMLElement>`
        );
      }

      let timeoutId: number;
      resizeObserver = new ResizeObserver((entries) => {
        if (toObserve === entries[0].target) {
          clearTimeout(timeoutId);
          timeoutId = window.setTimeout(() => {
            setDimensions(entries[0].contentRect);
          }, debounceTime);
        }
      });

      resizeObserver.observe(toObserve);
    }
    return () => {
      if (!resizeObserver) {
        return;
      }
      resizeObserver.unobserve(toObserve);
    };
  }, [element, debounceTime, setDimensions]);

  return dimensions;
}
