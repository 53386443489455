import React from "react";
import { Tip, TTips } from "./Tip";

export const TipWrapper: React.FunctionComponent<TTips> = (props) => {
  if (props.isOpen === false) {
    return null;
  }

  return <Tip {...props} />;
};
