import Axios from "axios";
import { useEffect, useState } from "react";

export type TBuildingResponse = {
  address: string | null;
  buildingNumber: number;
  designation: string;
  economicUnit: number;
  id: number;
  path: number;
  responsibleDepartment: string | null;
};

export const useGetBuildingById = (id: number) => {
  const [buildingData, setBuildingData] = useState<TBuildingResponse | null>();

  useEffect(() => {
    const process = async () => {
      const response = await Axios.get<TBuildingResponse>(`/building/${id}/`);
      setBuildingData(response.data);
    };

    process();
  }, [id]);

  return buildingData;
};
