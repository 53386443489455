import { ITableHeader } from "../../config/dataTable";
import { TFilterActions } from "../../config/quickFilter";
import {
  TDetailClosed,
  TFilterSettings,
  TSettingsState,
} from "./settingsReducer";
import {
  SETTINGS_DETAIL_CLOSE,
  SETTINGS_DETAIL_OPEN,
  SETTINGS_DETAIL_TABLE_CELL_VISIBILITY,
  SETTINGS_DETAIL_TABLE_PAGE,
  SETTINGS_FILTER,
  SETTINGS_FILTER_ORDER,
  SETTINGS_HOME_DATA,
  SETTINGS_HOME_TIP,
  SETTINGS_LIMIT_DATA_TABLE,
  SETTINGS_RESTORE,
  TSetFilterOrder,
  TSettingActions,
} from "./types";

export const restoreSettings = (payload: TSettingsState): TSettingActions => ({
  type: SETTINGS_RESTORE,
  payload,
});

export const setHomeTip = (payload: boolean): TSettingActions => ({
  type: SETTINGS_HOME_TIP,
  payload,
});

export const setHomeData = (payload: TFilterActions): TSettingActions => ({
  type: SETTINGS_HOME_DATA,
  payload,
});

export const updateFilter = (payload: TFilterSettings): TSettingActions => ({
  type: SETTINGS_FILTER,
  payload,
});

export const updateFilterOrder = (
  payload: TSetFilterOrder
): TSettingActions => ({
  type: SETTINGS_FILTER_ORDER,
  payload,
});

export const closeDetailWidget = (payload: TDetailClosed): TSettingActions => ({
  type: SETTINGS_DETAIL_CLOSE,
  payload,
});

export const openDetailWidget = (payload: TDetailClosed): TSettingActions => ({
  type: SETTINGS_DETAIL_OPEN,
  payload,
});

export const setDataTableLimit = (payload: number): TSettingActions => ({
  type: SETTINGS_LIMIT_DATA_TABLE,
  payload,
});

export const setDetailTablePage = (payload: number): TSettingActions => ({
  type: SETTINGS_DETAIL_TABLE_PAGE,
  payload,
});

export const toggleDetailTableCellVisibility = (
  payload: ITableHeader["dataIndex"]
) => ({
  type: SETTINGS_DETAIL_TABLE_CELL_VISIBILITY,
  payload,
});
