import * as React from "react";
import { TContentType, TLoginProps } from ".";

import { UserInput } from "./UserInput";
import { Button } from "../Button";

type TPasswordRequestFormProps = {
  content: TContentType;
  onUserChange: TLoginProps["onUserChange"];
  username: string;
  onPasswordRequest?: () => void;
  setContent: (value: TContentType) => void;
  help?: string;
};

export const PasswordRequestForm: React.FunctionComponent<
  TPasswordRequestFormProps
> = ({
  content,
  onUserChange,
  username,
  onPasswordRequest,
  setContent,
  help = "",
}) => {
  if (content !== "request" || typeof onPasswordRequest !== "function") {
    return null;
  }

  return (
    <div data-testid="password-request">
      <p></p>
      <div className="w-full mt-5">
        <UserInput
          onUserChange={onUserChange}
          username={username}
          error=""
          help={help}
        />
      </div>
      <div className="w-full mt-10">
        <Button
          disabled={username === ""}
          intent="primary"
          onClick={onPasswordRequest}
          iconRight="PAPER_PLANE_REGULAR"
          text="Change me"
          full={true}
        />
      </div>
      <div
        className="w-full mt-2 text-center text-xs cursor-pointer text-blue-700"
        onClick={() => setContent("login")}
      >
        zurück
      </div>
    </div>
  );
};
