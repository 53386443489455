import React, { useCallback, useContext, useEffect, useState } from "react";
import { TCols } from "../../config/measureBoard";
import { MeasureProgressBoardCard } from "./cards/MeasureProgressBoardCard";
import { KanbanBoardContext } from "./KanbanBoard";
import { TBoardData, TCalculateResult } from "./useBoardFilter";
import { Spinner } from "../Spinner";
import { Icon } from "../Icon";
import { MeasureWaitPtsUploadBoardCard } from "./cards/MeasureWaitPtsUploadBoardCard";
import { PointCloudStatusCard } from "./cards/PointCloudStatusCard";
import { MeasureSmbAssignBoardCard } from "./cards/MeasureSmbAssignBoardCard";

type TBoardColProps = {
  currentTargets: any[];
  transferData?: TCalculateResult | null;
  data?: TBoardData;
  config: TCols;
  setTarget?: (t: any[]) => void;
  setTransferData?: (d: TCalculateResult | null) => void;
} & TCols;

const isTargetColor = "bg-gray-400";
const isOverColor = "bg-gray-600";
const maxColWidth = 286;
const minColWidth = 27;

export const BoardCol: React.FC<TBoardColProps> = ({
  header,
  headerSub,
  data,
  targetId,
  currentTargets,
  dragTargets = [],
  isDraggable = false,
  transferData,
  useCondition,
  displayNoEditor,
  config,
  setTarget,
  setTransferData,
}) => {
  const { dropCallback } = useContext(KanbanBoardContext);
  const [isTarget, setIsTarget] = useState(false);
  const [isOpen, setOpen] = useState(true);
  const [displayData, setDisplayData] = useState<TCalculateResult[] | null>(
    null
  );

  useEffect(() => {
    setIsTarget(currentTargets.includes(targetId));
  }, [currentTargets, targetId]);

  const onDragStart = useCallback(
    (dragData: TCalculateResult | null = null, target: any[] = []) => {
      if (
        typeof setTarget !== "function" ||
        typeof setTransferData !== "function"
      ) {
        return;
      }
      setTarget(target);
      setTransferData(dragData);
    },
    [setTarget, setTransferData]
  );

  const onDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (!isTarget) {
        return;
      }
      e.preventDefault();
      e.currentTarget.classList.remove(isTargetColor);
      e.currentTarget.classList.add(isOverColor);
    },
    [isTarget]
  );

  const onDragEnter = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (!isTarget) {
        return;
      }
    },
    [isTarget]
  );

  const onDragLeave = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (!isTarget) {
        return;
      }
      e.currentTarget.classList.remove(isOverColor);
      e.currentTarget.classList.add(isTargetColor);
    },
    [isTarget]
  );

  const onDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      if (!isTarget || !transferData || typeof dropCallback !== "function") {
        return;
      }

      dropCallback(transferData, config);
    },
    [isTarget, transferData, config, dropCallback]
  );

  useEffect(() => {
    if (data && data.data) {
      setDisplayData(data.data);
    } else {
      setDisplayData(null);
    }
  }, [data, setDisplayData]);

  return (
    <div
      style={{
        width: isOpen ? maxColWidth : minColWidth + "px",
        maxWidth: isOpen ? maxColWidth : minColWidth + "px",
      }}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      className={`border border-gray-600 mr-1${
        isOpen ? " flex-1" : ""
      } relative ${isTarget ? isTargetColor : ""}`}
    >
      <div
        className={`py-1 text-center bg-gray-400 top-0 sticky z-10 flex ${
          !isOpen ? "text-top-bottom" : ""
        } `}
      >
        <div
          className={`border border-gray-400 hover:border-gray-600 rounded w-6 h-6 pt-1 cursor-pointer${
            !isOpen ? "transform rotate-90" : ""
          }`}
          onClick={() => setOpen(!isOpen)}
        >
          <Icon icon="ANGLE_RIGHT_SOLID" />
        </div>

        <div className={"flex-1"}>
          {header} ({displayData?.length ?? 0})
          {headerSub && isOpen ? (
            <div className={"text-xs"}>{headerSub}</div>
          ) : null}
        </div>
      </div>

      {!isOpen ? (
        <></>
      ) : displayData === null ? (
        <div className="mx-auto w-32 mt-10">
          <Spinner />
        </div>
      ) : (
        displayData.map((card, i) => {
          switch (config.cardType) {
            case "measureState":
              return (
                <MeasureProgressBoardCard
                  isOpen={isOpen}
                  {...card}
                  {...config}
                  displayNoEditor={displayNoEditor}
                  useCondition={useCondition}
                  key={`card-${i}`}
                  isDraggable={isDraggable}
                  onDragStart={() => {
                    onDragStart(card, dragTargets);
                  }}
                  onDragEnd={() => onDragStart(null)}
                />
              );
            case "ptsUpload":
              return (
                <MeasureWaitPtsUploadBoardCard
                  isOpen={isOpen}
                  {...card}
                  {...config}
                  displayNoEditor={displayNoEditor}
                  useCondition={useCondition}
                  key={`card-${i}`}
                  isDraggable={isDraggable}
                  onDragStart={() => {
                    onDragStart(card, dragTargets);
                  }}
                  onDragEnd={() => onDragStart(null)}
                />
              );
            case "pointCloud":
              return (
                <PointCloudStatusCard
                  isOpen={isOpen}
                  {...card}
                  {...config}
                  displayNoEditor={displayNoEditor}
                  useCondition={useCondition}
                  key={`card-${i}`}
                  isDraggable={isDraggable}
                  onDragStart={() => {
                    onDragStart(card, dragTargets);
                  }}
                  onDragEnd={() => onDragStart(null)}
                />
              );
            case "smbAssign":
              return (
                <MeasureSmbAssignBoardCard
                  isOpen={isOpen}
                  {...card}
                  {...config}
                  displayNoEditor={displayNoEditor}
                  useCondition={useCondition}
                  key={`card-${i}`}
                  isDraggable={isDraggable}
                  onDragStart={() => {
                    onDragStart(card, dragTargets);
                  }}
                  onDragEnd={() => onDragStart(null)}
                />
              );
            default:
              return <></>;
          }
        })
      )}
    </div>
  );
};
