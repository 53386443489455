import React from "react";

type TMenuItemProps = {
  onClick: (e: React.MouseEvent) => void;
  text: string | React.ReactNode;
};

export const MenuItem: React.FunctionComponent<TMenuItemProps> = ({
  onClick,
  text,
}) => {
  return (
    <li onClick={onClick} className="text-xs p-1 hover:bg-hover cursor-pointer">
      {text}
    </li>
  );
};
