import { IDataTableConfig } from "../components/DataTable/DataTable";
import { IFormatterTypes } from "./dataTable";
import { TMeasureQualityStates } from "./workflow";

export type TQualityResponse = {
  id: number;
  deliverDate: string;
  comment: string;
  updatedAt: string;
  userName: string;
  status: TMeasureQualityStates;
};

export interface IQualityControlTable extends IDataTableConfig {
  dataIndex: keyof TQualityResponse;
  formatter?: IFormatterTypes;
}

export const QualityControlTableHeader: IQualityControlTable[] = [
  {
    name: "Datum",
    dataIndex: "deliverDate",
    formatter: "Date",
    size: 100,
  },
  {
    name: "Bemerkung",
    dataIndex: "comment",
  },
  {
    name: "Benutzer",
    dataIndex: "userName",
    size: 150,
  },
  {
    name: "Status",
    dataIndex: "status",
    formatter: "Status",
    size: 40,
  },
];
