import React from "react";
import * as XLSX from "xlsx";

export const NoImportDetected: React.FC<{
  workBook: XLSX.WorkBook | null;
  isWorkBook: boolean;
}> = ({ workBook, isWorkBook }) => {
  if (!workBook || isWorkBook) {
    return null;
  }

  return (
    <div className="border-2 border-red-800 rounded-sm text-xl bg-red-500 text-white p-2 text-center">
      Es konnte keine Arbeitsmappe, die für einen import definiert ist, im Excel
      File gefunden werden.
      <br /> Bitte Arbeitsmappennamen überprüfen
      <br />
      <a
        rel={"noreferrer noopener"}
        target={"_blank"}
        className="smb-link text-xs m-1"
        href={
          "https://gitlab.com/smb-ag/dora13/fe-rv-vbbw-api/-/wikis/Excel-Import-Beispiel/Referenz-Dateien"
        }
      >
        Weiter Informationen in der importer Dokumentation
      </a>
    </div>
  );
};
