import axios from "axios";
import { useEffect, useState } from "react";
import { TStateResponse } from "../measure/useGetMeasureBoardData";
export type TSmbBoardResponse = {
  data: TStateResponse[];
  dataKey: string;
  user?: {
    id: number;
    firstname: string;
    lastname: string;
    qsTeam: "München" | "Stuttgart";
  };
  nrfSum?: number;
};

const intervall = 3 * 60 * 1000;

export const useGetBoardSmbData = (processId?: number) => {
  const [buildings, setBuildings] = useState<TSmbBoardResponse[] | null>(null);

  useEffect(() => {
    const process = async () => {
      setBuildings(null);
      const result = await axios.get("/smb/board/");
      setBuildings(result.data);
    };

    process();
    const timer = window.setInterval(() => {
      setBuildings(null);
      process();
    }, intervall);

    return () => {
      window.clearInterval(timer);
    };
  }, [processId, setBuildings]);

  return buildings;
};
