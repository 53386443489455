import { useGetCompanies } from "../../hooks/useGetCompanies";
import { TCompanyType } from "../../store/user/types";
import React from "react";

type TSelectionFilterProps = {
  filter: string;
  companyType: TCompanyType;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

type TSmbOptionProps = {
  companyType: TCompanyType;
};
const SmbOptions: React.FC<TSmbOptionProps> = ({ companyType }) => {
  if (companyType !== "smb") {
    return null;
  }

  return (
    <>
      <option value="myTasks">Meine Tasks</option>
      <option value="team|München">Team München</option>
      <option value="team|Stuttgart">Team Stuttgart</option>
    </>
  );
};

export const SelectionFilter: React.FC<TSelectionFilterProps> = ({
  filter,
  companyType,
  onChange,
}) => {
  const companies = useGetCompanies();

  if (companyType !== "smb" && companyType !== "leitung") {
    return null;
  }

  return (
    <select value={filter} onChange={onChange} className={"h-8"}>
      <option value="">Keine Filter</option>
      <SmbOptions companyType={companyType} />

      {companies.map((company) => {
        if (company.id !== 1 && company.id !== 2) {
          return (
            <option
              value={`${company.type}|${
                company.amt ? company.amt : company.id
              }`}
              key={`company-select-${company.id}`}
            >
              {company.name}
            </option>
          );
        }
        return null;
      })}
    </select>
  );
};
