import { Link } from "@reach/router";
import React from "react";
import { version } from "../../../package.json";
import { ROUTE_ACCOUNT, ROUTE_CHANGELOG } from "../../config/routes";

export const Footer: React.FunctionComponent = () => {
  return (
    <div className="bg-head h-6 text-white flex pt-1 justify-between pl-5 pr-5 text-xs">
      <div>&copy; F.M. digital GmbH {new Date().getFullYear()}</div>
      <div>
        Dora - Dokumentation Rahmenvertragsaufträge -
        <Link className="smb-link" to={ROUTE_ACCOUNT + ROUTE_CHANGELOG}>
          Version {version}
        </Link>
      </div>
    </div>
  );
};
