import { TBoardConfig, TCols } from "./measureBoard";
import {
  POINT_CLOUD_BILLED,
  POINT_CLOUD_CHECKED,
  POINT_CLOUD_DELIVERED,
  POINT_CLOUD_RECEIVE,
} from "./workflow";

const notStarted: TCols = {
  header: "Nicht geliefert",
  dataKey: "notDelivered",
  targetId: "notDelivered",
  cardType: "pointCloud",
};

const delivered: TCols = {
  header: "Geliefert",
  dataKey: "delivered",
  targetId: "delivered",
  cardType: "pointCloud",
};

const received: TCols = {
  header: "Empfangen",
  dataKey: "received",
  targetId: "received",
  cardType: "pointCloud",
};

const checked: TCols = {
  header: "Geprüft",
  dataKey: "checked",
  targetId: "checked",
  cardType: "pointCloud",
};

const billed: TCols = {
  header: "Abgerechnet",
  dataKey: "billed",
  targetId: "billed",
  cardType: "pointCloud",
};

export const POINT_CLOUD_BOARD_CONFIG: TBoardConfig = {
  firma: [
    {
      ...notStarted,
      isDraggable: true,
      dragTargets: ["delivered"],
      canSetTransferId: true,
      canSetTarget: true,
    },
    { ...delivered, historyState: POINT_CLOUD_DELIVERED },
    received,
    checked,
    billed,
  ],
  amt: [
    notStarted,
    delivered,
    received,
    {
      ...checked,
      historyState: POINT_CLOUD_CHECKED,
      isDraggable: true,
      dragTargets: ["billed"],
      canSetTransferId: true,
      canSetTarget: true,
    },
    {
      ...billed,
      historyState: POINT_CLOUD_BILLED,
    },
  ],
  leitung: [
    notStarted,
    {
      ...delivered,
      historyState: POINT_CLOUD_DELIVERED,
      isDraggable: true,
      dragTargets: ["received"],
      canSetTransferId: true,
      canSetTarget: true,
    },
    {
      ...received,
      historyState: POINT_CLOUD_RECEIVE,
      isDraggable: true,
      dragTargets: ["checked"],
      canSetTransferId: true,
      canSetTarget: true,
    },
    {
      ...checked,
      historyState: POINT_CLOUD_CHECKED,
      isDraggable: true,
      dragTargets: ["billed"],
      canSetTransferId: true,
      canSetTarget: true,
    },
    billed,
  ],
  smb: [
    notStarted,
    {
      ...delivered,
      historyState: POINT_CLOUD_DELIVERED,
      isDraggable: true,
      dragTargets: ["received"],
      canSetTransferId: true,
      canSetTarget: true,
    },
    {
      ...received,
      historyState: POINT_CLOUD_RECEIVE,
      isDraggable: true,
      dragTargets: ["checked"],
      canSetTransferId: true,
      canSetTarget: true,
    },
    {
      ...checked,
      historyState: POINT_CLOUD_CHECKED,
      isDraggable: true,
      dragTargets: ["billed"],
      canSetTransferId: true,
      canSetTarget: true,
    },
    billed,
  ],
};
