import React from "react";
import { IDataTableConfig } from "./DataTable";
import { TogglePaginationCells } from "./ToggleCells";
import { Icon } from "../Icon";

export type TPaginationProps = {
  count: number | null;
  limit: number;
  pagination?: boolean;
  toggleCell?: (field: unknown) => void;
  updateLimit?: (limit: number) => void;
  updatePage?: (page: number) => void;
  currentPage?: number;
  config: IDataTableConfig[];
};

const resultPerPage = [10, 20, 30, 40, 50];

export const Pagination: React.FunctionComponent<TPaginationProps> = ({
  limit,
  count = 0,
  currentPage = 0,
  config,
  toggleCell,
  updatePage,
  updateLimit,
  pagination: show = false,
}) => {
  if (show === false || !updateLimit || !updatePage) {
    return null;
  }

  const maxPage = count ? Math.ceil(count / limit) : 0;

  const backStyle = currentPage > 0 ? "cursor-pointer" : "opacity-30";
  const nextStyle = currentPage + 1 < maxPage ? "cursor-pointer" : "opacity-30";
  return (
    <div
      className="w-full bg-gray-300 border-l border-b border-r border-border p-3 flex rounded-b-lg justify-auto
    "
    >
      <div className="w-48 pt-1 flex">
        Gesamt Datensätze: <b>{count === null ? 0 : count}</b>
      </div>
      <div className="flex-1 text-center flex">
        <div className="ml-auto mr-auto flex">
          <span className="mr-4 pt-1">Seite</span>
          <div
            className="pt-1 mr-2"
            onClick={() => {
              if (currentPage > 0) {
                updatePage(0);
              }
            }}
          >
            <Icon
              size={6}
              icon="ANGLE_DOUBLE_LEFT_SOLID"
              className={`mr-2 ${backStyle}`}
            />
          </div>
          <div
            className="pt-1"
            onClick={() => {
              if (currentPage > 0) {
                updatePage(currentPage - 1);
              }
            }}
          >
            <Icon icon="ANGLE_LEFT_SOLID" className={backStyle} size={4} />
          </div>
          <div className="border border-border pt-0.5 pb-0.5 pl-1.5 pr-1.5 bg-white w-10 h-8 text-center">
            {currentPage + 1}
          </div>
          <div
            className="pt-1 mr-2"
            onClick={() => {
              if (currentPage + 1 < maxPage) {
                updatePage(currentPage + 1);
              }
            }}
          >
            <Icon icon="ANGLE_RIGHT_SOLID" className={nextStyle} size={4} />
          </div>

          <div
            className="pt-1"
            onClick={() => {
              if (currentPage + 1 < maxPage) {
                updatePage(maxPage - 1);
              }
            }}
          >
            <Icon
              icon="ANGLE_DOUBLE_RIGHT_SOLID"
              className={nextStyle}
              size={6}
            />
          </div>
          <div className="ml-4 w-16 text-left pt-1">von {maxPage}</div>
        </div>
      </div>
      <div className="flex">
        <div className="pt-1">Anzahl Datensätze pro Seite</div>

        <select
          value={limit}
          className="pr-5 text-xs ml-2 mr-3 w-14"
          onChange={(e) => {
            updateLimit(Number(e.target.value));
            updatePage(0);
          }}
        >
          {resultPerPage.map((v) => (
            <option value={v} key={`limit-${v}`}>
              {v}
            </option>
          ))}
        </select>
        <TogglePaginationCells config={config} toggleCell={toggleCell} />
      </div>
    </div>
  );
};
