import React from "react";
import { TMigrationSuccessProps } from "./MigrationPointCloudSuccess";

export const MigrationMessage: React.FC<TMigrationSuccessProps> = ({
  progress,
  data,
  template,
}) => {
  if (progress || !data.length || template !== "message") {
    return null;
  }

  return (
    <div className="bg-success p-10 text-center font-bold text-lg mt-4">
      {data[0].message}
    </div>
  );
};
