import * as React from "react";
import { Icon } from "../Icon";

type TModalCloseProps = {
  onClose?: () => void;
};

export const ModalClose: React.FunctionComponent<TModalCloseProps> = ({
  onClose,
}) => {
  if (!onClose) {
    return null;
  }

  return (
    // eslint-disable-next-line
    <button
      className="w-5 h-5 pt-1"
      onClick={onClose}
      role="button"
      data-testid="close-modal"
    >
      <Icon icon="TIMES_SOLID" />
    </button>
  );
};
