import React, { useEffect, useState } from "react";
import { TAufmassLoeschenData } from "./hooks/useAufmassLoeschen";
import { useVerifyAufmassDelete } from "../../../hooks/verify/useVerifyAufmassDelete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import {
  faExclamationCircle,
  faSync,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

type TRowProps = {
  force: number;
  toggleChecked: (path: string) => void;
  isChecked: boolean;
  setValid: (path: string) => void;
} & TAufmassLoeschenData;

export const DataRow: React.FC<TRowProps> = ({
  path,
  toggleChecked,
  isChecked,
  setValid,
  force,
}) => {
  const { buildingId, hasAufmass, hasPunktwolke } = useVerifyAufmassDelete(
    path,
    force
  );
  const [rowClassName, setRowClassName] = useState("");
  const [aufmassClassName, setAufmassClassName] = useState("");
  const [punktwolkeClassName, setPunktwolkeClassName] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [aufmassIcon, setAufmassIcon] = useState(faSync);
  const [punktwolkeIcon, setPunktwolkeIcon] = useState(faSync);

  useEffect(() => {
    if (!buildingId) {
      setRowClassName("bg-red-500");
      setAufmassClassName("bg-red-500");
      setPunktwolkeClassName("bg-red-500");
      setDisabled(true);
      setAufmassIcon(faTimesCircle);
      setPunktwolkeIcon(faTimesCircle);
      return;
    } else {
      setRowClassName("");
      if (path) {
        setValid(path);
      }
    }

    if (!hasAufmass) {
      setAufmassClassName("bg-red-500");
      setAufmassIcon(faTimesCircle);
    } else {
      setAufmassIcon(faCheck);
      setAufmassClassName("bg-green-500");
      setDisabled(false);
    }

    if (!hasPunktwolke) {
      setPunktwolkeClassName("bg-yellow-500");
      setPunktwolkeIcon(faExclamationCircle);
    } else {
      setDisabled(false);
      setPunktwolkeIcon(faCheck);
      setPunktwolkeClassName("bg-green-500");
    }
  }, [
    path,
    buildingId,
    hasAufmass,
    hasPunktwolke,
    setRowClassName,
    setAufmassClassName,
    setPunktwolkeClassName,
    setDisabled,
    setAufmassIcon,
    setPunktwolkeIcon,
    setValid,
  ]);

  if (!path) {
    return null;
  }

  return (
    <tr className={rowClassName}>
      <td className={`border border-border p-1`}>
        <input
          type={"checkbox"}
          checked={isChecked}
          onChange={() => toggleChecked(path)}
          disabled={isDisabled}
          className={isDisabled ? "opacity-20" : ""}
        />
      </td>
      <td className={`border border-border p-1`}>{path}</td>
      <td
        className={`border border-border p-1 text-center ${aufmassClassName}`}
      >
        <FontAwesomeIcon icon={aufmassIcon} />
      </td>
      <td
        className={`border border-border p-1 text-center ${punktwolkeClassName}`}
      >
        <FontAwesomeIcon icon={punktwolkeIcon} />
      </td>
      <td className={`border border-border p-1`}>
        <a
          href={`/building/${buildingId}/?close`}
          rel={"noopener noreferrer"}
          target={"_blank"}
          className={`smb-link ${!buildingId ? "invisible" : ""}`}
        >
          zum Gebäude
        </a>
      </td>
    </tr>
  );
};
