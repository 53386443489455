import { version } from "../../../package.json";
import {
  APP_DISABLE_AJAX_PROGRESS,
  APP_ENABLE_AJAX_PROGRESS,
  APP_SET_DATA,
  APP_SET_IS_MOBILE,
  APP_SHOW_LOGIN,
} from "./action";
import { AppActionTypes, AppState } from "./types";

const initialState: AppState = {
  hasData: false,
  showLogin: true,
  isMobile: false,
  ajaxProgress: false,
  version,
  loadingText: "",
  updateNotification: false,
};

export function appStateReducer(
  state = initialState,
  action: AppActionTypes
): AppState {
  switch (action.type) {
    case APP_SET_DATA:
      return {
        ...state,
        hasData: true,
      };
    case APP_SHOW_LOGIN:
      return {
        ...state,
        showLogin: action.payload,
      };
    case APP_SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    case APP_ENABLE_AJAX_PROGRESS:
      return {
        ...state,
        ajaxProgress: true,
        loadingText: action.payload,
      };
    case APP_DISABLE_AJAX_PROGRESS:
      return {
        ...state,
        ajaxProgress: false,
        loadingText: "",
      };
    default: {
      return state;
    }
  }
}
