import * as React from "react";
import { TContentType } from ".";
import { Spinner } from "../Spinner";

type TLoginLoadingProps = {
  content: TContentType;
};

export const LoginLoading: React.FunctionComponent<TLoginLoadingProps> = ({
  content,
}) => {
  if (content !== "loading") {
    return null;
  }

  return <Spinner size="large" center={true} testId="login-spinner" />;
};
