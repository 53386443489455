import axios from "axios";
import FileSaver from "file-saver";
import { useCallback, useState } from "react";
import { TReportType } from "../../config/reports";
import { TCompanyType } from "../../store/user/types";

export const useDownloadReport = (companyType: TCompanyType) => {
  const [inProgress, setProgress] = useState<TReportType[]>([]);
  const onDownload = useCallback(
    (report: TReportType) => {
      setProgress([...inProgress, report]);
      async function process() {
        const response = await axios(`/reports/${companyType}/${report}/`, {
          responseType: "blob",
        });

        FileSaver.saveAs(response.data, response.headers["x-file-name"]);
        setProgress(inProgress.filter((val) => val !== report));
      }

      setTimeout(process, 0);
    },
    [companyType, inProgress, setProgress]
  );

  return { onDownload, inProgress };
};
