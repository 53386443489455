import { useEffect, useState } from "react";
import axios from "axios";

export type TImport = {
  path: string;
  deadline: string;
};

export const useDeadlineImporterProcess = (
  data: TImport[],
  process: boolean
) => {
  const [progress, setProgress] = useState(false);

  useEffect(() => {
    if (!process) {
      return;
    }

    const request = async () => {
      try {
        await axios.post("/admin/import/deadline/", {
          data,
        });
      } catch (error) {
        if (axios.isAxiosError(error)) {
          // toast("Fehler beim import der Lieferzeiten", {
          //   intent: "danger",
          //   autoClose: 0,
          // });
        }
      }

      setProgress(false);
    };

    setProgress(true);
    request();
  }, [data, process, setProgress]);

  return progress;
};
