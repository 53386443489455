import React, { useEffect, useState } from "react";
import { TSmbToast } from "../../context/SmbTabsContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

type TToastItem = {
  remove: (id: number) => void;
} & TSmbToast;

export const ToastItem: React.FC<TToastItem> = ({
  message,
  intent,
  id,
  autoClose,
  remove,
}) => {
  const ref = React.createRef<HTMLDivElement>();

  const [color, setColor] = useState("bg-success");
  const [isFull, setFull] = useState<null | boolean>(null);

  useEffect(() => {
    let bg = "bg-success";

    if (intent === "warning") {
      bg = "bg-warning";
    } else if (intent === "error") {
      bg = "bg-danger text-white";
    }

    setColor(bg);
  }, [intent, setColor]);

  useEffect(() => {
    setTimeout(() => {
      if (!ref.current || isFull !== null) {
        return;
      }
      ref.current.style.maxHeight = "600px";

      const progress: HTMLDivElement | null =
        ref.current.querySelector(".progress");
      if (progress) {
        progress.style.width = "0";
      }
    }, 0);
  }, [ref, isFull]);

  useEffect(() => {
    if (isFull !== false || !id) {
      return;
    }
    remove(id);
  }, [isFull, id, remove]);

  if (!id) {
    return null;
  }

  return (
    <div
      onTransitionEnd={() => (isFull === null ? setFull(true) : setFull(false))}
      ref={ref}
      className={`transition-all duration-1000 ${color} py-2 px-6 overflow-hidden rounded-lg`}
      style={{ maxHeight: 0 }}
    >
      <div className={"flex"}>
        <div>{message}</div>
        <button className={"ml-3"} onClick={() => remove(id)}>
          <FontAwesomeIcon
            className="opacity-40 hover:opacity-100"
            style={{ transform: "translate(-50%, -50%)" }}
            icon={faTimes}
          />
        </button>
      </div>
      <div
        onTransitionEnd={() => {
          if (ref.current) {
            ref.current.style.maxHeight = "0";
          }
        }}
        className={`h-1 bg-gray-500 transition-all progress${
          !autoClose ? " hidden" : ""
        }`}
        style={{
          fontSize: "2px",
          width: "100%",
          transitionDuration: `${autoClose}ms`,
        }}
      >
        &nbsp;
      </div>
    </div>
  );
};
