import React, { useCallback, useState } from "react";
import { formatReadonly } from "./helper/formatter";

type TInputTextareaProps = {
  value: string;
  onChange: (val: string) => void;
  canEdit?: boolean;
};

export const InputTextarea: React.FC<TInputTextareaProps> = ({
  value,
  canEdit = true,
  onChange,
}) => {
  const [val, setVal] = useState(value);

  const onBlur = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      onChange(e.target.value);
    },
    [onChange]
  );

  if (!canEdit) {
    return <>{formatReadonly(value)}</>;
  }

  return (
    <>
      <textarea
        className={"w-full h-24 resize-none"}
        value={val}
        onChange={(e) => setVal(e.target.value)}
        onBlur={onBlur}
      />
    </>
  );
};
