import moment from "moment";
import React from "react";
import { useMaintanace } from "../../hooks/useMaintanace";
import { Button } from "../Button";
export const Maintenance: React.FunctionComponent = ({ children }) => {
  const { maintenance, isMaintenance, isBanner, closeBanner } = useMaintanace();

  if (
    maintenance === null ||
    (!isBanner && !isMaintenance) ||
    localStorage.getItem("skipMaintenance")
  ) {
    return <>{children}</>;
  }

  if (isBanner) {
    return (
      <>
        <div className="absolute top-0 left-0 right-0 h-12 bg-yellow-500 p-2 flex">
          <div className="font-bold flex-1 text-center mt-1">
            Eine Wartung ist am {moment(maintenance.date).format("LLL")} Uhr
            geplant. Geplante Dauer: {maintenance.duration} Std. In diesem
            Zeitraum ist kein Arbeiten möglich
          </div>
          <div>
            <Button
              buttonStyle="elevated"
              icon="TIMES_SOLID"
              onClick={closeBanner}
            />
          </div>
        </div>
        {children}
      </>
    );
  }

  return (
    <div className="absolute inset-0 bg-gray-300" style={{ zIndex: 9999 }}>
      <div className="text-center text-lg mt-10">
        Wir führen gerade Wartungsarbeiten durch.
        <br /> Die Anwendung wird voraussichtlich wieder um &nbsp;
        {maintenance.end} Uhr zur Verfügung stehen
      </div>
    </div>
  );
};
