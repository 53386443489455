import moment from "moment";
import React from "react";
import { TCommentResult } from "../../hooks/useGetComments";

type TCommentsProps = TCommentResult;

export const Comment: React.FunctionComponent<TCommentsProps> = ({
  comment,
  username,
  createdAt,
}) => {
  return (
    <div className="border-border border-b pt-3 w-full">
      <div className="text-sm">
        {comment.split("\n").map((line, i) => (
          <p key={`comment-${i}`}>{line}</p>
        ))}
      </div>
      <div className="text-xs text-right">
        Erstellt von: {username} am
        {moment(createdAt).format("LLL")}
      </div>
    </div>
  );
};
