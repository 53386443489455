import React, { useEffect, useState } from "react";
import { TQualityResponse } from "../../../config/qualityTable";
import {
  MEASURE_STATE_DECLINED,
  MEASURE_STATE_PTS_CORRECTED,
  MEASURE_STATE_PTS_UPLOAD,
} from "../../../config/workflow";
import { TWorkflowType } from "../../../hooks/useLastHistoryStatus";
import { TCompanyType } from "../../../store/user/types";
import { PtsModal } from "./PtsModal";
import { Button } from "../../Button";

type TStartPtsUploadProps = {
  workflowType: TWorkflowType;
  companyType?: TCompanyType;
  results: TQualityResponse[];
  buildingId: number;
  forceFetch?: () => void;
};

export const PtsStateButtons: React.FC<TStartPtsUploadProps> = ({
  workflowType,
  companyType,
  results,
  buildingId,
  forceFetch,
}) => {
  const [open, setIsOpen] = useState(false);
  const [buttonText, setButtonText] = useState<string>();
  const [ptsState, setPtsState] = useState<
    typeof MEASURE_STATE_PTS_UPLOAD | typeof MEASURE_STATE_PTS_CORRECTED
  >();

  useEffect(() => {
    const r = [...results];
    if (r.pop()?.status === MEASURE_STATE_DECLINED) {
      setButtonText("PTS erneut hochgeladen");
      setPtsState(MEASURE_STATE_PTS_CORRECTED);
    } else if (results.length === 0 && workflowType === "plausibilityCheck") {
      setPtsState(MEASURE_STATE_PTS_UPLOAD);
      setButtonText("Vorgang starten, PTS Upload erfolgt");
    } else {
      setPtsState(undefined);
    }
  }, [workflowType, results, setButtonText, setPtsState]);

  if (!ptsState || companyType !== "firma") {
    return null;
  }

  return (
    <div className="w-full">
      <Button
        intent="success"
        full={true}
        onClick={() => {
          setIsOpen(true);
        }}
      >
        {buttonText}
      </Button>
      <PtsModal
        isOpen={open}
        onClose={() => setIsOpen(false)}
        state={ptsState}
        workflowType={workflowType}
        buildingId={buildingId}
        forceFetch={forceFetch}
      />
    </div>
  );
};
