import React, { useCallback, useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { useNeuaufmassImporter } from "./hooks/useNeuaufmassImporter";
import { ImporterTableHeadCell } from "../ImporterTableHeadCell";
import { NeuaufmassRow } from "./NeuaufmassRow";
import { useMeasureOrderImporterProcess } from "../../../hooks/importer/useMeasureOrderImporterProcess";
import { Button } from "../../Button";

type TNeuaufmassProps = {
  workBook: XLSX.WorkBook | null;
  isWorkBook: (state: boolean) => void;
};

const HEADLINES: string[] = [
  "Pfad",
  "Wirtschaftseinheit (WE)",
  "Gebäude Nummer (GE)",
  "Bezeichnung",
  "Adresse",
  "NRF geschätzt",
  "NRF Aufmaß",
  "Kategorie",
  "Flächencluster",
  "Detailtiefe",
  "Los",
  "Leistung",
  "Datum Abruf",
  "Abruf Nr.",
  "Firma",
  "Wunschtermin",
  "Preis / m²",
  "AMT",
  "Schnitte und Ansichten",
  "Bemerkungen",
];

export const Neuaufmass: React.FC<TNeuaufmassProps> = ({
  workBook,
  isWorkBook,
}) => {
  const { isAufmassSheet, importData, defaultCheckable } =
    useNeuaufmassImporter(workBook);
  const [checkable, setCheckable] = useState<string[]>(defaultCheckable);
  const [toImport, setToImport] = useState<string[]>([]);
  const [force, setForce] = useState(Date.now);

  useEffect(() => {
    if (isAufmassSheet) {
      isWorkBook(true);
    }
  }, [isAufmassSheet, isWorkBook]);

  const process = useMeasureOrderImporterProcess(() => {
    setToImport([]);
    setForce(Date.now());
  });

  useEffect(() => {
    setCheckable(defaultCheckable);
  }, [defaultCheckable, setCheckable]);

  const checkableCallback = useCallback(
    (path: string, remove = false) => {
      if (remove) {
        setCheckable(checkable.filter((val) => val !== path));
      } else {
        setCheckable([...checkable, path]);
      }
    },
    [checkable, setCheckable]
  );

  const onImportClick = useCallback(() => {
    process(importData.filter((order) => toImport.includes(order.path ?? "")));
  }, [importData, process, toImport]);

  const checkboxChangeCallback = useCallback(
    (path: string, checked: boolean) => {
      if (checked) {
        setToImport([...toImport, path]);
      } else {
        setToImport(toImport.filter((val) => val !== path));
      }
    },
    [toImport, setToImport]
  );

  const toggleAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e.target.checked) {
        setToImport([]);
      } else {
        setToImport(checkable);
      }
    },
    [checkable, setToImport]
  );

  if (!isAufmassSheet) {
    return null;
  }

  return (
    <div>
      <div className="flex mb-4">
        <h1>Neuaufmaß / Nachaufmaß Import</h1>
        <Button
          intent={"success"}
          buttonStyle="outline"
          className="ml-4"
          disabled={!toImport.length}
          onClick={onImportClick}
        >
          Importieren
        </Button>
      </div>
      <table className="table-auto border-collapse border border-border">
        <thead>
          <tr>
            <th className="border border-border p-1">
              <input
                type="checkbox"
                onChange={toggleAll}
                checked={
                  toImport.length === checkable.length && checkable.length !== 0
                }
              />
            </th>
            {HEADLINES.map((val, index) => (
              <ImporterTableHeadCell
                headline={val}
                key={`deadline-headline-${index}`}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {importData.map((val, index) => (
            <NeuaufmassRow
              force={force}
              {...val}
              setCheckable={checkableCallback}
              isCheckable={checkable.includes(val.path ?? "")}
              onChange={checkboxChangeCallback}
              isChecked={toImport.includes(val.path ?? "")}
              key={`neuaufmass-import-${index}`}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};
