import React, { useEffect, useState } from "react";
import { useGetQsPersons } from "../../hooks/useGetQsPerson";
import { TSaveData } from "../MeasureEdit/MeasureEdit";
import { formatReadonly } from "../../helper/formatter";

type TTeamPersonSelectionProps = {
  qsPersonId: number;
  qsOfficeId: number;
  onChange: (val: TSaveData) => void;
  readOnlyValue: string;
  canEdit?: boolean;
};

export const TeamPersonSelection: React.FunctionComponent<
  TTeamPersonSelectionProps
> = ({ qsPersonId, qsOfficeId, canEdit = true, readOnlyValue, onChange }) => {
  const [value, setValue] = useState("");
  const persons = useGetQsPersons(canEdit);

  useEffect(() => {
    if (qsPersonId !== -1) {
      setValue(`${qsPersonId}|1`);
    } else if (qsOfficeId !== -1) {
      setValue(`${qsOfficeId}|0`);
    } else {
      setValue("");
    }
  }, [qsPersonId, qsOfficeId, setValue]);

  if (!canEdit) {
    return <>{formatReadonly(readOnlyValue)}</>;
  }

  return (
    <>
      <select
        value={value}
        onChange={(e) => {
          const result = e.target.value.split("|");
          const user = persons.find(
            (p) =>
              p.userId === Number(result[0]) && p.isUser === Number(result[1])
          );

          if (!user) {
            return;
          }
          setValue(e.target.value);

          if (user.isUser === 0) {
            onChange({
              qsPersonId: null,
              qsOfficeId: user.userId,
            });
          } else {
            onChange({
              qsPersonId: user.userId,
              qsOfficeId: null,
            });
          }
        }}
      >
        <option>Bitte wählen</option>
        {persons.map((person, i) => (
          <option
            value={`${person.userId}|${person.isUser}`}
            key={`qs-person-${i}`}
          >
            {person.qsPerson}
          </option>
        ))}
      </select>
    </>
  );
};
