import { useEffect, useState } from "react";
import axios from "axios";

export const useVerifyPontCloudByPathCompany = (
  company: string,
  path?: string,
  force = 0
) => {
  const [exists, setExists] = useState<boolean>();

  useEffect(() => {
    if (!path) {
      return;
    }

    const process = async () => {
      try {
        await axios.get(`/verify/point-cloud/?path=${path}&company=${company}`);
        setExists(true);
      } catch (e) {
        setExists(false);
      }
    };

    process();
  }, [path, company, force, setExists]);

  return exists;
};
