import axios from "axios";
import React, { useCallback, useContext, useState } from "react";
import { DeleteModal } from "./DeleteModal";
import { TCompanyType } from "../../../store/user/types";
import { SmbToastContext, TSmbToast } from "../../../context/SmbTabsContext";
import { Button } from "../../Button";

type TDeleteLastProps = {
  id: number;
  comment: string;
  userType: string;
  companyType: TCompanyType;
  forceFetch?: () => void;
};

export const DeleteLast: React.FunctionComponent<TDeleteLastProps> = ({
  id,
  comment,
  userType,
  companyType,
  forceFetch,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { addToast } = useContext(SmbToastContext);
  const deleteEntry = useCallback(async () => {
    let message: TSmbToast = {
      message: "Status gelöscht",
      intent: "success",
      autoClose: 3000,
    };
    try {
      await axios.delete(`/admin/workflow/${id}/`);
      setOpen(false);
      if (forceFetch) {
        forceFetch();
      }
    } catch (err) {
      message = {
        message: "Fehler beim löschen des Status",
        intent: "error",
        autoClose: 5000,
      };
    }
    if (addToast) {
      addToast(message);
    }
  }, [id, forceFetch, addToast]);

  if (!id || companyType !== "smb") {
    return null;
  }

  return (
    <>
      <DeleteModal isOpen={isOpen} onClose={() => setOpen(false)}>
        <div>
          {comment}
          <div className="text-danger">
            Soll der Eintag wirklich gelöscht werden? Dieser Vorgang kann nicht
            Rückgängig gemacht werden
          </div>
        </div>
        <div className="text-right mt-3">
          <Button
            onClick={() => setOpen(false)}
            text="Abbrechen"
            buttonStyle="elevated"
          />
          <Button
            intent="danger"
            text="Löschen"
            className="ml-3"
            onClick={() => deleteEntry()}
          />
        </div>
      </DeleteModal>
      <Button
        intent="danger"
        text="Letzten Eintrag Löschen"
        onClick={() => setOpen(true)}
      />
    </>
  );
};
