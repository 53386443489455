import React from "react";
import { Spinner } from "../Spinner";

type TLoaderProps = {
  loading: boolean;
};

export const Loader: React.FunctionComponent<TLoaderProps> = ({ loading }) => {
  if (!loading) {
    return null;
  }

  return (
    <div className="ml-auto mr-auto mt-3 mb-3 w-12" style={{ height: "120px" }}>
      <Spinner size={"normal"} />
    </div>
  );
};
