import { Icon } from "../Icon";

export const BoardHelp: React.FC = () => {
  return (
    <div className="text-xs">
      <p>
        <strong>Karten Farben:</strong>
      </p>
      <table className="border-collapse border border-gray-600 w-full">
        <tbody>
          <tr>
            <td className="bg-green-500 w-20 border border-gray-600">Grün:</td>
            <td className="border border-gray-600">
              Gebäude ist im Zeitrahmen
            </td>
          </tr>
          <tr>
            <td className="bg-yellow-300 border border-gray-600">Gelb:</td>
            <td className="border border-gray-600">
              Gebäude sollte abgeschlossen werden
            </td>
          </tr>
          <tr>
            <td className="bg-red-500 border border-gray-600">Rot:</td>
            <td className="border border-gray-600">
              Zeitrahmen für das Gebäude ist abgelaufen
            </td>
          </tr>
        </tbody>
      </table>

      <p>
        <strong>Icons:</strong>
      </p>
      <table className="border-collapse border border-gray-600">
        <tbody>
          <tr>
            <td className="w-20 border border-gray-600">
              <Icon icon="CHECK_CIRCLE_REGULAR" />
            </td>
            <td className="border border-gray-600">
              Anzahl der Qualitätsprüfung Zurückweisungen ist im Limit, gesamt
              unter 3.
            </td>
          </tr>
          <tr>
            <td className="border-gray-600">
              <Icon icon="CHECK_CIRCLE_SOLID" size={5} />
            </td>
            <td className="border border-gray-600">
              Hat das Limit von 3 Qualitätsprüfung Zurückweisungen erreicht.
            </td>
          </tr>
          <tr>
            <td className="border border-gray-600">
              <Icon icon="EXCLAMATION_TRIANGLE_SOLID" size={5} />
            </td>
            <td className="border border-gray-600">
              Hat das Limit vom 3 Qualitätsprüfung Zurückweisungen
              überschritten.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
